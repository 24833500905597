#notifications {
  position: absolute;
  width: 100vw;
  z-index: 102;
}

.header-message {
  text-align: center;
  background: transparent;
  transition: background 0.25s ease;
  position: relative;

  p:last-of-type {
    margin-bottom: 0;
  }

  &-close {
    position: absolute;
    top: rem-calc(12);
    right: rem-calc(24);
    cursor: pointer;
    display: none;
    font-size: 2em;
    line-height: 1;
    font-style: normal;
    font-weight: $global-weight-light;
  }

  &-content {
    padding: rem-calc(15 22);
  }

  &.dismissable {
    .header-message-close {
      display: block;
    }
  }

  /*
    ** Types of notifications
    */

  &.alert-danger {
    background: $alert;
    color: $white;

    .header-message-close {
      color: $white;
    }
  }

  &.alert-success {
    background: $primary;
    color: $white;

    .header-message-close {
      color: $white;
    }
  }

  &.promo-message {
    transition: background 0.25s ease, transform 0.3s ease;
    background: #ca1617;
    color: $white;
    //z-index: 3;
    transform: translateY(0);

    .header-message-close {
      color: $white;
    }

    &.go-down {
      transform: translateY(-100%);
      @media (max-width: 1024px) {
        transform: none;
      }
    }
  }
}