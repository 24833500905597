@import "../setup/settings";

.overlay-bubble-chat--tezenis {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 900;
}

.bubble-element--tezenis {
  position: fixed;
  bottom: rem-calc(18);
  z-index: 902;
  right: rem-calc(65);
  @include breakpoint(small down) {
    right: rem-calc(-25);
    bottom: rem-calc(160);
  }
  &.minipopup-interaction {
    bottom: rem-calc(140);
    z-index: 1001;
    @include breakpoint(medium down) {
      bottom: rem-calc(157);
    }
  }

  .botton-bubble {
    float: right;
    position: relative;
    color: $white;
    display: flex;
    &:hover {
      border-color: transparent;
    }
    .desk {
      display: flex;
      height: rem-calc(42);
      padding: rem-calc(0 20);
      background: #3b3b3b;
      border-radius: rem-calc(20);
      box-shadow: rem-calc(2 2 10) rgba(0, 0, 0, 0.3);
      align-items: center;
      @include breakpoint(small down) {
        display: none;
      }
    }
    .mobile {
      display: block;
      background: #949494;
      padding: rem-calc(8 30 8 15);
      border-radius: rem-calc(20);
      box-shadow: rem-calc(2 2 10) rgba(0, 0, 0, 0.3);
      @include breakpoint(medium) {
        display: none;
      }
      .show-for-checkout{
        display: none;
      }
    }
    img {
      padding-right: 10px;
    }
    span {
      display: inline-block;
      line-height: 1;
      font-weight: 400;
      margin-bottom: rem-calc(1);
    }
  }
  .bubble-block {
    padding: rem-calc(40);
    border: 1px solid $border-gray;
    display: none;
    background: $white;
    min-width: rem-calc(260);
    border-radius: rem-calc(8);
    position: relative;
    margin-bottom: rem-calc(50);
    box-shadow: rem-calc(2 2 10) rgba(0, 0, 0, 0.4);
    &.show {
      display: block;
      @include breakpoint(small down) {
        position: fixed;
        left: 0;
        bottom: 0;
        margin-bottom: -10px;
        z-index: 903;
        width: 100%;
      }
    }

    h4 {
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.3px;
      margin-bottom: rem-calc(25);
    }

    .bubble-service-list {
      margin-left: 0;
      list-style-type: none;
      margin-bottom: 0;
      li {
        padding: rem-calc(8 0);
        a {
          border: none;
          &.disabled {
            color: $black;
            text-decoration: none;
            opacity: 0.4;
          }
          &:hover {
            text-decoration: none;
            border: none;
          }
          span {
            display: inline-block;
            font-size: rem-calc(15);
            font-weight: 400;
            letter-spacing: 0.3px;
            line-height: 1;
            vertical-align: middle;
            &:first-of-type {
              padding-right: rem-calc(10);
            }
            &:last-of-type {
              padding-right: 0;
            }
            &.status {
              color: #00ab43;
              font-family: Poppins;
              font-weight: 600;
              font-size: rem-calc(10);
              text-transform: uppercase;
            }
            &.timetable {
              padding-left: rem-calc(10);
              font-weight: 500;
              font-size: rem-calc(11);
              color: #666;
              text-transform: uppercase;
              @include breakpoint(small down) {
                display: block;
                padding-left: rem-calc(54);
              }
            }
          }
        }
        img {
          padding-right: rem-calc(13);
        }
      }
    }
    .close-button-bubble {
      position: absolute;
      right: rem-calc(25);
      top: rem-calc(18);
      width: rem-calc(24);
      span {
        font-size: rem-calc(18);
        float: right;
      }
    }

    .icon-bubble-service {
      img {
        width: rem-calc(18);
        margin-right: rem-calc(8);
      }
    }
    .not-available-text {
      margin: 0;
      font-size: rem-calc(12);
      margin-left: rem-calc(25);
      display: block;
    }
    .loader {
      margin-left: rem-calc(25);
      margin-top: rem-calc(3);
      margin-right: rem-calc(9);
      border: 2px solid #f3f3f3; /* Light grey */
      border-top: 2px solid $black; /* Blue */
      border-radius: 50%;
      width: 15px;
      height: 15px;
      animation: spin 2s linear infinite;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    .availabilitySection {
    }
    .availabilityContainer {
      text-align: center;
      display: flex;
    }
    .availabilityText {
    }

    .mobile-version-phone {
      background: $light-gray;
      padding: rem-calc(3);
      border: 0;
    }
  }
}

.page[data-action="Product-Show"],
.page[data-action="Cart-Show"] {
  .bubble-element {
    display: none;
  }
}
.page[data-action="Search-Show"] {
  .bubble-element {
    display: inherit;
  }
}

.js-live-chat {
  .not-available-chat , .available-chat {
    font-family: Poppins;
    font-weight: 600 !important;
    font-size: rem-calc(10) !important;
    text-transform: uppercase;

    &.hidden {
      display: none !important;
    }
  }

  .not-available-chat {
    color: red;
  }
  .available-chat {
    color: #00ab43;
  }
}
