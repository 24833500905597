// MODAL POLICY PRIVACY
.privacy-policy-marketing-popup {
    z-index: 1001 !important;

    @include breakpoint(small down) {
        .form-request-title {
            width: 85%;
            padding-right: rem-calc(21);
        }
    }
}

.privacy-policy-profiling-popup {
    z-index: 1001 !important;

    @include breakpoint(small down) {
        .form-request-title {
            width: 85%;
            padding-right: rem-calc(21);
        }
    }
}

.form-request-title {
    font-size: rem-calc(20);
    line-height: rem-calc(26);
    margin-bottom: rem-calc(24);
    font-weight: 400;

    @include breakpoint(medium down) {
        font-size: rem-calc(16);
        line-height: rem-calc(22);
    }
}

.form-request-paragraph {
    font-size: rem-calc(14);
    margin-bottom: rem-calc(40);
}

.form-request-paragraph-no-margin {
    font-size: rem-calc(16);

    @include breakpoint(medium down) {
        font-size: rem-calc(14);
    }
}

.privacy-style {
    .content-page-wrapper {
        @include breakpoint(medium down) {
            padding: rem-calc(0 20);
        }

        @include breakpoint(large up) {
            padding-right: 5%;
        }
    }
}

.intro-content {
    text-align: justify;
}
.wrapperModal {
    &.regulation{
        .tingle-modal-box{
            max-height: unset;
            overflow: unset;
            .content-box-wrapper.privacy-style{
                max-height: 50vh;
                overflow-y: auto;
                @include breakpoint (large up){
                    padding-right: rem-calc(40);
                }
                @include breakpoint (medium down){
                    max-height: 65vh;
                }
                &::-webkit-scrollbar {
                    width: rem-calc(5);
                }

                &::-webkit-scrollbar-thumb {
                background-color: $medium-gray;
                border-radius: rem-calc(5/2);
                }
            }
        }
    }
    &.tingle-modal {
        z-index: 1001;
        .tingle-modal__close{
            &.regulation{
                position: absolute;
                top: rem-calc(12);
                right: rem-calc(30);
                @include breakpoint (medium down){
                    top: rem-calc(-3);
                    right: rem-calc(5);
                }
                .tingle-modal__closeIcon{
                    font-size: rem-calc(20);
                }
            }
        }

    }

    &.smartGiftModal{
        z-index: 9001;
    }

    .tingle-modal-box {
        max-width: rem-calc(700);
        max-height: rem-calc(780);
        height: auto;
        /* max-width: rem-calc(400);
        width: auto;
        max-height: rem-calc(780);
        height: auto;*/
        border-radius: rem-calc(2);

        .tingle-modal-box__content {
            padding: 0;

            .tingle-modal__close {
                display: block;
                float: right;
                padding-top: rem-calc(10);
                padding-right: -rem-calc(30);
                font-size: rem-calc(50);
                padding-right: rem-calc(10);
                margin-right: 0;
                z-index: 999;
                outline: none;
            }
        }

        .modalPrivacyPolicy {
            padding: rem-calc(40);

            @include breakpoint(medium down) {
                padding: rem-calc(24);
            }

            .modalTitle {
                font-size: rem-calc(18);
                font-weight: 600;
                text-align: center;
                padding: rem-calc(45 0);
            }

            .modalBody {
                font-size: rem-calc(13);
                font-weight: 400;
                text-align: left;

                p {
                    margin: 0;
                    padding: rem-calc(0 0 10 0);
                    letter-spacing: 0;

                    &:last-child {
                        padding: rem-calc(0);
                    }
                }
            }
            &.regulation{
                .title-regulation-modal{
                    font-size: rem-calc(18);
                    font-weight: 700;
                    padding-bottom: rem-calc(35);
                    @include breakpoint (medium down){
                        font-size: 14px;
                        font-weight: 600;
                        padding-left: 20px;
                        padding-top: 20px;
                        padding-bottom: 20px;
                    }
                }
            }
        }
    }

    .d-none {
        display: none;
    }

    .d-block {
        display: block;
    }

    .btn-modal-link {
        text-transform: uppercase;
        font-size: rem-calc(14);
        text-decoration: none;
        font-weight: 600;
    }

    .privacy-style {
        .content-page-wrapper {
            padding-right: 0;

            @include breakpoint(medium down) {
                padding-right: rem-calc(10);
            }
        }
    }

    &.order-status-new-form {
        background: rgba(130, 193, 189, 0.6);
        backdrop-filter: blur(50px);

        .tingle-modal-box__content {
            position: relative;
            height: 100%;

            @include breakpoint(large up) {
                height: auto;
            }

            .close-button {
                position: absolute;
                top: rem-calc(16);
                right: rem-calc(16);
                z-index: 1;
                width: rem-calc(24);
                height: rem-calc(24);
                margin: 0;
                cursor: pointer;

                @include breakpoint(large up) {
                    top: rem-calc(37);
                    right: rem-calc(40);
                }
            }
        }

        .tingle-modal-box {
            height: 100%;
            width: 100vw;
            border-radius: 0;
            box-shadow: none;

            @include breakpoint(medium up) {
                height: 90%;
            }

            @include breakpoint(large up) {
                height: auto;
                width: 90vw;
                max-width: rem-calc(996);
            }
        }

        .order-status-modal {
            height: 100%;

            @include breakpoint(large up) {
                height: auto;
            }

            form {
                height: 100%;

                @include breakpoint(large up) {
                    height: auto;
                }
            }

            .form-content {
                height: 100%;

                @include breakpoint(large up) {
                    height: auto;
                    display: flex;
                    flex-direction: row;
                }
            }

            .content-img {
                display: none;
                min-height: rem-calc(562);

                @include breakpoint(large up) {
                    display: block;
                    width: 50%;
                    flex: 0 0 50%;
                }

                .content-asset {
                    width: 100%;
                    height: 100%;
                }

                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .content-info {
                display: flex;
                flex-direction: column;
                height: 100%;

                @include breakpoint(large up) {
                    height: auto;
                    width: 50%;
                    flex: 0 0 50%;
                }
            }

            &__title {
                font-weight: 500;
                font-size: rem-calc(14);
                line-height: rem-calc(21);
                color: #1c1c1c;
                text-align: left;
                letter-spacing: rem-calc(0.2);
                padding: rem-calc(19 50 16 16);
                margin: rem-calc(0 0 24 0);

                @include breakpoint(large up) {
                    padding: rem-calc(40 70 16 40);
                    margin-bottom: rem-calc(8);
                }
            }

            &__text {
                display: block;
                font-weight: 400;
                font-size: rem-calc(14);
                line-height: rem-calc(21);
                color: #1c1c1c;
                text-align: left;
                letter-spacing: rem-calc(0.2);
                margin-bottom: 0;

                &--1 {
                    margin-bottom: rem-calc(24);
                }

                a {
                    text-decoration: underline;
                    font: inherit;
                }
            }

            &__required {
                display: block;
                font-size: rem-calc(10);
                line-height: rem-calc(15);
                letter-spacing: rem-calc(0.2);
                text-align: left;
                margin: rem-calc(-8 0 24 0);
                color: #646464;
            }

            .form-group {
                padding: rem-calc(0 16);

                @include breakpoint(large up) {
                    padding: rem-calc(0 40);
                }
            }

            .container-confirm-button {
                margin-top: auto;
                padding: rem-calc(16);
                border-top: 1px solid $light-gray;

                @include breakpoint(large up) {
                    padding: rem-calc(15 40 40 40);
                    border: none;
                }

                .button {
                    width: 100%;
                    margin: 0;
                }
            }
        }
    }
}

.left-align-cell {
    float: left;
    width: 70%;

    span {
        font-size: rem-calc(11);
        font-weight: 500;
    }

    &.disabled {
        color: $medium-gray !important;
        opacity: 0.5;
        pointer-events: none;
        text-decoration: none;
    }
}

.right-align-cell {
    float: right;
    width: 30%;
}

.privacy-cookie-policy {
    padding-top: rem-calc(20);
    font-size: rem-calc(11);
}


a {
    &.btn-modal-link {
        font-size: rem-calc(12);

        &.disable-link-privacy {
            &.disabled {
                color: $medium-gray !important;
                opacity: 0.5;
                pointer-events: none;
                text-decoration: none;
            }
        }
    }
}

.drawer {
    .policy-promotion-payment {
        display: none;
    }
}

.drawer {
    &.active {
        .policy-promotion-payment {
            display: block;

            .policy-payment {
                width: 100%;

                span {
                    font-size: rem-calc(11);
                    font-weight: 500;
                }

                &.disabled {
                    color: $medium-gray !important;
                    opacity: 0.5;
                    pointer-events: none;
                    text-decoration: none;
                }
            }

            p {
                padding-bottom: rem-calc(10);
                margin-bottom: 0;

                input {
                    padding-bottom: 0;
                    margin-bottom: 0;
                }
            }

            .right-align {
                padding-bottom: rem-calc(10);
                position: relative;
            }

        }
    }
}
