$include-fonts: false;


@import "../../setup/settings";


.banner-st-valentines {
    width: 100%;
    background-color: #F7F7F7;
    padding: rem-calc(22);
    min-height: rem-calc(100);

    .banner-content {
        max-width: rem-calc(980);
        margin:auto;

        button {
            margin-bottom: 0;
            margin-top: rem-calc(10);
        }
    }

    .banner-title {
        p {
            font-weight: bold;
            font-size: rem-calc(30);
            text-shadow: rem-calc(2) 0 #ed7199;
            line-height: rem-calc(30);
            letter-spacing: rem-calc(1.5);
            margin-bottom: rem-calc(2);
            @include breakpoint(small down) {
                font-size: rem-calc(24);
                text-align: center;
            }
        }
    }

    .banner-text {
        font-size: rem-calc(13);
        line-height: rem-calc(20);
        letter-spacing: rem-calc(0.65);
        p {
            margin-bottom: 0;
            @include breakpoint(small down) {
                text-align: center;
            }
        }
    }
}
