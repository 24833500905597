svg {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: -0.063em;
    width: 100%;
    height: 100%;
    fill: currentColor;

    &:not(:root) {
        overflow: hidden;
    }
}

.button,
a {
    i {
        display: inline-block;
        vertical-align: baseline;

        svg {
            margin-right: rem-calc($global-margin / 2);
        }
    }
}

.button, a, i {
    svg {
        width: 1.25em;
        height: 1.25em;

        &.outline {
            stroke-width: rem-calc(2);
            stroke: currentColor;
        }

        &.hollow {
            fill: transparent;
        }
    }

    &:hover {
        svg {
            &.hollow {
                fill: currentColor;
            }
        }
    }
}

i {
    display: inline-block;
    font-weight: normal;
    font-style: normal;

    svg {
        &.micro {
            width: rem-calc(12);
            height: rem-calc(12);
        }

        &.small {
            width: rem-calc(16);
            height: rem-calc(16);
        }

        &.medium {
            width: rem-calc(20);
            height: rem-calc(20);
        }

        &.large {
            width: rem-calc(24);
            height: rem-calc(24);
        }

        &.x-large {
            width: rem-calc(80);
            height: rem-calc(80);
        }
        
        &.extra-large {
            width: rem-calc(40);
            height: rem-calc(40);
        }
    }
}
.wishlist-icon{
    top:rem-calc(20);
    right:rem-calc(20);
}
