.alert[role="alert"] {
    padding: rem-calc(20);
    border: none;

    &.new-alert {
        padding: 0;
        background: transparent;
        color: $color-error;
        font-weight: 700;
    }

    &.alert-danger {
        border: rem-calc(1) solid $error;
        border-radius: 8px;
        color: $error;
    }

    &.alert-warning {
        background: rgba($warning, 0.06);
        color: darken($warning, 10%);
    }

    p {
        &:last-child {
            margin-bottom: 0;
        }
    }
}
