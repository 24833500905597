@import "../components/newsletterPopup";
@mixin newsletter-email {
  .input-label {
    position: absolute;
    top: rem-calc(5);
    left: rem-calc(0);
    font-weight: 400;
    font-size: rem-calc(12);
    letter-spacing: 0.03em;
    color: #FF6699;
    margin-bottom: 0;
    opacity: 0;
    transition: all .2s ease;
  }
  ::-webkit-input-placeholder {
    color: $black;
  }
  ::-moz-placeholder {
    color: $black;
  }
  :-ms-input-placeholder {
    color: $black;
  }
  :-moz-placeholder {
    color: $black;
  }
  .label-for-input {
    display: inline-block;
    padding-top: rem-calc(3);
    padding-left: rem-calc(17);
    font-size: rem-calc(12);
    font-weight: 400;
    color: #949494;
    letter-spacing: 0.3px;
  }
  #email-errors, #email-errors-newsletter-pd {
    margin-top: rem-calc(3);
    margin-bottom: 0;
    position: relative;
    .form-error.is-visible {
      text-transform: capitalize;
      display: inline-block;
      padding-top: .23077rem;
      padding-left: 0;
      font-size: .92308rem;
      font-weight: 400;
      color: $color-error;
      letter-spacing: 0.2px;
      position: absolute;
      bottom: 0;
      margin: 0;
    }
  }
}

.footer {
  .label-inside {
    margin-top: rem-calc(16);
    .form-control-label {
      font-size: rem-calc(12) !important;
    }
    .button-arrow-black {
      height: rem-calc(40);
      top: rem-calc(3);
      left: 100%;
      width: rem-calc(60);
      padding: rem-calc(10 19);
      background-color: $black;
      color: #fff;
      border: none !important;
      position: absolute;
    }
  }
}

.country-chooser-overlay-blur, .language-chooser-overlay-blur {
  width: 100%;
  height: calc(100vh - 110px);
  top: 0;
  left: 0;
  background-color: rgba(130,193,189,0.6);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  position: fixed;
  z-index: 10;
  display: none;
}

.footer {
  &.login-reg-footer{
    background-color: white;
    border-top: 1px solid $border-gray;
    padding: rem-calc(13 22);

    @include breakpoint(medium up) {
      position: fixed;
      bottom: 0;
      width: 100%;
    }

    .text-footer{
      font-size: rem-calc(11);
      color: #949494;
    }
  }
  .button {
    border-radius: rem-calc(40);
  }
  position: relative;
  @include breakpoint(medium down) {
    margin-top: rem-calc(24);
  }

  &.chat-container {
    cursor: pointer;
  }

  .close-button {
    position: absolute;
    top: rem-calc(40);
    right: rem-calc(40);
    cursor: pointer;
    @include breakpoint(small only) {
      top: rem-calc(32);
    }
  }

  .medium-border-right,
  .medium-border-bottom {
    @media screen and (max-width:1024px) {
      border-bottom: none !important;
    }
  }

  &-overlay {
    width: 100%;
    left: 0;
    bottom: rem-calc(54);
    position: absolute;
    z-index: 11;
    border-bottom: rem-calc(1) solid $light-gray;

    @include breakpoint(small only) {
      left: 0;
      bottom: 0;
      position: fixed;
    }
    &-container {
      z-index: 4;
      background-color: #fff;
    }
    &.country-overlay {
      @include breakpoint(small only) {
        bottom: auto;
        top: 0;
      }
    }
    & .language-selector {
      @include breakpoint(medium up) {
        width: 100%;
        left: 45%;
        bottom: 0;
        position: relative;
        background-color: #fff;
        max-width: rem-calc(371);
      }
      @include breakpoint(large up) {
        left: 25%;
      }
    }
    & .footer-inner-content {
      margin-top: 0;
      @include breakpoint(small down) {
        margin-top: rem-calc(32);
      }
    }

    & .keep-in-touch-panel {
      width: 33.45534407027818vw;
      padding: rem-calc(0 40 8 40);
      background-color: $over-gray;
    }

    & .footer-input-group {
      margin-top: rem-calc(30);
      margin-bottom: rem-calc(40);
    }
    & .footer-policy-condition {
      font-size: rem-calc(11);
      line-height: rem-calc(16);
      margin-bottom: rem-calc(10);
      a {
        display: inline;
      }
    }

    & .lang-selector {
      border-bottom: 0;
      padding: rem-calc(32px 40px 24px 40px);
    }

    & .country-selector {
      margin-top: rem-calc(36);
      overflow-y: auto;
      overflow-x: hidden;

      @include breakpoint(medium up){
        .four-columns {
          column-count: 4;
        }
      }

      @include breakpoint(small down) {
        -webkit-overflow-scrolling: touch;
        margin-bottom: 0;
      }

      &--tezenis {
        @include breakpoint(small down) {
          height: auto !important;
        }
        &.country-language-open{
            position: fixed;
            bottom: 0;
            width: 100%;
        }
      }

      &-legenda {
        padding: rem-calc(48 0 8 0);
        font-size: rem-calc(12);
        opacity: .6;
        img {
          margin-right: rem-calc(14);
        }
        @include breakpoint(small only) {
          padding: rem-calc(0 0 15 0);
        }
      }

      &.scrollbar-custom ul {
        height: 430px;
        gap: 0 16px;
        flex-flow: column wrap;
        @include breakpoint(small only) {
          flex-flow: column;
          height: 84vh;
        }
      }

      & li a {
        display: inline;
        left: rem-calc(14);
        font-size: rem-calc(12);
        font-weight: 400;
        .footer-check-flag {
              float: right;
              margin-right: rem-calc(15);
              margin-top: rem-calc(3);
        }
      }
    }

    & .country-selector li {
        padding-top: rem-calc(8);
        padding-bottom: rem-calc(8);
        border-bottom: rem-calc(1) solid $medium-light-gray;
        height: rem-calc(40);
        @include breakpoint(small only) {
          padding-top: rem-calc(16);
          height: rem-calc(48);
        }
        &:not(.js-country-line){
          @include breakpoint(large up) {
            width: 14.64128843338214vw;
          }
        }
        &.footer-check-flag-box {
          border-bottom: rem-calc(1) solid $black;
        }
      & .placeholder {
        width: 24px;
        height: 16px;
      }

      label {
        margin-bottom: 0;
        &.boolean-field {
          display: flex !important;
        }
      }
    }
    .box-right-form-country {
      height: 100%;
      .label-inside{
        .button-arrow-black{
          top: rem-calc(10);
        }
      }
    }
    .box-left-footer {
      padding: rem-calc(32 0 24 40);
      @include breakpoint(small only) {
        height: 100vh;
      }
    }
  }
  /*
  &-box {
    padding: rem-calc(24 0);

    @include breakpoint(medium up) {
      padding-top: rem-calc(36);
      padding-bottom: rem-calc(36);
    }

    &-small {
      padding-top: rem-calc(18);
      padding-bottom: rem-calc(18);
    }

    &-sides {
      padding-left: rem-calc(16);
      padding-right: rem-calc(16);

      @include breakpoint(medium up) {
        padding-left: rem-calc(40);
        padding-right: rem-calc(40);
      }
    }

    &-left {
      padding-left: rem-calc(16);

      @include breakpoint(medium up) {
        padding-left: rem-calc(40);
      }
    }

    &-right {
      padding-right: rem-calc(16);

      @include breakpoint(medium up) {
        padding-right: rem-calc(40);
      }
    }
  }
  */
  /*
  &-action-box {
    border-top: 1px solid $border-gray;

    &:nth-child(3),
    &:nth-child(4) {
      border-bottom: 1px solid $border-gray;
    }

    &:nth-child(odd) {
      border-right: 1px solid $border-gray;
    }

    @include breakpoint(medium up) {
      border-top: 0;
      border-bottom: 0;

      &:nth-child(3),
      &:nth-child(4) {
        border-top: 0;
        border-bottom: 0;
      }

      &:not(:last-child) {
        border-right: 1px solid $border-gray;
      }
    }
  }
  */
  /*
  &-contacts {
    .input-group {
      margin-bottom: 0;
    }
  }
  */

  &-bottom {
    &.footer-light-box{
      padding: rem-calc(16 19);
      & p {
        margin: 0;
        font-size: rem-calc(10);
      }
    }
    .cell {
      padding-bottom: rem-calc(24);
    }

    @include breakpoint(medium up) {
      padding: rem-calc(24 65);

      .cell {
        padding-bottom: 0;
      }
    }
  }
  /*
  &-links {
    @include breakpoint(medium up) {
      padding: rem-calc(24 0);
    }

    li {
      margin-bottom: rem-calc(8);
    }

    p {
      @include breakpoint(small down) {
        margin-bottom: 0;
      }
    }

    a {
      font-weight: $global-weight-dark;
    }
  }
  */
  /*
  &-actions {
    a {
      i {
        margin-right: rem-calc(12);

        @include breakpoint(small down) {
          display: block;
          margin-right: 0;
          margin-bottom: rem-calc(6);
        }
      }
    }
  }
  */

  &-change-country-social {
    .footer-box-small {
      padding-left: 0;
      cursor: pointer;
      @include breakpoint(small only) {
        border-left: 1px solid $light-gray;
        &:not(.js-language) {
          border-left: none;
        }
      }
      @include breakpoint(large up) {
        &:first-of-type {
          padding-left: 0;
        }
      }

      @include breakpoint(medium down) {
        border-right: none;
      }
    }

    a {
      display: block;
      position: relative;
    }
  }

  &-social {
    min-height: rem-calc(68);

    a {
      &:not(:last-of-type) {
        margin-right: rem-calc(16);
      }
    }
  }

  .icon-country {
    margin-right: rem-calc(8);
  }


  &-actions,
  &-change-country-social,
  &-bottom {
    p {
      margin-bottom: rem-calc(24);
      letter-spacing: 0.2px;
      &.strong {
        margin-bottom: rem-calc(16);
        font-weight: 500;
      }
    }
  }

  @include borders($border-gray, medium up);

  &--tezenis {
    h3 {
      font-size: rem-calc(16);
      font-weight: 600;
      letter-spacing: 0.3px;
      margin-bottom: rem-calc(17);
      @media screen and (max-width:1024px) {
        font-size: rem-calc(16);
      }
    }
    .footer-full {
      &__top {
        &--full {
          &.grid-container {
            @include breakpoint(small down) {
              padding-left: 0;
              padding-right: 0;
            }
          }
          .form-item{
            .label-inside {
              margin-top: 0;
              padding: rem-calc(20 0 0 0)
            }
          }
        }
        ul {
          margin-left: 0;
          list-style-type: none;
          margin-bottom: 0;
          li {
            padding: rem-calc(7 0);
            @media screen and (max-width:1024px) {
              padding: rem-calc(6 0);
            }
            a {
              border: none;
              &.disabled {
                color: $black;
                text-decoration: none;
                opacity: 0.4;
              }
              &:hover {
                text-decoration: none;
                border: none;
              }
              span {
                display: inline-block;
                font-size: rem-calc(14);
                font-weight: 400;
                letter-spacing: 0.3px;
                line-height: 1;
                vertical-align: middle;
                @media screen and (max-width:1024px) {
                  font-size: rem-calc(14);
                }
                &:first-of-type {
                  padding-right: rem-calc(10);
                }
                &:last-of-type {
                  padding-right: 0;
                }
                &.status {
                  color: #00ab43;
                  font-family: Poppins;
                  font-weight: 600;
                  font-size: rem-calc(10);
                  text-transform: uppercase;
                }
                &.timetable {
                  padding-left: rem-calc(10);
                  font-weight: 500;
                  font-size: rem-calc(11);
                  color: #666;
                  text-transform: uppercase;
                  @media screen and (max-width:1024px) {
                    display: block;
                    padding-left: rem-calc(44);
                  }
                }
              }
            }
          }
          svg {
            width: rem-calc(36);
            height: rem-calc(36);
            margin-right: rem-calc(14);
            @media screen and (max-width:1024px) {
              transform: scale(0.85);
              transform-origin: left;
              margin-right: rem-calc(3);
            }
          }
        }
        #mobile-view {
          p {
            font-size: rem-calc(14);
            font-weight: 500;
            letter-spacing: 0.2px;
            margin-bottom: rem-calc(8);
            @include breakpoint(small only) {
              font-weight: 600;
            }
          }
        }
        #newsletterFooterForm {
          .input-group {
            margin-bottom: 0;
          }
          @include newsletter-email;
          svg {
            width: rem-calc(17);
            height: rem-calc(12);
            position: absolute;
            top: 50%;
            left: rem-calc(17);
            transform: translateY(-50%);
            @media screen and (max-width:1024px) {
              left: rem-calc(10);
            }
          }
        }
        .evidence-cell-world,
        .evidence-cell,
        .help-cell {
            padding: rem-calc(30 0);
            @media screen and (max-width:1024px) {
              padding-left: rem-calc(35);
              padding-right: rem-calc(35);
              padding-bottom: rem-calc(10);
            }
        }
        .evidence-cell,
        .help-cell {
            @media screen and (max-width:768px) {
              display: none;
            }
        }
        .loyalty-banner-cell{
          padding-right: rem-calc(8);
          .content-asset{
            width: 100%;
            height: 100%;
          }
          .img-loyalty-banner{
            position: relative;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 44%;
            width: 100%;
            height: 100%;
          }
          .text-loyalty-banner{
            position: absolute;
            bottom: rem-calc(19);
            left: rem-calc(19);
            right: 30%;
            @media screen and (min-width:1500px) {
              right: 40%;
            }
            h4{
              font-size: rem-calc(18);
              font-weight: 700;
              margin-bottom: rem-calc(12);
              color: white;
              letter-spacing: 0.3px;
            }
            .backgrounded-paragraph-link{
              padding: rem-calc(14);
              p{
                font-size: rem-calc(12);
                font-weight: 300;
                color: white;
                letter-spacing: 0.3px;
              }
              a{
                font-size: rem-calc(14);
                font-weight: 600;
                color: white;
                border-bottom: rem-calc(2) solid white;
                &:hover{
                  border-color: transparent;
                }
              }
            }
          }
        }
        .newsletter-cell {
          padding: rem-calc(40 20 0 40);
          @include breakpoint(ipad down) {
            padding-left: rem-calc(16);
            padding-right: rem-calc(16);
          }
        }
        .storelocator-cell {
          padding: rem-calc(40 40 0 20);
          @include breakpoint(ipad down) {
            padding: rem-calc(16 16 8 16);
          }
        }
      }
      &__middle {
        padding: rem-calc(20 0 40);
        @media screen and (max-width:1024px) {
          padding: rem-calc(30 0 40);
        }
        @media screen and (max-width:768px) {
          padding: rem-calc(30 0);
        }
        @include breakpoint(small only) {
          padding: rem-calc(45 0 20);
        }
        &--full {
          padding: rem-calc(20 40 40 40);
          @include breakpoint(ipad down) {
            padding: 0;
          }
          .arrow-down-social {
            position: absolute;
            right: 17px;
            margin-top: rem-calc(21);
            @include breakpoint(large up) {
              display: none;
            }
          }
          .accordion-item {
            &:not(:last-of-type) {
              padding-right: rem-calc(38);
            }
            &.active {
              .arrow-down-social{
                transform: rotate(180deg);
              }
            }
            @include breakpoint(ipad down) {
              &:first-of-type {
                border-top: 1px solid $light-gray;
              }
              padding-right: rem-calc(16) !important;
            }
          }
          .accordion-title {
            @include breakpoint(ipad down) {
              padding: rem-calc(20 0 20 16);
              border-bottom: none;
              margin-bottom: 0;
            }
            span {
              font-size: rem-calc(14);
            }
          }
          ul.accordion-content {
            @include breakpoint(ipad down) {
              border-bottom: none;
              margin-bottom: 0;
              padding-left: rem-calc(32);
            }
            li {
              padding-bottom: rem-calc(16);
              @include breakpoint(ipad down) {
                padding: rem-calc(0 0 20 0);
                border-bottom: none !important;
              }
              &:last-of-type {
                @include breakpoint(large up) {
                  padding-bottom: 0;
                }
              }
              a {
                font-size: rem-calc(14);
                font-weight: 400;
              }
            }
          }
          &.open-country-language{
            margin-bottom: rem-calc(111);
          }
        }
        
        .footer-social {
          @include breakpoint(ipad down) {
            position: relative;
            min-height: auto;
            margin: rem-calc(32 0);
          }
          p {
            span {
              font-weight: 600;
              font-size: rem-calc(14);
            }
          }
          ul {
            @include breakpoint(ipad down) {
              justify-content: center;
            }
          }
          a {
            &:not(:last-of-type) {
              margin-right: rem-calc(16);
              @include breakpoint(ipad down) {
                margin-right: rem-calc(24);
              }
            }
            i {
              font-size: rem-calc(20);
            }
          }
        }
      }
      &__bottom {
        padding: rem-calc(0 40);
        background-color: #fff;
        @include breakpoint(small only) {
          padding: rem-calc(0 0 0 16);
        }
        &.footer-change-country-social {
          border-top: 1px solid $light-gray;
          border-bottom: 1px solid $light-gray;
          @include breakpoint(ipad up) {
            height: rem-calc(56);
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
          @include breakpoint(ipad down) {
            border-top: none;
            height: rem-calc(56);
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
          .footer-box-small {
            max-width: 50%;
            @include breakpoint(medium down) {
                overflow: hidden;
                text-overflow: ellipsis;
            }
          }
          .arrow-down-footer {
            transform: rotate(180deg);
            padding: rem-calc(0 17);
            @include breakpoint(small only) {
              float: right;
              margin-top: rem-calc(4);
            }
          }
          .arrow-toggle {
            .arrow-down-footer {
              transform: rotate(0deg);
            }
          }
          .js-country,
          .js-language {
            > span {
              position: relative;
              font-size: rem-calc(12);
              font-weight: 500;
              letter-spacing: 0.3px;
              @media screen and (max-width:1024px) {
                font-size: rem-calc(13);
              }
            }
          }
          .js-language {
            > span {
              padding-left: rem-calc(40);
            }
          }
          &.open-country-language{
            position: fixed;
            bottom: rem-calc(55);
            width: 100%;
            z-index: 12;
          }
        }
      }
      &__last {
        position: relative;
        background-color: #fff;
        padding: rem-calc(16 40);
        text-align: center;
        @include breakpoint(ipad up) {
          height: rem-calc(55);
        }
        @include breakpoint(small only) {
          padding-right: rem-calc(18);
          padding-left: rem-calc(18);
          margin-bottom:rem-calc(126);
        }
        p {
          margin-bottom: 0;
          &.small {
            &.dark {
              font-size: rem-calc(10);
              font-weight: 400;
              letter-spacing: 0.2px;
              color: #1C1C1C;
              padding-right: rem-calc(19);
              @include breakpoint(small only) {
                padding-right: rem-calc(0);
              }
            }
          }
        }
        img {
          max-height: rem-calc(17);
        }
        &.open-country-language{
          position: fixed;
          bottom: 0;
          width: 100%;
        }
      }
    }
    .footer-light {
      &__top {
        &.grid-container {
          @include breakpoint(small only) {
            padding-left: 0;
            padding-right: 0;
          }
        }
        .accordion--small--world,
        .accordion--small {
          @media screen and (max-width:1024px) {
            padding-left: rem-calc(35);
            padding-right: rem-calc(35);
          }
          @media screen and (max-width:768px) {
            padding-left: rem-calc(0);
            padding-right: rem-calc(0);
          }
        }
        .accordion--small--world {
          @include breakpoint(medium up) {
            padding-bottom: rem-calc(60);
          }

          @media screen and (max-width:1080px) {
            .accordion-item {
              padding-top: rem-calc(30);
            }
          }
        }
        .accordion-item {
          padding-top: rem-calc(60);
          padding-right: rem-calc(10);
          @media screen and (max-width:1024px) {
            padding-top: rem-calc(30);
          }
          @media screen and (max-width:768px) {
            width: 25%;
          }
          @include breakpoint(small only) {
            width: 100%;
          }
          &:first-of-type,
          &:nth-of-type(3) {
            @media screen and (max-width:768px) {
              padding-left: rem-calc(35);
              width: 50%;
            }
            @include breakpoint(small only) {
              padding-left: rem-calc(20);
              padding-right: rem-calc(20);
              padding-top: rem-calc(15);
              width: 100%;
            }
          }
          &:nth-of-type(2),
          &:nth-of-type(4) {
            @media screen and (max-width:768px) {
              padding-right: rem-calc(35);
              width: 50%;
            }
            @include breakpoint(small only) {
              padding-left: rem-calc(20);
              padding-right: rem-calc(20);
              padding-top: rem-calc(15);
              width: 100%;
            }
          }
          &:first-of-type {
            @include breakpoint(small only) {
              padding-top: rem-calc(50);
            }
          }
        }
        .accordion-title {
          @include breakpoint(small only) {
            border-bottom: none;
            margin-bottom: 0;
            padding: rem-calc(0 0 18);
          }
          &:after {
            @include breakpoint(small only) {
              transform: translateY(-71%);
              right: auto;
              font-size: rem-calc(20);
            }
          }
          span {
            font-size: rem-calc(16);
            letter-spacing: 0.3px;
            @media screen and (max-width:1024px) {
              font-size: rem-calc(15);
            }
            @include breakpoint(small only) {
              margin-right: 5px;
              font-size: rem-calc(14);
            }
          }
        }
        ul.accordion-content {
          @include breakpoint(small only) {
            margin-bottom: rem-calc(25);
            border-bottom: none;
          }
          li {
            padding-bottom: rem-calc(13);
            @media screen and (max-width:1024px) {
              padding-bottom: rem-calc(10);
            }
            @include breakpoint(small only) {
              padding-top: rem-calc(10);
              padding-left: 0;
              padding-right: 0;
              border-bottom: none !important;
            }
            &:last-of-type {
              @media screen and (min-width:768px) {
                padding-bottom: 0;
              }
            }
            a {
              font-size: rem-calc(14);
              font-weight: 300;
              @media screen and (max-width:1024px) {
                font-size: rem-calc(13);
              }
            }
          }
        }
        #mobile-view {
          p {
            font-size: rem-calc(16);
            font-weight: 600;
            letter-spacing: 0.3px;
            margin-bottom: rem-calc(21);
          }
        }
        #newsletterFooterForm {
          @media screen and (max-width:1024px) {
            max-width: 50%;
          }
          @include breakpoint(small only) {
            max-width: none;
          }
          .input-group {
            margin-bottom: 0;
          }
          @include newsletter-email;
          svg {
            width: rem-calc(17);
            height: rem-calc(12);
            position: absolute;
            top: 50%;
            left: rem-calc(17);
            transform: translateY(-50%);
            @media screen and (max-width:1024px) {
              left: rem-calc(10);
            }
          }
          #submitNewsletter {
            text-decoration: none;
            color: #fff;
            background-color: $black;
            height: calc(100% - 14px);
            top: 50%;
            transform: translateY(-50%);
            right: rem-calc(7);
            border-radius: rem-calc(3);
            @include breakpoint(small only) {
              padding-left: rem-calc(10);
              padding-right: rem-calc(10);
            }
          }
        }
        .newsletter-cell {
          display: flex;
          @media screen and (max-width:1024px) {
            order: -1;
            flex-direction: column;
          }
          &__wrapper {
            background-color: #fdcff2;
            padding: rem-calc(60 30 40);
          }
          h3 {
            display: none;
          }
        }
        .footer-social-world,
        .footer-social {
          flex-basis: rem-calc(60);
          @media screen and (max-width:1024px) {
            width: 100%;
            padding-left: 0;
            margin-top: 0;
            flex-basis: auto;
          }
          @include breakpoint(small only) {
            position: relative;
            min-height: auto;
            margin-top: rem-calc(45);
          }
          &:before {
            @include breakpoint(small only) {
              content: "";
              position: absolute;
              width: 100%;
              height: 1px;
              background-color: #e6e6e6;
              top: rem-calc(-7);
              left: 0;
            }
          }
          > div {
            height: 100%;
            @media screen and (max-width:1024px) {
              height: auto;
            }
          }
          ul {
            height: 100%;
            padding: rem-calc(30 0);
            @media screen and (max-width:1024px) {
              height: auto;
              justify-content: space-evenly;
              padding: 40px 20% 0;
            }
            @include breakpoint(small only) {
              padding: 30px 10%;
            }
            a {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              @media screen and (max-width:1024px) {
                display: inline-block;
                width: auto;
              }
              &:not(:last-of-type) {
                margin-right: 0;
              }
              i {
                font-size: rem-calc(19);
              }
            }
          }
        }
        .footer-social-world {
          ul {
            padding: 0;
            position: relative;
            top: rem-calc(30);
          }
          @media screen and (max-width:1080px) {
            order: -1;
            @include breakpoint (small down) {
              order: revert;
              margin-top: 3.46154rem;
            }
            width: 100%;
            padding-left: 0;
            margin-top: 0;
            flex-basis: auto;
            ul {
              position: revert;
              height: auto;
              justify-content: space-evenly;
              padding: 40px 20% 0;
              @include breakpoint(small only) {
                padding: 30px 10%;
              }
              a {
                display: inline-block;
                width: auto;
              }
            }
          }
        }
      }
    }
    .footer-advantages-row{
      border-bottom: 1px solid #e6e6e6;
      /*
      ul{
        &.footer-list-advantages{
          margin: 0;
          list-style: none;
          display: flex;
          padding: rem-calc(12 0);

          li{
            margin-right: rem-calc(30);
            flex-grow: 1;
            a{
              display: flex;
              align-items: center;
            }
            img{
              width: rem-calc(36);
              margin-right: rem-calc(16);
              @media screen and (max-width: 1290px) {
                margin-right: rem-calc(8);
              }
            }
            span{
              font-size: rem-calc(15);
              font-weight: 300;
            }
            @media screen and (max-width: 1290px) {
              margin-right: rem-calc(5);
            }
          }
        }
      }
      */
    }
  }
}

.scrollbar-custom {
  margin-right: rem-calc(5);
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: rem-calc(0.938em);
  @include breakpoint(small only) {
    padding-right: rem-calc(35);
  }

  &::-webkit-scrollbar {
    width: rem-calc(5);
  }

  &::-webkit-scrollbar-thumb {
    background-color: $medium-gray;
    border-radius: rem-calc(5/2);
  }
}

.custom-newsletter-popup-new {
  position: fixed;
  width: rem-calc(360);
  bottom: rem-calc(30);
  left: rem-calc(30);
  top: auto;
  right: auto;
  z-index: 10;
  border-radius: rem-calc(10);
  background: rgb(225,219,239);
  background: -moz-linear-gradient(0deg, rgba(225,219,239,1) 0%, rgba(239,185,226,1) 100%);
  background: -webkit-linear-gradient(0deg, rgba(225,219,239,1) 0%, rgba(239,185,226,1) 100%);
  background: linear-gradient(0deg, rgba(225,219,239,1) 0%, rgba(239,185,226,1) 100%);

  @media (max-width:800px) {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    left: 0px;
  }

  .custom-tingle-modal-wrapper{
    position: relative;
    width: 100%;
    height: 100%;

    .close{
      font-size: rem-calc(24);
      position: absolute;
      top: rem-calc(24);
      right: rem-calc(24);
      z-index: 2;
    }

    .close:hover{
      cursor: pointer;
    }

    .custom-tingle-modal-box{
      .custom-tingle-modal-box__content{
        padding: rem-calc(24);
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;

        .grid-x{
          width: 100%;
          padding: rem-calc(0);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: rem-calc(14);

          .heading{
            padding: rem-calc(40 0 24 0);
            margin-right: rem-calc(44);
            margin-bottom: 0;
          }

          .newsletter-description{
            margin-bottom: rem-calc(40);
            margin-right: rem-calc(40);
          }

          .button-open{
            display: block;
            width: 100%;
            padding-top: rem-calc(10);
            padding-bottom: rem-calc(10);
            margin-bottom: rem-calc(20);
            background-color: black;
            color: white;
            border-radius: rem-calc(30);
            text-align: center;
          }

          .button-open:hover{
            cursor: pointer;
          }
        }
      }
    }
  }
}

.new-checkout-review {
  &__footer {
    .footer {
      @include breakpoint(medium up) {
        color: #707070;
        &-bottom {
          padding: rem-calc(22 65);
        }
      }
      @include breakpoint(small down) {
        margin: rem-calc(0 0 40);
        padding: rem-calc(12);
        border-top: 1px solid #E6E6E6;
        .new-checkout-review__footer--cell {
          padding-bottom: 0;
        }
        p {
          font-size: rem-calc(9);
        }
      }
    }
  }
}

.store-locator-modal {
  z-index: 1022;
  .tingle-modal-box{
    width: 100%;
    max-height: 100vw;
    max-width: 100%;
    @include breakpoint(ipad down) {
      max-height: 100%;
      min-height: 100%;
    }
    &__content{
      padding: rem-calc(30 40);
      @include breakpoint(ipad down) {
        padding: rem-calc(20 16 0 16);
      }
    }
    .footer-modal-close{
      position: absolute;
      right: rem-calc(40);
      top: rem-calc(24);
      margin-right: 0;
      @include breakpoint(ipad down) {
        right: rem-calc(16);
        top: rem-calc(16);
      }
    }
    .store-locator-box{
      @include breakpoint(ipad down) {
        min-height: calc(100vh - 107px);
        flex-direction: column;
      }
      .map-canvas-modal {
        @include breakpoint(large up) {
          width: 66.39824304538799vw;
        }
      }
      .store-locator-box-text{
        @include breakpoint(large up) {
          padding-left: rem-calc(24);
        }
      }
    }
    .map-canvas-modal {
      width: 64vw;
      height: 100vh;
      margin: rem-calc(-30 0 0 -40);
      @include breakpoint(ipad down) {
        display: none;
        width: 100vw;
        min-height: rem-calc(260);
        max-height: calc(100vh - 410px);
        margin: rem-calc(0 0 0 -16);
      }
    }
    h4.page-title {
      font-size: rem-calc(14);
      line-height: rem-calc(21);
      padding-left: 0;
      margin: rem-calc(0 0 40 0);
      @include breakpoint(ipad down) {
        text-align: left;
      }
    }
    .card{
      margin: 0;
      .card-body {
        padding: rem-calc(0);
        .results {
          margin-top: rem-calc(0);
          @include scrollbars();
          @include breakpoint(large up) {
              overflow-y: auto;
              overflow-x: hidden;
              height: calc(53vh - 102px);
              margin: 0;
          }
          @include breakpoint(ipad down) {
              height: calc(58vh - 90px);
              overflow-x: hidden;
          }
          @media (max-width: 385px) {
              height: calc(48vh - 90px);
          }
          .store-result{
            padding-top: rem-calc(16);
            padding-bottom: rem-calc(32);
            @include breakpoint(ipad down){
              padding-bottom: rem-calc(24);
            }
            button{
              margin-right: rem-calc(16);
            }
            .store-details{
              .store-name {
                line-height: rem-calc(21);
                margin-top: 0;
                font-size: rem-calc(14);
                margin-bottom: rem-calc(4);
              }
              .store-map{
                font-weight: 400;
                font-size: rem-calc(12);
              }
              .store-hours{
                padding: rem-calc(16 0);
                &-list {
                  padding: rem-calc(16 0 0 0);
                  ul {
                    list-style: none;
                    margin: 0;
                    li {
                      font-size: rem-calc(12);
                      line-height: rem-calc(18);
                      margin-bottom: rem-calc(8);
                    }
                  }
                }
                .store-hours-current {
                  display: inline-block;
                  font-weight: 600;
                  .store-hours-open,
                  .store-hours-closed,
                  .store-hours-temporarily-closed {
                    &:after {
                      content: ' - ';
                      font-weight: 600;
                      color: $black;
                    }
                  }
                  .store-hours-open {
                      color: $water-green;
                  }

                  .store-hours-closed , .store-hours-temporarily-closed {
                      color: $black;
                  }
                }
                .toggle-store-hours-dropdown {
                  margin-left: rem-calc(11);
                  margin-top: rem-calc(0);
                  &.flip {
                      transform: rotate(180deg);
                  }
                }
              }
              .storelocator-phone{
                font-weight: 400;
              }
              .store-view-map-link{
                margin-left: rem-calc(24);
                & a{
                  font-weight: 400;
                }
              }
            }
          }
        }
      }
    }
    .store-locator-container{
      .boolean-field {
        margin-bottom: rem-calc(24);
        &:last-of-type{
          padding-left: 0;
        }
        .radio-label {
          font-size: rem-calc(12);
          line-height: rem-calc(18);
          padding-top: rem-calc(1);
        }
      }
      .store-locator-nearby-box {
        justify-content: space-between;
        .store-locator-nearby-button{
          @include breakpoint(large up){
            display: none;
          }
          .storeLocatorNearbyList{
            border-right: 1px solid $light-gray;
            padding-right: rem-calc(18);
            cursor: pointer;
          }
          .storeLocatorNearbyMap{
            padding-left: rem-calc(18);
            cursor: pointer;
          }
          .change-opacity{
            opacity: .3;
          }
        }
      }
      .card{
        margin-top: 0;
        margin-bottom: rem-calc(33);
        @include breakpoint(ipad down){
          margin-bottom: rem-calc(10);
        }
        .card-body{
          padding: 0;
          .detect-location {
            margin-left: 0;
            font-weight: 400;
            font-size: rem-calc(12);
            img{
              padding-right: rem-calc(12);
            }
          }
        }
        .form-item{
          .label-inside{
            margin-bottom: rem-calc(24);
          }
        }
      }
    }

  }
  .tingle-modal__close{
    display: none;
  }
  .store-locator-nearby{
    font-size: rem-calc(12);
    margin-bottom: rem-calc(24);
    @include breakpoint(ipad down){
      margin-bottom: rem-calc(20);
    }
  }
  .view-all-store{
    position: fixed;
    width: 33.5%;
    right: 0;
    bottom: rem-calc(30);
    padding: rem-calc(24 40 24 24);
    border-top: 1px solid $light-gray;
    margin-top: rem-calc(10);
    @include breakpoint(ipad down){
      position: sticky;
      width: 100vw;
      margin-left: rem-calc(-16);
      bottom: 0;
      padding: rem-calc(16);
      background-color: #fff;
      margin-top: 0;
    }
    a{
      width: 100%;
      margin: 0;
    }
  }
  .gm-style {
    font-family: 'Poppins';
    font-size: rem-calc(12);
    line-height: rem-calc(18);
    .gm-style-iw-c{
      padding: rem-calc(16 16 16 52) !important;
      transform: translate(-140px, 60px) !important;
      height: unset !important;
      max-height: unset !important;
      overflow: unset !important;
      border: 1px solid $water-green;
      box-shadow: none;
      @include breakpoint(medium down){
        transform: translate(-140px, -130px) !important;
      }
      button{
        display: none !important;
      }
    }
    .gm-style-iw-tc{
      display: none;
    }
    .gm-style-iw-d{
      overflow: unset !important;
      .store-pin-map-marker {
        float: left;
        margin-left: rem-calc(-36);
        img {
          max-width: rem-calc(20);
        }
      }
      .store-name {
        font-weight: 600;
        font-size: rem-calc(14);
        line-height: rem-calc(21);
        margin-bottom: rem-calc(8);
        border: none;
      }
      .store-map,
      .store-hours-map{
        font-size: rem-calc(12);
        line-height: rem-calc(18);
        margin-bottom: rem-calc(8);
        border: none;
      }
      & a {
        font-weight: 400;
      }
      .store-hours-map{
        font-weight: 600;
        .store-hours-open,
        .store-hours-closed,
        .store-hours-temporarily-closed {
          &:after {
            content: ' - ';
            font-weight: 600;
            color: $black;
          }
        }
        .store-hours-open{
          color: $black;
        }
      }
      .store-name-link{
        font-size: rem-calc(12);
        line-height: rem-calc(18);
      }
    }
  }
}

.privacyFlagEnable{
  .tingle-modal-box {
          /* The slider */
      .sliderToggle {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #646464;
          -webkit-transition: .4s;
          transition: .4s;
          width: rem-calc(48);
          height: rem-calc(24);
          @include breakpoint (medium down) {
              width: rem-calc(80);
              height: rem-calc(40);
          }
      }
      .sliderToggle:before {
          position: absolute;
          content: "";
          height: rem-calc(12);
          width: rem-calc(12);
          left: rem-calc(2);
          top: rem-calc(1.5);
          background-color: #FFFFFF;
          box-shadow: 2px 3px 6px rgba($color: #000000, $alpha: 0.16);
          transition: .4s;
          border-radius: 50%;
          @include breakpoint (medium down) {
              height: rem-calc(25);
              width: rem-calc(25);
              left: rem-calc(3);
              top: rem-calc(2.5);
          }
      }
      input:checked + .sliderToggle:before {
          transform: translateX(rem-calc(16));
          @include breakpoint (medium down) {
              transform: translateX(rem-calc(28));
          }
      }
      input:checked + .sliderToggle{
          background-color: #82C1BD;
      }
      .sliderToggle.round {
          border-radius: 34px;
      }
      /* The switch - the box around the slider */
      .switchToggle {
          position: relative;
          display: inline-block;
          width: rem-calc(47);
          height: rem-calc(28);
      }
  }
}


.pre-footer-main{
  margin: rem-calc(32);
  @include breakpoint (medium down) {
    margin: rem-calc(12);
  }
  &-box{
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: rem-calc(16);
    padding: rem-calc(32);
    @include breakpoint (medium down) {
      padding: rem-calc(16);
      margin-bottom: rem-calc(12) !important;
    }
    &-title{
      line-height: rem-calc(34);
      margin-bottom: rem-calc(8);
      @include breakpoint (medium down) {
        line-height: rem-calc(24);
        margin-bottom: rem-calc(6);
      }
    }
    &-text{
      width: 14.64128843338214vw;
      font-size: rem-calc(12);
      line-height: rem-calc(18);
      margin-bottom: rem-calc(16);
      @include breakpoint (ipad down) {
        width: 49.33333333333333vw;
        margin-bottom: rem-calc(22);
      }
    }
    .button {
      margin: 0;
    }
    .button-mobile-app{
      a{
        border-bottom: none;
        &:first-of-type{
          margin-right: rem-calc(8);
        }
      }
    }
  }
}

.embedded-service {
  .channelmenu, .fab{
    z-index: 12 !important;
  }
}


#modal-woosmap-suggestion {
  position: fixed;
  z-index: 1023;
  border: none;
}
#footer-woosmap-suggestion {
  border: none;
}
