$spinner-line-default-size: 3px;
$spinner-default-size: 32px;
$spinner-default-dash-size: 10px;

$spinner-line-small-size: 2px;
$spinner-small-size: 16px;
$spinner-small-dash-size: 8px;

.veil {
    position: fixed;
    z-index: 2100;
    text-align: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .underlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: .6;
        background-color: $white;
    }

    .spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-image: url('../images/tezenis-loader-2.gif');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        width: 30%;
        @include breakpoint(medium down) {
            width: 90%;
        }
    }
}

.landing_page_loader_img{
    width: 12vw;
}

.spinner {
    display: block;
    height: 15%;
    width: 19%;
    @include breakpoint(small down) {
        width: 40%;
    }

    /* span {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        height: $spinner-default-size;
        width: $spinner-default-size;
        clip: rect($spinner-default-dash-size, $spinner-default-size, $spinner-default-size, 0);
        animation: spinner-anim 1.5s linear infinite;

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            height: $spinner-default-size;
            width: $spinner-default-size;
            border: $spinner-line-default-size solid $primary;
            border-radius: 50%;
        }
    }

    &.small {
        height: $spinner-small-size;
        width: $spinner-small-size;

        span {
            height: $spinner-small-size;
            width: $spinner-small-size;
            clip: rect($spinner-small-dash-size, $spinner-small-size, $spinner-small-size, 0);

            &:after {
                height: $spinner-small-size;
                width: $spinner-small-size;
            }
        }
    } */
}

/* @keyframes spinner-anim {
    0%   { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
} */
