$include-fonts: false;

@import "setup/settings";
@import '../../node_modules/foundation-sites/scss/foundation';

@include foundation-global-styles;
@include foundation-xy-grid-classes;
@include foundation-forms;
// @include foundation-accordion;
@include foundation-button;
@include foundation-typography;
@include foundation-visibility-classes;
@include foundation-float-classes;
// @include foundation-sticky;
@include foundation-flex-classes;
// @include foundation-reveal;

@import "mixins/helpers";

@include extend-palette($brand-palette);
@include helpers();
@include full-container();

@import "layout/layout";

@include brand-buttons($buttons-palette-black-text, $black);
@include brand-buttons($buttons-palette-white-text, $white);

@include brand-text($brand-palette);
@include brand-links($brand-palette);

@import "components/components";

@import "plugins/slick";
@import "plugins/foundation-datepicker";
@import "plugins/paypal";

@import "pages/pdp/ecard";
@import "components/newsletter.scss";
@import "pages/content";
@import "plugins/woosmap";
@import "plugins/swiper";
@import "components/personalization";

body {
    font-size: rem-calc(14);
}

html[lang="ru-RU"], html[lang="uk-UA"], html[lang="ru-UA"] {
    body, h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
        font-family: 'Noto Sans', sans-serif;
    }
}

.tingle-modal.tingle-modal--visible.multilanguage-popup {
    z-index: 99999999;
}

* {
    @media screen and (max-width: 1024px) {
        -webkit-tap-highlight-color: transparent;
    }
}
