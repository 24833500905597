input:is([type="button"], [type="submit"], [type="reset"]), input[type="file"]::file-selector-button, button {
    color: inherit;
}

[type='text'],
[type='password'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='month'],
[type='week'],
[type='email'],
[type='number'],
[type='search'],
[type='tel'],
[type='time'],
[type='url'],
[type='color'],
textarea {
    &::placeholder {
        color: $medium-gray;
    }

    &:disabled {
        background-color: $white;
    }

    &.height-text-area {
        margin-bottom: rem-calc(8);
        min-height: 7.76923rem;
        resize: none;
    }
}

.input-label {
    display: inline-block;
    margin-bottom: 0.25rem;
    font-weight: 400;
    letter-spacing: rem-calc(1.3);
}

.form-error,
.invalid-feedback,
.unknown-email-domain {
    display: none;
    margin-top: rem-calc(4);
    margin-bottom: 0;
    margin-left: 0;
    font-size: rem-calc(12);
    font-weight: 400;
    color: $color-error;
    letter-spacing: rem-calc(0.2);
}

.is-invalid-input:not(:focus):not(.boolean-field),
input.is-invalid:not(:focus),
select.is-invalid:not(:focus),
textarea.is-invalid:not(:focus) {
    &:not(#packages-frm),
    &:not(#province-frm){
        background-color: transparent;
    }
}

.form-item {
    margin-bottom: rem-calc(16);

    .text-area-label {
        top: rem-calc(16);
        left: rem-calc(16);
        font-size: rem-calc(12);
        line-height: rem-calc(18);
        letter-spacing: rem-calc(0.2);
        color: $medium-gray;
        margin: 0;
        padding: 0;
    }

    .height-text-area {
        padding: rem-calc(36 16 16 16);
        min-height: rem-calc(150);
    }
}

.form-item .label-inside,
.label-inside {
    border: none !important;
    margin-top: 0;
    margin-bottom: 0;
    padding: rem-calc(26 0 0 0);
    height: auto !important;
    position: relative;

    &:active,
    &:focus {
        border-bottom: 1px solid $black;
    }
    input, input.is-invalid-input, select, select.is-invalid-input {
        margin: 0;
        padding: rem-calc(0 0 8 0);
    }

    select, select.is-invalid-input {
        padding-right: rem-calc(20);
    }

    .input-label {
        color: $medium-gray;
        font-style: italic;
        padding-right: rem-calc(8);
        line-height: 1;
    }

    .form-control-label,
    .form-control-label-select {
        display: block;
        font-size: rem-calc(12);
        line-height: rem-calc(18);
        min-height: rem-calc(18);
        color: $medium-gray;
        padding: 0;
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        position: absolute;
        top: 0;
        left: 0;
    }

    .form-control-label-select {
        transform: none;
        line-height: rem-calc(18) !important;
    }

    input {
        border: none !important;
        border-bottom: 1px solid $black !important;
        margin: 0;
        padding: 0;
        height: auto;
        position: relative;
        top: 0;
        font-size: rem-calc(14);
        line-height: rem-calc(21);
        font-weight: 400;
        color: $black;
        padding-bottom: rem-calc(8);
        background: transparent;

        &:focus {
            border: 0;
        }

        &.is-invalid,
        &.is-invalid-input,
        &.parsley-error {
            border-bottom-color: $alert !important;
        }
    }

    select {
        display: block;
        border: none !important;
        border-bottom: 1px solid $black !important;
        margin: 0;
        font-size: rem-calc(14);
        line-height: rem-calc(21);
        height: rem-calc(29);
        padding-bottom: rem-calc(8);
        padding-right: 0;

        &.is-invalid,
        &.is-invalid-input,
        &.parsley-error {
            border-bottom-color: $alert !important;
        }
    }

    input:not(:placeholder-shown) ~ .form-control-label,
    select:not(:placeholder-shown) ~ .form-control-label-select {
        opacity: 1;
        top: 0;
    }

    select:not(:placeholder-shown){
        padding-right: 0;
    }

    &.is-invalid-label {
        border-bottom-color: $alert;

        .input-label {
            color: $alert;
        }

        input:not(:focus) {
            border: 0;
        }
    }

    .errors-list {
        margin-top: rem-calc(17);
    }
}


select {
    &:focus {
        outline: 5px auto -webkit-focus-ring-color;
    }

    option {

        &:checked,
        &:hover {
            box-shadow: 0 0 10px 100px $black inset;
        }
    }
}

label {
    &.inline {
        display: inline-block;
        margin-right: rem-calc(32);
        &.extra-small-margin {
            margin-right: 0;
            margin-bottom: rem-calc(3);
        }
    }
}

.is-invalid-label {

    select,
    input {
        border: 2px solid $alert;
        margin-bottom: rem-calc(15);
    }
}

.parsley-errors-list {
    list-style-type: none;
    margin-left: 0;
    margin-top: rem-calc(4);
    margin-bottom: 0;

    li {
        margin-bottom: 0;
        margin-left: 0;
        font-size: rem-calc(12);
        font-weight: 400;
        color: $error;
        position: relative;
    }
}

// Input Autocomplete
.autocomplete {
    /*the container must be positioned relative:*/
    position: relative;
    display: inline-block;

    input {
        margin-bottom: 0;
    }

    .parsley-errors-list {
        margin-top: $input-padding;
    }

    .autocomplete-items {
        position: relative;
        border: 1px solid $black;
        border-bottom: none;
        border-top: none;
        z-index: 101;
        /*position the autocomplete items to be the same width as the container:*/
        top: 100%;
        left: 0;
        right: 0;
    }

    .autocomplete-items div {
        padding: rem-calc(8);
        cursor: pointer;
        background-color: $white;
        border-bottom: 1px solid $black;
    }

    .autocomplete-items div:hover {
        /* When hovering an item */
        background-color: $light-gray;
    }

    .autocomplete-active {
        /* When navigating through the items using the arrow keys */
        background-color: $medium-gray !important;
        color: $white;
    }
}

.margin-field-right,  .invalid-feedback-right {
    @include breakpoint(medium up) {
        margin-right: rem-calc(10);
    }
}

.margin-field-left, .invalid-feedback-left {
    @include breakpoint(medium up) {
        margin-left: rem-calc(20);
    }
}

.grid-margin-right-2-field {
    @include breakpoint(medium up) {
        margin-right: rem-calc(20);

        .invalid-feedback {
            margin-right: rem-calc(20);
        }
    }
}

.change-email-form-error {
    display: block;
    margin-top: rem-calc(4);
    margin-bottom: 0;
    margin-left: 0;
    font-size: rem-calc(12);
    font-weight: $global-weight-normal;
    color: $error;
}

.counter-text {
    color: $medium-gray;
    display: block;
    font-size: rem-calc(10);
    font-weight: 400;
    line-height: rem-calc(15);
    margin-bottom: 0;
}
