@import "chosen-js/chosen";

.chosen-single {
    border: 0;
    margin-bottom: 0 !important;
    .chosen-container-single & {
        background-color: $white;
        border: 0;
        border-bottom: rem-calc(1) solid $black;
        border-radius: 0;
        padding: rem-calc(0 0 8 0);
        height: unset;
        transition: all 0.25s ease-in-out;
        font-size: rem-calc(14);
        line-height: rem-calc(21);
        font-weight: 400;
        background: unset;
        box-shadow: unset;

        &:not(.no-hover):hover {
            text-decoration: none;
        }
    }

    .chosen-container-active.chosen-with-drop & {
        box-shadow: unset;
        background-image: unset;
        border-radius: 0;
        border: 0;
        border-bottom: rem-calc(1) solid $black;
    }

    .chosen-container-active:not(.chosen-with-drop) &{
        border-bottom: rem-calc(1) solid $black;
    }

    .chosen-disabled & {
        cursor: $input-cursor-disabled;
        background-color: $white;
        color: $more-light-gray;
    }
}

.chosen-disabled {
    opacity: 1 !important;
}

.chosen-container {
    width: 100% !important;
    margin-bottom: 0;

    &:not(.chosen-container-active) {
        .chosen-single {
            span {
                color: $medium-gray;
            }
        }
    }

    .chosen-drop {
        border: 0;
        top: calc(100% + #{rem-calc(8)});
        box-shadow: rem-calc(0 2 8) rgba($black, 0.1);
        padding: rem-calc(0 3 0 0);
        border: rem-calc(1) solid $water-green-light;
    }

    .chosen-results {
        padding: rem-calc(0 5 0 0);
        margin: rem-calc(8 0);
        max-height: rem-calc(185);

        &::-webkit-scrollbar {
            width: rem-calc(2);
            padding: rem-calc(10 0);
        }

        &::-webkit-scrollbar-thumb {
            background-color: $light-gray;
            border-radius: rem-calc(3);
        }

        &::-webkit-scrollbar-track {
            background-color: $over-gray;
            border-radius: rem-calc(3);
            padding: rem-calc(10 0);
        }

        li {
            padding: rem-calc(8 16);
            font-size: rem-calc(12);
            line-height: rem-calc(18);

            &.highlighted {
                color: $black;
                background: $water-green-light;
            }
        }
    }
}

// Chosen images override
.chosen-rtl .chosen-search input[type="text"],
.chosen-container-single .chosen-single abbr,
.chosen-container-single .chosen-single div b,
.chosen-container-single .chosen-search input[type="text"],
.chosen-container-multi .chosen-choices .search-choice .search-choice-close,
.chosen-container .chosen-results-scroll-down span,
.chosen-container .chosen-results-scroll-up span {
    background-image: url("../../images/icon-arrow-dropdown.svg") !important;
    background-size: rem-calc(15) !important;
    background-repeat: no-repeat !important;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi), only screen and (min-resolution: 1.5dppx) {
    .chosen-rtl .chosen-search input[type="text"],
    .chosen-container-single .chosen-single abbr,
    .chosen-container-single .chosen-single div b,
    .chosen-container-single .chosen-search input[type="text"],
    .chosen-container-multi .chosen-choices .search-choice .search-choice-close,
    .chosen-container .chosen-results-scroll-down span,
    .chosen-container .chosen-results-scroll-up span {
      background-image: url("../../images/icon-arrow-dropdown.svg") !important;
      background-size: rem-calc(15) !important;
      background-repeat: no-repeat !important;
    }
}

.chosen-container-single .chosen-single div b {
    background-position: center;
    position: relative;
    right: 0;
    top: rem-calc(-3);
}

.chosen-container-active.chosen-with-drop .chosen-single div b {
    background-position: center;
}

select {
    background-image: url('../../images/icon-arrow-dropdown.svg');
    background-size: rem-calc(18) auto;
    background-position: right;
    letter-spacing: rem-calc(0.64);
}