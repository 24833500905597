.site-search {
    display: none;
    opacity: 0;
    position: fixed;
    z-index: 11;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba(130, 193, 189, 0.6);
    backdrop-filter: blur(rem-calc(45));

    .flex-column {
        display: flex;
        flex-direction: column;
        padding: 0;
        gap: rem-calc(24); 
    }
    
    .flex-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        padding: 0;
        gap: rem-calc(8);
    }
}


#search-overlay {
    position: fixed;
    z-index: 1;
    max-height: 100%;
    width: 100%;
    flex-direction: column;
    background: $white;
    opacity: 0;

    .search-bar {
        background: $white;
        padding: rem-calc(0 40);

        @include breakpoint(small down) {
            padding: rem-calc(0 16);
        }

        input[type='search'] {
            height: 100%;
            margin-bottom: 0;
            padding: 0;
            letter-spacing: rem-calc(0.3);
            font-size: rem-calc(26);
            font-weight: 600;
            border: none;
    
            // Hide WebKit cancel search button
            &::-webkit-search-cancel-button {
                -webkit-appearance: none;
            }
    
            &::placeholder {
                height: 100%;
                margin-bottom: 0;
                letter-spacing: rem-calc(0.3);
                font-size: rem-calc(26);
                font-weight: 600;
                color: #C4C0C0 !important;
            }
        }
    
        .closeSearch {
            font-size: rem-calc(24);
            cursor: pointer;
        }
    
        form {
            display: flex; 
            align-items: center;
            margin: rem-calc(40 0);
    
            @include breakpoint(small down) {            
                margin: rem-calc(40 0 32 0);
            }
        }    
    }
    
    .suggestions-wrapper {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        overflow-y: auto;
        background: $white;
        padding: rem-calc(0 40 40);

        &::-webkit-scrollbar {
            display: none;
        }

        @include breakpoint(small down) {
            padding: rem-calc(0 16 0 16);
            max-height: calc(100dvh - 39px - 40px - 32px); /* Minus the height of .search-bar form */
        }
    }

    .suggestions-container {
        width: 20%;

        @include breakpoint(small down) {
            width: 100%;
        }

        .flex-column { 
            gap: rem-calc(16);
        }
    }

    .products-container {
        width: 80%;
        
        @include breakpoint(small down) {
            width: 100%;
            margin-top: rem-calc(16);
        }
        
        .items-body {
            gap: rem-calc(16);

            @include breakpoint(small down) {
                gap: rem-calc(4);
            }
        }

        .product-card {
            width: calc(20% - 12.8px); // 12.8px is 16(gap)*4(spaces between products)/5(product cards)
        
            .color-swatches {
                display: none;
            }

            @include breakpoint(small down) {
                width: calc(50% - 2px); // 2px is 4(gap)*1(spaces between products)/2(product cards)
            }

            .quick-buy-sizes {
                display: none;
            }
        }
    }

    .items-header {
        font-weight: 600;
        font-size: rem-calc(16);
    }

    .view-all a {
        font-weight: 400;
        font-size: rem-calc(12);
        letter-spacing: rem-calc(0.2);
    }

    .highlights {
        .items-body {
            gap: rem-calc(16);
        }
        .card {
            border-radius: rem-calc(16);       
            width: 19.98535871156662vw;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            overflow: hidden;
            margin: 0;
            padding: 0;
            @include breakpoint (medium down) {
                width: 100%;
            }

            a {
                position: absolute;
                left: rem-calc(16);
                bottom: rem-calc(25);
            }
        }
    }
}