// head space for fixed nav
.page {
    padding-top: rem-calc(136);
    position: relative;

    @include breakpoint(medium up) {
        padding-top: rem-calc(160);
        // padding-left: rem-calc(20);
        // padding-right: rem-calc(20);
    }
    &.page-hero {
        padding-left: 0;
        padding-right: 0;
    }
}

.main-container {
    overflow-x: hidden;
}

.title-page-font-16 {
    font-size: rem-calc(18);
}


.flex-center {
    display: flex;
    justify-content: center;
}