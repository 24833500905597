.spacer {
    height: rem-calc(24);

    &.verysmall {
        height: rem-calc(8);
    }

    &.small {
        height: rem-calc(16);
    }

    &.medium {
        height: rem-calc(24);
    }

    &.large {
        height: rem-calc(32);
    }

    &.extra-large {
        height: rem-calc(40);
    }

    &.double-extra-large {
        height: rem-calc(48);
    }
}

.horizontal-spacer {
    height: rem-calc(24);

    &.verysmall {
        height: rem-calc(8);
    }

    &.small {
        height: rem-calc(16);
    }

    &.medium {
        height: rem-calc(24);
    }

    &.large {
        height: rem-calc(32);
    }

    &.extra-large {
        height: rem-calc(40);
    }

    &.double-extra-large {
        height: rem-calc(48);
    }
}

.divider {
    margin: 1rem 0;
    height: 1px;
    background-color: $light-gray;

    &.small {
        margin: 0.5rem 0;
    }

    &.medium {
        margin: 1rem 0;
    }

    &.large {
        margin: 2rem 0;
    }
}
