
    .datepicker {
        td{
            &.active{
                &.day, &.year, &.month { background-color: $water-green!important;}
            }
            span{
                &.active{
                    &.day, &.year, &.month { background-color: $water-green!important;}
                }
             }
        }
    }
