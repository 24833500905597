// Size guide

.grid-container.size-guide-container {
  padding: rem-calc(0 40);
  @include breakpoint(medium down) {
    padding: 0;
  }
}

.size-guide-container,
div[data-querystring^="cid=size-guide-menu"] {
  color: $black;

  .size-guide-title {
    display: block;
    font-weight: 600;
    font-size: rem-calc(32);
    line-height: rem-calc(36);
    padding: rem-calc(40 0 24 0);
    text-align: left;
    border-bottom: 1px solid #F2EDED;
    margin: rem-calc(0 0 24 0);

    @include breakpoint(small down) {
      font-size: rem-calc(20);
      line-height: rem-calc(30);
      padding: rem-calc(26 16 16 16);
      margin: rem-calc(0 0 16 0);
    }
  }

  .customer-service-list-style li {
    background: $over-gray;
    border-radius: rem-calc(8);
    padding-top: rem-calc(24);
    padding-bottom: rem-calc(24);
    margin-left: 0;
    font-size: 0;
    position: relative;

    a {
      font-size: rem-calc(16);
      line-height: rem-calc(24);
      text-transform: none;
      color: $black;
      position: static;

      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        top: 0;
        left: 0;
      }

      img.arrow-back {
        width: rem-calc(16);
        height: rem-calc(16);
      }
    }
  }

  .customer-service-page-cell-right {
    padding-left: rem-calc(150);

    @include breakpoint(medium down) {
      padding-left: 0;
    }
  }

  .tabs-container {
    @include breakpoint(small down) {
      padding: rem-calc(0 16);
    }
  }
  .tabs {
    padding: rem-calc(8 0);

    &.js-tab-selector {
      padding: rem-calc(8 0);
      border-bottom: 1px solid $light-gray;
      margin: rem-calc(-24 0 24 0) !important;

      @include breakpoint(small down) {
        margin: rem-calc(0 0 24 0) !important;
      }
    }

    li {
      border-bottom: none !important;
      line-height: rem-calc(33);
      margin: 0;

      &:last-child {
        border-right: none;
      }

      &.active {
        border-bottom: none;
        font-weight: 600;
      }

      &.current {
        color: $black;
      }

      a {
        color: inherit;
      }
    }

    .tab-link.current::before {
      top: auto;
      bottom: 0;
    }
  }

  .size-guide-page {

    .size-block-wrap {
      margin-top: 0;
    }
    .size-block:first-of-type {
      border-top: none;
    }

    .size-block-header {
      padding-top: rem-calc(24);
      padding-bottom: rem-calc(24);
    }

    .size-block-header-title {
      font-size: rem-calc(18);
      line-height: rem-calc(27);

      @include breakpoint(small down) {
        font-size: rem-calc(14);
        line-height: rem-calc(21);
      }
    }

    .size-block-content .cnt-text {
      font-size: rem-calc(14);
      line-height: rem-calc(21);
      letter-spacing: rem-calc(0.2);
      margin-bottom: 16px !important
    }

    .accordion_01_content_container {
      background: $over-gray !important;
      border-radius: rem-calc(8) !important;
      overflow: hidden;
      margin-bottom: rem-calc(24) !important;

      .text-container {
        order: -1;
        padding: rem-calc(40 24);

        @include breakpoint(small down) {
          order: 1;
          padding: rem-calc(16 16 24 16) !important;
        }

        .h1 {
          color: $water-green !important;
        }

        .h3 {
          font-size: rem-calc(16);
          line-height: rem-calc(24);
          margin-bottom: rem-calc(16);

          @include breakpoint(small down) {
            font-size: rem-calc(20) !important;
            line-height: rem-calc(30);
            margin-bottom: rem-calc(8) !important;
          }
        }

        .button {
          width: auto;
          margin: 0;
          margin-bottom: 0 !important;

          @include breakpoint(small down) {
            width: 100%;
          }
        }

        .cnt-row {
          .button {
            margin-right: 0;
            margin-bottom: rem-calc(16) !important;

            @include breakpoint(large up) {
              margin-right: rem-calc(16);
            }
          }
        }
      }

      .layer-login-modal {
        margin-top: rem-calc(32);
      }

      .new-footer-form-style:not(.checkout-style) input {
        margin-bottom: rem-calc(32);
        border-bottom: 1px solid #646464 !important;
        padding-left: 0;
        width: 100%;
      }

      .video-container {
        padding: 0 !important;

        @include breakpoint(small down) {
          margin-bottom: 0;
        }

        .cnt-videobox {
          position: relative;
          padding-bottom: 132%;
          height: 0;

          @include breakpoint(small down) {
            padding-bottom: 90%;
          }

          video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 0 !important;
          }
        }
      }

      .replay-btn-container {
        border-radius: 0;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }

  .guide-type__table {
    thead,
    tr:first-child {
      th {
        border-top: none;

        &:first-child {
          border-left: none;
        }

        &:last-child {
          border-right: none;
        }
      }
    }

    tbody {
      tr {
        th {
          border-left: none;
        }

        td {
          &:last-child {
            border-right: none;
          }
        }

        &:last-child {
          th, td {
            border-bottom: none;
          }
        }
      }
    }

    th {
      font-size: rem-calc(14);
      text-transform: none;
      font-weight: 500;
      letter-spacing: rem-calc(0.2);
      white-space: nowrap;
      height: rem-calc(53) !important;

      @include breakpoint(small down) {
        white-space: initial;
      }
    }

    td {
      font-size: rem-calc(14);
      white-space: nowrap;
      letter-spacing: rem-calc(0.2);
      height: rem-calc(53) !important;

      @include breakpoint(small down) {
        white-space: initial;
      }
    }

    .hoverBold {
      background: $water-green;
    }

    .hover {
      background: $water-green-light !important;
    }
  }
}

div[data-querystring^="cid=size-guide"] .size-guide-container .customer-service-list-style li.general-info-item,
div[data-querystring^="cid=style-guide"] .size-guide-container .customer-service-list-style li.orders-item {
  border: 1px solid $water-green;
  background: $water-green-light;
}


/* Style guide */
.size-guide-page--style {
  .js-tab-content {
    padding: 0;

    @include breakpoint(small down) {
      padding: rem-calc(0 16);
    }
  }

  .cnt-title .h4,
  .all_style_guide_02_cnt_wrapper .similar-guide__h2 {
    display: block;
    font-weight: 600;
    font-size: rem-calc(26);
    line-height: rem-calc(34);
    padding: rem-calc(24 0 16 0);
    text-align: left;
    border-bottom: 1px solid #F2EDED;
    margin: rem-calc(0 0 32 0);

    @include breakpoint(small down) {
      font-size: rem-calc(16);
      line-height: rem-calc(24);
      font-weight: 500;
      padding: rem-calc(16 16);
      margin: rem-calc(0 -16 32 -16);
      display: none;
    }

    &.visible {
      padding-top: 0;
      @include breakpoint(small down) {
        display: block;
        padding: rem-calc(16 16);
      }
    }

    svg {
      width: rem-calc(24);
      height: rem-calc(24);
      margin-right: rem-calc(12);
      margin-bottom: 0;
    }

    a {
      font: inherit;
      text-decoration: none !important;
      border: none !important;
    }
  }

  .donna_style_guide_01_cnt_container,
  .all_style_guide_02_cnt_wrapper .similar-guide__container {
    grid-template-columns: 1fr 1fr;
    row-gap: rem-calc(24);
    column-gap: rem-calc(16);

    @include breakpoint(small down) {
      row-gap: rem-calc(24);
      column-gap: rem-calc(8);
    }
  }

  .donna_style_guide_01_cnt_image {
    border-radius: rem-calc(16);
    padding-bottom: 90.5%;
    height: 0;

    @include breakpoint(small down) {
      padding-bottom: 186%;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .all_style_guide_02_cnt_wrapper .similar-guide__img {
    display: block;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: rem-calc(16);
    margin-bottom: rem-calc(24);
  }

  .dona_style_guide_01_cnt_title {
    top: auto;
    bottom: rem-calc(28);
    left: rem-calc(24);
    right: auto;
    transform: none;
    width: calc(100% - 3.6923rem);

    @include breakpoint(small down) {
      left: rem-calc(12);
    }
  }

  .cnt-text {
    display: block;
    text-transform: none;
    font-size: rem-calc(20);
    line-height: rem-calc(30);
    margin: 0;
    text-align: left;
  }

  .all_style_guide_02_cnt_wrapper .similar-guide__title {
    display: block;
    font-size: rem-calc(20);
    line-height: rem-calc(30);
    margin: rem-calc(0 0 8 0);
    text-align: left;

    @include breakpoint(small down) {
      font-size: rem-calc(18);
      line-height: rem-calc(27);
    }
  }

  .all_style_guide_02_cnt_wrapper .similar-guide__info,
  .all_style_guide_02_cnt_wrapper .similar-guide__tip {
    display: block;
    font-size: rem-calc(14);
    line-height: rem-calc(21);
    font-style: normal;
    letter-spacing: rem-calc(0.2);
    text-align: left;
    margin: 0;
  }

  .all_style_guide_02_cnt_wrapper .similar-guide__link {
    margin-top: rem-calc(24);
    font-weight: 400;
    letter-spacing: rem-calc(0.2);
    font-size: rem-calc(14);
    line-height: rem-calc(21);

    span {
      padding-bottom: 0;
      border-bottom: 1px solid $black;
    }
  }

  .all_style_guide_02_cnt_wrapper .similar-guide__item {
    margin: 0;
  }
}

// Bra guide
div[data-querystring^="cgid=Tezenis_new_LP_bra_guide"] {
  .seo-container {
    display: none;
  }
}
.newBraGuideLP {

  &.area01 {
    position: relative;
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: linear-gradient(0deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 30%);
      pointer-events: none;

      @include breakpoint(medium up) {
        display: none;
      }
    }

    span.scrollVideoIcon {
      display: block;
      position: absolute;
      z-index: 2;
      padding: rem-calc(0 16 24);
      top: auto;
      bottom: 0;
      left: 0;
      width: 100%;
      margin: 0;
      height: auto;
      text-align: left;
      transform: none;
      font-weight: 500;
      font-size: rem-calc(12);
      line-height: rem-calc(18);
      letter-spacing: rem-calc(0.2);
      pointer-events: none;

      @include breakpoint(medium up) {
        padding: rem-calc(40);
        text-align: center;
      }

      img {
        display: none;
      }
    }
  }

  &.area02Swipe {
    background: $over-gray;
    padding: rem-calc(40 0);
    margin: rem-calc(0 0 40 0);

    .swiper {
      width: rem-calc(310);
      margin-bottom: rem-calc(32);
      overflow: visible;

      @include breakpoint(medium up) {
        margin-bottom: rem-calc(24);
      }
    }

    .swiper-pagination {
      display: none;
    }

    .swiper-slide {
      border-radius: rem-calc(16);
      overflow: hidden;

      h5.galleryTitle {
        font-size: rem-calc(40);
        line-height: rem-calc(48);
        font-weight: 600;
        bottom: rem-calc(26);
        text-shadow: none;
        text-transform: none;
      }
    }

    .galleryCaption p {
      font-size: rem-calc(16);
      line-height: rem-calc(24);
      letter-spacing: rem-calc(0.2);
      text-align: center;
      color: $black;
      padding: rem-calc(0 16);
      margin: 0 auto;
      width: 100%;
      max-width: rem-calc(470);
    }
  }

  &.area03 {
    h3 {
      font-size: rem-calc(26);
      line-height: rem-calc(34);
      font-weight: 600;
      padding: rem-calc(0 16);
      text-align: left;
      color: $black;
      letter-spacing: 0;
      text-shadow: none;
      text-transform: none;
      margin: rem-calc(0 0 16 0);

      @include breakpoint(medium up) {
        font-size: rem-calc(40);
        line-height: rem-calc(48);
        text-align: center;
        margin: rem-calc(0 0 32 0);
      }
    }

    .filterNav {
      margin-bottom: rem-calc(20);
      width: 100%;

      ul {
        font-size: 0;
        padding: rem-calc(0 0 0 16);

        li {
          margin: rem-calc(0 16 12 0);

          a {
            display: block;
            font-size: rem-calc(12);
            line-height: rem-calc(18);
            font-weight: 400;
            letter-spacing: rem-calc(0.2);
            margin: 0;
            color: $black;
            border: 1px solid $light-gray;
            text-decoration: none;
            padding: rem-calc(8 16);
            border-radius: rem-calc(20);

            &.active {
              background: $water-green-light;
              color: $black;
              border: 1px solid $water-green;
              text-decoration: none;
            }
          }
        }
      }
    }

    .filterAreaWrap {
      margin: 0 auto;
      padding: rem-calc(0 16);
      width: 100%;
      max-width: rem-calc(1000);

      .filterBoxesWrap {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: rem-calc(0 -8);
      }
    }

    .filterBox {
      display: block;
      margin: rem-calc(0 0 32 0);
      padding: 0;
      padding: rem-calc(0 8);
      width: 50%;
      flex: 0 0 50%;

      @include breakpoint(medium up) {
        width: 25%;
        flex: 0 0 25%;
      }

      @include breakpoint(large up) {
        width: 16.666666%;
        flex: 0 0 16.666666%;
      }

      img {
        border-radius: rem-calc(8);
        margin: rem-calc(0 0 16 0);
        width: 100%;
      }

      h4 {
        font-weight: 600;
        font-size: rem-calc(18);
        line-height: rem-calc(27);
        text-align: center;
        color: $black;
        letter-spacing: 0;
        margin: rem-calc(0 0 16 0);
      }

      a.ctaLink {
        font-weight: 400;
        font-size: rem-calc(12);
        line-height: rem-calc(18);
        color: $black;
        letter-spacing: rem-calc(0.2);
        border-bottom: 1px solid $black;
        margin: 0;
      }
    }
  }

  &.area04 {
    margin-bottom: rem-calc(64);

    h3 {
      font-size: rem-calc(26);
      line-height: rem-calc(34);
      font-weight: 600;
      padding: rem-calc(0 16);
      text-align: left;
      color: $black;
      letter-spacing: 0;
      text-shadow: none;
      text-transform: none;
      margin: rem-calc(0 0 16 0);

      @include breakpoint(medium up) {
        font-size: rem-calc(40);
        line-height: rem-calc(48);
        text-align: center;
        margin: rem-calc(0 0 32 0);
      }
    }

    .flipTabsWrap {
      width: 100%;

      .tab-content {
        @include breakpoint(large up) {
          display: block;
        }
      }

      .container-tabs {
        @include breakpoint(medium up) {
          margin: 0;
        }
        @include breakpoint(large up) {
          margin: rem-calc(0 -8);
        }

        .tab-content {
          @include breakpoint(medium up) {
            padding: rem-calc(0 16);
          }
          @include breakpoint(large up) {
            padding: rem-calc(0 8);
          }
        }
      }

      .tabs.tabs-center {
        font-size: 0;
        padding: rem-calc(0 0 0 16);
        margin: rem-calc(0 0 20 0);

        @include breakpoint(large up) {
          padding-left: 0;
        }

        li {
          margin: rem-calc(0 16 12 0);
          display: inline-block;
          width: auto;
          font-size: rem-calc(12);
          line-height: rem-calc(18);
          font-weight: 400;
          letter-spacing: rem-calc(0.2);
          color: $black;
          border: 1px solid $light-gray;
          text-decoration: none;
          padding: rem-calc(8 16);
          border-radius: rem-calc(20);

          &:before,
          &:after,
          img {
            display: none;
          }

          .cnt-txt {
            font: inherit !important;
            margin: 0;
            padding: 0;
          }

          &.current {
            background: $water-green-light;
            color: $black;
            border: 1px solid $water-green;
            text-decoration: none;
            padding: rem-calc(8 16);
          }
        }
      }
    }

    .hp-tabs {
      margin: 0;

      @include breakpoint(large up) {
        padding: rem-calc(0 40);
      }
    }

    .flipTabsWrap .tab-content {
      aspect-ratio: auto;
      min-height: 0;
      width: 100%;
      padding: rem-calc(0 16);
    }

    .area04-card {
      text-align: left;

      .card-img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        aspect-ratio: 343 / 415;
        border-radius: rem-calc(16);
        margin: rem-calc(0 0 16 0);

        @include breakpoint(medium up) {
          aspect-ratio: 1;
        }
      }

      h4 {
        font-size: rem-calc(18);
        line-height: rem-calc(27);
        font-weight: 600;
        padding: 0;
        text-align: left;
        color: $black;
        letter-spacing: 0;
        margin: rem-calc(0 0 8 0);

        @include breakpoint(medium up) {
          font-size: rem-calc(26);
          line-height: rem-calc(34);
          text-align: left;
          margin: rem-calc(0 0 8 0);
        }
      }

      p {
        display: block;
        font-size: rem-calc(16);
        line-height: rem-calc(24);
        color: $black;
        text-align: left;
        margin: rem-calc(0 0 24 0);
      }
    }
  }

  &.area05 {
    margin-bottom: rem-calc(56);
    padding: 0;

    @include breakpoint(large up) {
      margin-bottom: rem-calc(80);
    }

    h3 {
      font-size: rem-calc(20);
      line-height: rem-calc(30);
      font-weight: 600;
      padding: rem-calc(0 16);
      text-align: left;
      color: $black;
      letter-spacing: 0;
      text-shadow: none;
      text-transform: none;
      margin: rem-calc(0 0 16 0);

      @include breakpoint(medium up) {
        font-size: rem-calc(40);
        line-height: rem-calc(48);
        text-align: center;
        margin: rem-calc(0 0 32 0);
      }
    }

    a,
    a:hover {
      text-decoration: none;
      border: none;
    }

    .inspoSliderWrap {
      width: 100%;
      padding: rem-calc(0 16);

      @include breakpoint(medium up) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: rem-calc(0 8);
      }
      @include breakpoint(large up) {
        padding: rem-calc(0 32);
      }

      .area05-card {
        margin-bottom: rem-calc(16);
        position: relative;

        @include breakpoint(medium up) {
          width: 50%;
          flex: 0 0 50%;
          padding: rem-calc(0 8);
          margin: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }

        > a {
          display: block;
          position: relative;
          margin: 0;

          img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            aspect-ratio: 343 / 415;
            border-radius: rem-calc(16);

            @include breakpoint(medium up) {
              aspect-ratio: 635 / 533;
            }
          }
        }

        .buttonBox {
          position: absolute;
          bottom: rem-calc(16);
          left: rem-calc(16);
          z-index: 1;
          text-align: left;

          @include breakpoint(medium up) {
            bottom: rem-calc(32);
            left: rem-calc(32);
          }

          .button {
            margin: 0;
          }
        }
      }
    }
  }

  &.area06 {
    margin: rem-calc(0 0 56 0);
    padding: 0;

    @include breakpoint(medium up) {
      padding: rem-calc(0 8);
    }
    @include breakpoint(large up) {
      padding: rem-calc(0 32);
    }

    .guideBoxesWrap  {
      display: block;
      padding: 0;
      margin: 0;

      @include breakpoint(medium up) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }

      .guideBox-wrp {
        margin: rem-calc(0 0 16 0);

        @include breakpoint(medium up) {
          width: 50%;
          flex: 0 0 50%;
          padding: rem-calc(0 8);
          margin: 0;
        }
      }

      .guideBox {
        padding: rem-calc(32 16);
        margin: 0;
        text-align: left;
        background: $over-gray;
        width: 100%;

        @include breakpoint(medium up) {
          border-radius: rem-calc(4);
          height: 100%;
        }

        @include breakpoint(large up) {
          padding: rem-calc(40);
        }

        .guideBoxIcon {
          text-align: left;
          margin: rem-calc(0 0 24 0);

          img {
            width: rem-calc(24);
            height: rem-calc(24);
          }
        }

        h5 {
          display: block;
          font-size: rem-calc(20);
          line-height: rem-calc(30);
          color: $black;
          font-weight: 600;
          text-align: left;
          letter-spacing: 0;
          margin: rem-calc(0 0 16 0);
          padding: 0;
        }

        p {
          display: block;
          font-size: rem-calc(16);
          line-height: rem-calc(24);
          color: $black;
          font-weight: 400;
          text-align: left;
          letter-spacing: rem-calc(0.2);
          margin: rem-calc(0 0 24 0);
          padding: 0;
        }

        a.ctaLink {
          font-size: rem-calc(12);
          line-height: rem-calc(18);
          color: $black;
          font-weight: 400;
          text-align: left;
          letter-spacing: rem-calc(0.2);
          margin: 0;
          border-bottom: 1px solid $black;
        }
      }
    }
  }
}
