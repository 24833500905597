.cc-registrationModal {
    .new-footer-form-style:not(.checkout-style) input, .new-footer-form-style:not(.checkout-style) select, .shipping-phone-registered input, .shipping-phone-registered select {
        height: auto;
        padding-top: 0 !important;
        padding-bottom: rem-calc(8) !important;
    }

    &__body {
        margin-top: rem-calc(14);
        padding: rem-calc(16);

        @include breakpoint(medium up) {
            margin-top: 0;
            padding: 0;
        }
    }

    .registrationStart {
        width: 100%;
        padding: 0;
        margin: 0;
    }

    &__title {
        display: block;
        font-size: rem-calc(16);
        line-height: rem-calc(24);
        letter-spacing: rem-calc(0.2);
        color: $black;
        font-weight: $global-weight-bold;
        text-align: left;
        margin: rem-calc(0 0 16);
    }

    &__description {
        display: block;
        font-size: rem-calc(14);
        line-height: rem-calc(21);
        letter-spacing: rem-calc(0.2);
        color: $black;
        text-align: left;
        margin: rem-calc(0 0 16);
    }

    &__formWrp {
        margin-top: rem-calc(32);
    }

    &__formBtn {
        margin-top: rem-calc(32);

        .button {
            margin: 0;
            width: 100%;
        }

        &--loyaltyIntro {
            text-align: center;

            .button-tertiary-black {
                display: inline-block;
                position: static;
                font-size: rem-calc(14);
                line-height: rem-calc(21);
                margin: rem-calc(24 0 0);
            }
        }
    }

    #registration-form-email {
        width: 100%;
    }

    &__phoneWrp {
        display: flex;
        flex-direction: row;
        margin-left: rem-calc(-10);
        margin-right: rem-calc(-10);
    }

    &__phoneItem {
        display: block;
        width: 100%;
        padding-left: rem-calc(10);
        padding-right: rem-calc(10);
        flex: 1;

        &--prefix {
            width: rem-calc(123);
            flex: 0 0 rem-calc(123);
        }
    }

    .login-form-error {
        padding: rem-calc(16);
        border: rem-calc(1) solid $error;
        font-size: rem-calc(12);
        line-height: rem-calc(18);
        letter-spacing: rem-calc(0.2);
        text-align: left;
        color: $error;
        margin: rem-calc(-16 0 32);
        border-radius: rem-calc(8);

        @include breakpoint(medium up) {
            margin-top: rem-calc(-8);
        }

        a {
            color: inherit;
            font: inherit;
        }

        &.already-registered,
        &.already-registered-1,
        &.already-registered-2 {
            border-color: $water-green;
            color: $black;

            a {
                font-weight: $global-weight-black;
            }
        }
    }

    &__infoRecap {
        display: block;
        font-size: rem-calc(14);
        line-height: rem-calc(21);
        letter-spacing: rem-calc(0.2);
        text-align: left;
        margin: rem-calc(8 0 0);

        &__label {
            display: inline-block;
            font-weight: $global-weight-dark;
            margin-right: rem-calc(4);
        }

        &__value {
            display: inline-block;
        }
    }

    &__finalFieldWrp {
        padding-bottom: rem-calc(8);

        .toggle-password {
            bottom: auto;
            top: rem-calc(28);
        }

        .password-tip {
            padding: rem-calc(8 0 0);
            margin: 0;
            font-size: rem-calc(12);
            line-height: rem-calc(18);
            letter-spacing: rem-calc(0.2);
            color: $medium-gray;
            text-align: left;

            .active {
                color: $success;
            }
        }
    }

    &__mandatoryLabel {
        display: block;
        font-size: rem-calc(12);
        line-height: rem-calc(18);
        letter-spacing: rem-calc(0.2);
        color: #A2A2A2;
        text-align: left;
        margin: rem-calc(0 0 24);
    }

    &__genderWrp {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: rem-calc(-8);

        @include breakpoint(medium up) {
            margin-top: 0;
            padding-bottom: rem-calc(8);
        }

        .boolean-field {
            padding-right: rem-calc(24);
        }
    }

    &__rememberMe {
        margin-top: rem-calc(16);
        position: relative;
        padding-right: rem-calc(60);
        margin-bottom: rem-calc(32);

        @include breakpoint(medium up) {
            margin-bottom: rem-calc(34);
        }

        .boolean-field {
            position: static;

            .checkbox-label {
                font-size: rem-calc(14);
                line-height: rem-calc(24);
            }
        }

        .info-icon {
            margin: rem-calc(-2 0 0) !important;

            @include breakpoint(medium up) {
                margin: rem-calc(2 0 0) !important;
            }

            .icon-info-black {
                right: rem-calc(-14);

                @include breakpoint(medium up) {
                    right: rem-calc(-24);
                }
            }
        }
    }

    .switch {
        display: block;
        width: rem-calc(49);
        height: rem-calc(24);

        @include breakpoint(medium up) {
            width: rem-calc(32);
            height: rem-calc(16);
        }
    }

    .slider.round {
        width: rem-calc(49);
        height: rem-calc(24);
        border-radius: rem-calc(12);
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        left: auto;

        @include breakpoint(medium up) {
            width: rem-calc(32);
            height: rem-calc(16);
            border-radius: rem-calc(8);
        }

        &:before,
        &:after {
            width: rem-calc(18);
            height: rem-calc(18);
            top: rem-calc(3);

            @include breakpoint(medium up) {
                width: rem-calc(12);
                height: rem-calc(12);
                top: rem-calc(2);
            }
        }

        &:after {
            content: '';
            left: rem-calc(4);
            border-radius: 50%;
            background: $white;
            position: absolute;
            display: none;

            @include breakpoint(medium up) {
                left: rem-calc(2);
            }
        }
    }

    input:checked+.slider:before,
    input:checked+.slider:after {
        transform: translateX(rem-calc(8));

        @include breakpoint(medium up) {
            transform: translateX(rem-calc(18));
        }
    }

    &__divider {
        width: 100%;
        height: rem-calc(1);
        background: $medium-gray;
        margin-bottom: rem-calc(32);
    }

    .policy-promotion-payment {
        p {
            display: inline-block;
            font-size: rem-calc(12);
            line-height: rem-calc(18);
            letter-spacing: rem-calc(0.2);
            text-align: left;
            margin: 0;
            padding-right: rem-calc(24);
            width: calc(100% - #{rem-calc(49)});

            @include breakpoint(medium up) {
                width: calc(100% - #{rem-calc(32)});
            }

            span.small {
                font-size: rem-calc(12);
                line-height: rem-calc(18);
            }

            a {
                display: inline-block;
                font-weight: $global-weight-normal;
                color: inherit;
                font: inherit;
                text-transform: none;
                text-decoration: underline;
            }
        }

        .newslatter-switch-wrapper {
            width: rem-calc(49);

            @include breakpoint(medium up) {
                width: rem-calc(32);
            }
        }

        .form-group {
            margin-bottom: rem-calc(16);
        }

        .privacy-policy-p {
            font-size: rem-calc(10);
            line-height: rem-calc(18);
            width: 100%;
            padding: 0;

            a {
                display: inline-block;
            }
        }
    }

    &__loyalty {
        margin-top: rem-calc(32);
        border-top: rem-calc(1) solid $medium-gray;
        padding-top: rem-calc(32);

        &--noBorder {
            margin-top: 0;
            padding-top: 0;
            border: none;
        }

        &__title {
            display: block;
            font-size: rem-calc(14);
            line-height: rem-calc(21);
            letter-spacing: rem-calc(0.2);
            text-align: left;
            font-weight: $global-weight-bold;
            margin: rem-calc(0 0 12);
        }

        &__description {
            display: flex;
            flex-direction: row;
            margin-bottom: rem-calc(24);

            &__text {
                display: block;
                font-size: rem-calc(12);
                line-height: rem-calc(18);
                letter-spacing: rem-calc(0.2);
                margin: 0;
                padding-right: rem-calc(24);
                width: calc(100% - #{rem-calc(49)});

                @include breakpoint(medium up) {
                    width: calc(100% - #{rem-calc(32)});
                }

                a {
                    font-weight: $global-weight-normal;
                    color: inherit;
                    font: inherit;
                }
            }

            .mobile-flex-fields {
                width: rem-calc(49);

                @include breakpoint(medium up) {
                    width: rem-calc(32);
                }
            }
        }

        .dob {
            .form-control-label {
                top: 0 !important;
            }

            .calendar-datepicker-img {
                position: absolute;
                top: rem-calc(32);
                right: 0;
                width: rem-calc(16);
                height: rem-calc(16);
                transform: none;
            }
        }

        &__opposition {
            font-size: rem-calc(12);
            line-height: rem-calc(18);
            letter-spacing: rem-calc(0.2);
            text-align: left;
            color: $medium-gray;
            margin: rem-calc(0 0 16);

            a {
                font-weight: $global-weight-normal;
                color: inherit;
                font: inherit;
            }
        }

        .opposition {
            .info-icon .icon-info-black {
                right: rem-calc(-24);
            }
        }
    }

    &__disclaimer {
        display: block;
        font-size: rem-calc(10);
        line-height: rem-calc(15);
        letter-spacing: rem-calc(0.2);
        text-align: left;
        margin: rem-calc(24 0 -16);

        @include breakpoint(medium up) {
            margin: rem-calc(24 0 -8);
        }

        a {
            font-weight: $global-weight-normal;
            color: inherit;
            font: inherit;
        }
    }

    .popover-loyalty-member-tez {
        position: absolute;
        bottom: rem-calc(50);
        right: rem-calc(-2);
        width: rem-calc(235);
        background: radial-gradient(circle at 100% 100%, #ffffff 0, #ffffff 6px, transparent 6px) 0% 0%/8px 8px no-repeat,
        radial-gradient(circle at 0 100%, #ffffff 0, #ffffff 6px, transparent 6px) 100% 0%/8px 8px no-repeat,
        radial-gradient(circle at 100% 0, #ffffff 0, #ffffff 6px, transparent 6px) 0% 100%/8px 8px no-repeat,
        radial-gradient(circle at 0 0, #ffffff 0, #ffffff 6px, transparent 6px) 100% 100%/8px 8px no-repeat,
        linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 4px) calc(100% - 16px) no-repeat,
        linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 16px) calc(100% - 4px) no-repeat,
        linear-gradient(153deg, rgba(236,177,100,0.7) 0%, rgba(233,153,96,0.7) 20%, rgba(238,132,114,0.7) 40%, rgba(249,109,143,0.7) 60%, rgba(227,102,156,0.7) 80%, rgba(149,98,159,0.7) 100%);
        box-sizing: border-box;
        padding: rem-calc(24);
        border-radius: rem-calc(8);
        margin: 0;

        p {
            margin: 0;
            font-size: rem-calc(12);
            line-height: rem-calc(18);
            letter-spacing: rem-calc(0.02);

            &.loyalty-popover-subtitle {
                margin-top: rem-calc(8);
            }
        }
    }

    &__waiting {
        .opt-subtitle {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: rem-calc(0 0 24);
            font-size: 0;

            .opt-subtitle-icon {
                width: rem-calc(24);
            }

            .opt-subtitle-text {
                display: block;
                flex: 1;
                font-size: rem-calc(14);
                line-height: rem-calc(21);
                letter-spacing: rem-calc(0.2);
                font-weight: $global-weight-dark;
                text-align: left;
                padding-left: rem-calc(16);
                margin: 0;
            }
        }

        .opt-para {
            display: block;
            font-size: rem-calc(12);
            line-height: rem-calc(18);
            letter-spacing: rem-calc(0.2);
            text-align: left;
            margin: rem-calc(0 0 32);

            @include breakpoint(medium up) {
                margin: rem-calc(0 0 40);
            }
        }

        .opt-in-inputs {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin: rem-calc(0 0 40);

            @include breakpoint(medium up) {
                max-width: rem-calc(315);
                margin-left: auto;
                margin-right: auto;
                margin-bottom: rem-calc(40);
            }

            input {
                display: block;
                width: rem-calc(56);
                height: rem-calc(57);
                border: rem-calc(2) solid $medium-gray;
                border-radius: rem-calc(5);
                font-size: rem-calc(20);
                line-height: rem-calc(57);
                font-weight: $global-weight-bold;
                text-align: center;
                margin: 0;
                padding: 0;
                -moz-appearance: textfield;

                @include breakpoint(medium up) {
                    width: rem-calc(50);
                }

                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
            }

            &.invalid input {
                border-color: $error;
            }
        }

        .opt-error {
            font-size: rem-calc(12);
            line-height: rem-calc(18);
            letter-spacing: rem-calc(0.2);
            text-align: center;
            color: $error;
            font-weight: $global-weight-dark;
            margin: rem-calc(-16 0 40);
        }
    }

    .cc-ty {
        &__head {
            display: flex;
            flex-direction: row;
            margin-bottom: rem-calc(16);
            align-items: center;
        }

        &__icon {
            width: rem-calc(24);
            flex: 0 0 rem-calc(24);
            font-size: 0;

            .cc-icon {
                width: rem-calc(24);
            }
        }

        &__title {
            display: block;
            font-weight: $global-weight-dark;
            flex: 1;
            font-size: rem-calc(14);
            line-height: rem-calc(21);
            text-align: left;
            padding-left: rem-calc(16);
            margin: 0;
        }

        &__text {
            display: block;
            font-size: rem-calc(12);
            line-height: rem-calc(18);
            letter-spacing: rem-calc(0.2);
            text-align: left;
            margin: 0;
        }

        .cc-registrationModal__formBtn {
            margin: rem-calc(24 0 0);
        }
    }

    &--loyalty {
        .cc-registrationModal {
            &__title {
                font-size: rem-calc(14);
                line-height: rem-calc(21);
                margin: rem-calc(0 0 12);
            }

            &__description {
                font-size: rem-calc(12);
                line-height: rem-calc(18);
                margin: rem-calc(0 0 18);

                @include breakpoint(medium up) {
                    margin-bottom: rem-calc(24);
                }

                a {
                    font: inherit;
                    color: inherit;
                    font-weight: $global-weight-normal;
                    text-decoration: underline;
                }

                &--loyaltyIntro {
                    font-size: rem-calc(14);
                    line-height: rem-calc(21);
                    letter-spacing: rem-calc(0.02);
                    margin-bottom: rem-calc(40);

                    @include breakpoint(medium up) {
                        margin-bottom: rem-calc(32);
                    }

                    a {
                        display: block;
                        margin-top: rem-calc(8);
                    }
                }
            }

            &__formWrp {
                margin-top: rem-calc(18);
            }
        }

        &__oppositionFiels {
            margin-bottom: 0;
        }
    }

    .slider.round.loyalty-highlight:before {
        transform: scale(1.67);
        background: linear-gradient(135deg, rgba(236, 177, 100, 1) 0%, rgba(233, 153, 96, 1) 21.98%, rgba(238, 132, 114, 1) 37.41%, rgba(249, 109, 143, 1) 59.66%, rgba(227, 102, 156, 1) 77.35%, rgba(149, 98, 159, 1) 100%);
    }

    .slider.round.loyalty-highlight:after {
        display: block;
    }
}

.cc-loyaltyModal {
    &__features {
        padding: rem-calc(24);
        margin-bottom: rem-calc(32);
        border-radius: rem-calc(16);
        background: linear-gradient(135deg, rgba(236, 177, 100, 0.60) 0%, rgba(233, 153, 96, 0.60) 21.98%, rgba(238, 132, 114, 0.60) 37.41%, rgba(249, 109, 143, 0.60) 59.66%, rgba(227, 102, 156, 0.60) 77.35%, rgba(149, 98, 159, 0.60) 100%);

        &__title {
            display: block;
            font-size: rem-calc(14);
            line-height: rem-calc(21);
            letter-spacing: rem-calc(0.02);
            text-align: left;
            margin: rem-calc(0 0 24);
        }

        &__item {
            display: flex;
            align-items: center;
        }

        &__list {
            margin: 0;
            padding: 0;

            li {
                padding: 0;
                margin: rem-calc(0 0 24);
                list-style-type: none;

                &:last-child {
                    margin: 0;
                }
            }

            img {
                display: block;
                width: rem-calc(24);
                height: rem-calc(24);
                margin: 0;
                flex: 0 0 rem-calc(24)
            }

            span {
                display: block;
                flex: 1;
                font-size: rem-calc(14);
                line-height: rem-calc(21);
                letter-spacing: rem-calc(0.02);
                text-align: left;
                margin: 0;
                padding-left: rem-calc(16);
            }
        }
    }
}

.wrapperModal.tingle-modal.registrationAddedModal {
    z-index: 1002;

    .tingle-modal-box {
        max-height: 90%;
        background: transparent;

        @include breakpoint(large up) {
            width: 90%;
            max-width: rem-calc(996);
        }

        .tingle-modal-box__content {
            background: $white;
            border: rem-calc(1) solid $water-green;
            border-radius: rem-calc(8);

            .tingle-modal__close {
                position: absolute;
                top: rem-calc(23);
                right: rem-calc(24);
                font-size: 0;
                width: rem-calc(20);
                height: rem-calc(20);
                background-image: url("../images/tzn-close-icon.svg");
                background-size: contain;
                padding: 0;
                cursor: pointer;

                @include breakpoint(large up) {
                    top: rem-calc(37);
                    right: rem-calc(40);
                    width: rem-calc(24);
                    height: rem-calc(24);
                }
            }
        }
    }

    .privacy-style .content-page-wrapper {
        padding: 0;

        .welcome-content {
            display: block;
            font-weight: $global-weight-bold;
            font-size: rem-calc(12);
            line-height: rem-calc(18);
            letter-spacing: rem-calc(0.02);
            text-align: left;
            margin-bottom: rem-calc(10);
            padding: rem-calc(0 28 0 0);

            @include breakpoint(large up) {
                font-size: rem-calc(14);
                line-height: rem-calc(21);
                margin-bottom: rem-calc(16);
            }
        }

        p a {
            font-weight: $global-weight-normal;
            text-decoration: underline;
        }

        p {
            font-size: rem-calc(12);
            line-height: rem-calc(18);
            letter-spacing: rem-calc(0.02);
            text-align: left;
            margin: 0;

            @include breakpoint(large up) {
                font-size: rem-calc(14);
                line-height: rem-calc(22);
            }
        }
    }
}
