@import "../pages/personalization/fonts";
@import "../pages/personalization/changeOption";

.fnPersonalizationPageContainer {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    right:0;
    display: none;
    z-index: 2011;

    &:before {
        @include breakpoint (large up) {
            content: '';
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: rgba($water-green, 0.6);
            backdrop-filter: blur(5px);
            opacity: 0;
            z-index: -1;
            pointer-events: none;
            transition: opacity 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);
        }
    }

    &.opened {
        display: block;

        &:before {
            @include breakpoint (large up) {
                opacity: 1;
            }
        }
    }

    .fnPersModal {
        background-color: $white;
        width: 100%;
        height: 100%;

        @include breakpoint (large up) {
            display: flex;
            flex-direction: column;
            margin-left: auto;
            width: rem-calc(459);
        }

        &-header {
            background-color: transparent;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 1;
            padding: rem-calc(20 16);
            color: $black;

            @include breakpoint (large up) {
                padding: rem-calc(24 70 24 24);
                position: relative;
                margin-bottom: rem-calc(16);
            }

            &-title {
                display: none;

                @include breakpoint (large up) {
                    display: block;
                    font-weight: $global-weight-dark;
                    font-size: rem-calc(14);
                    line-height: rem-calc(21);
                    letter-spacing: 0.02em;
                    color: $black;
                    text-align: left;
                    margin: 0;
                }
            }

            &-back {
                display: block;

                svg {
                    display: block;
                    width: rem-calc(24);
                    height: rem-calc(24);
                    margin: 0;
                    cursor: pointer;
                }

                @include breakpoint (large up) {
                    display: none;
                }
            }

            &-close {
                display: none;

                @include breakpoint (large up) {
                    display: block;
                    position: absolute;
                    top: rem-calc(22);
                    right: rem-calc(40);
                }

                svg {
                    display: block;
                    width: rem-calc(24);
                    height: rem-calc(24);
                    margin: 0;
                    cursor: pointer;
                }
            }
        }

        &-body {
            height: 100%;
            display: flex;
            flex-direction: column;

            @include breakpoint (large up) {
                flex: 1;
                height: rem-calc(500);
            }
        }

        &-imageSection {
            position: relative;
            flex: 1;
            overflow-y: auto;

            @include breakpoint (large up) {
                position: absolute;
                top: 50%;
                left: calc(50% - 230px);
                transform: translate(-50%, -50%);
                width: rem-calc(414);
            }
        }

        &-image {
            position: relative;

            img {
                width: 100%;
            }

            &.visually-hidden {
                opacity: 0;
                z-index: -1;
                position: absolute;
                visibility: hidden;
            }
        }

        &-infoSection {
            @include breakpoint (large up) {
                flex: 1;
                display: flex;
                flex-direction: column;
                height: rem-calc(500);
            }
        }

        &-tabs {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            overflow-x: auto;
            padding: rem-calc(8 16);
            margin: rem-calc(0 -12);
            border-bottom: rem-calc(1) solid $light-gray;

            @include breakpoint (large up) {
                display: none;
            }
        }

        &-tab {
            display: block;
            padding: rem-calc(7 12);
            border-right: rem-calc(1) solid $light-gray;
            font-weight: $global-weight-bold;
            color: #BCBCBC;
            font-size: rem-calc(14);
            line-height: rem-calc(21);
            letter-spacing: 0.02em;
            margin: 0;
            white-space: nowrap;
            cursor: pointer;

            &.active {
                color: $black;
            }

            &:last-child {
                border-right: none;
            }
        }

        &-options {
            @include breakpoint (large up) {
                flex: 1;
                overflow-y: auto;
                height: 100%;
            }

            .fnPersModal-legal {
                display: block;
                font-size: rem-calc(10);
                line-height: rem-calc(15);
                color: $black;
                padding: rem-calc(0 16 8);
                margin: 0;

                @include breakpoint (large up) {
                    padding: rem-calc(0 40 16 24);
                }

                a {
                    text-decoration: underline;
                    color: $black;
                    font-weight: $global-weight-normal;
                }
            }
        }

        &-option {

            @include breakpoint (large up) {
                padding: rem-calc(0 40 0 24);
            }

            &-title {
                display: none;

                @include breakpoint (large up) {
                    display: block;
                    font-size: rem-calc(12);
                    line-height: rem-calc(18);
                    color: $black;
                    letter-spacing: 0.02em;
                    margin: 0;
                    padding: 0;
                }

                &--multiple {
                    @include breakpoint (large up) {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                    }

                    span {
                        @include breakpoint (large up) {
                            display: block;
                            flex: 1;
                        }
                    }

                    .delete-embroidery {
                        display: none;
                        font: inherit;
                        color: inherit;
                        margin-left: auto;
                        border: none;
                        background: transparent;
                        padding: 0;
                        border-bottom: rem-calc(1) solid $black;
                        cursor: pointer;

                        @include breakpoint (large up) {
                            display: block;
                        }
                    }
                }
            }

            &-body {
                @include breakpoint (large up) {
                    border-bottom: rem-calc(1) solid $light-gray;
                    margin-bottom: rem-calc(16);
                }

                &.customizationText {
                    @include breakpoint (large up) {
                        border-bottom: none;
                        margin-bottom: 0;
                    }
                }
            }
        }

        &-tabContent {
            display: none;
            min-height: rem-calc(74);

            @include breakpoint (large up) {
                min-height: 0;
                display: block;
            }

            &.active {
                display: block;
            }
        }

        &-footer {
            border-top: rem-calc(1) solid $light-gray;
            padding: rem-calc(12 16);

            @include breakpoint (large up) {
                padding: rem-calc(16 40 16 16);
            }

            &-priceWrp {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: rem-calc(8);

                @include breakpoint (large up) {
                    margin-bottom: rem-calc(16);
                }

                .price-label {
                    display: block;
                    font-weight: $global-weight-dark;
                    font-size: rem-calc(14);
                    line-height: rem-calc(21);
                    color: $black;
                    text-align: left;
                    margin: 0;
                    flex: 1;

                    @include breakpoint (large up) {
                        font-size: rem-calc(16);
                        line-height: rem-calc(24);
                    }

                    &-text {
                        display: block;

                        @include breakpoint (large up) {
                            display: inline-block;
                            vertical-align: middle;
                        }
                    }

                    .info-icon {
                        display: none;

                        @include breakpoint (large up) {
                            display: inline-block;
                            vertical-align: middle;
                            width: rem-calc(16);
                            height: rem-calc(16);
                            margin-left: 0;
                        }

                        .icon-info-black {
                            margin: 0;
                            top: 0;
                            left: 0;
                            right: auto;
                            bottom: auto;
                        }

                        .tooltip {
                            font-size: rem-calc(12);
                            line-height: rem-calc(18);

                            .tooltip-title {
                                font-size: rem-calc(12);
                                line-height: rem-calc(18);
                                margin-bottom: rem-calc(8);
                            }
                        }
                    }
                }

                .price {
                    display: block;
                    font-weight: $global-weight-dark;
                    font-size: rem-calc(14);
                    line-height: rem-calc(21);
                    color: $black;
                    text-align: right;
                    margin: 0 0 0 auto;

                    @include breakpoint (large up) {
                        font-size: rem-calc(16);
                        line-height: rem-calc(24);
                    }
                }
            }

            &-btn .button {
                width: 100%;
                margin: 0;
            }
        }
    }
}

.canvasSection {
    width: 100%;

    canvas {
        display: block;
    }
}

.personalization-close-modal {

    @include breakpoint (large up) {
        background: transparent;
        backdrop-filter: none !important;
        align-items: flex-end;
    }

    &.tingle-modal.tingle-modal--visible {
        backdrop-filter: none !important;
    }

    .tingle-modal-box {
        @include breakpoint (large up) {
            height: 100%;
            max-height: 100%;
            width: rem-calc(459);
        }

        &__content {
            padding: rem-calc(24);

            @include breakpoint (large up) {
                height: 100%;
            }
        }

        .modal-dialog {
            @include breakpoint (large up) {
                height: 100%;
                display: flex;
                flex-direction: column;
            }
        }

        .modal-content {
            width: 100%;

            @include breakpoint (large up) {
                height: 100%;
                flex: 1;
            }

            .close {
                right: 0;
                top: rem-calc(-4);
                margin: 0;

                @include breakpoint (large up) {
                    display: none;
                }

                svg {
                    width: rem-calc(24);
                    height: rem-calc(24);
                }
            }
        }

        .modal-title {
            display: block;
            font-weight: $global-weight-dark;
            font-size: rem-calc(14);
            line-height: rem-calc(21);
            padding-right: rem-calc(40);

            @include breakpoint (large up) {
                padding-right: 0;
                margin-bottom: rem-calc(40);
            }
        }

        .modal-text {
            display: block;
            font-size: rem-calc(14);
            line-height: rem-calc(21);
            margin-bottom: rem-calc(16);

            @include breakpoint (large up) {
                font-size: rem-calc(12);
                line-height: rem-calc(18);
            }
        }

        .modal-footer {

            @include breakpoint (large up) {
                margin: rem-calc(0 -24);
                padding: rem-calc(24 24 0);
                border-top: rem-calc(1) solid $light-gray;
            }

            .button {
                width: 100%;
                margin-bottom: rem-calc(16);

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.mobile-legal-modal {
    .tingle-modal-box {
        height: 100%;
        max-height: 100%;
        width: 100%;

        @include breakpoint (large up) {
            width: 90vw;
            max-height: 60%;
        }

        &__content {
            padding: 0;
        }

        .modal-content {
            width: 100%;

            .close {
                right: auto;
                left: rem-calc(16);
                top: rem-calc(13);
                margin: 0;

                @include breakpoint (large up) {
                    display: none;
                }

                svg {
                    width: rem-calc(24);
                    height: rem-calc(24);
                }

                &.close-x {
                    left: auto;
                    right: rem-calc(16);
                    top: rem-calc(13);

                    @include breakpoint (large up) {
                        display: block;
                    }
                }
            }
        }

        .modal-title {
            display: block;
            font-weight: $global-weight-dark;
            font-size: rem-calc(14);
            line-height: rem-calc(21);
            padding: rem-calc(18 16 18 56);
            border-bottom: rem-calc(1) solid $light-gray;
            margin: rem-calc(0 0 30);

            @include breakpoint (large up) {
                padding: rem-calc(18 16);
            }
        }

        .modal-text {
            display: block;
            font-size: rem-calc(12);
            line-height: rem-calc(18);
            margin-bottom: rem-calc(16);
            padding: rem-calc(0 16);

            a {
                text-decoration: underline;
                font-weight: $global-weight-normal;
            }
        }
    }
}

.fnPersButtonContainer {
    &.create {
        margin: 0;

        .fnPersButt {

            .icon {
                width: rem-calc(24);
                height: rem-calc(24);
                margin-right: rem-calc(5);
                margin-top: rem-calc(-4);
            }
        }
    }
}

.select-custom-PDP {
    .fnPersButtonContainer {
        &.create {
            position: absolute;
            top: auto;
            left: 0;
            right: 0;
            bottom: 0;
            margin: 0;
            padding: rem-calc(20);
            background: $white;

            .fnPersButt {
                width: 100%;
                background: $black;
                color: $white;
                font-size: rem-calc(14);
                letter-spacing: 0.2px;
                line-height: rem-calc(14);
                margin-bottom: 0;
                border: unset;

                &.disabled {
                    background: $black;
                    border-color: $black;
                    color: $white;
                }
            }
        }
    }
}

.fnCartOptions {
    &-personalization {
        padding-top: 0;

        p {
            font-size: rem-calc(14);
            line-height: rem-calc(21);
            margin-top: 0;
            margin-bottom: rem-calc(10);

            .pers-sym {
                font-family: 'Personalization';
            }

            .text-cart-personalization {
                @include breakpoint(medium down) {
                    font-size: rem-calc(12);
                    span {
                        display: inline-flex;
                        align-items: center;
                    }
                }
            }

            .font-dark {
                font-weight: $global-weight-dark;
            }
        }
        .fnPersButt {
            padding-top: rem-calc(10);
            padding-bottom: rem-calc(3);
            border-bottom: 1px solid #000000;
            color: #000000;
            font-size: rem-calc(14);
            letter-spacing: 0.2px;
            line-height: rem-calc(14);
            .quickcart & {
                margin-bottom: rem-calc(14);
            }
        }
        &-color {
            height: rem-calc(16);
            width: rem-calc(16);
            border-radius: 50%;
            display: inline-block;
            vertical-align: middle;
            margin: 0;

            &.personalization-color-border {
                border: rem-calc(1) solid $black;
            }
        }

        .return-info {
            padding-top: rem-calc(26);
            margin: 0;

            .tooltip-background-return {
                @include breakpoint(medium down) {
                    background-color: rgba($water-green, 0.6);
                    backdrop-filter: blur(5px);
                    position: fixed;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    z-index: 10;
                  }
            }

            .return-info-text {
                display: inline-block;
                vertical-align: middle;
                font-size: rem-calc(12);
                line-height: rem-calc(18);
            }

            .info-icon {
                display: inline-block;
                vertical-align: middle;
                width: rem-calc(16);
                height: rem-calc(16);
                margin-left: 0;
                position: relative;
                top: auto;
                right: auto;

                .icon-info-black {
                    margin: 0;
                    top: 0;
                    left: 0;
                    right: auto;
                    bottom: auto;
                }

                .tooltip {
                    display: block;
                    opacity: 1;
                    visibility: visible;
                    pointer-events: all;
                    font-size: rem-calc(12);
                    line-height: rem-calc(18);
                    top: 0;

                    @include breakpoint(medium down) {
                        position: fixed;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }

                    svg {
                        display: none;
                        position: absolute;
                        top: rem-calc(22);
                        right: rem-calc(24);
                        background: transparent;
                        width: rem-calc(20);
                        height: rem-calc(20);

                        @include breakpoint(medium down) {
                            display: block;
                        }
                    }

                    .tooltip-title {
                        font-size: rem-calc(12);
                        line-height: rem-calc(18);
                        margin-bottom: rem-calc(8);
                    }

                    a {
                        font-weight: $global-weight-normal;
                        text-decoration: underline;
                        border: none;
                    }
                }
            }
        }
    }
}
.personalization-close-modal, .mobile-legal-modal {
    z-index: 9999;
}
.text-cart-personalization img {
    width: rem-calc(16);
    margin-left: rem-calc(4);
}
