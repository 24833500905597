.half-slide{
    position: relative;
    cursor: pointer;
    .slide-center-text-button{
        position: absolute;
        top:50%;
        text-align: center;
        width: 100%;
        p{
            &.first-row{
                font-size: rem-calc(34);
                font-weight: 500;
                line-height: 0.3;
                letter-spacing: rem-calc(4);
                color:#ee3233;
                @include breakpoint (medium down) {
                    font-size: rem-calc(24); 
                } 
                &.mobile-white{
                    color:#ffff;
                }
            }
            &.second-row{
                font-size: rem-calc(48);
                font-weight: 500;
                line-height: 1;
                letter-spacing: rem-calc(4);
                color:#ee3233;
                @include breakpoint (medium down) {
                    font-size: rem-calc(38); 
                } 
                &.mobile-white{
                    color:#ffff;
                }
            }
            &.last-row{
                font-size: rem-calc(20);
                font-weight: 500;
                line-height: 0.8;
                letter-spacing: rem-calc(4);
                color:#ee3233;
                @include breakpoint (medium down) {
                    font-size: rem-calc(15); 
                } 
                &.mobile-white{
                    color:#ffff;
                }
            }
        }
        .button{ 
            font-size: rem-calc(12);
            padding: rem-calc(10 30);
            line-height: rem-calc(20);
            width:auto;
            border:0;
            background: #ee3233;
            outline: none;
            &.mobile-white{
                background: #ffff;  
                color:#ee3233;
            }
        }

    }
    .img-full{
        width:100%;
    }
}

.sales-background{
    padding: rem-calc(10 30);
    .carousel-title{
        font-size: rem-calc(70);
        color: #ffff;
        text-align: center;
    }
}

.slider-3img-dots{
    .slick-slide{
        outline: none;
    }
    .slick-dots {
        position: absolute;
        bottom: rem-calc(16);
        right: rem-calc(20);
        width: inherit;
        @include breakpoint (small down) { 
            right: inherit;
            width: 100%;
            bottom: 0;
        }
        li  {
            button {
                &:before {
                    width: rem-calc(6);
                    height: rem-calc(6);
                    border: rem-calc(1) solid $more-light-gray;
                    background: $more-light-gray;
                    @include breakpoint (small down) {
                        border: rem-calc(1) solid $white;
                        background: $white;
                        opacity: 0.5;
                    }
                }
            }

            &.slick-active {
                button {
                    &:before {
                        width: rem-calc(6);
                        height: rem-calc(6);
                        border: rem-calc(1) solid $black;
                        background: $black;   
                        @include breakpoint (small down) {
                            border: rem-calc(1) solid $white;
                            background: $white;  
                            opacity: 1;
                        }           
                        }
                }
            }

        }
    }
    .button-slider{
        margin: rem-calc(20 0);
        .button-primary{
            padding: rem-calc(10 20);
            width:auto;
            border-width: rem-calc(2);
            text-transform: uppercase;
            font-weight: 400;
            font-size: rem-calc(12);
        }
    }

    .image-box{
        padding:rem-calc(20 30);
    }
}
.mobile-title-sales{
    font-size:rem-calc(24);
    text-align: center;
    font-weight: 500;
    font-weight: bold;
}
