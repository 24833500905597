.standard-video-component {
    position: relative;
    .pd-video-container {
        height: rem-calc(490);
        @include breakpoint(small only) {
            height: auto;
        }

        .pd-standard-video {
            padding: rem-calc(50 0);
            @include breakpoint(small only) {
                padding: 0;
            }
        }
    }

    .standard-video-container {
        position: relative;
    }

    .video-text-wrapper-custom {
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;
        top: 40%;
        height: fit-content;

        .video-btns-custom {
            display: flex;
            margin: 0 auto;
            width: rem-calc(700);
            justify-content: space-evenly;
            @include breakpoint(small only) {
                display: flex;
                margin: 0 auto;
                flex-direction: column;
                align-items: center;
                width: auto;
            }
        }
        .text-box-slide:nth-child(1):nth-last-child(1) {
            @include breakpoint(small only) {
                display: none;
            }
        }
        .text-box-slide {
            @include breakpoint(small only) {
                display: inline-flex;
            }
        }

        .text-box-slide {
            @include breakpoint(small only) {
                margin-bottom: rem-calc(20);
            }
            .link-wrapper {
                transform: translateY(50%);
                width: rem-calc(240);
                margin: rem-calc(0);
                height: rem-calc(40);
                border-radius: rem-calc(50);
                border: rem-calc(2) solid;
                box-shadow: rem-calc(3 3 10) #00000029;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                &:hover {
                    cursor: pointer;
                }
                @include breakpoint(large down) {
                    width: rem-calc(200);
                }

                &:hover {
                    a.custom-video-link{
                        span {
                            transform: translateY(-103%);
                        }
                    }
                }
            }

            .custom-video-link {
                padding: 0;
                overflow: hidden;
                span {
                    position: relative;
                    display: inline-block;
                    transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
                    &:before {
                        position: absolute;
                        top: 100%;
                        content: attr(data-hover);
                        transform: translate3d(0, 0, 0);
                        @include breakpoint(medium down) {
                            display: none;
                        }
                    }
                }
            }
        }

        a {
            &:hover {
                border-color: none;
                border: 0;
            }
        }


        .h2-video-custom {
            font-size: rem-calc(40);
            font-weight: bold;
            color: #fff;
            filter: drop-shadow(0px 3px 6px #00000029);
            @include breakpoint(medium down) {
                font-size: rem-calc(30);
            }
            @media screen and(max-width: 630px) {
                font-size: rem-calc(30);
            }
            @media screen and(max-width: 1024px) {
                font-size: rem-calc(30);
            }
        }

        .h4-video-custom {
            font-size: rem-calc(20);
            font-weight: 600;
            color: #fff;
            filter: drop-shadow(0px 3px 6px #00000029);
            margin-bottom: rem-calc(15);
            @include breakpoint(medium down) {
                font-size: rem-calc(20);
            }
        }

    }

    .show-medium-custom {
        @media screen and(min-width: 630px) {
            display: block !important;
        }
    }
}

