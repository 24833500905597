.page-title{
    font-size: rem-calc(22);
}
.guide-type {
    color: black;
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
    margin-right: 0;
    max-width: rem-calc(900);
    @include breakpoint(large up) {
        margin-right: 14%;
    }
}

.guide_type__special-word {
    font-size: rem-calc(13);
    color: rgb(255, 102, 153);
    text-transform: uppercase;
    line-height: 1.4;
    letter-spacing: rem-calc(2);
    font-weight: bold;

}

.similar-guide__title {
    margin: rem-calc(20) 0;
    font-size: rem-calc(14);
    color: rgb(255, 102, 152);
    line-height: 2;
    letter-spacing: rem-calc(1.1);
    font-weight: bold;
    text-transform: uppercase;
}

.similar-guide__info {
    line-height: 2;
    font-size: rem-calc(12);
    color: rgb(0, 0, 0);
}

.similar-guide {
    margin-top: rem-calc(100);
}

.similar-guide__container {
    padding-right: 0;
    padding-left: 0;
    padding-top: rem-calc(25);
    display: flex;
    flex-wrap: wrap;

}

.similar-guide__item {
    margin-bottom: rem-calc(70);
    width: 100%;
    margin-right: 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
}

.similar-links__item {
    span {
        font-size: rem-calc(13);
        color: black;
        letter-spacing: rem-calc(1.2);
        margin-top: rem-calc(10);
    }
}

.product-guide__desc {
    text-align: center;
    font-weight: 400;
    line-height: rem-calc(20);
    font-size: rem-calc(13);
    padding: rem-calc(0 5);
    margin-top:rem-calc(70);
    @include breakpoint (medium down){
        font-size: rem-calc(17);
        padding: 0 4%;
    }
}

.product-guide__guide-cards {
    margin-top: rem-calc(65);
    a{
        border-bottom:0;
    }
    @include breakpoint (medium down){
        margin-top: 7.3%;
    }

}

.guide-card__h2 {
    padding: 0;
    font-size: 15px;
    color: rgb(0, 0, 0);
    font-weight: 500;
    margin: 16px 0 0;
    width: 100%;
    letter-spacing: rem-calc(0.75);
    @include breakpoint (small  down){
        padding: 0;
        color: rgb(0, 0, 0);
        font-weight: 500;
        margin-bottom: 0;
        font-size: rem-calc(7 + 2vw);
        margin-top: 4vw;
        word-break: break-word;
        word-wrap: break-word;
    }
}   

.guide-card__img-holder {
    height: auto;
    background: #f6edf6;
    padding: rem-calc(30 30 10 30);
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    font-weight: bold;
    min-height: rem-calc(140);
    width: 100%;	
    @include breakpoint (small down){
        padding: 15% 9% 6%;
        min-height: 70%;
    }
    .img-height{
        height:rem-calc(44);
    }		
}

.guide-card__footer span {
    font-size: rem-calc(13);
    line-height: auto;
    word-break: break-word;
    word-wrap: break-word;
    line-height: 1.5;
    max-width: calc(100% - (3px + 3vw));
    font-weight: 400;
}

.guide-card__footer {
    margin-top: auto;
    background: #eddced;
    display: flex;
    padding: rem-calc(15 30);
    align-items: center;
    font-size: rem-calc(13);
    letter-spacing: rem-calc(1.3);
    width: 100%;
    justify-content: space-between;
    @include breakpoint (small down){
        letter-spacing: rem-calc(1.8);
        padding: 7.3% 9%;
    }
}
/*----------------------------------------------------------*/
.product-guide__h1 {
    text-align: center;
    font-size: rem-calc(22);
    line-height: 1.364;
    font-weight: 500;
    margin-bottom: rem-calc(21);
    letter-spacing: rem-calc(1.3);
}

.guide-card__h2 {
    height: rem-calc(42);
    padding: 0;
    font-size: rem-calc(15);
    color: rgb(0, 0, 0);
    font-weight: 500;
    margin: rem-calc(16 0 0);        
    width: 100%;
}

.product-guide {
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;       
    max-width: rem-calc(900);
    margin: 0 auto;
}



.guide-card {
    height: auto;
    width: 100%;		
    margin-bottom: 3%;
    text-decoration: none;
    color: black;
}

.guide-card:hover {
    color: black;
    text-decoration: none;
}

.guide-card a {
    color: black;
}

.guide-card__footer img {
    width: rem-calc(16);
    margin-left: rem-calc(10);
}

.guide-card__img {
    height: rem-calc(44);
}

.guide-card__img.small-icon{
    height: rem-calc(30);
}

.guide-footer {
    margin-top: rem-calc(180);
}

.guide-footer__h3 {
    text-align: center;
    font-size: rem-calc(20);
    line-height: 1.5;
    margin-top: 0;
    letter-spacing: rem-calc(1.1);
    margin-bottom: rem-calc(16);
    padding: 0 8%;
}

.guide-footer__desc {
    text-align: center;
    font-size: rem-calc(13);
    color: rgb(102, 102, 102);
    padding: 0 8%;
}

.guide-footer__desc {
    display: flex;
}

.guide-footer__contact {
    display: flex;
    margin: 5.3% 0;
}

.guide-footer__chat {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: rem-calc(20 0);
    flex-direction: column;
    font-size: rem-calc(13);
    border-right: rem-calc(1) solid #cccccc;
    border-left: rem-calc(1) solid #cccccc;
    font-weight: 500;
}

.guide-footer__email {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: rem-calc(20 0);
    flex-direction: column;
    font-size: rem-calc(13);
    border-right: rem-calc(1) solid #cccccc;
    font-weight: 500;
}

.guide-footer a {
    text-decoration: underline;
    font-size: rem-calc(10);
    font-weight: 600;
}

.guide-footer__chat a {
    color: #49b42f;
}

.guide-card__footer a {
    color: black;
}

.guide-footer__email img {
    height: 14px;
    margin-bottom: 10px;
}

.guide-footer__chat img {
    height: 18px;
    margin-bottom: 10px;
}

.product-guide__h1 {
    padding: 0 4%;
    font-size: 30px;
    letter-spacing: 1.5px;
    margin-bottom: 17px;
}


.guide-card {           
    height: auto;
    width: 100%;
    margin-bottom: 6%;
    text-decoration: none;
    color: black;
    display: block;
}



.guide-card__footer img {
    width: rem-calc(16);
    margin-left: rem-calc(10);

}


.guide-footer {
    margin-top: 15.5%;
}

.guide-footer__h3 {
    padding: 0 20%;
    margin-bottom: 15px;
}

.guide-footer__desc {
    padding: 0 20%;
}

.guide-footer__h3 {
    padding: 0 10%;
}

.guide-footer__desc {
    padding: 0 10%;
}


.hidden {
    display: none;
}




.hoverBold {
    background-color: red;
}



.tb-bold {
    font-weight: 700 !important;
    color: black;
}

.similar-guide__h2 {
    font-size: 20px;
    line-height: 1.5;
    text-align: center;
    letter-spacing: 1.3px;
}



.similar-guide__item:nth-of-type(2n) {
    margin-right: 0;
}

.similar-guide__tip {
    font-size: 12px;
    font-style: italic;
    margin-bottom: 25px;
}

.similar-guide__img {
    height: 40px;
}

.similar-links {
    display: flex;
    flex-direction: column;
}

.similar-links:hover {
    text-decoration: none;
}

.similar-links__h2 {
    text-align: center;
    font-size: 20px;
    line-height: 1.5;
    letter-spacing: 1.2px;
    margin-bottom: 26px;
}


.guide-footer {
    margin-top: 152px;
}

.guide-footer__h2 {
    text-align: center;
    font-size: 20px;
    line-height: 1.5;
    margin-top: 0;
    letter-spacing: 1.2px;
    margin-bottom: 16px;
    padding: 0 8%;
}

.guide-footer__desc {
    letter-spacing: 1.4px;
    text-align: center;
    font-size: 13px;
    color: rgb(102, 102, 102);
    line-height: 1.692;
    padding: 0 8%;
}

.guide-footer__desc {
    display: flex;
}

.guide-footer__contact {
    display: flex;
    margin: 40px 0;
}

.guide-footer__chat {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    flex-direction: column;
    font-size: 13px;
    border-right: 1px solid #cccccc;
    border-left: 1px solid #cccccc;
    font-weight: 500;
}

.guide-footer__email {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    flex-direction: column;
    font-size: 13px;
    border-right: 1px solid #cccccc;
    font-weight: 500;
}

.guide-footer__email img {
    height: 15px;
    margin-bottom: 10px;
}

.guide-footer__chat img {
    height: 18px;
    margin-bottom: 10px;
}

.guide-footer a {
    text-decoration: underline;
    font-size: 10px;
    font-weight: 600;
}

.guide-footer__chat span {
    letter-spacing: 1.3px;
    margin-bottom: 4px;
}

.guide-footer__chat a {
    color: #49b42f;
    letter-spacing: 1.1px;
}

.guide-card__footer a {
    color: black;
    letter-spacing: 1.1px;
}

.similar-links__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.similar-links__item img {
    width: 16px;
}





.similar-guide__tip {
    line-height: initial;

}

.table-hidden {
    display: table-cell;
}


.similar-guide__h2 {
    font-size: calc(7.5px + 3vw);
    line-height: 1;
    letter-spacing: 2px;
    /*margin-bottom: 15px;*/
}


.similar-guide__img {
    height: calc(3px + 11vw);
}

.similar-guide__tip {
    font-size: calc(2px + 3vw);
    font-style: italic;
    margin-bottom: 7%;
}

.similar-links__h2 {
    font-size: calc(7.5px + 3vw);
    letter-spacing: 1.7px;
    margin-bottom: 20px;
    margin-top: 27%;
}

.guide-footer {
    margin-top: 19.3%;
}

.guide-footer__h2 {
    padding: 0 20%;
    margin-bottom: 15px;
}

.guide-footer__desc {
    padding: 0 20%;
    letter-spacing: 1.3px;
}

.guide-footer__h2 {
    padding: 0 10%;
    font-size: calc(7.5px + 3vw);
    letter-spacing: 2px;
    margin-bottom: 6.6%;
}

.guide-footer__desc {
    padding: 0 10%;
    font-size: calc(10px + 1vw);
    color: rgb(102, 102, 102);
    line-height: 1.224;
    letter-spacing: 1.8px;
}

.guide-footer__chat span {
    text-align: center;
    font-size: 13px !important;
    margin-bottom: 4px;
    letter-spacing: 1.3px;
}

.guide-footer__chat a {
    text-align: center;
    font-size: 10px !important;
    letter-spacing: 1.1px;
    color: black;

}

.guide-footer__email span {
    text-align: center;
    font-size: 13px !important;
    margin-bottom: 4px;
    letter-spacing: 1.3px;
}

.guide-footer__email img {
    margin-bottom: 13px;
}

.guide-footer__email a {
    text-align: center;
    font-size: 10px !important;
    color: black;
    letter-spacing: 1.1px;

}
.guide-footer__contact {
    margin: 7.6% 0;
}


.abs2::after {
    content: '';
    display: block;
    position: absolute;
    right: -1px;
    top: 0;
    height: 200px;
    width: 1px;
    background: #eddced;
}


.table-holder--wide {
    margin-left: calc(111px + 4vw) !important;
}
