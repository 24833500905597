@import "../layout/pdpplpcomponents";

body.tingle-enabled {
    position: fixed;
    overflow: hidden;
    left: 0;
    right: 0;
}

body.noscroll {
    overflow: hidden;
}

.modal {
    display: none;
}

.consent-modal .tingle-modal-box__content {
    padding: rem-calc(10 50 10 10);
}

.tingle-modal {
    background: rgba(130, 193, 189, 0.6);
    backdrop-filter: blur(5px);
    z-index: 1001;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    cursor: default;
    transition: opacity .35s cubic-bezier(0.165, 0.84, 0.44, 1);

    &.tingle-modal--visible {
        visibility: visible;
        opacity: 1;
        backdrop-filter: blur(10px) !important;

        .tingle-modal-box__footer {
            bottom: 0;
        }

        .tingle-modal-box {
            transform: translateY(0);
            opacity: 1;
        }
        .tingle-modal-box-resized {
            max-width: rem-calc(459);
            transform: translateY(0);
            opacity: 1;
        }
    }

    &__close,
    &__closeLabel {
        display: none;
    }

    &--noClose {
        &.tingle-modal__close {
            display: none;
        }
    }
}

.tingle-modal-box {
    position: relative;
    flex-shrink: 0;
    margin-top: auto;
    margin-bottom: auto;
    width: 90vw;
    max-width: rem-calc(800);
    max-height: 90vh;
    border-radius: 2px;
    background: #fff;
    cursor: auto;
    overflow-y: auto;
    transform: translateY(50px);
    opacity: 0;
    transition: all .35s cubic-bezier(0.165, 0.84, 0.44, 1);

    .close-custom{
        position: absolute;
        top: rem-calc(13);
        right: rem-calc(13);
        font-size: rem-calc(26);
        cursor: pointer;
    }

    &__content {
        padding: rem-calc(30 40);

        .close {
            position: absolute;
            top: rem-calc(13);
            right: rem-calc(-16);
            font-size: 2em;
            cursor: pointer;
        }

        button {
            margin-bottom: 0;
        }
    }

    .modal__closeIcon {
        position: absolute;
        top: rem-calc(13);
        right: rem-calc(13);
        font-size: 2em;
        cursor: pointer;
        color: black;
    }

    &__footer {
        //padding: rem-calc(10 20);
        width: auto;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        background-color: #f5f5f5;
        cursor: auto;
        text-align: right;

        &:after {
            display: table;
            clear: both;
            content: "";
        }

        &--sticky {
            position: fixed;
            bottom: -200px; // TODO: find a better way
            z-index: 10001;
            opacity: 1;
            transition: bottom .35s cubic-bezier(0.165, 0.84, 0.44, 1);
        }
    }

    button {
        margin-right: 1em;
    }
}

.consent-modal {
    & .tingle-modal-box {
        position: absolute;
        bottom: 3%;
        right: 3%;
    }
}

.quickview-modal.quickview-modal--tezenis {
    .tingle-modal-box {
        max-width: rem-calc(459);
        * {
            outline: none;
        }
        &__content {
            padding: rem-calc(27 40 24 24);
            @include breakpoint (small down) {
                padding: rem-calc(16);
			}
        }
        .quickviewCarousel__slider-container {
            .quickviewCarousel__slide {
                .productImageCarouselItem {
                    cursor: default;
                }
            }
            .next-button {
                margin-right: 0;
            }
        }
        .go-to-page {
            display: inline-block;
            position: absolute;
            bottom: 0;
            right: 0;
            margin: 0 rem-calc(20) rem-calc(20) 0;
            a {
                &:hover {
                    border: none;
                    border-color: transparent;
                }
                span {
                    display: inline-block;
                    font-size: rem-calc(14);
                    font-weight: 500;
                    line-height: rem-calc(18);
                    padding: rem-calc(3 10);
                    border-bottom: 2px solid $black;
                }
            }
        }
    }
    section {
        margin-bottom: rem-calc(25);
    }
    .promo-and-name-cell {
        width: 100%;
    }
    .quickview-price {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-top: rem-calc(4);
    }
    .name-wishlist {
        display: flex;
        width: 100%;
        span.product-name {
            font-size: rem-calc(14);
            line-height: rem-calc(21);
            margin-top: rem-calc(8);
            a{
                font-weight: 400;
            }
        }
        .button.button-primary:not(:disabled):not(.disabled) {
            border-color: #fff;
            background: #fff;
            color: $black;
        }
        .add-to-wish-list {
            flex-basis: 32px;
            padding: 0;
            margin: 0;
            position: relative;
            outline-color: #fff;
            @media screen and (max-width: 768px) {
                flex-basis: 30px;
            }
            svg {
                position: absolute;
                top: 0;
                right: 0;
                width: auto;
                height: auto;
                max-width: rem-calc(22);
                transition: all .2s ease-out;
                @media screen and (max-width: 768px) {
                    transform: scale(0.8);
                    transform-origin: top right;
                }
                &.empty {
                    opacity: 1;
                    visibility: visible;
                    pointer-events: auto;
                }
                &.full {
                    opacity: 0;
                    visibility: hidden;
                    pointer-events: none;
                }
            }
            &:hover {
                background: $white;
                color: $black;
                /*svg {
                    &.empty {
                        opacity: 0;
                        visibility: hidden;
                        pointer-events: none;
                    }
                    &.full {
                        opacity: 1;
                        visibility: visible;
                        pointer-events: auto;
                    }
                }*/
            }
            &.added {
                color: $primary !important;
                background: white;
                svg {
                    &.empty {
                        opacity: 0;
                        visibility: hidden;
                        pointer-events: none;
                    }
                    &.full {
                        color: $primary;
                        opacity: 1;
                        visibility: visible;
                        pointer-events: auto;
                    }
                }
            }
        }
    }
    .m-d-price-container {
        margin-top: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: rem-calc(32);
        @media (max-width: 1440px) {
            margin-bottom: rem-calc(28);
        }
        @media screen and (max-width: 1024px) {
            margin-bottom: rem-calc(20);
        }
    }
    .quickview-price {
        @include price-tezenis;
        .price--tezenis.cc-hidePercentage {
            display: inline-block;
        }
        .price--tezenis {
            span {
                font-size: rem-calc(14);
                font-weight: 500;

                &.strike-through{
                    margin-right: rem-calc(6);
                }
            }
            .cc-CountryWithDifferentTemplate {
                .strike-through {
                    margin: rem-calc(0 0 0 8);
                }
            }
        }
    }
    .cc-omnibusStack {
        &-strikeThrough {
            text-decoration: none;
        }
    }

    @include product-info-details;
    @include pdp-carousel;
}

.giftcard-balance {
    background: #F2F4ED;
    padding: rem-calc(19 18);
    font-size: rem-calc(13);
    line-height: rem-calc(19);
    margin-top: rem-calc(15);
    margin-bottom: rem-calc(15);
    .giftcard-checkbalance-success {
        &.bold {
            font-weight: bolder;
            padding-left: rem-calc(2);
        }
    }
}


.multilanguage-popup{
    .tingle-modal-box{
        border-radius: rem-calc(10);
        width: 33vw;
        @include breakpoint (tablet down) {
            width: 90vw;
        }
        @include breakpoint (tablet only) {
            width: 50vw;
        }
        .multilanguage{
            button{
                border-radius: rem-calc(40);
                width: rem-calc(225);
                @include breakpoint (tablet down) {
                    width: 100%;
                }
            }
            .boolean-field{
                @include breakpoint (tablet down) {
                    padding-right: rem-calc(20);
                }
                img{
                    display:none;
                    width: rem-calc(20);
                    height: rem-calc(18.5);
                }
                input{
                    &:checked{
                        color: #ff6699;
                        & + .radio-input{
                            &::before{
                                border-color: #ff6699;
                                outline: none;
                                width: 16px;
                                height: 16px;
                                top: 2px;
                                left: 0;
                            }
                        }
                        & ~ img{
                            display: inline-block;
                        }
                    }
                }
                .radio-input{
                    @include breakpoint (tablet down) {
                        display: none;
                    }
                    &::after{
                        background-color: #ff6699;
                        top: 6px;
                        height: 8px;
                        width: 8px;
                        left: 4px;
                    }
                }
            }
            hr{
                display: none;
                &:not(:last-child){
                    border-color: #c0c0c0;
                    margin: rem-calc(10) auto;
                    display: block;
                }
            }
        }
    }
}

// Modal in order return process and checkout - 'shipping' stage, as well as in the popup of an express checkout.
.green-choice {

    // This is for an express checkout since it has a z-index of 1001.
    z-index: 1002;

    .tingle-modal-box {
        @include breakpoint (medium up){
            width: 33%
        }
        &__content {
            padding: rem-calc(30);
            text-align: center;
        }
    }

    .close {
        top: rem-calc(12);
        right: rem-calc(14);
        margin-right: 0;
    }

    h3 {
        font-size: rem-calc(18);
        font-weight: bold;
        color: #5BC480;
        margin-bottom: 20px;
        padding: 0 rem-calc(14);
    }
    p {
        font-size: rem-calc(12);
        color: #797979;
    }

}
