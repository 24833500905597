h1 {
  &.page-title {
    font-size: rem-calc(22);
    line-height: rem-calc(28);
    padding-left: rem-calc(0);
    margin: rem-calc(0 0 25 0);
    @include breakpoint(small down) {
      padding: 0;
      text-align: center;
      margin: rem-calc(25 0 25 0);
    }
  }
}

.accordion-content {
  .behave-ul-default {
    list-style-type: disc !important;
    padding: 0 !important;
    margin-left: rem-calc(18) !important;
  }
}

.behave-li-default {
  border: none !important;
  list-style-type: disc !important;
  display: list-item !important;
  padding: 0 !important;
}

.behave-li-default-letters {
  border: none !important;
  list-style-type: upper-latin !important;
  display: list-item !important;
  padding: 0 !important;
}

.table-privacy-policy-legal {
  overflow-x: auto;
  table {
    width: rem-calc(1900);
    border-collapse: collapse;
    th {
      padding: rem-calc(10);
      text-align: left;
      text-transform: uppercase;
    }
    tr {
      td {
        &:first-child {
          width: rem-calc(166);
        }
        padding: rem-calc(10);
        width: 300px;
        text-align: left;
        vertical-align: super;
        border-bottom: 1px solid $border-gray;
      }
    }
  }
  &::-webkit-scrollbar {
    width: rem-calc(5);
    height: rem-calc(4);
  }

  &::-webkit-scrollbar-thumb {
    background-color: $medium-gray;
    border-radius: rem-calc(5/2);
  }
}

.faq-section {
  min-height: rem-calc(969);
  &.body_wrap {
    padding: 0 15% 0 0;
    @include breakpoint(medium down) {
      padding: 0;
      max-width: 100%;
      width: 100%;
      margin: 0;
    }
    @include breakpoint(small down) {
      padding: 0;
    }
  }

  /*     .faq-breadcrumb {
      font-size: rem-calc(13);
      line-height: rem-calc(16);

      ul {
        text-align: center;

        li {
          display: inline-block;

          span {
            color: $black;
          }

          + li:before {
            padding: rem-calc(0 5);
            color: $more-light-gray;
            content: "/\00a0";
          }
        }

      }
    } */
  .img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
  }

  .cs-content {
    .box {
      margin-right: rem-calc(75);
      float: left;
      margin: rem-calc(0 30 30 30);

      a {
        text-decoration: none;
        color: inherit !important;
        font-size: rem-calc(14) !important;
        border-bottom: 0;

        &:hover {
          text-decoration: none !important;
        }
      }
    }

    .grid-x {
      &.title,
      .title {
        margin: rem-calc(15 0 35 0);
        //display: table;
        border-bottom: rem-calc(1) solid $border-gray;
        padding-bottom: rem-calc(5);
        width: 100%;

        span {
          &.big {
            font-size: rem-calc(30);
            font-weight: 500;
            margin-right: rem-calc(20);
            color: $medium-light-gray;
            text-transform: uppercase;
          }
        }

        .cell {
          padding: 0;
        }
      }

      .subtitle {
        font-weight: 400;
        color: $medium-light-gray;
        margin-left: rem-calc(25);
        line-height: rem-calc(44);
      }

      .icon {
        text-align: center;
        margin-bottom: rem-calc(10);
        border: rem-calc(1) solid $border-light-gray;
        transition: 0.2s ease-in;
        padding: rem-calc(30);

        &:hover {
          border: rem-calc(1) solid $white;
          transition: 0.2s ease-in;
          background-color: $over-gray;
        }

        img {
          width: rem-calc(60);
        }
      }

      .text-box {
        text-align: center;
        margin-bottom: 0;
        border: rem-calc(1) solid $border-light-gray;
        transition: 0.2s ease-in;
        padding: rem-calc(25 10);
        width: rem-calc(145);
        height: 100%;

        &:hover {
          border: rem-calc(1) solid $white;
          transition: 0.2s ease-in;
          background-color: $over-gray;
        }
      }

      .service {
        p {
          font-weight: bold;
          max-width: rem-calc(120);
          text-transform: uppercase;
          font-size: rem-calc(11);
        }

        &.kids {
          min-height: rem-calc(86);
          @include breakpoint(small down) {
            min-height: rem-calc(100);
          }
          p {
            padding-top: 10px;
          }
        }
      }
    }

    .size-guide {
      a {
        font-weight: bold;
        font-size: rem-calc(12) !important;
        text-decoration: none;
        /* color: #463823;*/
        //  display: table;
        &:hover {
          text-decoration: none;
        }
      }

      .box {
        margin: rem-calc(0 10 20 20);
      }

      p {
        margin: 0;
      }
    }

    .christmas {
      .icon {
        img {
          &.star {
            width: rem-calc(30);
            position: absolute;
            left: -rem-calc(13);
            top: -rem-calc(11);
            transform: rotate(50deg);
            transition: 1s;
          }

          &.christmas-gift {
            transition: 0.3s;
          }
        }

        &:hover {
          img {
            &.star {
              transition: 1s ease-out;
              transform: rotate(720deg);
            }

            &.christmas-gift {
              transition: 0.3s;
              transform: scale(1.2) rotate(10deg);
            }
          }
        }
      }
    }

    @include breakpoint(medium down) {
      .grid-x {
        .title {
          text-align: center;
        }
      }
      .box {
        min-height: rem-calc(135);
        margin: rem-calc(0 0 30 15);
      }
    }

    @include breakpoint(small down) {
      .size-guide {
        .box {
          margin: rem-calc(0 10 20 10);

          &.line-height {
            line-height: rem-calc(25) !important;
          }
        }

        .box:nth-child(6) {
          .service p {
            padding-top: rem-calc(20) !important;
          }
        }
      }
      .grid-x {
        margin-left: 0;
        margin-right: 0;

        .text-box {
          padding: rem-calc(20 10);
          width: rem-calc(95);
          min-height: rem-calc(100) !important;
        }

        .size-guide {
          .service {
            p {
              max-width: inherit !important;
              font-size: rem-calc(11);
            }
          }

          .box {
            margin: rem-calc(0 10 0 10);
          }
        }

        .icon {
          padding: rem-calc(8);
        }

        .service p {
          font-weight: bold;
          max-width: rem-calc(80);
          font-size: rem-calc(12);
        }

        .title {
          margin: rem-calc(0 0 30 0);
          padding-bottom: rem-calc(5);

          span {
            &.big {
              margin-right: 0;
            }
          }
        }

        .subtitle {
          margin-left: 0;
          line-height: normal;
        }
      }

      .box {
        margin: rem-calc(0 0 0 10);
        line-height: rem-calc(16);
        min-height: rem-calc(125);
        margin-bottom: 0;
      }
    }
  }

  /* ORDERS */

  .cs-orders {
    .box {
      width: 25%;
      border: rem-calc(1) solid $border-light-gray;
      float: left;
      opacity: 0.5;
      transition: 0.2s ease-in;
      min-height: rem-calc(110);
      height: 100%;

      a {
        text-decoration: none !important;
        color: inherit !important;
        font-size: 11px !important;
        border-bottom: 0;
      }

      &:hover {
        transition: 0.2s ease-in;
        background-color: $over-gray;
        opacity: 1;
      }

      &.selected {
        opacity: 1;
        background-color: $over-gray;
      }
    }
    .grid-x .title {
      margin: rem-calc(15 0 35 0);
      display: table;
    }

    .grid-x .title span.big {
      font-size: rem-calc(40);
      font-weight: 100;
      margin-right: rem-calc(25);
      color: $medium-light-gray;
    }

    .grid-x .title .col-sm-10 {
      padding: rem-calc(21 30);
    }

    .grid-x .title span.subtitle {
      font-style: italic;
      font-weight: 100;
      color: $medium-light-gray;
    }

    .grid-x .icon {
      text-align: center;
      padding: rem-calc(15 25);
    }

    .grid-x .icon:hover {
    }

    .grid-x .icon img {
      width: rem-calc(40);
    }

    .grid-x .service p {
      font-weight: bold;
      font-size: rem-calc(11);
      padding: rem-calc(0 11);
      @include breakpoint(small down) {
        padding: rem-calc(0 8);
      }
    }

    .collapse.in {
      border-bottom: rem-calc(1) solid $border-gray;
    }

    .bottom-image {
      margin: rem-calc(30 0);
    }

    p.bottom-cta {
      font-size: rem-calc(11);
      margin-top: rem-calc(50);
      color: $medium-gray;
      a {
        text-decoration: none;
        border-bottom: rem-calc(1) solid transparent;
        &:hover {
          border-color: $dark-gray;
        }
      }
    }

    @media (max-width: 1024px) {
      .box {
        float: left;
      }

      .grid-x .icon {
        padding: rem-calc(15 20);
      }
    }

    @media (min-width: 768px) {
      .col-sm-6,
      .cs-content .col-lg-6 {
        padding: 0;
        width: 48%;
      }
    }

    @media (max-width: 768px) {
      .box {
        float: left;
        width: 25%;
      }

      .questions h1 {
        text-align: center !important;
      }

      p.bottom-cta {
        padding: rem-calc(0 50);
        text-align: center;
      }
    }
  }

  /* ACCORDION*/
  .accordion[disabled] .accordion-title {
    cursor: not-allowed;
  }

  .accordion.accordion-page {
    margin-left: 0;
    background: $white;
    list-style-type: none;

    .accordion-item {
      ul,
      li {
        display: block;
        list-style-type: none;
        border-bottom: 0;
        margin-left: 0;
        padding: 0;
      }
      ul {
        padding-bottom: rem-calc(20);
      }

      &:first-child > :first-child {
        border-radius: 0 0 0 0;
      }

      &:last-child > :last-child {
        border-radius: 0 0 0 0;
      }

      &:last-child.active {
        .accordion-title {
          border-bottom: 0;
        }
      }

      &:last-child {
        .accordion-title {
          border-bottom: rem-calc(1) solid $border-light-gray;
        }
      }
    }

    .accordion-title {
      position: relative;
      display: block;
      padding: rem-calc(20 30 20 10);
      border: 0;
      border-top: 1px solid $border-light-gray;
      background-color: $white;
      -webkit-transition: opacity 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      font-size: rem-calc(13);
      line-height: rem-calc(20);

      &:hover,
      &:focus {
        background-color: $white;
      }
    }

    .is-active > .accordion-title::before {
      content: "\2013";
    }

    .accordion-content {
      display: none;
      padding: rem-calc(16);
      border: 0;
      border-bottom: 0;
      background-color: $white;

      p {
        &.share {
          font-style: normal;
          color: $medium-gray;
          font-size: rem-calc(11);
        }
        a {
          text-decoration: none;
          border-bottom: rem-calc(1) solid transparent;
          &:hover {
            border-color: $dark-gray;
          }
        }
      }
      .all-ico-payments {
        padding: rem-calc(10 0);
      }

      .table-holiday {
        width: 100%;
        .table-row {
          width: 100%;
          padding: rem-calc(5 0);
          border-top: rem-calc(1) solid $border-light-gray;
          display: inline-block;
          .table-cell {
            width: 50%;
            text-align: center;
            display: inline-block;
            float: left;
          }
        }
      }
    }

    :last-child > .accordion-content:last-child {
      border-bottom: rem-calc(1) solid $border-light-gray;
    }
  }
}

//faq-section

.new-customer-service-menu {
  .customer-service-menu {
    width: 100%;
    //background-color: #f8f9f6;
    list-style-type: none;
    margin: 0;
    li {
      //border-top: 1px solid #cccccc;
      &.background-active-item {
        background-color: #f2f4ed;
      }
      // &:last-child {
      //   border-bottom: 1px solid #cccccc;
      // }
      a {
        //padding: rem-calc(15);
        width: 100%;
        position: relative;
        display: inline-flex;
        align-items: center;
        display: -webkit-inline-flex;
        -webkit-align-items: center;
        border: 0;
        font-size: rem-calc(16);
        font-weight: 500;
        //line-height: rem-calc(24);
        letter-spacing: rem-calc(0.2);
        //text-transform: uppercase;
        @include breakpoint (small down){
          vertical-align: text-top;
        }
        &:hover {
          border: 0;
        }
        img {
          margin-right: rem-calc(10);
          width: rem-calc(24);
          &.arrow-back {
            width: rem-calc(16);
            height: rem-calc(16);
            position: absolute;
            color: rgba(28, 28, 28, 1);
            right: 10px;
          }
        }
      }
    }
  }
}

.extra-margin-top {
  //margin-top: rem-calc(30);
  @include breakpoint(small down) {
    margin-top: 0;
  }
}

.customer-service-page-cell-left {
  height: fit-content;
  top: rem-calc(70);
  position: sticky;
  //padding-right: rem-calc(20);
  @include breakpoint(small down) {
    padding-right: 0;
  }
}

.customer-service-page-cell-right {
  // padding-left: rem-calc(20);
  // @include breakpoint(small down) {
  //   padding-left: 0;
  // }
  padding-left: rem-calc(161);
  @include breakpoint(large down) {
    padding-left: rem-calc(80);
  }
  @include breakpoint(medium down) {
    padding: 0;
    margin: 0;
  }
}

.new-customer-service {
  h5 {
    font-weight: 600;
    font-size: rem-calc(26);
    line-height: rem-calc(24);
    font-style: normal;
    color: #1c1c1c;
    border-bottom: rem-calc(1) solid $light-gray;
    padding: rem-calc(7 0 20 0);
    margin-bottom: 0;
    @include breakpoint(small down) {
      margin-top: rem-calc(20);
    }
  }
  p {
    font-size: rem-calc(14);
    font-weight: 400;
    line-height: rem-calc(21);
    letter-spacing: rem-calc(0.2);
    color: #1c1c1c;
    text-align: left;
    width: 100%;
    margin: rem-calc(20) 0;
  }
  .banner-important-warning-customer-service {
    width: 100%;
    background-color: #fdcff2;
    padding: rem-calc(20) rem-calc(30);
    margin: 0 auto;
    position: relative;
    p {
      margin: 0;
    }
    .ico-close-warning-banner {
      position: absolute;
      top: rem-calc(10);
      right: rem-calc(15);
      img {
        width: rem-calc(10);
      }
    }
  }
  h6 {
    font-weight: 600;
    font-size: rem-calc(16);
    line-height: rem-calc(24);
    width: 100%;
    text-align: left;
    margin: rem-calc(40 0 15 0);
    //margin: rem-calc(40) auto 0;
    @include breakpoint(medium down) {
      padding-left: rem-calc(16);
    }
  }

  .accordion {
    // margin: rem-calc(20) 0;
    margin: 0;
    @include breakpoint(medium down) {
      padding: rem-calc(0 16);
    }
    .accordion-item {
      border-bottom: rem-calc(1) solid $light-gray;
      // &.active {
      //   //background-color: rgba(242, 242, 242, 1);
      // }
      .accordion-title {
        font-size: rem-calc(14);
        font-style: normal;
        font-weight: 600 !important;
        letter-spacing: rem-calc(0.2);
        line-height: rem-calc(21);
        color: #1c1c1c;
        padding: rem-calc(25 25 25 0);
        position: relative;
        display: inline-flex;
        align-items: center;
        display: -webkit-inline-flex;
        -webkit-align-items: center;
        width: 100%;
        &:after {
          font-weight: 500;
          top: auto;
          right: rem-calc(5);
          font-size: rem-calc(14);
          transform: none;
        }
        &.active {
          //border-bottom: none;
          &:after {
            content: url('../images/minus-icon.svg') !important;
            right: 0%;
          }
        }
        &:after {
          content: url('../images/plus-icon.svg');
          top: 50%;
          width: rem-calc(20);
          height: rem-calc(20);
          transform: rotate(90deg);
          transform: translateY(-50%);
          right: 0%;
        }
      }
      .accordion-content {
        padding: 0;
        //padding-top: rem-calc(16);
        //margin-top: rem-calc(16);
        p {
          margin: 0;
          font-weight: 400;
          font-size: rem-calc(14);
          line-height: rem-calc(21);
          letter-spacing: rem-calc(0.2);
          text-align: left;
          color: rgba(0, 0, 0, 1);
          a {
            text-decoration: underline;
          }
        }
        ul {
          padding-bottom: 0;
          li {
            font-size: rem-calc(12);
            border: 0;
            width: 100%;
            &::before {
              content: "-";
            }
          }
          &.return-step {
            width: 100%;
            margin-left: 0;
            li {
              display: inline-flex;
              align-items: center;
              display: -webkit-inline-flex;
              -webkit-align-items: center;
              padding-left: 0;
              padding-right: 0;
              &:nth-child(1){
                img {
                  content: url('../images/icon-shipment-2.svg');
                  margin-right: rem-calc(10);
                  width: rem-calc(18);
                }
              }
              &:nth-child(2){
                img {
                  content: url('../images/icon-returns-2.svg');
                  margin-right: rem-calc(10);
                  width: rem-calc(18);
                }
              }
              &:nth-child(3){
                img {
                  content: url('../images/icon-refund-2.svg');
                  margin-right: rem-calc(10);
                  width: rem-calc(18);
                }
              }
              &::before {
                content: "";
              }
            }
          }
        }
      }
    }
  }
  .cs-back {
    width: 100%;
    display: inline-flex;
    align-items: center;
    display: -webkit-inline-flex;
    -webkit-align-items: center;
    font-size: rem-calc(16);
    font-weight: 500;
    font-style: normal;
    line-height: rem-calc(24);
    border-bottom: 1px solid $light-gray;
    padding: rem-calc(25 0 25 16);
    img {
      margin-right: rem-calc(17.5);
    }
  }
}

.customer-service-list-style {
  @include breakpoint(medium down) {
    margin: 0;
    overflow: none;
  }
  &.customer-service-menu {
    box-sizing: border-box;
    margin: 0;
    @include breakpoint(medium down) {
      padding-top: rem-calc(24);
      //margin: rem-calc(0 16);
    }
  }
  @include breakpoint(medium down) {
    //padding: rem-calc(0 16);
    //flex: none;
  }

  li {
    width: rem-calc(418);
    //min-height: rem-calc(72);
    background-color: #F2F2F2;
    border-radius: rem-calc(4);
    padding: rem-calc(16 0 16 26);
    margin-bottom: rem-calc(8);
    list-style: none;
    border: 0;
    @include breakpoint(medium down) {
      border-radius: calc-rem(4);
      margin: rem-calc(0 16 8 16);
      padding: rem-calc(25.5 0 25.5 24);
      //height: rem-calc(72);
    }
    &.background-active-item {
      background-color: $water-green;
    }
    &:hover {
      //background-color: rgba(242, 244, 237, 1);
    }
    @include breakpoint(large down) {
      width: auto;
    }
    @include breakpoint(medium down) {
      min-width: rem-calc(343);
      min-height: rem-calc(72);
    }
  }

  li {
    font-size: 1rem;
    a {
      display: flex;
      justify-content: flex-start;
      flex-wrap: nowrap;
      align-items: center;
      border-bottom: none !important;
      vertical-align: middle;
    }
  }
}

.new-customer-service{
  .accordion{
    &.initialized{
      .accordion-item{
        &.active{
          .accordion-title{
            &:after{
              content: url('../images/minus-icon.svg') !important;
              right: 0%;
              }
            }
          }
        }
      }
  }
}

.new-customer-service{
  .text-content {
    @include breakpoint(medium down) {
      padding: rem-calc(0 16);
    }
  }
  .option-shipping-contet {
    width: 100%;
    display: inline-block;
    .option-shipping {
      &:nth-child(1){
            img{
              content: url('../images/icon-shipment-2.svg');
              width: rem-calc(18);
              margin-right: rem-calc(10);
          }
      }
      &:nth-child(2){
            img{
              content: url('../images/icon-shipment-2.svg');
              width: rem-calc(18);
              margin-right: rem-calc(10);
          }
      }
      &:nth-child(3){
          img{
            content: url('../images/icon-stores-2.svg');
            width: rem-calc(18);
            margin-right: rem-calc(10);
        }
      }
      &:nth-child(4){
        img{
          content: url('../images/icon-stores-2.svg') !important;
          width: rem-calc(18);
          margin-right: rem-calc(10);
      }
     }
      width: 100%;
      display: inline-block;
      text-align: left;
      margin: rem-calc(5 0);
      @include breakpoint (medium down){
        margin-left: 0 !important;
      }
      &:first-child {
        margin-top: rem-calc(20) !important;
      }
      .option-title {
        font-size: rem-calc(14);
        font-weight: 500;
        display: inline-flex;
        align-items: center;
        display: -webkit-inline-flex;
        -webkit-align-items: center;
        width: 100%;
        //flex-wrap: wrap;
        img {
          width: rem-calc(18);
          margin-right: rem-calc(10);
        }
        p {
          text-align: left;
          margin: rem-calc(0 0 0 5) !important;
          font-size: rem-calc(14);
          font-weight: 400;
          width: auto;
          //width: 100%;
          margin: 0;
        }
      }
    }
  }
}

.returns-basic-info{
  .option-shipping-contet{
    .option-shipping{
      &:nth-child(1){
            img{
              content: url('../images/icon-shipment-2.svg');
              width: rem-calc(18);
              margin-right: rem-calc(10);
          }
      }
      &:nth-child(2){
            img{
              content: url('../images/icon-stores-2.svg');
              width: rem-calc(18);
              margin-right: rem-calc(10);
          }
      }
      &:nth-child(3){
          img{
            content: url('../images/icon-orders-2.svg');
            width: rem-calc(18);
            margin-right: rem-calc(10);
        }
      }
    }
  }
}



.new-customer-service {
  @include breakpoint(medium down) {
    .option-shipping-contet {
      .option-shipping {
        font-size: rem-calc(14);
        margin: rem-calc(20);
        text-align: left;
        margin-top: 10px;
        &:first-child{
          margin-top: 0;
        }
        &.option-title{
          flex-wrap: wrap;
          p{
            width: 100%;
			      margin: 0;
          }
        }
      }
    }
  }
}

.customer-service-container {
  padding: rem-calc(0 40);
  @include breakpoint(medium down) {
    padding: 0;
  }
  h2 {
    font-weight: 600;
    font-size: rem-calc(32);
    line-height: rem-calc(36);
    font-style: normal;
    color: rgba(0, 0, 0, 0.87);
    padding: rem-calc(40 16 23 0);
    border-bottom: rem-calc(1) solid #cccccc;
    @include breakpoint (medium down){
      font-weight: 600;
      font-size: rem-calc(20);
      line-height: rem-calc(30);
      font-style: normal;
      padding: rem-calc(24 16 16 16);
      margin: 0;
    }
  }
}

.returns-basic-info {
  @include breakpoint(medium down) {
    padding: rem-calc(0 16);
  }
}

div[data-querystring^="cid=general-info"] .general-info-item,
div[data-querystring^="cid=orders"] .orders-item,
div[data-querystring^="cid=shipping"] .shipping-item,
div[data-querystring^="cid=returns-refunds"] .returns-item,
div[data-querystring^="cid=customer-service-refunds"] .refund-item,
div[data-querystring^="cid=payments"] .payments-item,
div[data-querystring^="cid=gifts"] .gift-item,
div[data-querystring^="cid=loyalty"] .fidelity-item,
div[data-querystring^="cid=stores"] .stores-item,
div[data-querystring^="cid=customer-service-account"] .account-item {
  background-color: $water-green-light;
  border: rem-calc(1) solid $water-green;
}
