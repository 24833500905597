.top-header-placement {
  display: none;
}
.page[data-action="Home-Show"] {
  .top-header-placement{
      display: block;
      z-index: 20;
  }
}

#cc-mini-cart-adapter {
  display: none;
}

.page[data-action="Home-Show"] {
  #cc-mini-cart-adapter{
      display: block  !important;
      z-index: 20;
  }
}

.registration-header{
  background: white;
  border-bottom: 1px solid $light-gray;
  @include breakpoint (medium up) {
    height: rem-calc(56);

    nav.register-navigation {
      height: auto;
    }
  }
}
.register-navigation{
  height: unset;
  .register-navigation-container{
    padding: 0;
    margin: rem-calc(10 0);
  }
}
.main-header {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 3;
  transition: transform 0.3s ease;

  .navcheckout {
    @include breakpoint(large up) {
      background: $white;
    }
    &.new-checkout{
      padding: rem-calc(0);
      @include breakpoint (small down) {
        padding: rem-calc(0);
        width: 100%;
      }
      .new-checkout-header{
        padding: rem-calc(0 40);
        border-bottom: rem-calc(1) solid $light-gray;
        @include breakpoint (small down) {
          padding: rem-calc(0 16);
          width: 100%;
        }
      }
      .logo-container{
        img{
          height: rem-calc(39);
        }
        @include breakpoint (medium down){
          img{
            height: rem-calc(28);
            max-width: unset;
            width: auto;
            margin: 0;
          }
        }
      }
    }
  }

  &--tezenis {
    z-index: 10;

    &.open {
      @include breakpoint (small down) {
          z-index: 901 ;
      }
    }
  }

  nav {
    padding: rem-calc(0 40);
    background: $white;
    transition: background 0.3s ease;
    position: relative;
    z-index: 3;
    height: rem-calc(56);

    @include breakpoint(small down) {
      padding: rem-calc(2 5);
    }

    @include breakpoint(large up) {
      background: $white;

      &.solid {
        background: $white;
      }
    }

    &.navigation--tezenis {
      .navigation--tezenis-container {
        height: rem-calc(56);

        @include breakpoint (small down) {
          justify-content: space-around;
        }
        @include breakpoint(medium down){
          display: flex;
          justify-content: space-between;
        }
        .after-icon {
          &:hover {
            &::before {
              background-color: $black;
              border-radius: rem-calc(4);
              content: '';
              height: rem-calc(4);
              left: calc(50%);
              transform: translateX(-50%);
              position: absolute;
              top: 100%;
              width: rem-calc(4);
            }
          }
        }
      }
      @include breakpoint(medium down) {
        padding-left: rem-calc(15);
        padding-right: rem-calc(15);
        width: 100%;
      }
      .navbar-content {
        @include breakpoint(medium down) {
          margin: 0;
          padding: rem-calc(25 40 0);
        }
        p.cell.dark.caption.text-primary.no-margin {
          padding: rem-calc(30 0);
          background-color: rgba(253, 207, 242, 0.5);
          text-align: center;
          font-size: rem-calc(14);
          letter-spacing: 0.03em;
          line-height: rem-calc(25);
          font-weight: 400;
          color: $black;
          @include breakpoint(small down) {
            padding: rem-calc(20 0);
            font-size: rem-calc(12);
          }
          br {
            @include breakpoint(small down) {
              display: none;
            }
          }
        }
      }
    }
    /*
    .backgrounded-text-box-loyalty{
      font-size: rem-calc(12);
      font-weight: 600;
      color: white;
      padding: rem-calc(18 14);
      text-align: center;
    }
    */
    .close-button-menu{
      right: 0;
      position: absolute;
      display: flex;
      -ms-flex-pack: right;
      justify-content: right;
      background: transparent;
      z-index: 6;
      padding-top: rem-calc(22);
      padding-right: rem-calc(24);
      &.desktop{
        padding: 0;
      }
      @include breakpoint (medium down){
        position: fixed;
        top: 0;
      }
      img{
        transition: filter 0.5s;
        filter: invert(1);
        width: rem-calc(16);
        height: rem-calc(16);
        &.animation-button-menu{
          transition: filter 0.5s;
          filter: invert(0);
        }
      }
    }
  }
  // Nav icons
  a,
  button {
    svg {
      width: rem-calc(24);
      height: rem-calc(24);
    }
  }

  .minicart {
    @include breakpoint (small down) {
      margin-left: rem-calc(16);
    }
  }

  .navbar-toggler,
  .search-toggler {
    cursor: pointer;
  }

  &-cart,
  &-wishlist,
  .minicart-total {
    position: relative;
  }

  .icon-counter-wrapper {
    position: relative;
    display: inline-block;
  }

  .svg-label {
    @include breakpoint (large down) {
      display: none;
    }
  }

  &-counter,
  .minicart-quantity {
    font-weight: $global-weight-dark;
    text-align: center;
    font-size: rem-calc(10);
    letter-spacing: 0;
    background: $water-green;
    color: $black;
    position: absolute;
    right: rem-calc(-6);
    top: rem-calc(-1);
    width: rem-calc(14);
    height: rem-calc(14);
    line-height: rem-calc(14);
    border-radius: 50%;

    &.hidden {
      display: none;
    }
  }

  &-search {
    @include breakpoint(medium up) {
      margin-right: rem-calc(0);
      position: relative;
      width: rem-calc(118);
      padding-left: rem-calc(25)!important;

      input {
        height: rem-calc(23);
        line-height: rem-calc(23);
        font-size: rem-calc(13);
        margin-bottom: 0;
        padding-left: rem-calc(30);
        padding-top: rem-calc(2);
        padding-bottom: rem-calc(2);
        max-width: rem-calc(170);
        border: none;
        border-bottom: 1px solid $black;
        text-align: left;
        font-weight: $global-weight-dark;
      }

      svg {
        width: rem-calc(26);
        height: rem-calc(26);
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    @include breakpoint(xlarge up) {
      margin-right: rem-calc(0);
      width: auto;
    }

    &--tezenis {
      align-self: center;
      display: none;
      @include breakpoint (tablet up) {
        position: relative;
        display: inline-flex;
        display: -webkit-inline-flex;
      }
    }
  }

  &-login {
    @include breakpoint(medium up) {
      margin-left: rem-calc(0);

      a {
        svg {
          width: rem-calc(23);
          height: rem-calc(20);
        }

        span {
          font-weight: $global-weight-dark;
        }
      }
    }

    &__dropdown {
      position: relative;
      z-index: 12;

      &:hover,
      &.hover {
        > div {
          visibility: visible;
          opacity: 1;
          transform: translateX(0);
        }
      }
    }

    &__dropdown-wrapper {
      background: $white;
      border-width: rem-calc(0 0 1 1);
      border-style: solid;
      border-color: $light-gray;
      padding: rem-calc(24 40 0 24);
      margin-top: rem-calc(18);
    }

    &__dropdown-content {
      position: absolute;
      left: rem-calc(-201);
      top: rem-calc(23);
      width: rem-calc(313);
      background: transparent;
      text-align: right;
      transition: all 0.3s ease;
      visibility: hidden;
      opacity: 0;
      display: none;
      transform: translateX(1000px);

      @include breakpoint(medium up) {
        display: block;
      }
      .button-secondary-black,
      .button-secondary-black-outline{
        width: 100%;
      }
      .button-secondary-black{
        margin-bottom: rem-calc(8);
      }
      /*
      .button-secondary-black-outline{
        margin-bottom: 0;
      }
      */
      /*
      .profile-pink-header{
        a{
          font-size: rem-calc(14);
          font-weight: 600;
          &.force-hover{
            border-bottom: 2px solid;
            padding-bottom: rem-calc(2);
          }
        }
        .pink-slash-divider{
          color: #FF6699;
          font-size: rem-calc(24);
          font-weight: lighter;
          display: inline-block;
          margin: rem-calc(0 14);
          transform: rotate(15deg);
        }
      }
      */
      .loyalty-box{
        margin-top: rem-calc(16);
        ul{
          width: 100%;
          li{
            display: flex;
            flex-direction: column;
            justify-content: center;
            //margin-bottom: rem-calc(32);
            text-align: left;
            padding: rem-calc(16 0);
            width: rem-calc(313);
            margin-left: rem-calc(-24);
            padding-left: rem-calc(24);
            &:last-child{
              margin-bottom: 0;
            }
            span{
              padding-left: rem-calc(8);
              &.small{
                font-size: rem-calc(14);
                line-height: rem-calc(21);
                font-weight: 400 !important;
                letter-spacing: rem-calc(0.2);
                color: #1C1C1C;
                vertical-align: bottom;
              }
            }
            a {
              width: 100%;
            }
            .loyalty-points-text {
              color: $black;
              font-size: rem-calc(12);
              font-weight: 400;
              text-transform: none;
              margin-top: rem-calc(8);
              line-height: rem-calc(18);
              margin-top: rem-calc(8)!important;
            }
            img{
              width: rem-calc(24);
              height: rem-calc(24);
              filter: invert(0%) sepia(0%) saturate(40%) hue-rotate(172deg) brightness(25%) contrast(78%);
            }

            .loyalty-user-description{
              display: inline-block;
            }

            .label-card-type{
              font-weight: 400;
              margin-bottom: 0;
            }

            .label-card-description{
              padding-left: 0;
            }


          &.loyalty-li {
            background: linear-gradient(135deg, rgba(236, 177, 100, 0.6) 0%, rgba(233, 153, 96, 0.6) 21.98%, rgba(238, 132, 114, 0.6) 37.41%, rgba(249, 109, 143, 0.6) 59.66%, rgba(227, 102, 156, 0.6) 77.35%, rgba(149, 98, 159, 0.6) 100%) !important;
            width: rem-calc(313);
            margin-left: rem-calc(-24);
            padding-left: rem-calc(24);
            padding-right: rem-calc(40);
            &:hover{
              // background: linear-gradient(135deg, rgba(236, 177, 100, 0.7) 0%, rgba(233, 153, 96, 0.7) 21.98%, rgba(238, 132, 114, 0.7) 37.41%, rgba(249, 109, 143, 0.7) 59.66%, rgba(227, 102, 156, 0.7) 77.35%, rgba(149, 98, 159, 0.7) 100%);
              background-color: $water-green-light;
            }
          }

          &:hover {
            background-color: $water-green-light;
          }
          }
        }

        &.not-logged-in {
          span.small{
            color: #646464;
          }
          a {
            color: $medium-gray !important;

            img {
              filter: none;
              color: $medium-gray !important;
            }
          }
        }
      }
      .main-header-login__dropdown-content-buttons {
        .button-tertiary-black{
          text-decoration: underline;
        }
        a {
          border: none;
          margin-left: auto;
          &:hover {
            border: none;
          }
        }

        .cc-login-button {
          border: rem-calc(1) solid $black;
          background-color: unset;
          color: unset;
          &:hover {
            border: rem-calc(1) solid $black;
          }
        }
        .cc-login-no-hover:hover {
          background-color: $black;
          color: $white;
        }

        &.logged {
          display: flex;
          text-align: left;
        }
      }
    }
  }

  .main-header-login__dropdown-content .loyalty-box.not-logged-in{
    .loyalty-li {
      background: none !important;
      width: rem-calc(313);
      margin-left: rem-calc(-24);
      padding-left: rem-calc(24);
      padding-right: rem-calc(40);
      &:hover{
        //background: linear-gradient(135deg, rgba(236, 177, 100, 0.7) 0%, rgba(233, 153, 96, 0.7) 21.98%, rgba(238, 132, 114, 0.7) 37.41%, rgba(249, 109, 143, 0.7) 59.66%, rgba(227, 102, 156, 0.7) 77.35%, rgba(149, 98, 159, 0.7) 100%) !important;
        background-color: #D0E6E2 !important;
      }
    }
  }

  .logo-container {
    max-width: 100%;
    display: inline;

    .home-link {
      width: min-content;
      display: table-cell;
    }

    img {
      max-width: 100%;
      height: rem-calc(38);
      @include breakpoint(medium down) {
        padding-left: rem-calc(3);
        height: rem-calc(28);
      }
    }
    @include breakpoint(medium down) {
      text-align: center;
    }
  }

  .wishlist-icon{
    @include breakpoint (tablet up){
      margin-left: rem-calc(24);
      position: unset;
    }
  }

  .secondary-menu-wrap {
    [class^="icon-"],
    [class*=" icon-"] {
      @include breakpoint(small down) {
        margin: rem-calc(0 8);
      }
    }
    @include breakpoint (medium up){
      .main-header-account, .wishlist-icon, .minicart{
        //padding-right: rem-calc(20);
        &:hover {
          .svg-label{
            text-decoration: underline;
          }
        }
        .svg-label{
          letter-spacing: rem-calc(0.3);
          font-size: rem-calc(15);
          font-weight: 600;
        }
      }
      .minicart{
        @include breakpoint (tablet up){
          //padding-right: rem-calc(40);
          margin-left: rem-calc(24);
        }
      }
      .main-header-account{
        @include breakpoint (tablet up){
          //padding-right: rem-calc(40);
          margin-left: rem-calc(24);
        }
      }
    }
  }
  // SERVICE MENU HELP
  /*
  .dnone {
    display: none;
  }
  .dblock {
    display: block;
  }
  */
  /*
  .header-drawer {
    max-height: 0;

    &.callout {
      border-bottom: rem-calc(1) solid $more-light-gray;
      height: auto;
      text-align: center;
      position: relative;
      z-index: 2;
      max-height: rem-calc(180);
      background-color: $white;
      margin-top: rem-calc(-155);
      &.start {
        transition: all 1s;
        margin-top: rem-calc(0);
      }
      &.closed {
        transition: all 1s;
        margin-top: -rem-calc(190);
      }
      &.mui-leave-active {
        transition: max-height 1s;
        overflow: hidden;
        max-height: 0;
      }

      .more-padding {
        padding-bottom: rem-calc(20);
        padding-top: rem-calc(40);
      }
      .icon {
        margin-bottom: rem-calc(10);
        &.icon-call {
          &::before {
            font-size: rem-calc(25);
          }
        }
        &.icon-chat {
          &::before {
            font-size: rem-calc(20);
            padding-top: rem-calc(9);
          }
        }
        &.icon-chat-online {
          background-image: url("../images/bg-chat-online.png");
          background-size: 38px 36px;
          background-repeat: no-repeat;
          width: 38px;
          height: 36px;
          margin: 0 auto;
          background-position: 2px 1px;
          cursor: pointer;
        }
      }
      .title-icon {
        font-size: rem-calc(15);
        font-weight: 500;
        letter-spacing: rem-calc(0.5);
        margin-bottom: rem-calc(10);
      }
      .description-icon {
        font-size: rem-calc(13);
        font-weight: 500;
        letter-spacing: rem-calc(0.5);
        line-height: rem-calc(20);
      }
      .chat-status {
        color: $success;
        font-size: rem-calc(10);
        padding-top: rem-calc(10);
        line-height: rem-calc(18);
      }

      [data-whatinput="mouse"] .close-button {
        outline: 0;
      }
      [data-whatinput="mouse"] button {
        outline: 0;
      }
      .close-button {
        right: rem-calc(36);
        top: rem-calc(8);
        font-size: rem-calc(40);
        line-height: 0.9;
        font-weight: 200;
        position: absolute;
        color: $black;
        cursor: pointer;
        outline: none;
      }

      div.drawel-triangle {
        width: 0;
        height: 0;
        line-height: 0;
        border-top: rem-calc(10) solid $more-light-gray;
        border-left: rem-calc(10) solid transparent;
        border-right: rem-calc(10) solid transparent;
        position: absolute;
        right: rem-calc(221);
        bottom: rem-calc(-10);
        z-index: 999999;
        border-bottom: 0 solid transparent;

        &::before {
          content: " ";
          display: block;
          height: 0;
          width: 0;
          position: absolute;
          top: -12px;
          left: -10px;
          border-top: 10px solid $white;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 0 solid transparent;
        }
      }
    }
  }
  */

  .bg-white-nav {
    background-color: $white;
  }
  .main-header-login-logged-icon {
    background-color: $water-green;
    border-radius: rem-calc(18);
    color: $black;
    font-size: rem-calc(14);
    font-weight: 600;
    height: rem-calc(22);
    line-height: rem-calc(21);
    padding: rem-calc(3 4 4);
    text-align: center;
    text-transform: uppercase;
    width: rem-calc(22);
    @include breakpoint (medium down) {
        margin-top: rem-calc(0);
        line-height: rem-calc(0);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: rem-calc(4);
    }

    @include breakpoint (medium only) {
        padding: rem-calc(5);
    }

    @include breakpoint (medium up) {
      span {
        bottom: 0;
        left: rem-calc(1);
        position: absolute;
        right: 0;
      }
    }
  }

  .menu-for-tezenis {
    @include breakpoint (small down) {
      margin-right: rem-calc(16);
    }
  }
  @include breakpoint(767px down) {
    .menu-for-tezenis{
      width: 10%;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
    }
  }
}

.password-reset{
  font-size: rem-calc(12);
  line-height: rem-calc(18);
  font-weight: 400;
  border: none;
  text-underline-offset: rem-calc(5);
  @include breakpoint (medium down){
    display: flex;
    justify-content: center;
  }
  @include breakpoint (small down){
    font-size: rem-calc(12);
    line-height: rem-calc(18);
    font-weight: 400;
    display: flex;
    justify-content: left;
  }
}
.breadcrumb-wrap {
  order: 0;
  @include breakpoint(small down) {
    order: 1;
  }
}

.header-message.promo-message {
  .link.underline.dark {
    text-decoration-color: transparent;
    border-bottom-color: black;
    border-bottom: 1px solid;
    white-space: nowrap;
    @include breakpoint(small down) {
      color: $black;
      border-bottom-color: $black;
      font-size: rem-calc(11);
    }
  }

  .header-message-content {
    display: none;
    padding: rem-calc(13 0);
    @include breakpoint(small down) {
      padding: rem-calc(1 0 5);
    }

    // MEC-9509 - hide during loading and show only when slick is initialized
    &.slick-initialized {
      display: block;
    }
  }
}

.main-header a.main-header-wishlist .icon-counter-wrapper,
.main-header .minicart .icon-counter-wrapper {
  @include breakpoint(small down) {
    margin: 0;
  }
}

.main-header-login {
  &--tezenis {
    a {
      font-weight: 500;
      svg {
        transform-origin: center;
        width: rem-calc(24);
        height: rem-calc(24);
        @include breakpoint(small down) {
          transform: scale(.95);
          width: rem-calc(24);
          height: rem-calc(24);
        }
      }
    }
  }
}

.main-header {
  a.main-header-wishlist {
    &--tezenis {
      .icon-counter-wrapper {
        svg {
          height: rem-calc(24);
          @include breakpoint(small down) {
            width: rem-calc(24);
            margin: rem-calc(0 7);
          }
        }
      }
      .svg-label {
        font-weight: 500;
      }
    }
  }
}

.minicart-link {
  &--tezenis {
    .icon-counter-wrapper {
      svg {
        width: rem-calc(24);
        height: rem-calc(24);
      }
    }
    .svg-label {
      font-weight: 500;
    }
  }
}

.main-header {
  .search-toggler {
    &--tezenis {
      svg {
        @include breakpoint(small down) {
          transform-origin: center;
        }
      }
    }
  }
}
.d-flex{
  display: flex;
}

.remember-me-container {
    position: relative;
    display: flex;
    .popover-remember-me {
        width: rem-calc(319);
        color: #1C1C1C;
        background:#FFFFFF;
        position: absolute;
        transform: translateY(-100%);
        top: rem-calc(-15);
        padding: rem-calc(24);
        left: rem-calc(-135);
        border: 1px solid #82C1BD;
        filter: drop-shadow(1px 2px 6px rgba($color: #000000, $alpha: 0.16));
        font-size: rem-calc(12);
        line-height: rem-calc(17);
        border-radius: rem-calc(5);
        overflow-wrap: break-word;
        @media (min-width:768px) and (max-width:1079px){
          left: 0;
        }

        @media (min-width: 320) and (max-width:329){
          width: rem-calc(291);
        }
        @include breakpoint(medium down){
            left: 0;
            z-index:2;
            width: rem-calc(330);
        }
        &:after, &:before {
            bottom: rem-calc(-12);
            right: rem-calc(155);
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            @include breakpoint(medium down){
                left: rem-calc(15);
            }
            @media (min-width:768px) and (max-width:1079px){
              left: rem-calc(15);
            }
        }
        .close-button-info-popover{
          content: url('../../images/tzn-close-icon.svg');
          position: absolute;
          top: rem-calc(13);
          right: rem-calc(13);
          width: rem-calc(10);
          cursor: pointer;
        }
        .tooltip-title{
            color: #1C1C1C;
            margin-bottom: rem-calc(8);
            font-size: rem-calc(12);
            line-height: rem-calc(20);
            font-weight: 600;
        }
        .tooltip-subtitle{
          color: #1C1C1C;
          font-size: rem-calc(12);
          line-height: rem-calc(20);
          margin-bottom: 0;
      }
      .cookie-policy-tooltip{
        font-size: rem-calc(12);
        font-weight: 400;
        text-decoration: underline;
        margin-left: rem-calc(3);
        color: #1C1C1C;
        @include breakpoint (medium down){
          font-size: rem-calc(14);
        }
      }
    }
    .popover-remember-me.for-modal{
      left: 0;
      &:after, &:before {
        right: rem-calc(285);
      }
    }
    .info-icon {
        margin-left: rem-calc(16);
        @include breakpoint (small down){
          margin-top: rem-calc(2);
          margin-left: rem-calc(10);
        }
        @include breakpoint(ipad down) {
          margin-top: rem-calc(8);
        }
        @include breakpoint(medium down) {
          margin-top: rem-calc(4);
        }
        .tooltip{
          @include breakpoint (large up){
            overflow-wrap: break-word;
          }
          color: black;
          background: white;
          opacity: 1;
          visibility: visible;
          pointer-events: auto;
          padding: rem-calc(24);
          border-radius: rem-calc(5);
          z-index: 1;
          transform: translate(0%, -100%);
          // left: rem-calc(-7);
          //top: rem-calc(-3);
          @include breakpoint (medium down){
            padding: rem-calc(24);
            width: calc(100vw - 34px);
            left: 50%;
            top: 50%;
            position: fixed;
            transform: translate(-50%, -50%);
            z-index: 5;
            overflow-wrap: break-word;
          }
          .title-persistent-login{
            font-size: rem-calc(12);
            line-height: rem-calc(20);
            font-weight: 600;
            margin-bottom: rem-calc(8);
            @include breakpoint(medium down){
              font-size: rem-calc(12);
              line-height: rem-calc(18);
              margin-bottom: rem-calc(10);
            }
          }
          .subtitle-persistent-login{
            font-size: rem-calc(12);
            font-weight: 400;
            line-height: rem-calc(18);
            margin-bottom: 0;
            @include breakpoint (medium down){
              font-size: rem-calc(12);
            }
          }
          .close-button-info-tooltip{
            position: absolute;
            right: rem-calc(16);
            width: rem-calc(10);
            cursor: pointer;
            &.desktop{
              font-size: rem-calc(21);
              height: unset;
              width: unset;
              line-height: rem-calc(12);
              right: rem-calc(12);
              top: rem-calc(12);
            }
            @include breakpoint (medium down){
              top: rem-calc(24);
              right: rem-calc(24);
              width: rem-calc(16);
            }
          }
          .cookie-policy-tooltip{
            font-size: rem-calc(12);
            font-weight: 400;
            text-decoration: underline;
            margin-left: rem-calc(3);
            @include breakpoint (medium down){
              font-size: rem-calc(12);
            }
          }
        }
        .tooltip::before{
            position: absolute;
            content: "";
            bottom: rem-calc(-10);
            left: rem-calc(140);
            @include breakpoint (medium down){
              display: none;
            }
        }
    }
    .checkbox-label{
        font-size: rem-calc(12);
        line-height: rem-calc(16);
        font-weight: 500;
        position: relative;
        top: rem-calc(2);
    }
    .checkbox-label.for-modal{
      top: 0;
    }

  .login-save-access{
      margin-bottom: 0;
    @include breakpoint (medium down){
      margin-right: rem-calc(8);
    }
  }
}
.icon-info-black{
  content: url('../../images/tooltip-icon.svg');
  background: white!important;
  color: black!important;
  width: rem-calc(16)!important;
  height: rem-calc(16)!important;
  margin-left: rem-calc(8)!important;
  line-height: rem-calc(16)!important;
  top: rem-calc(1.5);
}
.icon-info-black.for-modal{
  content: url(../../images/tooltip-icon.svg);
  top: rem-calc(0.5);
  @include breakpoint (medium down){
    top: rem-calc(6);
  }
  .tooltip{
    @include breakpoint (medium down){
      top: rem-calc(140);
    }
  }
}
.tooltip-background{
  @include breakpoint(medium down){
    background: rgba(130, 193, 189, 0.6);
    backdrop-filter: blur(50px);
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100vw;
    z-index: 4;
  }
}
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-flex;
  width: rem-calc(40);
  height: rem-calc(23);
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  cursor: pointer;
  top: rem-calc(-2);
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider.login{
  width: rem-calc(43.69);
  height: rem-calc(23.5);
  background-color: #CFCECF;
}

.slider.login::before{
  content: "";
  /* top: rem-calc(1.3); */
  background-repeat: no-repeat;
  background-position: center;
  @include breakpoint (medium down){
    background-position: rem-calc(5);
  }
}

.slider:before {
  position: absolute;
  content: "";
  font-size: rem-calc(20);
  color: #CFCECF;
  text-align: center;
  line-height: rem-calc(18);
  height: rem-calc(12);
  width: rem-calc(12);
  left: rem-calc(2);
  background-color: $white;
  -webkit-transition: .4s;
  transition: .4s;
  @include breakpoint (ipad down){
    bottom: rem-calc(4);
    left: rem-calc(4);
    height: rem-calc(24);
    width: rem-calc(24);
  }
}

input:checked + .slider {
  background-color: $water-green;
}

input:checked + .slider:before {
  -webkit-transform: translateX(rem-calc(18));
  -ms-transform: translateX(rem-calc(18));
  transform: translateX(rem-calc(18));
  background-color: white;
  content: "";
  left: rem-calc(0);

  @include breakpoint (ipad down) {
    left: rem-calc(20);
  }
}

/* Rounded sliders */
.slider.round {
  background-color: $medium-gray;
  border-radius: 34px;
  height: rem-calc(32);
  top: rem-calc(1);
  right: 0;
  width: rem-calc(65);
  position: absolute;
  @include breakpoint (small down){
    left: 0;
  }
  @include breakpoint (large up) {
    height: rem-calc(16);
    width: rem-calc(32);
  }
}

.slider.round:before {
  border-radius: 50%;
  @include breakpoint (medium up) {
    top: rem-calc(1.75);
  }
}

.privacyFlagEnable {
    background: rgba(130, 193, 189, 0.6) !important;
      /* blur 10% */
    backdrop-filter: blur(5px) !important;
  .tingle-modal-box {
    @include breakpoint (medium up){
      width: rem-calc(708);
    }
    button{
      width: 100%;
    }
    #buttons-modifyConsent{
      width: 100%;
      @include breakpoint (small down){
        position: fixed;
        bottom: 16px;
        width: 91.8%!important;
      }
     }
      box-shadow: none;
      @include breakpoint (small down) {
          height: 100vh;
          height: -webkit-fill-available !important;
          max-height: unset;
          max-width: unset;
          width: 100vw;
      }
      .tingle-modal-box__content {
          padding: rem-calc(40);
          @include breakpoint (small down) {
              padding: rem-calc(19 16 16 16);
          }
          .closePrivacyPopUP {
              position: absolute;
              top: rem-calc(36);
              right: rem-calc(40);
              font-size: 2em;
              cursor: pointer;
              width: unset;
              @include breakpoint (small down) {
                  top: rem-calc(14);
                  right: rem-calc(19);
              }
              img{
                  width: rem-calc(24);
                  height: rem-calc(24);
              }
          }
          .title {
              font-size: rem-calc(16);
              font-weight: 500;
              line-height: rem-calc(24);
              margin-bottom: rem-calc(24);
              letter-spacing: rem-calc(0.2);
              color: $black;
              @include breakpoint (medium up) {
                  max-width: rem-calc(350);
              }
              @include breakpoint (small down){
                margin-bottom: rem-calc(40);
              }
          }

          .first-step-spacer{
            height: rem-calc(48);
          }
          .privacyFlag-popup-text {
              font-size: rem-calc(14);
              font-weight: 400;
              line-height: rem-calc(21);
              color: #1C1C1C;
              margin-bottom: 0;
              @include breakpoint (small down){
                  margin-bottom: rem-calc(30);
                  padding-right: rem-calc(10);
              }
              @include breakpoint (large up) {
              }
          }
          .boolean-field {
              input[type="checkbox"] {
                  height: rem-calc(20);
                  width: rem-calc(50);
                  z-index: 1;
                  @include breakpoint (medium up) {
                      left: unset;
                      right: 0;
                  }
              }
          }
          .sliderToggle {
              @include breakpoint (medium up) {
                width: rem-calc(32);
                height: rem-calc(16);
                left: unset;
                top: rem-calc(5);
              }
              @include breakpoint (small down) {
                  width: rem-calc(65);
                  height: rem-calc(32);
                  left: rem-calc(-14);
              }
          }
      }
      .editPrivacyPolicyForm {
          margin-top: 0;
          .newsletter-pref {
              margin-top: rem-calc(24);
              @include breakpoint (small down) {
                  margin-top: rem-calc(30);
              }
          }
          .profiling-pref {
              margin-top: rem-calc(24);
              margin-bottom: 0;
              @include breakpoint (small down) {
                  margin-top: rem-calc(10);
                  margin-bottom: rem-calc(2);
              }
              .preferences-text {
                  @include breakpoint (medium up) {
                  }
              }
          }
          .preferences-text {
              font-size: rem-calc(14);
              font-weight: 400;
              line-height: rem-calc(21);
              letter-spacing: rem-calc(0.2);
              padding-right: rem-calc(20);
              margin-bottom: 0;
              @include breakpoint (small down){
                  margin-bottom: rem-calc(15);
                  padding-right: rem-calc(100);
              }
              &.disabled {
                  color: #646464;
              }
              .privacy-policy-short-profiling,
              .privacy-policy-short-marketing {
                  font-weight: 400;
                  text-decoration: underline;
                  text-transform: uppercase;
                  @include breakpoint (small down){
                    font-weight: 400;
                  text-decoration: underline;
                  text-transform: none;
                  }
              }
          }
      }
      .privacy-response-ok {
          .privacyFlag-popup-text {
              font-size: rem-calc(16);
              font-weight: 600;
              line-height: rem-calc(22);
              margin-bottom: rem-calc(24);
              letter-spacing: rem-calc(0.2);
              @include breakpoint (small down){
               margin-bottom: rem-calc(40);
              }
          }
          .success {
              color: $black;
              font-size: rem-calc(14);
              font-weight: 400;
              line-height: rem-calc(18);
              letter-spacing: rem-calc(0.36);
              margin-bottom: rem-calc(48);
              @include breakpoint (small down){
                  margin-bottom: rem-calc(30);
              }
              i {
                  margin-right: rem-calc(16);
              }
          }
          .check-green{
            svg{
              content: url('../images/check-green.svg');
            }
          }
      }
      .check-icon-popup{
        margin-right: rem-calc(16);
        img{
          position: relative;
          top: rem-calc(-3);
        }
      }
      .thank-you-message{
        display: flex;
        flex-direction: row;
      }
      .button{
          &.activable-after-change{
            width: 100%;
            @include breakpoint (small down){
              position: fixed;
              bottom: rem-calc(16);
              width: 91.8% !important;
            }
          }
      }
  }
  .privacypopUPTitle{
      margin-bottom: rem-calc(24);
      @include breakpoint (small down){
          margin-bottom: rem-calc(20);
      }
  }
  .privacypopUPTitleModify{
      margin-bottom: rem-calc(8);
      @include breakpoint (small down){
          margin-bottom: rem-calc(30);
      }
  }
  .buttons-modifyConsent{
      font-size: rem-calc(14);
      font-weight: 400;
      line-height: rem-calc(20);
      letter-spacing: rem-calc(0.35);
      margin-top: rem-calc(48);
      margin-bottom: 0;
      width: 100% !important;
      @include breakpoint (small down){
          button{
              width: 100%;
          }
      }
  }
  .buttons-preference-form{
    margin-top: rem-calc(48);
  }
  .continue-consent{
    width: 100%;
      @include breakpoint (small down){
        position: fixed;
        bottom: 16px;
        width: 91.8% !important;
      }
  }
  .button:disabled{
      background-color: #CCCCCC;
      color: #fff;
      width: 100%;
      border-bottom: 0;
  }
  .profiling-preferences-switches{
      display: flex;
      justify-content: flex-end;
  }
}

.cc-notification {
  position: relative;

  .account-menu-title {
      &:after {
          content: '';
          display: inline-block;
          vertical-align: middle;
          margin-left: rem-calc(8);
          width: rem-calc(8);
          height: rem-calc(8);
          border-radius: 50%;
          background: linear-gradient(135deg, rgba(236, 177, 100, 0.6) 0%, rgba(233, 153, 96, 0.6) 21.98%, rgba(238, 132, 114, 0.6) 37.41%, rgba(249, 109, 143, 0.6) 59.66%, rgba(227, 102, 156, 0.6) 77.35%, rgba(149, 98, 159, 0.6) 100%);

          @include breakpoint (medium up) {
              position: absolute;
              top: 50%;
              right: rem-calc(22);
              width: rem-calc(12);
              height: rem-calc(12);
              margin: rem-calc(-6 0 0);
              
          }
      }
  }

  .small {
      &:after {
          content: '';
          display: block;
          width: rem-calc(8);
          height: rem-calc(8);
          border-radius: 50%;
          position: absolute;
          top: 50%;
          right: rem-calc(24);
          margin: rem-calc(-4 0 0);
          background: linear-gradient(135deg, rgba(236, 177, 100, 0.6) 0%, rgba(233, 153, 96, 0.6) 21.98%, rgba(238, 132, 114, 0.6) 37.41%, rgba(249, 109, 143, 0.6) 59.66%, rgba(227, 102, 156, 0.6) 77.35%, rgba(149, 98, 159, 0.6) 100%);
      }
  }
}
