.video-full-width-component {
    .text-box-slide:nth-child(1):nth-last-child(1) {

        @media screen and(max-width: 768px) {

            display: none;

        }

    }

    .hide-medium-custom {
        @media screen and(max-width: 768px) {
            display: none !important;
        }
    }

    .show-medium-custom {
        display: none;
        @media screen and(max-width: 768px) {
            display: inherit !important;
            width: 100%;
        }
    }

    #btnContainer {
        margin-bottom: rem-calc(54);
        justify-content: center;
        @media screen and(max-width: 768px) {
            margin-bottom: rem-calc(43);
            display: block;
            align-self: center;
            position: relative;
        }
    }

    .pd-container {
        width: 100%;
        max-width: 100%;
        overflow: hidden;
        background-repeat: no-repeat;
        margin: 0;
        padding: 10px 0;
        background-position: center;
        background-size: cover;
        background-position-x: 50%;
        background-position-y: 50%;
        cursor: pointer;
    }
    .svgImageOptions {
        width: 100%;
        height: 100%;
        @media screen and(max-width: 768px) {
            padding: rem-calc(0 9);
        }
    }

    #svg-file {
        @media screen and(max-width: 768px) {
            padding: rem-calc(0 9);
        }
    }
    
    .alignItems {
        text-align: center;
    }
    
    .video-section {
        width: 100%;
        object-fit: cover;
    }

    .video-mobile {
        width: 100%;
        object-fit: cover;
    }

    .calculated-height {
        height: auto;
        margin-bottom: rem-calc(-1);

    }
    
    .fontH1-responsive-custom {
        color: white;
        font-size: rem-calc(60);
        font-weight: 700;
        letter-spacing: rem-calc(1.8);
        @media screen and(max-width: 768px) {
            font-size: rem-calc(30);
            letter-spacing: rem-calc(0.6);
        }
    }

    .text-button-container {
        position: absolute;
        text-align: center;
        flex-direction: column;
        display: flex;
        width: 100%;
        @media screen and(max-width: 768px) {
            margin-top: rem-calc(46);
        }
    }
    
    .fontH4-responsive-custom {
        color: white;
        font-size: rem-calc(20);
        font-weight: 600;
        letter-spacing: rem-calc(0.6);
        margin-top: rem-calc(8);
        @media screen and(max-width: 768px) {
            letter-spacing: rem-calc(1);
            margin-top: 0;

        }
    }
    
    .wrapper-pd {
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
    }
    
    .alignItems {
        text-align: center;
    }
    
    .wrapper-pd {
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
    }
    
    .btn-pd-style {
        box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.16);
        margin: 10px;
        width: 300px;
        height: 40px;
        border-radius: 50px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &:hover {
            cursor: pointer;
            transition: transform 0.25s ease-in-out;
        }
    }
    
    .custom-pd-link {
        display: inline-block;
    }
    
    .scrollingTextWrap {
        width: 100%;
        z-index: 1;
    }
    
    .scrollWrap {
        overflow: hidden;
        padding: 0;
    }
    
    .scrollInner {
        color: white;
        width: max-content;
        white-space: nowrap;
        overflow: hidden;
    }
    
    .scrollableText {
        animation: scrollText 5s linear infinite;
        float: left;
        letter-spacing: 0.03em;
        font-size: rem-calc(60);
        font-weight: 700;
        line-height: rem-calc(76);

        @media screen and(max-width: 768px) {
            font-size: rem-calc(30);
            font-weight: 600;
            line-height: rem-calc(56);
        }
    }
    
    @keyframes scrollText {
        0% {
            -webkit-transform: translateX(0px);
            transform: translateX(0px);
        }
        100% {
            -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
        }
    }
    
    a {
        &:hover {
            border-color: none;
            border: 0;
        }
    }
    a:not(.no-hover):hover,
    a.force-hover {
        border-color: transparent !important;
        border: 0;
    }
    
    
    .text-box-slide {
        .link-wrapper {
            transform: translateY(50%);
            width: rem-calc(240);
            height: rem-calc(40);
            margin-top: rem-calc(-6);
            border-radius: rem-calc(50);
            border: rem-calc(2) solid;
            box-shadow: rem-calc(3 3 10) #00000029;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            &:hover {
                cursor: pointer;
                a.custom-pd-link {
                    span {
                        transform: translateY(-103%);
                        @media screen and(max-width: 768px) {
                            transform: none;
                        }
                    }
                }
            }
            @media screen and(max-width: 768px) {
                margin-bottom: rem-calc(25);
            }
        }
    
        .custom-pd-link {
            padding: 0;
            overflow: hidden;
            letter-spacing: rem-calc(0.42);
            span {
                position: relative;
                display: inline-block;
                transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
                &:before {
                    position: absolute;
                    top: 100%;
                    content: attr(data-hover);
                    transform: translate3d(0, 0, 0);
                    @media screen and(max-width: 768px) {
                        display: none;
                    }
                }
            }
        }
    }    

    .text-box-slide:first-child {
        margin-right: rem-calc(35);
        @media screen and(max-width: 768px) {
            margin-right: 0;
        }
    }
    .text-box-slide:last-child {
        margin-left: rem-calc(35);
        @media screen and(max-width: 768px) {
            margin-left: 0;
        }
    }
}
