.notFoundPage{
    h4{
        font-size: rem-calc(20);
        margin-bottom: rem-calc(20);
        font-weight: 500;
        text-align: left;
    }
   p{
    font-size: rem-calc(13);
    margin-bottom: rem-calc(15);
    font-weight: 500;
    text-align: left;
    &.info{
        font-size: rem-calc(11);
        text-align: left;
        font-weight: 400;
        a{
            font-weight: 700;
        }
        }
    }

    .error-hero{
        margin-bottom: rem-calc(200);
        @include breakpoint (small down) {
            margin-bottom: rem-calc(150);
        }

    }

    .tzn-error-hero{
        margin-bottom: rem-calc(200);
            @include breakpoint (small down) {
                margin-bottom: rem-calc(150);
            }
            h4{
                font-size: rem-calc(26);
                font-weight: 600;
                line-height: rem-calc(34);
                color: black;
                text-align: center;
                margin-top: rem-calc(41);
                margin-bottom: rem-calc(17);
                @include breakpoint (small down){
                    margin-top: rem-calc(40);
                    margin-bottom: rem-calc(16);
                }
            }
            p{
                text-align: center;
                &.error-message{
                    font-size: rem-calc(16);
                    font-weight: 500;
                    line-height: rem-calc(24);
                    letter-spacing: rem-calc(0.2);
                    margin-bottom: rem-calc(33);
                    @include breakpoint (small down){
                        margin-bottom: rem-calc(16);
                    }
                }
                &.info{
                    font-size: rem-calc(14);
                    font-weight: 400;
                    line-height: rem-calc(21);
                    letter-spacing: rem-calc(0.2);
                    text-align: center;
                    margin-bottom: rem-calc(24);
                    @include breakpoint (small down){
                        margin-bottom: rem-calc(16);
                    }
                    a{
                        font-weight: 400;
                    }
                }
            }
            .back-to-home-button{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 16px 24px;
                gap: 10px;
                position: absolute;
                width: 184px;
                height: 34px;
                left: calc(50% - 184px/2);
                background: #1C1C1C;
                border-radius: 43px;
            }
    }
}

.tzn-notFoundPage{
    @include breakpoint (small down){
        padding-left: 0;
        padding-right: 0;
    }
}