// Custom Main container
.grid-container,
.grid-container.fluid {
    padding-left: rem-calc(40);
    padding-right: rem-calc(40); 

    @include breakpoint (medium down) {
        padding-left: rem-calc(16);
        padding-right: rem-calc(16);
    }

    &--narrow {
        @include breakpoint (medium up) {
            padding-left: calc(#{xy-cell-size(1 of 12)} + #{map-get($grid-margin-gutters, small)});
            padding-right: calc(#{xy-cell-size(1 of 12)} + #{map-get($grid-margin-gutters, small)});
        }
    }

    .cell {
        .padding-right {
            padding-right: rem-calc(12);
        }
    }
}
.page {
    .grid-container {
        max-width: 100%;
    }
}
.main-header .grid-container {
    max-width: 100%;
}

footer {
    .grid-container {
        max-width: 100% !important;
    }
}
