.nav {
    margin: 0;

    &-tabs {
        list-style-type: none;
        display: flex;
    }

    &-item {
        flex: 1 1;
    }
}

.tab-bar {
    display: flex;
    justify-content: flex-start;
    margin-bottom: rem-calc(36);
    margin-left: 0;
    overflow-y: auto;

    &__item {
        display: flex;
        align-items: center;
        padding: rem-calc(8);
        min-width: rem-calc(180);
        border: 1px solid gray(6);
        text-align: center;
        margin-right: rem-calc(36);
        position: relative;

        &--has-image {
            text-align-last: left;

            img {
                display: inline-block;
                margin-right: rem-calc(10);
                border-radius: 50%;
                border: 1px solid gray(7);
            }
        }

        &--selected,
        &:active,
        &:hover,
        &:focus {
            &::after {
                content: '';
                position: absolute;
                left: 0;
                width: 100%;
                bottom: 0;
                right: 0;
                height: rem-calc(2);
                background-color: $black;
            }
        }
    }
}




/* TABS PAGE  */
.container-tabs {
    width: 100%;

    ul {
        padding: 0;
        margin: 0;
        &.tabs{
            margin: rem-calc(0 0 35 0);
            padding: 0;
            list-style: none;
            //background-image: url('../images/pixel-grey.png');
            background-position: bottom;
            background-repeat: repeat-x;


            li {
                background: none;
                color: $medium-light-gray;
                display: inline-block;
                border-bottom: rem-calc(4) solid transparent !important;
                padding: rem-calc(5 16 0 16);
                font-weight: 600;
                font-size: rem-calc(18);
                cursor: pointer;
                margin-right: rem-calc(5);
                position: relative;
                @include breakpoint(small down) {
                    margin-right: rem-calc(0);
                }
                &:last-child {
                    margin-right:0;
                }

                &.current {
                    background:none;
                    color: $black;
                    padding: rem-calc(5 16);

                    &::after {
                        content: '';
                        color: $black;
                        height: rem-calc(4);
                        position: absolute;
                        width: rem-calc(4);

                    }
                }
            }
            &.tabs-center{
                text-align: center;
            }
        }
    }

    .tab-content {
        display: none;
        background: none;
        padding: rem-calc(15 0);


        &.current {
            display: inherit;
        }
    }
}

.tabs {
    display: flex;
    flex-direction: row;
    width: 100%;

    .tab-link {
        border-right: 1px solid $light-gray;
        color: $medium-light-gray;
        font-size: rem-calc(18);
        font-weight: 600;
        line-height: rem-calc(27);
        text-align: center;
        width: inherit;

        &:hover {
            border: none;
            border-right: 1px solid $light-gray;
            cursor: pointer;
            text-decoration: none;
        }

        &.current {
            color: $black;
            position: relative;

            &::before {
                background-color: $black;
                border-radius: rem-calc(4);
                content: '';
                height: rem-calc(4);
                left: calc(50% - 4px);
                position: absolute;
                top: 100%;
                width: rem-calc(4);
            }
        }

        &:last-child {
            border-right: none;
        }

    }
}

.review-category-container {
    .tabs {
        .tab-link {
            @include breakpoint(small down) {
                font-size: rem-calc(16);
            }
            &.product-review-category-item {
                @include breakpoint(small down) {
                    font-size: rem-calc(15.5);
                }
            }
        }
    }
}
