.mediaGrid {
    background: linear-gradient(90deg, rgba(99,202,204,1) 0%, rgba(79,159,215,1) 100%, rgba(225,225,224,1) 100%);
    min-height: 100vh;
  }

.mediaGridItem{
  height: 100%;
  width: 100%;
  position: relative;
}

.mediaGridHeadingFont {
  color:white;
  font-family: 'Poppins';
  font-stretch: extra-expanded;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.6px;
  font-stretch: normal;
  font-style: normal;
  font-size: rem-calc(20);
}

.mediaGridTopHeader{
padding-top: 7rem;
font-stretch: normal;
font-weight: bold;
line-height: 1.67;
letter-spacing: 0.9px;
font-style: normal;
font-size: rem-calc(30);
}

.mediaGridFontImageHeading {
color:white;
font-family: 'Poppins';
font-size: rem-calc(20);
font-weight: bold;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
text-align: center;
position: absolute;
font-stretch: normal;
font-style: normal;
line-height: 0.95;
letter-spacing: 0.6px;
}

.gridMedia {
display: inline-grid;
width: 100%;
padding-left: rem-calc(182);
padding-right: rem-calc(182);
grid-template-columns: auto auto auto auto;
padding-top: 7rem;
gap: 3rem;
justify-content: center;
padding-bottom: 13rem;
align-items: center;
.quickViewLink {
    border-color: transparent;
    border: 0;
    .mediaGridQuickViewSvg {
      overflow: visible;
      padding-bottom: 0.1rem;
      transform: scale(1.5);
    }
}
@include breakpoint(small down) 
  {
    gap: 1rem;
    padding-left: 0;
    padding-right: 0;
  }
}

.mediaGridItemButtonBackground {
bottom: -2%;
right: -2%;
transform: translate(-50%, -50%);
text-align: center;
position: absolute;
background: #F5F5F5;
opacity: 0.5;
border-radius: 0.938rem;
height: rem-calc(35);
width: rem-calc(35);
@include breakpoint(ipad down) 
{
  visibility: hidden;
}
}