.boolean-field {
    display: inline-flex;
    position: relative;
    margin-bottom: $global-margin;
    padding-right: rem-calc(10);

    input[type="checkbox"],
    input[type="radio"] {
        opacity: 0;
        width: 0;
        height: 0;
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;

        &:disabled {
            cursor: not-allowed;

            ~ .checkbox-input {
                &:before {
                    cursor: not-allowed;
                }
            }
        }
    }

    .checkbox-label,
    .check-radio-label,
    .radio-label {
        color: $black;
        cursor: pointer;
        line-height: rem-calc(19);

        &.radio {
            position: relative;
            // top: rem-calc(2);
        }
    }

    .check-radio-label,
    .radio-label {
        position: relative;
        // top: rem-calc(2);
    }

    .checkbox-input {
        display: inline-block;
        position: relative;
        padding-left: rem-calc(19);
        text-transform: none;
        margin: rem-calc(0 8 0 0);

        &:before,
        &:after {
            content: "";
            position: absolute;
        }

        &:before {
            width: rem-calc(19);
            height: rem-calc(19);
            background-color: transparent;
            border: 1px solid $black;
            transition: all 0.2s;
            cursor: pointer;
            border-radius: rem-calc(4);
            top: 0;
            left: 0;
        }

        &:after {
            width: rem-calc(11);
            height: rem-calc(11);
            background: $black;
            opacity: 0;
            transform: scale3d(0, 0, 0);
            transition: all 0.1s;
            cursor: pointer;
            top: rem-calc(4);
            left: rem-calc(4);
            border-radius: rem-calc(2);
        }

        &.pink-checkbox-input{
            &:before {
                top: rem-calc(2);
                left: 0;
                width: rem-calc(16);
                height: rem-calc(16);
                background-color: transparent;
                border: 1px solid $medium-gray;
                transition: all 0.2s;
                cursor: pointer;
                border-radius: rem-calc(3);
            }

            &:after {
                top: 2px;
                left: 0;
                width: 16px;
                height: 16px;
                background: #FF6699;
                opacity: 0;
                transform: scale3d(0, 0, 0);
                transition: all 0.1s;
                cursor: pointer;
                border-radius: rem-calc(3);
                background-image: url("../../images/checkwhite.svg");
                background-size: 12px;
                background-repeat: no-repeat;
                background-position: center;
            }
        }
        &.pink-checkbox-input-simple{
            &:before {
                top: rem-calc(2);
                left: 0;
                width: rem-calc(16);
                height: rem-calc(16);
                background-color: white;
                border: 1px solid $black;
                transition: all 0.2s;
                cursor: pointer;
            }

            &:after {
                top: 2px;
                left: 0;
                width: 16px;
                height: 16px;
                background: white;
                opacity: 0;
                transform: scale3d(0, 0, 0);
                transition: all 0.1s;
                cursor: pointer;
                background-image: url("../../images/checkpink.svg");
                background-size: 12px;
                background-repeat: no-repeat;
                background-position: center;
                border: 1px solid $black;
            }
        }
    }

    .checkbox-input-round {
        display: inline-block;
        position: relative;
        padding-left: rem-calc(20);
        text-transform: none;
        margin: 0;

        &:before,
        &:after {
            content: "";
            position: absolute;
        }

        &:before {
            top: rem-calc(2);
            left: rem-calc(1);;
            width: rem-calc(12);
            height: rem-calc(12);
            border-radius: 50%;
            background-color: transparent;
            border: 1px solid transparent;
            transition: all 0.2s;
            cursor: pointer;
        }

        &:after {
            opacity: 0;
            top: 0;
            left: rem-calc(-1);
            width: rem-calc(16);
            height: rem-calc(16);
            border-radius: 50%;
            background-color: transparent;
            border: 1px solid transparent;
            transition: all 0.2s;
            cursor: pointer;
        }

        .disabled & {
            &:before {
                opacity: 0.6;
            }
        }
    }

    .radio-input {
        display: inline-block;
        position: relative;
        padding-left: rem-calc(19);
        text-transform: none;
        margin: rem-calc(0 8 0 0);

        &:before,
        &:after {
            content: "";
            position: absolute;
        }

        &:before {
            top: 0;
            left: 0;
            width: rem-calc(19);
            height: rem-calc(19);
            border-radius: 50%;
            background-color: transparent;
            border: 1.5px solid $black;
            transition: all 0.2s;
            cursor: pointer;
        }

        &:after {
            top: rem-calc(4);
            left: rem-calc(4);
            border-radius: 50%;
            width: rem-calc(11);
            height: rem-calc(11);
            background-color: $black;
            opacity: 0;
            transform: scale3d(0, 0, 0);
            transition: all 0.1s;
            cursor: pointer;
        }

        &.error-refund {
            &:before {
                top: rem-calc(2);
                left: 0;
                width: rem-calc(16);
                height: rem-calc(16);
                border-radius: 50%;
                background-color: transparent;
                border: 1px solid red;
                transition: all 0.2s;
                cursor: pointer;
            }

            &:after {
                top: rem-calc(5);
                left: rem-calc(3);
                border-radius: 50%;
                width: rem-calc(10);
                height: rem-calc(10);
                background-color: $black;
                opacity: 0;
                transform: scale3d(0, 0, 0);
                transition: all 0.1s;
                cursor: pointer;
            }
        }

        // Add squared modifier for checkbox-looking radios
        &--squared {
            &:after,
            &:before {
                border-radius: 0;
            }
        }
    }

    .check-radio-input {
        display: inline-block;
        position: relative;
        padding-left: rem-calc(19);
        text-transform: none;
        margin: rem-calc(0 8 0 0);

        &:before,
        &:after {
            content: "";
            position: absolute;
        }

        &:before {
            top: 0;
            left: 0;
            width: rem-calc(19);
            height: rem-calc(19);
            border-radius: 50%;
            background-color: transparent;
            border: 1.5px solid $black;
            transition: all 0.2s;
            cursor: pointer;
        }

        &:after {
            top: rem-calc(4);
            left: rem-calc(4);
            border-radius: 50%;
            width: rem-calc(11);
            height: rem-calc(11);
            background-color: $black;
            opacity: 0;
            transform: scale3d(0, 0, 0);
            transition: all 0.1s;
            cursor: pointer;
        }

        &.error-refund {
            &:before {
                top: rem-calc(2);
                left: 0;
                width: rem-calc(16);
                height: rem-calc(16);
                border-radius: 50%;
                background-color: transparent;
                border: 1px solid red;
                transition: all 0.2s;
                cursor: pointer;
            }

            &:after {
                top: rem-calc(5);
                left: rem-calc(3);
                border-radius: 50%;
                width: rem-calc(10);
                height: rem-calc(10);
                background-color: $black;
                opacity: 0;
                transform: scale3d(0, 0, 0);
                transition: all 0.1s;
                cursor: pointer;
            }
        }

        // Add squared modifier for checkbox-looking radios
        &--squared {
            &:after,
            &:before {
                border-radius: 0;
            }
        }
    }

    input[type="checkbox"]:checked + .checkbox-input:before,
    input[type="checkbox"]:checked + .checkbox-input-round:after,
    input[type="checkbox"]:checked ~ .check-radio-input:before,
    input[type="radio"]:checked + .radio-input:before {
        border-color: $black;
    }

    input[type="checkbox"]:checked + .checkbox-input:after,
    input[type="checkbox"]:checked + .checkbox-input-round:after,
    input[type="checkbox"]:checked ~ .check-radio-input:after,
    input[type="radio"]:checked + .radio-input:after {
        opacity: 1;
        transform: scale3d(1, 1, 1);
    }

    input[type="checkbox"]:checked ~ .checkbox-label,
    input[type="checkbox"]:checked ~ .check-radio-label,
    input[type="radio"]:checked ~ .radio-label {
        color: $black;
    }

    input[type="checkbox"]:focus ~ .checkbox-input:before,
    input[type="checkbox"]:focus ~ .check-radio-input:before,
    input[type="radio"]:focus ~ .radio-input:before {
        outline: 5px auto -webkit-focus-ring-color;
    }

    &.is-invalid-input {
        background: transparent;

        input[type="checkbox"]:not(:focus) + .checkbox-input:before,
        input[type="checkbox"]:not(:focus) ~ .check-radio-input:before,
        input[type="radio"]:not(:focus) + .radio-input:before {
            border: 2px solid $alert;
        }
    }

    // &.is-valid-input {
    //     background: transparent;

    //     input[type="checkbox"] + .checkbox-input:before,
    //     input[type="radio"] + .radio-input:before {
    //         border: 2px solid $success;
    //     }

    //     input[type="checkbox"] + .checkbox-input:after,
    //     input[type="radio"] + .radio-input:after {
    //         background-color: $success;
    //     }
    // }
}

input[type="radio"]:disabled ~ .radio-label,
input[type="checkbox"]:disabled ~ .check-radio-label {
    opacity: 0.5;
}
input[type="radio"]:disabled ~ .radio-input,
input[type="checkbox"]:disabled ~ .check-radio-input {
    opacity: 0.5;
}
