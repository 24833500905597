// GENERIC ARROW CSS
.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    height: rem-calc(20);
    width: rem-calc(20);
    line-height: 0;
    font-size: 0;
    cursor: pointer;
   //background: black;
    //color: $black;
    padding: 0;
    border: none;
    outline: none;
    &:before{
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: rem-calc(23);
        height: rem-calc(36);
        background-size: 50% 50%;
        background-repeat: no-repeat;
    }
}
    .slick-prev{
        @include breakpoint (medium down) {
            left: rem-calc(-25);
            z-index: 500;
        }
        @include breakpoint (small down) {
        left: rem-calc(-25);
        z-index: 500;
    }
    &:before {
            background-image: url('../images/arrow-left.png');
        }
    }
        .slick-next{
            @include breakpoint (medium down) {
                right: rem-calc(-30);
                z-index: 500;
            }
            @include breakpoint (small down) {
               right: rem-calc(-30);
                z-index: 500;
            }
        &:before {
            background-image: url('../images/arrow-right.png');
        }
    }


// GENERIC COLOR AND DIMENSION (ONLY)
    .slick-dots {

        li  {
            button {
                &:before {
                    width: rem-calc(6);
                    height: rem-calc(6);
                    border: rem-calc(1) solid $more-light-gray;
                    background: $more-light-gray;

                }
            }

            &.slick-active {
                button {
                    &:before {
                        width: rem-calc(6);
                        height: rem-calc(6);
                        border: rem-calc(1) solid $black;
                        background: $black;

                        }
                }
            }

        }
    }

// GENERIC HREF FOR DIV
a{
    &.all-div-block {
        display:block;
        padding:0;
        border:0;
        text-decoration: none;
        &:hover, &:focus, &:active {
            text-decoration: none;
        }
    }
}

.component3img3link3price {
    .slick-prev {
        z-index: 20;
    }
}

.multi-image-link-single1, .two-slider-dots, .carousel-fullwidth-title-link-desc {
    .slick-slide{
        position: relative;
        .slider-center-content{
            position: absolute;
            bottom: rem-calc(20);
            text-align: center;
            left: 20%;
            right: 20%;
            p{
                font-size: rem-calc(30);
                font-weight:500;
                line-height: rem-calc(42);
                letter-spacing: 0.5;
                margin: 0 auto;
                text-align: center;
                padding-bottom: rem-calc(15);
                &.white{
                    color: $white;
                    font-weight:500;
                }
                @include breakpoint (small down) {
                    font-size: rem-calc(24);
                    color: $white;
                    line-height: rem-calc(26);
                }
            }
            .button{
                width: rem-calc(154);
                height: rem-calc(50);
                font-size: rem-calc(10);
                font-weight: 700;
                text-transform: uppercase;
                padding: rem-calc(15);
                line-height: rem-calc(20);
                border: rem-calc(2) solid $black ;
                @include breakpoint (small down) {
                    width: rem-calc(131);
                    height: rem-calc(40);
                    padding: rem-calc(10);
                }

            }
        }
    }

    .slick-dots {
        position: absolute;
        bottom: rem-calc(16);
        right: rem-calc(20);
        width: inherit;
        @include breakpoint (small down) {
            right: inherit;
            width: 100%;
            bottom: 0;
        }
        li  {
            button {
                &:before {
                    width: rem-calc(6);
                    height: rem-calc(6);
                    border: rem-calc(1) solid $more-light-gray;
                    background: $more-light-gray;
                    @include breakpoint (small down) {
                        border: rem-calc(1) solid $white;
                        background: $white;
                        opacity: 0.5;
                    }
                }
            }

            &.slick-active {
                button {
                    &:before {
                        width: rem-calc(6);
                        height: rem-calc(6);
                        border: rem-calc(1) solid $black;
                        background: $black;
                        @include breakpoint (small down) {
                            border: rem-calc(1) solid $white;
                            background: $white;
                            opacity: 1;
                        }
                        }
                }
            }

        }
    }
}

.carousel-fullwidth-title-link-desc {
    .slick-slide{
        .slider-center-content{
            bottom: 15%; //27
            left: 20%;
            right: 20%;
            @include breakpoint (small down) {
                bottom: 5%; //7
                left: 8%;
                right: 8%;
            }
            a.discover-more{
                color: $white;
                &:not(.no-hover):hover, &.force-hover {
                    border-color: $white;
                }
            }
            p{
                color: $white;
                font-size: rem-calc(13);
                line-height: rem-calc(18);

            }
            h3{
                color: $white;
                font-size: rem-calc(30);
                line-height: rem-calc(42);
                font-weight: 700;
                @include breakpoint (small down) {
                    font-size: rem-calc(24);
                    line-height: rem-calc(34);
                }
            }
            &.more-pb{
                bottom: 27%;
                left: 20%;
                right: 20%;
                @include breakpoint (small down) {
                    bottom: 7%;
                    left: 8%;
                    right: 8%;
                }
        }
        &.more-plr{
                left: 27%;
                right: 27%;
                @include breakpoint (small down) {
                    left: 8%;
                    right: 8%;
                }
        }
        }
    }
    .slick-dots {
        @include breakpoint (small down) {
            text-align: right;
        }
    }
}
.imhlink_slidermultilink{
    .imhlink-left{
        width: 100%;
        text-align: right;
        img{
            max-width: rem-calc(398);
            width: auto;
            text-align: right;

        }
    }
    .imhlink-left-text{
        margin: rem-calc(30 0 0) auto;
        max-width: rem-calc(398);
        width: auto;
        text-align: center;
        @include breakpoint (small down) {
           // width: 96%;
            margin-top: rem-calc(30);
            margin-left: 2%;
            margin-right: 2%;
            margin-bottom: 0;
         }

    h3{
        @include breakpoint (small down) {
            .pdp-link-discover-more{
                font-size: rem-calc(24);
            }
        }
    }
}
}

    .single-slider-arrow-dots {
        text-align: center;
        margin: 0 auto;
        max-width: rem-calc(398);
        width: auto;
        text-align: center;
        @include breakpoint (small down) {
            width: 80%;
        }
        @include breakpoint (medium down) {
            width: 80%;
        }
        .slick-dots {
            position: relative;
            bottom: rem-calc(16);
            margin-top: rem-calc(20);
        //  right: rem-calc(20);
            width: inherit;
            @include breakpoint (small down) {
                right: inherit;
                width: 100%;
                bottom: 0;
            }
            li  {
                button {
                    &:before {
                        width: rem-calc(6);
                        height: rem-calc(6);
                        border: rem-calc(1) solid $more-light-gray;
                        background: $more-light-gray;
                        @include breakpoint (small down) {
                            border: rem-calc(1) solid $more-light-gray;
                            background: $more-light-gray;
                            opacity: 1;
                        }
                    }
                }
                &.slick-active {
                    button {
                        &:before {
                            width: rem-calc(6);
                            height: rem-calc(6);
                            border: rem-calc(1) solid $black;
                            background: $black;
                            @include breakpoint (small down) {
                                border: rem-calc(1) solid $black;
                                background: $black;
                                opacity: 1;
                            }
                            }
                    }
                }


            }

        }
    }
    .imglink_slider1link{
        .imhlink-img-size{
            max-height: rem-calc(495);
            height: auto;
            margin: 0 auto;
        }
        .imhlink{
            text-align: right;
            display: block;
            float: right;
            img{
                max-height: rem-calc(495);
                height: auto;
                text-align: right;
                @include breakpoint (small down) {
                   // width: 96%;
                   // margin-left: 2%;
                  //  margin-right: 2%;
                 }
            }

        }
        .imhlink-text{
            margin: rem-calc(30 0 0) auto;
            text-align: center;
            padding-bottom: rem-calc(5);
            @include breakpoint (small down) {
            // width: 96%;
                margin-top: rem-calc(30);
                margin-left: 2%;
                margin-right: 2%;
                margin-bottom: 0;
            }

        h3{
            @include breakpoint (small down) {
                .pdp-link-discover-more{
                    font-size: rem-calc(24);
                }
            }
        }
    }
        .slick-dots{
            position: absolute;
                bottom: rem-calc(96);
                left: 0;
                right: 0;
            }
    }
.component3img3link3price{

        text-align: center;
        margin: 0 auto;
        width: 100%;
        text-align: center;
        @include breakpoint (small down) {
            width: 80%;
        }
        @include breakpoint (medium down) {
            width: 80%;
        }
        .slick-dots {
            position: relative;
            bottom: rem-calc(16);
            margin-top: rem-calc(20);
        //  right: rem-calc(20);
            width: inherit;
            @include breakpoint (small down) {
                right: inherit;
                width: 100%;
                bottom: 0;
            }
            li  {
                button {
                    &:before {
                        width: rem-calc(6);
                        height: rem-calc(6);
                        border: rem-calc(1) solid $more-light-gray;
                        background: $more-light-gray;
                        @include breakpoint (small down) {
                            border: rem-calc(1) solid $more-light-gray;
                            background: $more-light-gray;
                            opacity: 1;
                        }
                    }
                }
                &.slick-active {
                    button {
                        &:before {
                            width: rem-calc(6);
                            height: rem-calc(6);
                            border: rem-calc(1) solid $black;
                            background: $black;
                            @include breakpoint (small down) {
                                border: rem-calc(1) solid $black;
                                background: $black;
                                opacity: 1;
                            }
                            }
                    }
                }


            }

        }
        .three-slider-arrow{
            .product{
            margin: rem-calc(20 0 20 20);
            &:last-child{
                margin-right: rem-calc(20);
            }
            }
        }
    }


    // SWIPE
    .three-img-swipe{
       /* margin: rem-calc(39 150 39 230);*/
        padding: 3% 9.7% 3% 18%;
        @include breakpoint (medium down) {
            padding: 1.5% 4.8% 1.5% 9%;
        }
        @include breakpoint (small down) {
            padding: rem-calc(0);
        }

        .wrapper-header{
            text-align: center;
            width: 100%;
            padding: 0 14%;
            @include breakpoint (medium down) {
                padding: 0 10%;
            }
            @include breakpoint (small down) {
                padding: rem-calc(0);
            }

            h2{
                font-size: rem-calc(30);
                font-weight: 700;
                line-height: rem-calc(42);
                letter-spacing: 0;
                margin: 0 auto;
                text-align: center;
                padding-bottom: rem-calc(15);
                text-shadow: rem-calc(2 -2 0) $shadow-text;
                @include breakpoint (small down) {
                    font-size: rem-calc(24);
                    line-height: rem-calc(26);
                }
            }
            p{
                font-size: rem-calc(14);
                font-weight: 400;
                line-height: rem-calc(20);
                text-align: center;
                letter-spacing: 0.5;
            }
            a {
                &.shop-now{
                    font-size: rem-calc(13);
                    line-height: rem-calc(18);
                    text-align: center;
                    letter-spacing: 0;
                }
            }
        }

        .wrapper-overflow {
            width: 100%;
            @include breakpoint (small down) {
            display: block;
            width: 100%;
            white-space: nowrap;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: -ms-autohiding-scrollbar;
             }
            .card {
                display: inline-block;
                width: 32%;
                border: 0;
                background-color: transparent;
                @include breakpoint (small down) {
                    width: auto;

                }
                img{

                    margin: 0 auto;
                    @include breakpoint (small down) {
                        height: rem-calc(340);
                        width: rem-calc(253);
                        margin: 0 auto;
                    }

                }

                h3{
                    font-size: rem-calc(16);
                    font-weight: 600;
                    line-height: rem-calc(22);
                    letter-spacing: 0.5;
                    text-align: left;
                    margin-top: rem-calc(5);
                    margin-bottom: 0;
                    @include breakpoint (small down) {
                        text-align: center;
                    }
                }

                a {
                    &.shop-now{
                        font-size: rem-calc(13);
                        line-height: rem-calc(18);
                        text-align: left;
                        letter-spacing: 0;
                        @include breakpoint (small down) {
                            text-align: center;
                        }
                    }
                }
              }
        }
        .wrapper-images{
            margin:  rem-calc(0 10);
            @include breakpoint (small down) {
                text-align: center;
            }
        }
    }

    .pre-footer-banner-txt-cta{
        text-align: center;
        img{
            max-width: rem-calc(820);
            width: 100%;
        }
        h3{
            font-size: rem-calc(11);
            font-weight: 600;
            line-height: rem-calc(15);
            text-align: center;
            margin-top: rem-calc(5);
            margin-top: rem-calc(20);
            margin-bottom: rem-calc(5);
        }

        a {
            font-size: rem-calc(10);
            line-height: rem-calc(14);
            text-align: center;
        }
    }
    .pdp-link-discover-more{
        font-weight: 700;
    }
    // COMPONENT
    .three-image-price-link-txt-body-cta{
        @include breakpoint (medium) {
        padding-top: 11.5%;
        padding-bottom: 11.5%;
        }
        .three-slider-arrow-dots-variable-width{

            @include breakpoint (medium) {
            height: 73%;
            }
            @include breakpoint (small down) {
                margin: 0 auto;
                max-width: 80%;
                width: auto;
                text-align: center;
            }
            .img-size{
                &.img-size-left{
                    @include breakpoint (medium) {
                    width: 25%!important;
                    height: auto;
                    margin-top: 151px;
                    margin-left: 2.5%!important;
                    margin-right: 2.5%!important;
                }
            }

                &.img-size-center{
                    @include breakpoint (medium) {
                    width: 39%!important;
                    height: auto;
                    margin: 0 auto;
                    }
                }
                &.img-size-right{
                    @include breakpoint (medium) {
                    width: 25%!important;
                    height: auto;
                    margin-top: 82px;
                    margin-left: 2.5%!important;
                    margin-right: 2.5%!important;

                    }
                }

                    .format-text{
                        margin-bottom: rem-calc(40);
                        @include breakpoint (small down) {
                            margin-bottom: rem-calc(0);
                            margin-top: rem-calc(20);
                        }
                        h5{
                        font-size: rem-calc(16);
                        font-weight: 600;
                        line-height: rem-calc(22);
                        }

                        a{
                            font-size: rem-calc(13);
                            font-weight: 600;
                            line-height: rem-calc(18);
                        }
                    }

            }
        }

        .wrapper-left{
            padding-top: 9.7%;
            padding-left: 43.7%;
            position: relative;
            z-index: 1;
            @include breakpoint (medium down) {
                padding-left: 14%;
            }
            @include breakpoint (small down) {
                padding: rem-calc(15 10 0 10);
            }

            .bg-square{
                position: absolute;
                left: rem-calc(20);
                top: rem-calc(14);
                z-index: 2;
            }
            h2{
                font-size: rem-calc(38);
                font-weight: 700;
                line-height: rem-calc(53);
                letter-spacing: 0;
                margin: 0 auto;
                text-align: left;
                padding-bottom: rem-calc(15);
                text-shadow: rem-calc(2 -2 0) $shadow-text;
                position: relative;
                z-index: 3;
                @include breakpoint (small down) {
                    font-size: rem-calc(24);
                    line-height: rem-calc(26);
                    text-align: center;
                    text-shadow: none;
                }
            }
            p {
            font-size: rem-calc(14);
            font-weight: 400;
            line-height: rem-calc(20);
            text-align: left;
            padding-bottom: rem-calc(15);
            padding-right: rem-calc(63);
            position: relative;
            z-index: 3;
            @include breakpoint (small down) {
                text-align: center;
                padding-bottom: 0;
                padding-right: 0;
            }
        }
        a {
            text-align: left;
            @include breakpoint (small down) {
                text-align: center;
                padding-bottom: rem-calc(30);
                display: block;
            }
        }


    }
}
// COMPONENT
.banner-full-txt-pink{
    text-align: center;
    padding-top: rem-calc(15);
    padding-bottom: rem-calc(15);
    padding-left: 19%;
    padding-right: 19%;
    width: 100%;
    background-color: $pink-banner;
    @include breakpoint (small down) {
        padding: rem-calc(10);
    }
        span{
        font-size: rem-calc(13);
        font-weight: 500;
        line-height: rem-calc(18);
        }
        a{
            font-size: rem-calc(13);
            font-weight: 500;
            line-height: rem-calc(18);
        }

    }
