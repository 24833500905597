
.prefooter-valentines{
    position: relative;
    img{
        width:8000px;
    }
    
}

.valentines-card{
   // width: 25%;
   // max-width: rem-calc(218);
    padding: rem-calc(10 10);
    text-align: center;
    &:focus {
        outline: none;
    }
}

.valentines-img{
    margin-bottom:rem-calc(20);
    img{
        width: rem-calc(218);
        margin: auto;
    }
}

.valentines-message{
    font-size: rem-calc(11);
    line-height: rem-calc(15);
    letter-spacing: rem-calc(0.55);
    font-weight: 600;
    margin-bottom:rem-calc(10);
}

.valentines-link{
    text-align: center;
    font-size: rem-calc(10);
    line-height: rem-calc(14);
}

.prevArrow, .nextArrow {
    display:none;
    @include breakpoint(small down) {
        display:block;
    }
}

.prevArrow {
    &:focus {
        outline: none;
    }
    position: absolute;
    left: 10%;
    bottom: 65%;
}

.nextArrow {
    &:focus {
        outline: none;
    }
    position: absolute;
    right: 10%;
    bottom: 65%;
}