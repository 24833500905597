.banner-text {
    padding-top: 20px;
    padding-bottom: 20px;
}

.girls-block{
    position: relative;
}

.images-girls{
    padding: rem-calc(10);  
}

.girls-card{
    text-align: center;
    padding-bottom: rem-calc(33);
    &:focus {
        outline: none;
    }
    @include breakpoint(small down) {
        margin-top: rem-calc(20);
    }
}

.girls-message{
    font-size: rem-calc(22);
    line-height: rem-calc(17);
    margin: rem-calc(0 10);
    p {
        font-size: rem-calc(30);
        margin-bottom:0;
    }
    .sub-message{
        font-size: rem-calc(12);
    }
}

.girls-link{
    text-align: center;
    font-size: rem-calc(13);
    line-height: rem-calc(14);
    margin-top: rem-calc(10);
}

.two-img_2link_custom_x_homepage-left{
    padding-top: 2.18%;
    .img-left{
        margin:0 1.48% 0 0;
        text-align: center;
        @include breakpoint (small down) {
            margin:0 auto;
            width: 80%;
        }
    }
    .img-right{
        margin:0 1.48% 0 0;
        text-align: center;
        @include breakpoint (small down) {
            margin: 0 auto;
        }
    }
    .wrapper-text{
        padding-top: rem-calc(13);
        padding-bottom: rem-calc(27);
        text-align: center;
        h3{
            @include breakpoint (small down) {
                font-size: rem-calc(24);
            }
        }

    }
}
