.page-title{
        font-size: rem-calc(22);
        line-height: rem-calc(28);
        padding-left: rem-calc(0);
        margin: rem-calc(0 0 25 0);
        @include breakpoint (small down) {
          padding: 0;
          text-align: center;
          margin: rem-calc(25 0 25 0);
        }
}

.privacy-style{

    .content-page-wrapper{
    @include breakpoint(medium down) {
        padding: rem-calc(0 20);
    }
    @include breakpoint(large up) {
        padding-right: 15%;
    }
}

.page-content-box{
    p{
        margin-bottom: rem-calc(20);
        a{
            text-decoration: none;
            border-bottom: rem-calc(1) solid transparent;
            &:hover{
                border-color:  $dark-gray;
            }
        }
    }
}

.welcome-content{
    margin-top: rem-calc(30);
    margin-bottom: rem-calc(30);
}

.page-row{
    h3{
        font-size: rem-calc(16);
        line-height: rem-calc(20);
        margin-top: rem-calc(60);
        margin-bottom: rem-calc(35);
        overflow: hidden;
        span{
            position: relative;
            &::after{
                content: "";
                position: absolute;
                top: 50%;
                height: rem-calc(1);
                width: rem-calc(9999);
                display: block;
                margin-top: rem-calc(-1);
                background-color: rgba(0,0,0,.075);
                left: 100%;
                margin-left: .5em;
            }
        }
    }
    p{
        padding-left: rem-calc(20);
        text-align: left;
        margin-bottom: rem-calc(30);
    }
    ul{
        list-style-type: none;
        padding-left: rem-calc(20);
        margin:0;

        &.list-type-triangle{
            padding-left: rem-calc(20);
            text-align: left;
            list-style-type: none;
            
            li{ 
                padding-left: rem-calc(30);
                letter-spacing: rem-calc(0.65);
                &::before{
                    content: "";
                    border-color: transparent #595959;
                    border-style: solid;
                    border-width: .35em 0 .35em .45em;
                    display: block;
                    height: 0;
                    width: 0;
                    left: rem-calc(-30);
                    top: .9em;
                    position: relative;

                }
            }
        }
    }
}
.margin-top-p{
    margin-top:rem-calc(30);
}

.box-grey{
    width: 100%;
    background-color: #f6f6f6;
    padding: rem-calc(15 20);
    float: none;
    p{
        padding-left: 0;
    }

    + .box-grey{
        margin-top:rem-calc(20);
    }
}

.title-box{
    font-size: rem-calc(20);
    font-weight: 700;
    color: #666;
    text-align: left;
    text-transform: none;
    margin-top: 0;
}

.content-img-right-top{
    @include breakpoint(medium up){
        max-width: rem-calc(405);
        width: 100%;
        height: auto;
        float: right;
        padding: rem-calc(20 0 20 20);
        padding-top: rem-calc(40);
    }
    @include breakpoint(small down){
        text-align: center;
        margin: rem-calc(30 0);
    }
}

.img-responsive{
    display: inline-block;
    height: auto;
    max-width: 100%;
}

.content-img-right-bottom{
    @include breakpoint(medium up){
        max-width: rem-calc(405);
        width: 100%;
        height: auto;
        float: right;
        padding: rem-calc(20 0 20 20);
        padding-top: rem-calc(40);
    }
    @include breakpoint(small down){
        text-align: center;
        margin: rem-calc(30 0);
    }
    
}

.footer-franchising{
    p{
        font-size: rem-calc(16);
        padding-top: rem-calc(20);
    }
}

}