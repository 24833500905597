@import "../setup/settings";

$slide-out-animation-timing: 0.4s cubic-bezier(0.85, 0.15, 0.15, 0.85);
$slide-out-animation: left $slide-out-animation-timing, right $slide-out-animation-timing;
$slide-out-animation-all: all $slide-out-animation-timing;

// Mixins
@include breakpoint(medium down) {
    .navbar-toggleable-small {
        background-color: $white;
        position: fixed;
        overflow: auto;
        margin-top:0;
        right: -100%;
        top: 0;
        bottom: 0;
        transition: $slide-out-animation;
        display: block;
        max-width: 100%;
        z-index: 1;
        &.in {
            @include breakpoint(small down) {
                right: 0;
                width: 100%;
                .navbar-toggler {
                    display: none;
                }
            }
            @include breakpoint(medium down) {
                right: 0;
                width: 100%;
                .navbar-toggler {
                    display: none;
                }
            }
        }
    }
}

/*
@mixin caret-left() {
    border-top: 0.3em solid transparent;
    border-bottom: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-left: 0.3 solid transparent;
    width: 0;
    height: 0;
    display: inline-block;
    margin-bottom: 0.125em;
}
*/
/*
@mixin caret-right() {
    border-top: 0.3em solid transparent;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
    position: absolute;
    right: 0.3em;
    margin-top: 0.55em;
}
*/
/*
@mixin slide-navbar-hover {
    position: relative;
    &:after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: -5px;
        width: calc(100% + 24px);
        height: 8px;
        background-color: #FDCFF2;
        z-index: -1;
        transform: scaleX(0);
        transform-origin: bottom left;
        transition: transform 0.5s;
        @include breakpoint(medium down) {
            display: none;
        }
    }
    &:hover {
        text-decoration: none;
        &:after {
            transform-origin: bottom left;
            transform: scaleX(1);
        }
    }
}
*/

.page[data-action="Home-Show"] {
    .navigation--tezenis.open {
        @include breakpoint (small down) {
            position: fixed;
            top: 0;
        }
    }
}
/*
.black-background-for-menu{
    position: fixed;
    background: rgba(0,0,0,.5);
    height: 100vh;
    width: 100vw;
    z-index: 0;
    top: 0;
    left: 0;
}
*/


.overlay-menu-desktop {
    @include breakpoint (small down) {
        display: none !important;
    }
    @include breakpoint (medium down) {
        display: none !important;
    }
    position: fixed;
    display: none;
    top: rem-calc(175);
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(130, 193, 189, 0.6);
    backdrop-filter: blur(50px);
    z-index: 12;
}

.flex-spacer {
    flex-grow: 1;
}
/*
.invisible-scrollbar::-webkit-scrollbar {
    display: none;
}
*/

// Styles
/*
.navbar {
    &.follow-scroll {
        position: fixed;
        &.at-top {
            margin-top: rem-calc(-20);
        }
        &.at-bottom {
            bottom: 0;
        }
    }

    padding: 0;
    z-index: 2;

    @include breakpoint(large up) {
        width: calc(16% - #{rem-calc(map-get($grid-margin-gutters, large) / 2)});
        transition: opacity 0.15s ease;
        opacity: 0;

        &.show {
            opacity: 1;
        }

        position: fixed;
        top: rem-calc(190);
    }

    @include breakpoint(medium down) {
        height: 98vh;
        width: 100%;
    }

    @media screen and (min-height: 768px) {
        display: flex;
        flex-direction: column;
    }

    // &-content,
    .mobile-header {
        @include breakpoint(tablet up) {
            margin-left: 0;
            margin-right: 0;
        }
    }

    &-promo {
        display: block;
        background-color: rgba($primary, 0.4);
        padding: rem-calc(60 30 50);
        margin: rem-calc(42 8 64);
        // position: absolute;
        bottom: 0;
        left: 0;
        right: 0;

        &.man {
            background-color: rgba($info, 0.4);
        }

        &-title, h2 {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            text-align: center;
            transform: translateY(-50%);
            text-transform: uppercase;
            font-size: rem-calc(28);
            font-weight: $global-weight-black;
            color: $dark-gray;
        }

        &-subtitle, h3 {
            position: absolute;
            top: 0;
            left: 50%;
            font-size: rem-calc(28);
            font-weight: $global-weight-black;
            color: rgba($dark-gray, 0.3);
        }

        &-blurb, p {
            font-size: rem-calc(20);
            font-weight: $global-weight-bold;
            margin-bottom: 1em;
        }

        &-cta, a {
            font-size: rem-calc(13);
            font-weight: $global-weight-bold;
        }
    }

    &.bg-inverse {
        background-color: transparent;
    }

    @include breakpoint(large up) {
        .navbar-nav .nav-item + .nav-item {
            margin-left: 0;
        }
    }

    &.mt-header-drawer{
        -webkit-transition: margin-top 1s;
        -moz-transition: margin-top 1s;
        -ms-transition: margin-top 1s;
        -o-transition: margin-top 1s;
        transition: margin-top 1s;
        overflow: hidden;
        margin-top: rem-calc(180);

    }
    &.mt-header-drawer-none{
        -webkit-transition: margin-top 1s;
        -moz-transition: margin-top 1s;
        -ms-transition: margin-top 1s;
        -o-transition: margin-top 1s;
        transition: margin-top 1s;
        overflow: hidden;
        margin-top: initial;
    }
}
*/
/*
.nav-item .nav-link:hover,
.nav-item .nav-link:focus,
.nav-item .nav-link:active,
.nav-item.show .nav-link {
    @include breakpoint(medium down) {
        background-color: transparent;
    }
}
*/
.menu-header, .menu-top-level {
    margin: 0;
}
.mobile-header {
    @include breakpoint (medium down){
        padding: rem-calc(0 20);
    }
}

.tabs-submenu {
    transition: $slide-out-animation-all;
    transform: translateX(0);
    height: unset;
    opacity: unset;
    background-color: $white;

    @include breakpoint (large up) {
        max-height: rem-calc(592);
    }
}

.menu-overflow-state-1 .tabs-submenu {
    transform: translateX(100%);
    position: absolute;
    top: rem-calc(16);
    background-color: $white;
}

.menu-header {
    display: flex;
    @include breakpoint (medium up) {
        padding: rem-calc(24 40 0 40);
    }
}
.menu-top-level {
    @include breakpoint (small down){
        align-items: center;
        border-bottom: rem-calc(1) solid #DEDEDE;
        border-top: rem-calc(1) solid #DEDEDE;
        height: rem-calc(58);
        /* padding-bottom: rem-calc(14.5); */
        
    }
    /*
    ul {
        display: flex;
    }
    */
    li {
        display: flex;
        font-size: rem-calc(14);
        line-height: rem-calc(21);
        padding: rem-calc(0 24 0 0);
        @include breakpoint (small down){
            padding: 0;
        }
    }
    /*
    .level-menu {
        @include breakpoint (small down){
            text-align: center;
        }
    }
    */
}
.menu-for-tezenis {
    z-index: 13;
    font-size: 0;
    * {
        outline: none;
    }
    .top-category {
        color: $black;
        font-size: rem-calc(16);
        font-weight: 600;
        line-height: rem-calc(24);
        letter-spacing: rem-calc(0.2);
        text-align: center;
        width: inherit;
        border-bottom: 0;

        @include breakpoint(small down) {
            border-right: 1px solid $light-gray !important;
            margin-right: rem-calc(0);
            padding: rem-calc(0 16);
            width: max-content;
        }

        &:last-child {
            margin-right: 0;
        }
        /*
        &:hover {
            cursor: pointer;
            text-decoration: none;
        }
        */

        &.active {
            // color: $black;
            position: relative;

            &::before {
                background-color: $black;
                border-radius: rem-calc(4);
                content: '';
                height: rem-calc(4);
                left: calc(50% - 4px);
                position: absolute;
                top: 100%;
                width: rem-calc(4);
            }
        }
    }
    .js-open-tab-menu {
        &:last-of-type {
            margin-bottom: 0;
        }
        @include breakpoint (medium up) {
            margin-bottom: rem-calc(12);
            line-height: rem-calc(21);
            font-size: rem-calc(12);
            position: relative;
            min-height: rem-calc(21.43);
            width: max-content;

            &.highlight {
                font-size: rem-calc(14);
                line-height: rem-calc(21);
                margin-bottom: rem-calc(8);

                &.fix-margin-section{
                    margin-bottom: rem-calc(24);
                }
            }
        }

        @include breakpoint (small down) {
            &.highlight {
                font-weight: 600;
            }
        }

        a {
            margin-right: rem-calc(4);
            font-weight: 500;
            
            @include breakpoint (medium up) {
                transition: color 0.1s;
                &:hover, &:active{
                    @include breakpoint (medium up) {
                        font-weight: 600;
                    }
                    // border: 0;
                }
            }
            /*
            &:hover {
                border: 0;
            }
            */
            /*
            &:hover,
            &:link {
                border-width: 0;
                border-color: unset;
                border: 0 !important;
                text-decoration: none!important;
                -webkit-tap-highlight-color: transparent;
                outline: none;
                text-decoration-line: none;
            }
            */
            /*
            &:active {
                @include breakpoint (medium up) {
                    font-weight: 600;
                }
            }
            */
        }
        &.active {
            a {
                @include breakpoint (medium up) {
                    font-weight: 600 !important;
                    transition: unset;
                    &:hover {
                        // border: none;
                        transition: unset;
                    }
                }
                /*
                &:hover {
                    border: 0;
                }
                */
            }
        }
        /*
        &.category-active::after{
            @include breakpoint (medium up){
                visibility: visible;
            }
        }
        &.category-active{
            a{
                font-weight: 600;
            }
        }
        */
        /*
        &.highlighted-tab{
            a{
                font-weight: 600;
            }
        }
        &.highlighted-tab{
            height: unset;
        }
        */
    }
    /*
    .js-open-tab-menu.active {
        a {
            @include breakpoint (medium up) {
                font-weight: 600 !important;
                transition: unset;
                &:hover {
                    border: none;
                    transition: unset;
                }
            }
            &:hover {
                border: 0;
            }
        }
    }
    */
    /*
    .view-all-label{
        font-size: rem-calc(14);
        font-weight: 500;
        @include breakpoint (medium down){
            font-size: rem-calc(16);
        }
        &:hover{
            border-bottom: transparent;
            color: $black;
        }
    }
    */
    .category-tag-name-div{
        display: inline-block;
        line-height: 0;
        .category-tag-name {
            vertical-align: super;
            font-size: rem-calc(10);
            font-weight: 500;
            line-height: rem-calc(10);
            letter-spacing: rem-calc(0.2);
        }
        /*
        &.animation-for-mobile {
            @include breakpoint (small down){
                opacity: 1;
                transition: transform 0.5s ease, opacity 1s ease;
            }
        }
        */
    }

    /*
    .js-open-tab-menu-level2{
        margin-bottom: rem-calc(8);
        .menu-item-level-2{
            display: inline-block;
            font-size: rem-calc(14);
            font-weight: 400;
            line-height: rem-calc(21);
            transition: unset;
            margin-right: rem-calc(4);
            
            &:hover{
                font-weight: 600 !important;
                border-color: transparent;
            }
            &.active{
                text-decoration: underline;
                font-weight: 600;
                display: inline-block;
                @include breakpoint (medium down){
                    color: black;
                }
            }
            @include breakpoint (medium down){
                font-size: rem-calc(16);
                font-weight: 500;
                line-height: rem-calc(25);
            }
            &.animation-for-mobile{
                @include breakpoint (small down){
                    opacity: 1;
                    transition: transform 0.5s ease, opacity 1s ease;
                }
            }
        }
        .image-category-mobile{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            z-index: -1;
            filter: unset;
            height: rem-calc(147);
            border-radius: rem-calc(0 0 8 8);
        }
        .background-second-category{
            position: absolute;
            width: 100%;
            top: 0;
            height: rem-calc(147);
            left: 0;
            background: black;
            opacity: 0.15;
            border-radius: rem-calc(0 0 8 8);
        }
        .background-default-category{
            position: absolute;
            width: 100%;
            top: 0;
            height: rem-calc(147);
            left: 0;
            border-radius: rem-calc(0 0 8 8);
        }
    }
    */
    /*    
    .highlighted-category {
        font-size: rem-calc(20);
        line-height: rem-calc(30);
        font-weight: 600;
    }
    */

    // button {
        /*
        display: flex;
        align-items: center;
        position: relative;
        z-index: 4;
        */
        /*
        .hamburger {
            display: inline-block;
            margin-right: rem-calc(7);
            span {
                width: 20px;
                height: 3px;
                background-color: $black;
                display: block;
                margin-bottom: 4px;
                transition: all 0.3s ease-in-out;
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
            &.active{
                @include breakpoint (medium down){
                }
            }
        }
        */
        /*
        span{
            letter-spacing: rem-calc(0.3);
            font-size: rem-calc(15);
            font-weight: 600;
            @include breakpoint (tablet down) {
              display: none;
            }
            @include breakpoint (tablet up) {
                position: relative;
                bottom: rem-calc(-8.8);
            }
        }
        */
    // }
    /*
    @include breakpoint (medium down){
        .slide-navbar {
            > .navbar {
                overflow-x: unset;
                .menu-group {
                    .mobile-header {
                        margin: 0;
                        padding: rem-calc(50 40 30);
                    }
                    .nav {
                        .nav-item {
                            .dropdown-menu {
                                padding-left: 0;
                                -webkit-transform: unset;
                                -ms-transform: unset;
                                transform: unset;
                            }
                            &:after {
                                content: unset;
                            }
                            .nav-link {
                                padding-left: rem-calc(40);
                                border-top: 1px solid $more-light-gray;
                                &:hover {
                                    border-top: 1px solid $more-light-gray;
                                }
                            }
                            &:first-child {
                                .nav-link {
                                    border-top: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    */

    .close-button {
        @include breakpoint (small down) {
            position: relative;
            z-index: 9999999999;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            position: fixed;
            top: 0;
            right: 0;
            background-color: $white;
            width: 100vw;
            padding-right: rem-calc(16);
            height: rem-calc(58);
        }
    }
}
/*
.underlineAnimation-for-mobile {
    @include breakpoint (medium down){
        display: inline-block;
        position: relative;
        &::before {
        position: absolute;
        border-bottom: 3px solid black;
        bottom: 0;
        content: "";
        height: 100%;
        transition: width 0.3s ease;
        width: 0;
        z-index: -1;
        bottom: rem-calc(-6);
        }
        &.active {
            &::before {
                transition: width 0.4s ease;
                width: 100%;
            }
        }
    }
}
*/

/*
.selected-category {
    @include breakpoint (medium up) {
        a {
            color: #FF6699;
        }
    }
}
*/

.dropdown-menu {
    li.dropdown-item.dropdown & {
        @include breakpoint(medium down) {
            padding-left: 6rem !important;
            transform: translateX(-6rem) !important;
            width: calc(100% + 3rem);
        }
        @include breakpoint(small down) {
            width: calc(100% + 6rem);
        }
    }
}
/*
.slide-navbar {
    min-width: rem-calc(373);
    position: fixed;
    background: white;
    padding: rem-calc(41 33 41 80);
    top: 0;
    left: 0;
    height: calc(100% - 99px);
    transform: translateX(-100%);
    transition: transform .5s ease;
    overflow: auto;
    @include breakpoint (medium up){
        z-index: 5;
    }
    @include breakpoint(small down) {
        width: 100%;
        height: 100%;
        top: 0;
        padding-top: 0;
        overflow-x: hidden;
        transform: translateX(0);
        visibility: hidden;
        opacity: 0;
    }
    @include breakpoint(tablet up) {
        height: calc(100%);
        overflow-y: hidden;
    }
    &.open {
        transform: unset;
        overflow: auto;
        z-index: 5;
        padding: rem-calc(41 33 41 80);
        @include breakpoint (small down){
            padding: 0;
            transition: unset;
            visibility: visible;
            opacity: 1;
        }
        &::-webkit-scrollbar {
            display: none;
        }

        @include breakpoint (medium down) {
            .close-menu {
                display: block;
            }
        }
    }

    > .navbar {
        position: relative !important;
        top: auto !important;
        display: block !important;
        opacity: 1 !important;
        margin-top: 0 !important;
        overflow-x: hidden;
    }

    .navbar .menu-group .nav .nav-item {
        .dropdown-menu {
            @include breakpoint(medium down) {
                min-height: inherit;
                position: relative;
                padding-left: 3rem;
                transform: translateX(-6rem);
            }
            &.show {
                // @include breakpoint(small down) {
                //     padding-left: 2rem;
                //     transform: translateX(-2rem);
                // }
            }
        }
    }

    .navbar {
        top: 0;
        width: 100%;
        margin-top: 0;
        background-color: transparent;
        opacity: inherit;
        border-bottom: none;
        padding: 0;
        margin-top: 0!important;
        @include breakpoint(medium down) {
            padding: 0;
            height: 100%;
        }
        span , a {
            letter-spacing: 0;
        }
        ul.navbar-nav {
            li {
                &.nav-item {
                    &.dropdown {
                        &.super-container {
                            a {
                                -webkit-tap-highlight-color: transparent;

                                &.super-item {
                                    .menu-item-category-name:before {
                                        content: '+';
                                        right: -15px;
                                        top: 0;
                                        position: absolute;
                                        @include breakpoint(medium down) {
                                            top: 50%;
                                            transform: translateY(-50%);
                                            right: -20px;
                                        }
                                    }
                                    @include slide-navbar-hover;
                                }
                                &.active {
                                    text-decoration: none;
                                    .menu-item-category-name:before {
                                        content: '-';
                                    }
                                    &:after {
                                        transform-origin: bottom left;
                                        transform: scaleX(1);
                                    }
                                }
                            }

                            @include breakpoint (medium down) {
                                .menu-item-category-name {
                                    position: relative;
                                }

                                // Second level category
                                & > .dropdown-menu > .dropdown-item {
                                    padding-left: rem-calc(60);

                                    &:before {
                                        top: rem-calc(30);

                                        @include breakpoint (small down) {
                                            top: rem-calc(20);
                                        }
                                    }

                                    // Third level category
                                    .dropdown-menu .dropdown-item {
                                        padding-left: rem-calc(30);

                                        a.dropdown-link {
                                            padding: rem-calc(12) 0;
                                        }
                                    }
                                }
                            }
                        }
                        ul.dropdown-menu {
                            li {
                                &.dropdown-item {
                                    a {
                                        &.dropdown-link {
                                            @include slide-navbar-hover;
                                            &:after {
                                                width: calc(100% + 10px);
                                                bottom: -1px;
                                            }
                                        }
                                        &.active {
                                            text-decoration: none;
                                            .menu-item-category-name:before {
                                                content: '';
                                            }
                                            &:after {
                                                transform-origin: bottom left;
                                                transform: scaleX(1);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .nav-item {
            .nav-link {
                @include slide-navbar-hover;
                font-size: rem-calc(15);
            }
        }
    }
    .close-button-menu{
        position: absolute;
        top: rem-calc(46);
        left: rem-calc(35);
        cursor: pointer;
        @include breakpoint (medium down){
            left: unset;
            right: rem-calc(24);
            z-index: 4;
            top: rem-calc(24);
            width: rem-calc(16);
        }
    }
}
*/
/*
.fnMenu-subCategory, .fnMenu-subCategoryCookie{
    height: 100vh;
    transform: scaleX(0);
    transform-origin: bottom left;
    transition: transform .5s;
    background: white;
    left: 0px;
    position: fixed;
    top: 0;
    padding: rem-calc(127.5 33 41 33);
    min-width: rem-calc(273);
    @include breakpoint (medium down){
        z-index: 5;
        width: 100%;
        height: 100%;
        padding: rem-calc(25);
        background: white;
    }
    .close-button-menu{
        width: rem-calc(16);
        &.button-container{
            right: 0;
            position: absolute;
            width: rem-calc(50);
            display: flex;
            justify-content: right;
            background: transparent;
            padding-right: rem-calc(25);
            z-index: 1;
        }
    }
    .menu-back-icon{
        font-size: rem-calc(32);
        width: rem-calc(9);
        height: rem-calc(16);
        top: rem-calc(24);
        color: white;
        &.image-container{
            width: rem-calc(50);
            height: rem-calc(30);
            display: flex;
            justify-content: left;
            position: absolute;
            background: transparent;
            left: 0;
            padding-left: rem-calc(25);
            z-index: 1;
        }
    }
    .fnMenu-grid{
        align-items: center;
        justify-content: space-between;
    }
    .fnMenu-subCategory-content, .fnMenu-subCategory-content-cookie{
        @include breakpoint (medium down){
            margin-top: rem-calc(147)!important;
        }
    }
    .category-name{
        @include breakpoint (medium down) {
            position: absolute;
            top: rem-calc(103);
            font-size: rem-calc(20);
            line-height: rem-calc(30);
            color: white;
            font-weight: 600;
            z-index: 1;
        }
    }
    &.opened{
        opacity: 1;
        @include breakpoint (medium down){
            transition: all 0.5s ease ;
        }
    }
    @include breakpoint (medium up){
        opacity: 0;
    }
    &.no-transition{
        @include breakpoint (small down){
            transition: none;
        }
    }
}
.fnMenu-subCategory{
    opacity: 1;
    @include breakpoint (medium down){
        visibility: hidden;
        transition: all .5s ease;
    }
    &.opened{
        opacity: 1;
        @include breakpoint (medium down){
            visibility: visible;
            transition: all 0.5s ease;
        }
    }
}

.fnMenu-subCategoryCookie{
    opacity: 1;
    @include breakpoint (medium down){
        visibility: hidden;
        transition: all 0.5s ease;
    }
    &.opened{
        transition: all 0.5s ease;
        opacity: 1;
        @include breakpoint (medium down){
            visibility: visible;
            transition: all 0.5s ease;
        }
    }
}
*/
/*
.background-for-animation-first-column{
    position: fixed;
    top: 0;
    left: 0;
    min-width: rem-calc(373);
    height: 100vh;
    background: white;
    transform: translateX(0);
    visibility: hidden;
    &.add-visibility{
        visibility: visible;
        z-index: 4;
    }
}
*/
/*
.background-for-animation{
    position: fixed;
    top: 0;
    left: 0;
    width: rem-calc(273);
    height: 100vh;
    background: white;
    transform: translateX(-100%);
    opacity: 0;
    transition: opacity .2s linear;
    &.add-visibility{
        visibility: visible;
        opacity: 1;
    }
    &-cookie{
        position: fixed;
        top: 0;
        left: 0;
        width: rem-calc(273);
        height: 100vh;
        background: white;
        transform: translateX(-100%);
        transition: all .2s ease;
        opacity: 0;
        &.add-visibility{
            visibility: visible;
            opacity: 1;
            transition: all .2s ease;
        }
    }
}
*/
/*
.fnMenu-subCategoryCookie{
    transform-origin: left right;
}
*/

.menu-toggleable-left {
    @include breakpoint (small down) {
        width: 100%;
    }

    .dropdown {
        margin-bottom: 0;
        .caret-right {
            display: none;
            margin-right: rem-calc(12);
        }
    }

    .dropdown-item {
        .dropdown-link,
        .view-all-link {
            @include breakpoint(medium up) {
                font-weight: 500;
                line-height: 1;
                border-color:transparent;
                &.active,&:hover {
                    font-weight: 600
                }
            }
        }
    }
    
    // Mobile
    @include breakpoint(medium down) {
        .bg-inverse {
            background-color: $white;
        }

        .dropdown {
            display: block;
            position: static;
        }

        .nav-item,
        .dropdown-item {
            .menu-for-tezenis & {
                width: 100vw;
                position: relative;
            }

            .nav-link,
            .dropdown-link {
                display: flex;
                font-weight: 500;
                width: 100%;
                justify-content: space-between;
                font-size: rem-calc(16);
                border: none;

                &:hover {
                    border: none;
                }

                @include breakpoint(large up) {
                    display: inline-block;
                    padding-left: 0;
                    width: auto;
                }

                .menu-icon {
                    margin-right: rem-calc(4);
                    svg {
                        width: rem-calc(16);
                        height: rem-calc(16);
                    }
                }
            }
        }
    }
}

.multilevel-dropdown {
    ul {
        list-style-type: none;
        margin-left: 0;

        .account-label {
            @include breakpoint (small down) {
                font-size: rem-calc(16);
                font-weight: 600;
                line-height: rem-calc(24);
                letter-spacing: rem-calc(0.2);
                margin-bottom: rem-calc(16);
                margin-top: rem-calc(40);
            }
        }

        .account-option {
            @include breakpoint (small down) {
                background-color: $over-gray;
                border-radius: rem-calc(8);
                display: block;
                font-size: rem-calc(16);
                font-weight: 500;
                height: rem-calc(72);
                line-height: rem-calc(24);
                letter-spacing: rem-calc(0.2);
                margin-bottom: rem-calc(8);
                padding: rem-calc(24 40 24 56);
                position: relative;

                &:hover, &:active, &:focus {
                    border-bottom: none;
                    text-decoration: none;
                }

                &.loyalty-li {
                    background: linear-gradient(135deg, rgba(236, 177, 100, 0.6) 0%, rgba(233, 153, 96, 0.6) 21.98%, rgba(238, 132, 114, 0.6) 37.41%, rgba(249, 109, 143, 0.6) 59.66%, rgba(227, 102, 156, 0.6) 77.35%, rgba(149, 98, 159, 0.6) 100%) !important;
                    height: auto;
                    min-height: rem-calc(72);
                    padding-bottom: rem-calc(0);
                    img.talent-icon {
                        height: rem-calc(24);
                        left: rem-calc(24);
                        position: absolute;
                        width: rem-calc(24);
                    }

                    .loyalty-points-text {
                        font-size: rem-calc(12);
                    }
                }

                .mobile-menu-icon {
                    position: absolute;
                    left: rem-calc(24);

                    svg {
                        height: rem-calc(24);
                        width: rem-calc(24);
                    }
                }
            }

            .menu-label-smaller {
                font-weight: 500;
            }

            .arrow-right {
                display: none;
                height: rem-calc(16);
                position: absolute;
                right: rem-calc(16);
                top: calc(50% - 8px);
                width: rem-calc(16);

                @include breakpoint (small down) {
                    display: block;
                }
            }
        }
    }

    .dropdown-item {
        @include breakpoint(medium down) {
            + .dropdown-item {
                border-top: 1px solid $more-light-gray;
                .menu-for-tezenis & {
                    border-top: none;
                }
            }
        }
    }
}

// fix for iPhone
/*
.navbar-promo {
    display: none;
}
.show.latest .navbar-promo {
    display: block;
}
*/

// mobile-header
/*
.mobile-header {
    p {
        margin-bottom: rem-calc(13);

        &:last-child {
            margin-bottom: 0;
        }

        a {
            font-size: rem-calc(11);
            text-decoration: none;
            font-weight: $global-weight-dark;
        }
    }
    @include breakpoint (medium down){
        margin-bottom: rem-calc(40);
    }
}
*/

// @include breakpoint(medium down) {
    /*
    .underline-mobile {
        .menu-for-tezenis & {
            &:before {
                content: '';
                position: absolute;
                top: rem-calc(32);
                left: -20%;
                width: 120%;
                height: rem-calc(35);
                background-color: rgba(253, 207, 242, 0.5);
                z-index: -1;
            }
        }

        .active-on-mobile {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: calc(100% + 3rem);
            height: 100%;
            background: rgba(253, 207, 242, 0.5);

            li.dropdown-item.dropdown & {
                width: calc(100% + 6rem);
                background: rgba(253, 207, 242, 0.5);
            }
        }
    }
    */
    /*
    &.super-container {
        .menu-for-tezenis & {
            @include breakpoint(small down) {
                &:before {
                    top: rem-calc(32);
                }
            }
        }
    }
    */
    /*
    .dropdown-item.dropdown.underline-mobile {
        &:before {
            top: rem-calc(15);
        }
    }
    */
// }

.spacer {
    .menu-for-tezenis & {
        @include breakpoint(small down) {
            height: 1rem;
        }
    }
}
/*
.mobile-menu {
    .cell {
        margin-bottom: rem-calc(10);

        a {
            font-size: rem-calc(12);
            font-weight: 400;
        }

        .mobile-menu-icon {
            margin-right: rem-calc(20);
        }

        svg {
            height: rem-calc(45);
            width: rem-calc(45);
        }
    }
}
*/
/*
.secondary-menu-wrap-for-menu{
    @include breakpoint (medium down){
        z-index: -1;
    }
}
*/
.menu-banner-section{
    margin-top: rem-calc(22);
    .image-loyalty-container{
        position: relative;
        min-height: rem-calc(138);
        border-radius: rem-calc(8);
        overflow: hidden;
        .loyalty-paragraph{
            padding: rem-calc(20 22);
            .loyalty-title-paragraph{
                color: white;
                font-size: rem-calc(18);
                margin-bottom: rem-calc(8);
                line-height: rem-calc(27);
                padding-right: 52%;
            }
            .loyalty-subtitle-paragraph{
                color: white;
                font-size: rem-calc(14);
                margin-bottom: 0;
                line-height: rem-calc(18);
                font-weight: 400;
                padding-right: 40%;
            }
        }
        img{
            position: absolute;
            z-index: -1;
            top: 0;
            bottom: 0;
            height: 100%;
            object-fit: cover;
            object-position: right;
        }
    }
}
.menu-header-container .active .tab-submenu,.menu-header-container .active .content-tab {
    display: block;
    @include breakpoint (medium down) {
        width: 100%;
    }
}



.menu-header-container .tab-submenu,.menu-header-container .content-tab {
    display: none;
}
li.js-open-tab-menu {
    @include breakpoint (medium up) {
        display:flex;
    }
}
.tab-submenu {
    //bottom: rem-calc(48);
    position: relative;
}
li.js-open-tab-menu.active a.subCategoryElement {
    font-weight: bold;
}
li.js-open-tab-menu a {
    border-bottom: 0;
}

.menu-top-level-fade-background {
    @include breakpoint(small down) {
        background-color: #fff;
        position: fixed;
        top: 3.625rem;
        width: 100vw;
        height: rem-calc(58);
        z-index: 999999;
        border-top: rem-calc(1) solid #DEDEDE;
        border-bottom: rem-calc(1) solid #DEDEDE;
    }
}

.menu-top-level {
    display:flex;

    @include breakpoint (small down) {
        background-color: $white;
        position: fixed;
        top: rem-calc(58);
        width: 100%;
        z-index: 10000;
        transition: all 0.4s;
        opacity: 1;
        overflow-x: scroll;
        z-index: 9999999;
        
        &.mask-container {
            mask-image: linear-gradient(to right, black calc(100% - 48px), transparent 100%);
        }
    }

    &.menu-display-disable {
        @include breakpoint (small down) {
            opacity: 0;
            pointer-events: none;
        }
    }
}

.menu-bordered-container {
    &.category-name {
        @include breakpoint (small down) {
            background-color: $white;
            border-bottom: 1px solid $light-gray;
            height: rem-calc(56);
            left: rem-calc(-16);
            transform: translate(-1rem, -1rem);
            padding: rem-calc(0);
            position: sticky;
            top: rem-calc(16);
            width: 100vw;
            z-index: 999;
            margin-bottom: rem-calc(-16);
        }
    }
}
/*
.menu-first-row-height {
    @include breakpoint (small down) {
    }
}
*/
.menu-header-container {
    background-color: #FFF;
    border-top: 1px solid $light-gray;
    left: 0;
    margin-top: rem-calc(16);
    position:absolute; 
    right: 0;
    z-index: 9999;

    @include breakpoint (small down) {
        border: none;
        overflow-x: clip;
        padding: rem-calc(16);
    }
    @include breakpoint (large up) {
        max-height: rem-calc(618);
    }

    &.cc-menu-header-container-mobile{
        @include breakpoint (small down) {
            margin-top: rem-calc(116);
        }
    }

    .tabs-menu {
        font-size: rem-calc(14);
        font-weight: 600;
        line-height: rem-calc(21);
        transition: all 0.4s;
        display: flex;
        flex-flow: column;

        @include breakpoint (small down) {
            font-size: rem-calc(16);
            font-weight: 600;
            line-height: rem-calc(24);
        }

        @include breakpoint (medium up) {
            height: rem-calc(570);
            overflow-y: auto;
            margin-bottom: rem-calc(24);
            margin-right: rem-calc(40);
            max-height: rem-calc(570);
            width: rem-calc(465);

            &::-webkit-scrollbar {
                border-radius: rem-calc(1.5);
                width: rem-calc(3);
            }
            &::-webkit-scrollbar-thumb {
                background-color: $medium-gray;
                border-radius: rem-calc(1.5);
            }
            &::-webkit-scrollbar-track {
                background-color: $light-gray;
                border-radius: rem-calc(1.5);
            }
        }
    }

    .tab-submenu {
        font-size: rem-calc(16);
        font-weight: 600;
        line-height: rem-calc(24);
        justify-content: space-between;
        @include breakpoint (medium up) {
            display: flex;
            font-size: rem-calc(12);
            font-weight: 500;
            flex-direction: row;
            line-height: rem-calc(18);
        }
      


        .menu-image-content-asset {
            .content-asset {
                @include breakpoint (medium up) {
                    margin-right: rem-calc(16);
                }
                @include breakpoint (small down) {
                    margin-bottom: rem-calc(24);
                }
            }
        }

        .contents-tab {
            @include breakpoint (small down) {
                display: none;
            }
            @include breakpoint (medium up) {
                display: flex;
            }

            .category-contents .content-asset,
            .category-guide .content-asset {
                display: none;
                @include breakpoint (medium up) {
                    margin-right: rem-calc(16);
                }
                @include breakpoint (small down) {
                    margin-bottom: rem-calc(24);
                }
            }
        }
    }

    .tabs-submenu {
        @include breakpoint (medium up) {
            width: 100%;
        }

        @include breakpoint (small down) {
            z-index: 14;
        }
    }

    .contents-tab-mobile {
        display: none;
        @include breakpoint (small down) {
            display: flex;
            margin-top: rem-calc(24);

            .menu-fourth-column {
                width: 100%;
            }
        }
    }
    .contents-tab-desktop {
        display: flex;
        @include breakpoint (small down) {
            display: none;
        }
    }
}
.menu-header.menu-overflow-state-2 {
    display: flex;

    .tabs-menu {
        @include breakpoint (small down) {
            opacity: 0;
            height: 0;
            width: 0;
            transition: all 0.4s;
        }
    }

    .tabs-menu .subcategory-small {
      @include breakpoint (small down) {
          opacity: 0;
          height: 0;
          transition: all 0.1s 0.4s;
      }
    }
}

.subcategory-small {
    @include breakpoint (medium up) {
        margin-bottom: rem-calc(10);
    }
    @include breakpoint (small down) {
        border-bottom: 1px solid #E8E8E8;
        font-size: rem-calc(16);
        font-weight: 500 !important;
        height: rem-calc(56);
        left: rem-calc(-16);
        line-height: rem-calc(24);
        letter-spacing: rem-calc(0.2);
        padding: rem-calc(16 16);
        position: relative;
        width: 100vw;

        &:first-of-type {
            margin-top: rem-calc(-16);

            &.menuright {
                margin-top: rem-calc(0);
            }
        }
    }

    .arrow-right {
        display: none;
        position: absolute;
        right: rem-calc(21);
        top: rem-calc(20);

        @include breakpoint (small down) {
            display: block;
        }
    }
}

.go-back.js-menu-back {
    @include breakpoint (small down) {
        left: rem-calc(16);
        margin-right: rem-calc(40);
        position: relative;

        i {
            svg.small {
                height: rem-calc(24);
                width: rem-calc(24);
            }
        }
    }
}

.menu-banner {
    border-radius: rem-calc(16);
    height: rem-calc(422);
    position: relative;
    width: rem-calc(273);

    @include breakpoint (medium only) {
        height: rem-calc(203);
        width: rem-calc(303);
    }

    @include breakpoint (small down) {
        height: rem-calc(238);
        width: 100%;
    }

    @media (min-width:520px) and (max-width:640px) {
        height: rem-calc(320);         
    }

    .menu-banner-link {
        border-bottom: none;
        display: block;
        height: inherit;
        width: inherit;

        &:hover {
            border-bottom: none !important;
        }

        .menu-banner-image {
            border-radius: rem-calc(16);
            height: inherit;
            width: inherit;

            @include breakpoint (medium down) {
                object-fit: contain;
            }
        }

        .text-on-image {
            bottom: rem-calc(25);
            left: rem-calc(16);
            position: absolute;


            .menu-section-link-title {
                color: $white;
                font-size: rem-calc(20);
                font-weight: 600;
                line-height: rem-calc(30);
                margin-bottom: rem-calc(8);
            }

            .menu-section-link-subtitle {
                color: $white;
                font-size: rem-calc(12);
                font-weight: 400;
                line-height: rem-calc(18);
                margin-bottom: rem-calc(16);
                width: rem-calc(241);

                @include breakpoint (medium only) {
                    width: rem-calc(271);
                }

                @include breakpoint (small down) {
                    width: rem-calc(311);
                }
            }

            .menu-section-button {
                background-color: $white;
                border: 1px solid #D0E6E2;
                border-radius: rem-calc(16);
                color: $black;
                font-size: rem-calc(12);
                font-weight: 500;
                height: rem-calc(34);
                line-height: rem-calc(18);
                text-align: center;
                padding: rem-calc(8 24);
                width: fit-content;

                @include breakpoint (small down) {
                    height: rem-calc(32);
                    padding: rem-calc(7 16);
                }

                &:hover {
                    opacity: 70%;
                    cursor: pointer;
                }

            }
        }
    }
}

.menu-banner-small {
    border-radius: rem-calc(16);
    height: rem-calc(203);
    position: relative;
    width: rem-calc(273);

    @include breakpoint (medium only) {
        height: rem-calc(203);
        width: rem-calc(303);
    }

    @include breakpoint (small down) {
        height: rem-calc(254);
        width: rem-calc(343);
    }

    .menu-banner-link {
        border-bottom: none;
        display: block;
        height: inherit;
        width: inherit;

        &:hover {
            border-bottom: none !important;
        }

        .menu-banner-image {
            border-radius: rem-calc(16);
            height: inherit;
            width: inherit;
        }

        .text-on-image {
            bottom: rem-calc(25);
            left: rem-calc(16);
            position: absolute;


            .menu-section-link-title {
                color: $white;
                font-size: rem-calc(20);
                font-weight: 600;
                line-height: rem-calc(30);
                margin-bottom: rem-calc(8);
            }

            .menu-section-link-subtitle {
                color: $white;
                font-size: rem-calc(12);
                font-weight: 400;
                line-height: rem-calc(18);
                margin-bottom: rem-calc(16);
                width: rem-calc(241);

                @include breakpoint (medium only) {
                    width: rem-calc(271);
                }

                @include breakpoint (small down) {
                    width: rem-calc(311);
                }
            }

            .menu-section-button {
                background-color: $white;
                border: 1px solid #D0E6E2;
                border-radius: rem-calc(16);
                color: $black;
                font-size: rem-calc(12);
                font-weight: 500;
                height: rem-calc(34);
                line-height: rem-calc(18);
                letter-spacing: rem-calc(0.2);
                text-align: center;
                padding: rem-calc(8 24);
                width: fit-content;

                @include breakpoint (small down) {
                    height: rem-calc(32);
                    padding: rem-calc(7 16);
                }

                &:hover {
                    opacity: 70%;
                    cursor: pointer;
                }
            }
        }
    }
}

.menu-guide {
    background: #D0E6E2;
    border-radius: rem-calc(16);
    margin-top: rem-calc(16);
    padding: rem-calc(16);
    width: rem-calc(273);

    @include breakpoint (medium only) {
        width: rem-calc(303);
    }

    @include breakpoint (small down) {
        margin-top: rem-calc(16);
        padding: rem-calc(24);
        width: 100%;
    }

    .text-on-image {
        position: relative;
        display: flex;
        align-items: center;
        
        svg {
            height: rem-calc(20);
            margin-right: rem-calc(10);
            position: relative;
            top: rem-calc(2);
            width: rem-calc(20);
        }
        .menu-guide-button {
            font-size: rem-calc(16);
            font-weight: 500;
            line-height: rem-calc(24);
            letter-spacing: rem-calc(0.2);

            &::after {
                content: url('../../images/arrow-right-small.svg');
                position: absolute;
                right: rem-calc(-4);
                top: rem-calc(3);
            }

            &:hover {
                opacity: 70%;
                cursor: pointer;
            }
        }
    }
}

.menu-special-categories {
    margin-top: rem-calc(32);
    width: 100vw;

    &:first-of-type {
        margin-top: rem-calc(24);
    }

    .menu-special-categories-title {
        font-size: rem-calc(16) ;
        font-weight: 600 ;
        line-height: rem-calc(24) ;
        letter-spacing: rem-calc(0.2);
        margin-bottom: rem-calc(32);
    }

    .menu-special-categories-item {
        align-items: center;
        display: flex;
        margin-top: rem-calc(16);

        &:first-of-type {
            margin-top: rem-calc(32);
        }

        .menu-special-categories-anchor {
            width: 100%;

            &:hover, &:active, &:focus {
                border-bottom: none;
                text-decoration: none;
            }

            .menu-special-categories-image {
                display: inline-block;
                height: rem-calc(64);
                border-radius: rem-calc(8);
                margin-right: rem-calc(24);
                width: rem-calc(64);
            }

            .menu-special-categories-name {
                display: inline-block;
                font-size: rem-calc(16) ;
                font-weight: 400 ;
                line-height: rem-calc(24) ;
                letter-spacing: rem-calc(0.2);

                &::after {
                    content: url('../../images/arrow-right-small.svg');
                    position: absolute;
                    right: rem-calc(36.75);
                    top: rem-calc(20.75);
                }
            }
        }
    }
}

@include breakpoint (small down) {
    .js-menu-account.menu-display-disable, 
    .menu-bottom.menu-display-disable,
    .menu-display-disable + .contents-tab-mobile {
        display: none;
    }
}

.content-asset {
    .menu-fourth-column {
        @include breakpoint (small down) {
            padding-right: rem-calc(32);
            width: 100vw;
        }
    }
}

.Tezenis_Women #tezenis-women-menu-asset {
    display: block;
}

.Tezenis_Men #tezenis-men-menu-asset {
    display: block;
}

.Tezenis_Kids #tezenis-kids-menu-asset {
    display: block;
}