.button {
    border-radius: rem-calc(43);
    position: relative;
    border: 1px solid transparent;
    font-weight: $button-font-weight;
    line-height: 1.38;
    text-decoration: none;
    margin: rem-calc(0 0 16 0);
    cursor: pointer;

    &:disabled,
    &.disabled,
    &.hollow:disabled,
    &.hollow.disabled {
        cursor: default;
        background: $button-background-disabled;
        border: none;
        color: rgba($black, 0.4);

        .input-group-button & {
            border: 1px solid $black;
        }

        &:hover {
            @include breakpoint (large up) {
                background: $button-background-disabled;
                border-color: $button-background-disabled;
                color: rgba($black, 0.4);
            }
        }
    }

    &.rounded {
        border-radius: rem-calc(30);
    }

    &.wide {
        min-width: 12rem;
    }
    /*
    &.vertical {
        transform: rotate(90deg);
        margin-bottom: 0;
    }
    */

    &.inline {
        transform: translateX(-100%);
        position: absolute;
        height: 100%;
        text-decoration: underline;
    }

    &.button {
        &-primary {
            font-size: rem-calc(14);
            line-height: rem-calc(21);
            font-weight: $global-weight-bold;
            height: rem-calc(53);
            padding: rem-calc(16 32);

            &-black {
                font-size: rem-calc(14);
                line-height: rem-calc(21);
                font-weight: $global-weight-bold;
                height: rem-calc(53);
                padding: rem-calc(16 32);
                background-color: $black;
                border: none;
                color: $white;

                &:hover,
                &:active {
                    @include breakpoint (large up) {
                        background-color: rgba(28, 28, 28, 0.6);
                        border-color: rgba(28, 28, 28, 0.6);
                    }
                }

                &:disabled,
                &.disabled {
                    background-color: $medium-gray;
                    border-color: $medium-gray;
                    color: $medium-light-gray;
                }
            }
            
            &-white {
                font-size: rem-calc(14);
                line-height: rem-calc(21);
                font-weight: $global-weight-bold;
                height: rem-calc(53);
                padding: rem-calc(16 32);
                background-color: $white;
                border: none;
                color: $black;

                &:hover,
                &:active {
                    @include breakpoint (large up) {
                        background-color: rgba(255, 255, 255, 0.6);
                        border-color: rgba(255, 255, 255, 0.6);
                    }
                }

                &:disabled,
                &.disabled {
                    background-color: rgba(255, 255, 255, 0.3);
                    border-color: rgba(255, 255, 255, 0.3);
                    color: $medium-gray;
                }
            }

            &-sale {
                font-size: rem-calc(14);
                line-height: rem-calc(21);
                font-weight: $global-weight-bold;
                height: rem-calc(53);
                padding: rem-calc(16 32);
                background-color: $sale;
                border: none;
                color: $white;

                &:hover,
                &:active {
                    @include breakpoint (large up) {
                        background-color: rgba(209, 11, 130, 0.6);
                        border-color: rgba(209, 11, 130, 0.6);
                    }
                }

                &:disabled,
                &.disabled {
                    background-color: $medium-gray;
                    border-color: $medium-gray;
                    color: $medium-light-gray;
                }
            }

            &-promotion {
                font-size: rem-calc(14);
                line-height: rem-calc(21);
                font-weight: $global-weight-bold;
                height: rem-calc(53);
                padding: rem-calc(16 32);
                background-color: $promotion;
                border: none;
                color: $white;

                &:hover,
                &:active {
                    @include breakpoint (large up) {
                        background-color: rgba(140, 18, 255, 0.6);
                        border-color: rgba(140, 18, 255, 0.6);
                    }
                }

                &:disabled,
                &.disabled {
                    background-color: $medium-gray;
                    border-color: $medium-gray;
                    color: $medium-light-gray;
                }
            }

            &-sustainability {
                font-size: rem-calc(14);
                line-height: rem-calc(21);
                font-weight: $global-weight-bold;
                height: rem-calc(53);
                padding: rem-calc(16 32);
                background-color: $sustainability;
                border: none;
                color: $white;

                &:hover,
                &:active {
                    @include breakpoint (large up) {
                        background-color: rgba(34, 116, 106, 0.6);
                        border-color: rgba(34, 116, 106, 0.6);
                    }
                }

                &:disabled,
                &.disabled {
                    background-color: $medium-gray;
                    border-color: $medium-gray;
                    color: $medium-light-gray;
                }
            }
            
            &-black-outline {
                font-size: rem-calc(14);
                line-height: rem-calc(19);
                font-weight: $global-weight-bold;
                height: rem-calc(53);
                padding: rem-calc(16 32);
                background-color: transparent;
                border: 1px solid $black;
                color: $black;

                &:hover,
                &:active {
                    @include breakpoint (large up) {
                        background-color: rgba(0, 0, 0, 0.2);
                        border-color: $medium-gray;
                        color: $medium-gray;
                    }
                }

                &:disabled,
                &.disabled {
                    background-color: transparent;
                    border-color: $medium-gray;
                    color: $medium-gray
                }
            }

            &-white-outline {
                font-size: rem-calc(14);
                line-height: rem-calc(19);
                font-weight: $global-weight-bold;
                height: rem-calc(53);
                padding: rem-calc(16 32);
                background-color: transparent;
                border: 1px solid $white;
                color: $white;

                &.selected {
                    background-color: $white;
                    color: $black;
                }

                &:hover,
                &:active {
                    @include breakpoint (large up) {
                        background-color: rgba(255, 255, 255, 0.3);
                        border-color: $white;
                        color: $white;
                    }
                }

                &:disabled,
                &.disabled {
                    background-color: $medium-gray;
                    border-color: $medium-light-gray;
                    color: $medium-light-gray;
                }
            }

        }

        &-secondary {
            font-size: rem-calc(12);
            line-height: rem-calc(18);
            font-weight: $global-weight-dark;
            height: rem-calc(34);
            padding: rem-calc(8 24);

            &-black {
                font-size: rem-calc(12);
                line-height: rem-calc(18);
                font-weight: $global-weight-dark;
                height: rem-calc(34);
                padding: rem-calc(8 24);
                background-color: $black;
                border: none;
                color: $white;

                &:hover,
                &:active {
                    @include breakpoint (large up) {
                        background-color: rgba(28, 28, 28, 0.6);
                        border-color: rgba(28, 28, 28, 0.6);
                    }
                }

                &:disabled,
                &.disabled {
                    background-color: $medium-gray;
                    border-color: $medium-gray;
                    color: $medium-light-gray;
                }
            }

            &-white {
                font-size: rem-calc(12);
                line-height: rem-calc(18);
                font-weight: $global-weight-dark;
                height: rem-calc(34);
                padding: rem-calc(8 24);
                background-color: $white;
                border: none;
                color: $black;

                &:hover,
                &:active {
                    @include breakpoint (large up) {
                        background-color: rgba(255, 255, 255, 0.6);
                        border-color: rgba(255, 255, 255, 0.6);
                        color: $medium-gray;
                    }
                }

                &:disabled,
                &.disabled {
                    background-color: #FFFFFF 30%;
                    border-color: #FFFFFF 30%;
                    color: $medium-gray;
                }
            }
            
            &-sale {
                font-size: rem-calc(12);
                line-height: rem-calc(18);
                font-weight: $global-weight-dark;
                height: rem-calc(34);
                padding: rem-calc(8 24);
                background-color: $sale;
                border: none;
                color: $white;

                &:hover,
                &:active {
                    @include breakpoint (large up) {
                        background-color: rgba(209, 11, 130, 0.6);
                        border-color: rgba(209, 11, 130, 0.6);
                    }
                }

                &:disabled,
                &.disabled {
                    background-color: $medium-gray;
                    border-color: $medium-gray;
                    color: $medium-light-gray;
                }
            }

            &-promotion {
                font-size: rem-calc(12);
                line-height: rem-calc(18);
                font-weight: $global-weight-dark;
                height: rem-calc(34);
                padding: rem-calc(8 24);
                background-color: $promotion;
                border: none;
                color: $white;

                &:hover,
                &:active {
                    @include breakpoint (large up) {
                        background-color: rgba(140, 18, 255, 0.6);
                        border-color: rgba(140, 18, 255, 0.6);
                    }
                }

                &:disabled,
                &.disabled {
                    background-color: $medium-gray;
                    border-color: $medium-gray;
                    color: $medium-light-gray;
                }
            }

            &-sustainability {
                font-size: rem-calc(12);
                line-height: rem-calc(18);
                font-weight: $global-weight-dark;
                height: rem-calc(34);
                padding: rem-calc(8 24);
                background-color: $sustainability;
                border: none;
                color: $white;

                &:hover,
                &:active {
                    @include breakpoint (large up) {
                        background-color: rgba(34, 116, 106, 0.6);
                        border-color: rgba(34, 116, 106, 0.6);
                    }
                }

                &:disabled,
                &.disabled {
                    background-color: $medium-gray;
                    border-color: $medium-gray;
                    color: $medium-light-gray;
                }
            }


            &-black-outline {
                font-size: rem-calc(12);
                line-height: rem-calc(16);
                font-weight: $global-weight-dark;
                height: rem-calc(34);
                padding: rem-calc(8 24);
                background-color: transparent;
                border: 1px solid $black;
                color: $black;

                &:hover,
                &:active {
                    @include breakpoint (large up) {
                        background-color: rgba(0, 0, 0, 0.2);
                        border-color: $medium-gray;
                        color: $medium-gray;
                    }
                }

                &:disabled,
                &.disabled {
                    background-color: rgba(0, 0, 0, 0.2);
                    border-color: $medium-gray;
                    color: $medium-gray;
                }
            }

            &-white-outline {
                font-size: rem-calc(12);
                line-height: rem-calc(16);
                font-weight: $global-weight-dark;
                height: rem-calc(34);
                padding: rem-calc(8 24);
                background-color: transparent;
                border: 1px solid $white;
                color: $white;

                &.selected {
                    background-color: $white;
                    color: $black;
                }

                &:hover,
                &:active {
                    @include breakpoint (large up) {
                        background-color: #FFFFFF 30%;
                        border-color: $white;
                        color: $white;
                    }
                }

                &:disabled,
                &.disabled {
                    background-color: $medium-gray;
                    border-color: $medium-light-gray;
                    color: $medium-light-gray;
                }
            }

        }
    }
}

.button-tertiary {
    background-color: transparent;
    border: none;
    font-size: rem-calc(12);
    line-height: rem-calc(18);
    cursor: pointer;

    &-black {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid $black;
        color: $black;
        font-size: rem-calc(12);
        line-height: rem-calc(18);
        font-weight: 400;
        text-decoration: none;
        cursor: pointer;

        &:hover,
        &:active {
            @include breakpoint (large up) {
                background-color: transparent;
                color: $black;
                font-weight: 500;
                text-decoration: none;
            }
        }

        &:disabled,
        &.disabled {
            font-weight: 400;
            background-color: transparent;
            color: $medium-gray;
            border-color: $medium-gray;
            text-decoration: none;
            cursor: default;
        }
    }
    /*
    &-white {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid $white;
        color: $white;
        font-size: rem-calc(12);
        line-height: rem-calc(18);
        font-weight: 400;
        text-decoration: none;
        cursor: pointer;

        &:hover,
        &:active {
            @include breakpoint (large up) {
                background-color: transparent;
                color: $white;
                font-weight: 500;
                text-decoration: none;
            }
        }

        &:disabled,
        &.disabled {
            font-weight: 400;
            background-color: transparent;
            color: $medium-gray;
            border-color: $medium-gray;
            text-decoration: none;
            cursor: default;
        }
    }
    */
}
/*
.button-arrow {
    border-radius: rem-calc(20);
    float: none !important;
    height: rem-calc(40);
    padding: rem-calc(15 18);
    position: relative;
    width: rem-calc(58);

    &:after {
        content: '';
        position: absolute;
        width: rem-calc(20);
        height: rem-calc(10);
    }

    &.top {
        &:after {
            content: url('../svg/arrow-top-fat.svg');
        }
    }

    &.down {
        &:after {
            content: url('../svg/arrow-down-fat.svg');
        }
    }

    &.right {
        &:after {
            content: url('../svg/arrow-right-fat.svg');
        }
    }

    &.left {
        &:after {
            content: url('../svg/arrow-left-fat.svg');
        }
    }

    &.top,
    &.down {
        &:after {
            width: rem-calc(24);
            height: rem-calc(16);
        }
    }

    &.black-primary {
        background-color: $black;
    }

    &.black-secondary {
        background-color: rgba(28, 28, 28, 0.6);
    }

    &.black-tertiary {
        background-color: $medium-gray;
    }
}
*/

// generate buttons background and color text
@mixin brand-buttons($palette, $text-color) {
    .button {
        @each $color, $hex in $palette {
            &.button-#{$color} {
                &:not(:disabled):not(.disabled) {
                    color: $text-color;
                    background: $hex;
                    border-color: $text-color;
                }

                &:not(.no-hover):not(:disabled):not(.disabled):hover,
                &:not(.no-hover):not(:disabled):not(.disabled):active,
                &:not(.no-hover):not(:disabled):not(.disabled).active,
                &:not(.no-hover):not(:disabled):not(.disabled):focus {
                    color: $hex;
                    background: $text-color;
                }

                &:disabled,
                &.disabled,
                &.hollow:disabled,
                &.hollow.disabled {
                    border-color: rgba($text-color, 0.4);
                    color: rgba($text-color, 0.4);
                    background: $hex;
                }
            }
        }
    }
}

.label-cta {
    border-radius: rem-calc(4);
    color: $white;
    font-size: rem-calc(12);
    font-weight: 500;
    height: unset;
    line-height: rem-calc(18);
    margin-bottom: rem-calc(4);
    padding: rem-calc(4 12);
    text-align: center;
    width: fit-content;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: rem-calc(182);
    white-space: nowrap;

    @include breakpoint (small down) {
        font-size: rem-calc(10);
        line-height: rem-calc(10);
        padding: rem-calc(4 8);
    }

    &-loyalty {
        background: linear-gradient(135deg, rgba(236, 177, 100, 0.6) 0%, rgba(233, 153, 96, 0.6) 21.98%, rgba(238, 132, 114, 0.6) 37.41%, rgba(249, 109, 143, 0.6) 59.66%, rgba(227, 102, 156, 0.6) 77.35%, rgba(149, 98, 159, 0.6) 100%);
        border-radius: rem-calc(4);
        color: $black;
        font-size: rem-calc(12);
        font-weight: 500;
        height: rem-calc(26);
        line-height: rem-calc(18);
        margin-bottom: rem-calc(4);
        padding: rem-calc(4 12);
        text-align: center;
        width: fit-content;

        @include breakpoint (small down) {
            font-size: rem-calc(10);
            height: rem-calc(18);
            line-height: rem-calc(10);
            padding: rem-calc(3 8);
        }
    }
    &-topseller {
        background: black;
        color: white;
        padding: rem-calc(4 12);
        border-radius: rem-calc(4);
        font-weight: 500;
        font-size: rem-calc(12);
        line-height: rem-calc(18);
    }
}

.primary-tag {
    background-color: $white;
    border: 1px solid $light-gray;
    border-radius: rem-calc(20);
    font-size: rem-calc(12);
    font-weight: 500;
    line-height: rem-calc(18);
    margin: rem-calc(0 8 8 0);
    height: rem-calc(34);
    padding: rem-calc(8 16);
    text-align: center;

    &:hover {
        background-color: $water-green-light;
        border: 1px solid transparent;
        cursor: pointer;
    }

    &.selected,
    &.active {
        background-color: $water-green-light;
        border: 1px solid $water-green;
    }

    &.disabled {
        color: $medium-light-gray;
    }
}

.outofstock-tag {
    background-color: $white;
    border: 1px solid $light-gray;
    border-radius: rem-calc(20);
    color: $medium-light-gray;
    font-size: rem-calc(12);
    font-weight: 500;
    line-height: rem-calc(18);
    margin: rem-calc(0 8 8 0);
    height: rem-calc(34);
    padding: rem-calc(8 16);
    text-align: center;

    &:hover {
        background-color: $water-green-light;
        border: 1px solid transparent;
        cursor: pointer;
    }

    &.selected {
        background-color: $water-green-light;
        border: 1px solid $water-green;
    }

    &.disabled {
        color: $medium-light-gray;
    }
}
