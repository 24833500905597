input,
select {
    &, &.is-invalid-input {
        font-weight: 400;
        border: 1px solid transparent;
        border-bottom: 1px solid $medium-gray;
        transition: all 0.2s ease;
        margin-bottom: rem-calc(42);
        font-size: rem-calc(14);
        line-height: rem-calc(21);
        padding: rem-calc(8 0);
    }

    &.is-valid-input {
        font-weight: 400;
    }

    &:active,
    &:focus {
        border: none;
        border-bottom: 1px solid $medium-gray;
        outline: none;
    }

    &.is-invalid-input {
        border-bottom: 1px solid $error;
        ~ .label-for-input {
            display: none;
        }
    }

    &::placeholder {
        color: $medium-gray !important;
        font-size: rem-calc(14);
        font-weight: 400;
        line-height: rem-calc(21);
        letter-spacing: 0;
    }

    &:focus {
        outline: none;
        &::-webkit-input-placeholder {
            opacity: 0;
        }
        &::-moz-placeholder {
            opacity: 0;
        }
        &:-ms-input-placeholder {
            opacity: 0;
        }
        &:-moz-placeholder {
            opacity: 0;
        }
        &:not(:placeholder-shown) {
        }
        ~ .form-control-label {
            opacity: 1;
            color: $medium-gray;
            font-size: rem-calc(12);
            line-height: rem-calc(18);
        }
    }

    &:not(:placeholder-shown) {
        ~ .form-control-label {
            opacity: 1;
            color: $medium-gray;
            font-size: rem-calc(8);
        }
    }

    &:disabled,
    &.disabled {
        border: 0;
        color: $medium-gray;

        &:focus {
            outline: none;
        }
    }
    &.cc-shipping-form-margin {
        margin: rem-calc(20 0 0 0);
    }
}


select {
    background-image: url("../../images/icon-arrow-dropdown.svg");
    background-size: rem-calc(16 16);
    &:not(:placeholder-shown) {
        padding-right: rem-calc(20);
    }

    &:disabled {
        background-color: $white;
        border-bottom: 1px solid $medium-gray;
    }

    &:active {
        border-bottom: 1px solid $black;

        ~ .form-control-label-select {
            opacity: 1;
            color: $medium-gray;
            font-size: rem-calc(8);
            line-height: rem-calc(12);
        }
    }

    &:focus {
        ~ .form-control-label-select {
            opacity: 1;
            color: $medium-gray;
            font-size: rem-calc(8);
            line-height: rem-calc(12);
        }
    }

    &:visited {
        ~ .form-control-label-select {
            opacity: 1;
            color: $medium-gray;
            font-size: rem-calc(8);
            line-height: rem-calc(12);
        }
    }
    
    &::placeholder{
        color: $medium-gray !important;
        font-size: rem-calc(14);
        font-weight: 400;
        line-height: rem-calc(21);
        letter-spacing: 0;
    }
}

.errors-list{
    span {
        color: $error;
        font-size: rem-calc(12);
        font-weight: 400;
        line-height: rem-calc(18);
        letter-spacing: rem-calc(0.2);
    }
}

.success {
    color: $success;
    font-size: rem-calc(12);
    font-weight: 400;
    line-height: rem-calc(18);
    letter-spacing: rem-calc(0.2);
}

.suggestion-tip {
    color: $medium-gray;
    font-size: rem-calc(12);
    font-weight: 400;
    line-height: rem-calc(18);
    letter-spacing: rem-calc(0.2);
}

.height-text-area {
    border: 1px solid $black;
    border-radius: rem-calc(10);
    font-size: rem-calc(14);
    font-weight: 400;
    line-height: rem-calc(21);
    padding: rem-calc(28 20);

    &::placeholder {
        color: $medium-gray;
        font-size: rem-calc(14);
        line-height: rem-calc(21);
    }
}

.form-control-label {
    position: absolute;
    top: rem-calc(-14);
    font-weight: 400;
    font-size: rem-calc(12) !important;
    letter-spacing: rem-calc(0.2);
    color: $medium-gray;
    margin-bottom: 0;
    opacity: 0;
    transition: all 0.2s ease;
    line-height: rem-calc(18);
    border: none;
    &.is-invalid-input {
        border: none;
    }
    &.cc-shipping-form-margin {
        margin: rem-calc(20 0 0 0);
    }
    .fiscalcode & {
        display: none;
    }
}

.form-control-label-select {
    position: absolute;
    top: rem-calc(-8);
    font-weight: 400;
    font-size: rem-calc(12) !important;
    letter-spacing: rem-calc(0.2);
    color: $medium-gray;
    margin-bottom: 0;
    opacity: 0;
    transition: all 0.2s ease;
    line-height: rem-calc(18);
    border: none;
    &.is-invalid-input {
        border: none;
    }

    &.always-visible {
        opacity: 1 !important;
    }
}

.text-area-label {
    position: absolute;
    top: rem-calc(16);
    left: rem-calc(20);
    font-weight: 400;
    font-size: rem-calc(12);
    letter-spacing: 0.03em;
    color: $medium-gray;
    margin-bottom: 0;
    transition: all 0.2s ease;
    line-height: rem-calc(11);
    border: none;
    &.is-invalid-input {
        border: none;
    }
}

.label-inside {
    margin-bottom: rem-calc(16);
}