.info-icon {
    display: inline-block;
    position: relative;
    line-height: rem-calc(24);

    .icon {
        display: inline-block;
        background: $info;
        color: $white;
        font-style: normal;
        height: rem-calc(12);
        width: rem-calc(12);
        line-height: rem-calc(12);
        font-size: rem-calc(8);
        vertical-align: top;
        text-align: center;
        border-radius: 50%;
        cursor: pointer;

        &:hover + .tooltip {
            opacity: 1;
            visibility: visible;
        }
    }

    .icon-info-black {
        display: inline-block;
        background: $black;
        color: $white;
        font-style: normal;
        height: rem-calc(12);
        width: rem-calc(12);
        line-height: rem-calc(12);
        font-size: rem-calc(8);
        vertical-align: top;
        text-align: center;
        border-radius: 50%;
        cursor: pointer;
        letter-spacing: 0;
        position: absolute;
        right: rem-calc(-16);

        &:hover + .tooltip {
            opacity: 1;
            visibility: visible;
            z-index: 99999;
        }
    }

    .tooltip {
        position: absolute;
        border-radius: rem-calc(8);
        top: rem-calc(-20);
        left: 50%;
        width: rem-calc(235);
        transform: translate(-50%, -100%);
        background: $white;
        color: $black;
        padding: rem-calc(24);
        opacity: 0;
        visibility: hidden;
        transition: opacity .25s ease;
        pointer-events: none;
        border: 1px solid $water-green;
        font-size: rem-calc(12);

        @include breakpoint (small down) {
            width: rem-calc(343);
        }

        h3,
        .tooltip-title {
            display: block;
            font-weight: 600;
            font-size: rem-calc(12);
            line-height: rem-calc(18);
            color: $black;
            text-align: left;
            margin: rem-calc(0 0 8 0);
            padding: 0;
        }

        p,
        .tooltip-subtitle {
            display: block;
            font-weight: 400;
            font-size: rem-calc(12);
            line-height: rem-calc(18);
            color: $black;
            letter-spacing: rem-calc(0.2);
            text-align: left;
            margin: 0;
            padding: 0;

            a {
                text-decoration: underline;
                font-weight: 400;
                border: none;
                color: inherit;
            }
        }

        &.info-tooltip-shipping{
            top: rem-calc(70);
            left: rem-calc(50);
            @include breakpoint(medium down) {
                top: rem-calc(9);
                left: rem-calc(-94);
            }
            @include breakpoint(small down) {
                top: rem-calc(64);
                left: rem-calc(-220);
            }
        }
    }

    .tooltip-icon {
        cursor: pointer;
        &:hover + .tooltip {
            opacity: 1;
            visibility: visible;
            z-index: 99999;
        }

        & + .tooltip {
            transform: translate(0%, -100%);
            left: 0;
            top: -10px;
            background-color: #fff;
            color: $black;
            font-size: rem-calc(10);
            line-height: rem-calc(15);
            width: rem-calc(246);
            border-radius: rem-calc(8);
            padding: rem-calc(16);
        }
    }
}
