$include-fonts: false;

@import "../setup/settings";


.login-forms-cell{
    padding-right:rem-calc(5) !important;
    padding-left: rem-calc(80);
    @include breakpoint (ipad down){
        padding: 0;
    }
    @include breakpoint (medium down){
        padding: 0 !important;
    }
}

.registrationStart{
    width: rem-calc(393);
    @include breakpoint (ipad down){
        padding-left: rem-calc(180);
    }
    @include breakpoint (small down){
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }
}

#registration-form-prefix{
    margin-bottom: rem-calc(16);
    padding-right: 0;
}

#registration-form-phone{
    &.is-invalid-input{
        margin-bottom: rem-calc(5);
    }
}

#registration-form-email{
    width: rem-calc(393);
    @include breakpoint (medium down){
        width: 100% !important;
        padding-left: 0;
        padding-right: 0;
    }
}

.button-register-phone{
    margin: 0 auto;
}

.registration-page-button{
    background-color: $dark-gray;
    margin-bottom: rem-calc(40);
    button{
        font-size: rem-calc(14);
        font-weight: 600;
        line-height: rem-calc(21);
        width: rem-calc(393);
    }
    @include breakpoint (ipad down){
        margin-bottom: rem-calc(30);
        width: rem-calc(393);
    }
    @include breakpoint (small down){
        margin-bottom: rem-calc(30);
        width: 100%;
    }
}

.registration-page-button-login-tab{
    background-color: $white;
    color: rgba(28, 28, 28, 1);
    border: rem-calc(1) solid rgba(28, 28, 28, 1);
    margin: rem-calc(26 0);
    font-size: rem-calc(14);
    font-weight: 600;
    line-height: rem-calc(21);
    letter-spacing: rem-calc(0.2);
    width: rem-calc(393) !important;
    @include breakpoint (ipad down){
        margin-left: rem-calc(180);
    }
    @include breakpoint (small down){
        width: 100% !important;
        margin: rem-calc(24 0);

    }
}

.new-footer-form-style{
    .form-group{
        #password-reset{
            font-size: rem-calc(12);
            font-weight: 400;
            line-height: rem-calc(18);
            letter-spacing: rem-calc(0.2);
            font-style: normal;
            color: rgba(28, 28, 28, 1);            ;
        }
    }
}

.layer-password-modal{
    .modal-header{
        h4, p {
            font-size: rem-calc(14) !important;
            font-weight: 400 !important;
            line-height: rem-calc(21);
            letter-spacing: rem-calc(0.2);
        }
    }
}

.bold{
    font-weight: bold;
}
.new-footer-form-style:not(.checkout-style), .shipping-phone-registered{
    .form-group{
        position: relative;
        .pwd-input{
            z-index: 0;
        }
    }
    .form-control-label:not(.label-billing-checkbox) {
        position: absolute;
        top: rem-calc(5);
        left: rem-calc(13);
        left: 0;
        &.email-label{
            left: 0;
        }
        font-weight: 400;
        font-size: rem-calc(12);
        letter-spacing: rem-calc(0.2);
        margin-bottom: 0;
        opacity: 0;
        transition: all .2s ease;
        line-height: rem-calc(18);
        border:none;
        &.is-invalid-input {
            border:none;
        }
    }

    input,select {
        font-size: rem-calc(14);
        font-weight: 400;
        line-height: rem-calc(21);
        letter-spacing: rem-calc(0.2);
        //padding: rem-calc(0 0 0 13);
        border: 0;
        border-bottom: 1px solid $dark-gray;
        border-top-left-radius: rem-calc(3);
        border-top-right-radius: rem-calc(3);
        height: rem-calc(56);
        transition: all .2s ease;
        padding-left: 0 !important;
        margin-bottom: rem-calc(16);
        &.gender:focus{
            outline: none;
            padding-left: 0;
            border-bottom: 2px solid $dark-gray !important;;
        }
        &::placeholder{
            color: black;
            padding-left: 0;
            font-size: rem-calc(14);
            letter-spacing: rem-calc(0.2);
            margin-bottom: rem-calc(8);
        }
        &.is-invalid-input {
                margin-bottom: rem-calc(16);
            }
        &#registration-form-email,
        &#login-form-email,
        &#reset-password-email{
            // Workaround to overwrite commit 6882e94
            &:not(#fiscalCodeOpen) {
                padding-left: rem-calc(45);
            }
        }

        &.is-invalid-input {
            &::placeholder{
                color:  $color-error;
            }
            margin-bottom: 0!important;
            border: 0 !important;
            border-bottom: 1px solid #f66 !important;
            &:focus{
                border-bottom: 2px solid $dark-gray !important;
            }
            ~ .label-for-input {
            display: none;
            }
        }
        &:not(:placeholder-shown):not(.gender):not(.shippingAddressTwo):not(.billingAddressTwo):not(.shippingFlatNumber):not(.shippingOrderNotes):not(.button-modal-checkBalance):not(#fiscalCodeOpen) {
            padding: rem-calc(15 0 0 0);
            //border-bottom: 1px solid !important;
            ~ .form-control-label {
                margin-bottom: rem-calc(8);
                opacity: 1;
            }
            ~ .email-icon{
                top: 35px;
            }
        }
        &:focus:not(.gender):not(.shippingAddressTwo):not(.billingAddressTwo):not(.shippingFlatNumber):not(.shippingOrderNotes):not(.giftCardPinField):not(.giftCardNumberField),
        &:valid:not(.gender):not(.shippingAddressTwo):not(.billingAddressTwo):not(.shippingFlatNumber):not(.shippingOrderNotes):not(.giftCardPinField):not(.giftCardNumberField) {
            outline: none;
            border: 0;
            padding: rem-calc(15 10 0 13);
            &::-webkit-input-placeholder {
            opacity: 0;
            }
            &::-moz-placeholder {
            opacity: 0;
            }
            &:-ms-input-placeholder {
            opacity: 0;
            }
            &:-moz-placeholder {
            opacity: 0;
            }
            ~ .form-control-label {
                opacity: 1;
            }
            ~ .email-icon{
                top: 35px;
            }
        }
        &:focus{
            border-bottom: 2px solid $dark-gray !important;
            ~ .form-control-label {
                color: $dark-gray;
            }
        }
        &:valid{
            border-bottom: 1px solid black!important;
        }
    }
    #login-form-email{
        @include breakpoint (small down){
            margin-bottom: rem-calc(24);
        }
    }
    select{
        padding-right: rem-calc(20) !important;
        background-size: rem-calc(7 7);
        background-position: right;
        &.gender{
            padding-right: 0 !important;
            background-size: unset !important;
            background-position: right center;
        }
    }
}

.input-select-item-register{
    padding-top: rem-calc(17);
}

.is-invalid-label{
    color: $color-error;
}
.login-tabs,
.layer-login-modal{
    .or-divider{
        color: rgba(28, 28, 28, 1);
        font-size: rem-calc(12);
        font-weight: 400;
        line-height: rem-calc(18);
        margin: rem-calc(0 0 26 0);
        @include breakpoint(ipad down){
            text-align: left;
            margin: rem-calc(24 0 24 0);
        }
    }
    .privacy-cookie-policy-bottom{
        padding: 0;
        @include breakpoint(ipad down){
            margin-top: rem-calc(32);
        }
        &, .grey-text{
            color: #666666;
            font-size: rem-calc(10);
            line-height: rem-calc(16);
        }
    }
}


.social-login-form-error{
    padding: rem-calc(10 15);
    background: #F2F4ED;
    margin: rem-calc(-26 0 24);
    color: #666666 !important;
    @include breakpoint(ipad down){
        margin: rem-calc(0 0 20);
    }
    .layer-login-modal &{
        margin: rem-calc(-10 0 21);
        @include breakpoint(ipad down){
            margin: rem-calc(0 0 20);
        }
    }
}
.social-login-account-accept{
    color: $medium-gray;
    width: rem-calc(393);
    font-size: rem-calc(10);
    line-height: rem-calc(16);
    margin: rem-calc(26 0);
    @include breakpoint(ipad down){
        text-align: left;
        margin: rem-calc(24 0);
    }
    .layer-login-modal &{
        @include breakpoint(ipad down){
            margin: rem-calc(24 0 42);
        }
    }

    .social-login-account-privacy {
        color: $black;
        font-size: rem-calc(10);
        font-weight: 600;
        text-decoration: underline;

        &:hover {
            border-bottom: none;
        }
    }
}
#tab-registration-cell.current + .gigyaLoginCell + .social-login-account-accept{
    @include breakpoint(large up){
        margin-top: rem-calc(40);
    }
}

.socialLoginContainer {
    @include breakpoint(ipad down){
        margin: 0 auto;
    }
    tr{
        background-color: white !important;
    }
    table[align="center"],
    td[align="center"]{
        width: 100%;
    }
    td[align="right"],
    td[align="left"]{
        display: none;
    }
}
