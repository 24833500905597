.banner-full-width-splited {
    .no-border{
        border: none;
    }
    .viewport-custom-pd {
        width: 100%;
        height: 100%;
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
    }
    
    .resize-custom-pd {
        @include breakpoint(small only) {
            height: rem-calc(339); 
            width: rem-calc(339);
        }
    }
    
    .h2-custom-pd {
        font-size: rem-calc(40);
        font-weight: bold;
        filter: drop-shadow(0px 3px 6px #00000029);
        margin-bottom: rem-calc(10);
        @include breakpoint(medium down) {
            font-size: rem-calc(26);
        }
        @media screen and (max-width: 630px)  {
            font-size: rem-calc(26);
        }
        @media screen and (max-width: 1024px)  {
            font-size: rem-calc(26);
        }
    }
    
    .h4-custom-pd {
        font-size: rem-calc(20);
        font-weight: 600;
        filter: drop-shadow(0px 3px 6px #00000029);
        @include breakpoint(medium down) {
            font-size: rem-calc(20);
        }
    }

    .center-wrapper-custom-pd {
        position: absolute;
        top: 40%;
        right: 0;
        left: 0;
        bottom: 0;
        @include breakpoint(large up) {
            top: 45%;
         }
            
         @media screen and (min-width: 630px) {
            top: 35%;
        }

            .text-box-slide {
              text-align: center;
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              @include breakpoint(small only) {
                display: none;
             }
              
              .link-wrapper {
                transform: translateY(50%);
                width: rem-calc(240);
                margin: rem-calc(10);
                height: rem-calc(40);
                border-radius: rem-calc(50);
                border: rem-calc(2) solid;
                box-shadow: rem-calc(3 3 10) #00000029;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                &:hover{
                    cursor: pointer;
                }
                @include breakpoint(large down) {
                    width: rem-calc(200);
                }

                &:hover {
                  a.custom-pd-link {
                    span {
                      transform: translateY(-103%);
                    }
                  }
                }
              }

              .custom-pd-link {
                padding: 0;
                overflow: hidden;
                span {
                  position: relative;
                  display: inline-block;
                  transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
                  &:before {
                    position: absolute;
                    top: 100%;
                    content: attr(data-hover);
                    transform: translate3d(0, 0, 0);
                    @include breakpoint (medium down) {
                      display: none;
                    }
                  }
                }
              }
            }
    }

    .mobile-ml-custom-pd {
        @include breakpoint(small only) {
            margin-left: auto;
            margin-top: rem-calc(40);
            margin-bottom: rem-calc(40);
        }
    }

    .show-medium-custom {
        @media screen and (min-width: 630px) {
            display: block!important;
        }
    }

    .banner-container-pd1, .banner-container-pd2{
        cursor: pointer;
    }
}
