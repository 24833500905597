@import "../../scss/pages/loginRegisterStyle.scss";

.layer-login-modal .rightModalAttribute,
.layer-password-modal .rightModalAttribute{
    position: absolute;
    right: 0;
    min-height: 100% !important;
    width: 100%;
    max-width: rem-calc(600) !important;
    @include breakpoint (medium down) {
        min-width: 100vw !important;
        overflow-y: auto;
        height: 100%;
    }
    .tingle-modal-box__content {
        width: 100%;
        //padding: rem-calc(40 100 40 60);
        padding: rem-calc(24 40 24 24);
        @include breakpoint (medium down) {
            padding: rem-calc(40);
        }
        @include breakpoint (small down) {
            padding: rem-calc(40 0 0 0);
        }
    }
    .close {
        font-size: 0;
        top: rem-calc(24);
        right: rem-calc(40);
        z-index: 3;
        cursor: pointer;

        @include breakpoint(small down) {
            top: rem-calc(16);
            right: rem-calc(16);
        }
    }
    .close-arrow,
    .js-back-step-arrow {
        display: block;
        z-index: 3;
        position: absolute;
        top: rem-calc(24);
        left: rem-calc(24);
        font-size: 0;
        cursor: pointer;

        @include breakpoint(small down){
            top: rem-calc(16);
            left: rem-calc(16);
        }
    }

    .password-input-modal{
        .errors-list.filled{
            margin-top: rem-calc(-35);
        }
    }

    .password-input-popup{
        margin-top: 0;
        margin-bottom: rem-calc(40);
    }

    .login-popup-register{
        margin-top: 0 !important;
    }
}

.layer-password-modal{
    .tingle-modal{
        &.tingle-modal--visible{
          background: rgba(130, 193, 189, 0.6);
          backdrop-filter: blur(5px);
        }
      }
      .tingle-modal-box{
          &.rightModalAttribute{
            width: rem-calc(459);
            background-color: $white;
            @include breakpoint (small down){
                width: 100%;
                overflow-x: hidden;
            }
        }
        .close{
            top: rem-calc(20);
            @include breakpoint (small down){
                top: rem-calc(12);
                right: rem-calc(18) !important;
            }
        }
    }
}

.layer-login-modal{
    .tingle-modal{
        &.tingle-modal--visible{
          background: rgba(130, 193, 189, 0.6);
          backdrop-filter: blur(5px);
        }
      }
      .tingle-modal-box{
          &.rightModalAttribute{
            width: rem-calc(459);
            background-color: $white;
            @include breakpoint (small down){
                width: 100%;
                overflow-x: hidden;
            }
        }
    }
}


.button-login{
    margin-bottom: rem-calc(24);
}

.layer-password-modal #submitEmailButton{
    width: 86% !important;
    position: absolute;
    bottom: rem-calc(24);
    @include breakpoint (small down){
        width: 90.5% !important;
    }
}
.layer-login-modal, .layer-password-modal {
    @include breakpoint(small down){
        overflow-y: hidden;
        .close{
            right: rem-calc(16);
        }
    }
    button{
        &:focus{
            outline: none;
        }
    }
    .errors-list.filled{
        margin-left: 0;
    }
    hr{
        border-color: #ccc;
        margin: rem-calc(30 0);
    }
    .login-popup-header,
    .modal-header{
        margin-bottom: rem-calc(25);
        h4, p {
            font-size: rem-calc(18);
            font-weight: 500 !important;
            line-height: rem-calc(27);

            &.cc-registrationModal__headTitle {
                padding-left: rem-calc(40);
                padding-right: rem-calc(40);
            }
        }
        @include breakpoint(small down){
            margin: 0;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            z-index: 2;
            h4, p {
                margin: 0;
                text-align: left;
                padding-left: rem-calc(62) !important;
                &.login-page-title-tab{
                    border-bottom: 1px solid #f2f2f2;
                    padding: rem-calc(16 0 16 64);
                }

                &.login-page-title-tab--no-arrow {
                    padding-left: rem-calc(16) !important;
                }

                &.cc-registrationModal__headTitle {
                    padding-left: rem-calc(48);
                    padding-right: rem-calc(48);
                }
            }
        }
        .request-password-title{
            @include breakpoint(small down){
                padding-left: rem-calc(20);
                font-weight: 500 !important;
                margin-top: rem-calc(17) !important;
                margin-right: rem-calc(35) !important;
            }
        }
    }

    .login-popup-header{
        .login-page-title-tab{
            font-size: rem-calc(14);
            font-weight: 500 !important;
            line-height: rem-calc(21);
            letter-spacing: rem-calc(0.2);
            color: rgba(28, 28, 28, 1);
            margin-bottom: rem-calc(24);
            @include breakpoint (small down){
                display: block;
                margin-bottom: 0;
            }
        }
    }

    .email-input-modal{
        margin-bottom: rem-calc(16);
        @include breakpoint (small down){
            margin-top: rem-calc(34);
            margin-bottom: rem-calc(24);
        }
    }

    .toggle-password{
        cursor: pointer;
        position: absolute;
        bottom: rem-calc(5);
        right: 0;
        content: url('../../images/password-eye-closed.svg');
        width: rem-calc(20);
        height: rem-calc(20);
        z-index: 1;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 20px;
        touch-action: manipulation;
        &.pwd-icon-visible{
            content: url('../../images/password-eye-open.svg');
        }
    }

    .modal-title-for-login-persistent{
        @include breakpoint(large up){
            margin: rem-calc(-16 0 28);
        }
        @include breakpoint(small down){
            margin-bottom: rem-calc(34);
        }
    }
    .login-popup-header{
        @include breakpoint(large up){
            margin-bottom: rem-calc(24);
        }
    }
    .login-pwd{
        margin-bottom: rem-calc(19);
        font-size: rem-calc(12);
        line-height: rem-calc(18);
        @include breakpoint(small down){
            margin: rem-calc(24 0 32);
        }
        .checkbox-label{
            font-size: rem-calc(12);
            font-weight: 400;
            line-height: rem-calc(18);
            cursor: unset;
            top: rem-calc(0.5);
            margin-top: rem-calc(8);
            margin-left: rem-calc(75) !important;
 
            @include breakpoint (large up){
                margin-top: 0;
                margin-left: rem-calc(40) !important;
             }
        }
        .password-reset{
            border-bottom: 2px solid black;
        }
        #password-reset{
            font-size: rem-calc(12);
            font-weight: 400;
            line-height: rem-calc(18);
            letter-spacing: rem-calc(0.2);
        }
    }
    .tooltip{
        &.info-rememberme{

        }
    }
    // .activable-after-change{
    //     background-color: black;
    //     color: white;
    // }
    .registration-button-hover-account{
        width: 100%;
        margin: rem-calc(16 0 16 0);
    }
    .tzn-hover-account-login-pwd{
        margin: rem-calc(32 0 32 0);
        height: rem-calc(20);
        @include breakpoint (small down){
            height: rem-calc(32);
            margin: rem-calc(32 0 18 0);

        }
    }

    .input:active, .input:focus, .select:active, .select:focus {
        border: none;
        outline: none;
    }

    // #login-form-email{
    //     @include breakpoint (small down){
    //         margin-bottom: rem-calc(32);
    //     }
    // }
    // .hover-account-login-button{
    //     background-color: #646464;
    //     color: #c4c0c0;
    // }
    .login-popup-footer{
        font-size: rem-calc(12);
        line-height: rem-calc(24);
        text-align: left;
        @include breakpoint(small down){
            font-size: rem-calc(14);
            line-height: rem-calc(24);
            text-align: left;
            margin-bottom: 0;
         .new-customer-text{
            font-size: rem-calc(12);
            font-weight: 400;
            line-height: rem-calc(18);
            letter-spacing: rem-calc(0.2);
         }
        }
        a{
            border-bottom: 2px solid black;
        }
    }
    .overflow-mobile{
        @include breakpoint(small down){
            padding: rem-calc(0 16 16 16);
            margin-top: rem-calc(22);
            &::-webkit-scrollbar {
                display: none;
            }
            .privacy-cookie-policy-bottom{
                text-align: center;
                margin: rem-calc(24 0 42);
            }
            hr{
                display: none;
            }
        }
    }
    .height-100{
        height: 100%;
    }
    .or-divider{
        margin-top: rem-calc(32);
        margin-bottom: rem-calc(16);
        text-align: left;
        @include breakpoint(small down){
            margin-top: rem-calc(32);
        }
    }
    .social-login-account-accept{
        margin-top: rem-calc(32);
        margin-bottom: rem-calc(24);
        width: 100%;

        a{
            font-weight: 400;
        }
        @include breakpoint (small down){
            margin: rem-calc(24 0 24 0);
        text-align: left;
        }
    }
}
.layer-login-modal{
    .overflow-mobile{
        @include breakpoint(large up){
            height: calc(100vh - 143px);
            display: flex;
            flex-direction: column;
            .top-section{
                //margin-bottom: rem-calc(30);
                //margin-top: rem-calc(30);
            }
            .mt-auto{
                margin-top: auto;
                hr{
                    margin: rem-calc(23 0 16);
                }
            }
        }
    }
}

.layer-password-modal {
    z-index: 1002;
    .modal-content{
        position: unset;
        width: 100%;
    }
    .tingle-modal-box__content{
        padding-left: rem-calc(51);
        @include breakpoint(ipad down){
            padding: 0 !important;
            .overflow-mobile{
                margin-top: 0;
                padding-top: rem-calc(20);
                padding-right: rem-calc(20);
            }
        }
    }
    .reset-password-form{
        .send-email-btn{
            margin-top: rem-calc(40);
        }
        @include breakpoint(small down){
            display: flex;
            flex-direction: column;
            .send-email-btn{
                margin-top: auto;
            }
        }
        .forgot-pwd-message{
            font-size: rem-calc(14);
            line-height: rem-calc(24);
            margin-bottom: rem-calc(40);
        }
    }
    .modal-header{
        margin-bottom: rem-calc(20);
        @include breakpoint(ipad down){
            position: unset;
            text-align: center;
        }
        .back-to-login{
            position: absolute;
            top: rem-calc(17.5);
            left: rem-calc(12);
        }
    }
    .request-password-body p{
        margin-bottom: rem-calc(20);
    }
    #submitEmailButton,
    #loginButton{
        width: 100%;
        padding: rem-calc(16 32);
        @include breakpoint (small down){
            width: 100%;
            padding: rem-calc(16 32);
        }
    }
}

.rightModalAttribute.unsubscribe-loyalty-modal {
    @include breakpoint (large up) {
        position: absolute;
        right: 0;
        min-height: 100% !important;
        width: 100%;
        max-width: rem-calc(600) !important;
        .tingle-modal-box__content {
            width: 100%;
            height: 100vh;
            padding: rem-calc(24 40 24 24);
        }
        .modal-myaccount-newtezenis{
            height: 100%;
        }
    }
    .tingle-modal-box__content {
        padding-bottom: rem-calc(16);
    }
    h4{
        font-size: rem-calc(16);
        font-weight: 600;
        margin-bottom: rem-calc(24);

        @include breakpoint(small down){
            font-size: rem-calc(14);
            margin-bottom: rem-calc(32);
        }
    }
    .password-setted-privacy-policy,
    .unsubscribe-text{
        font-size: rem-calc(12);
        font-weight: 400;
        margin-bottom: rem-calc(24);
        @include breakpoint(small down){
            margin-bottom: rem-calc(32);
        }
    }

    .button-loyalty-wrapper {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: rem-calc(24 40 24 24);
        border-top: rem-calc(1) solid $light-gray;

        @include breakpoint(small down){
            padding: rem-calc(16);
        }

        button {
            width: 100%;
        }
    }

    .close {
        top: rem-calc(24);
        right: rem-calc(40);

        @include breakpoint(small down){
            top: rem-calc(16);
            right: rem-calc(16);
        }
    }

    button{
        &:focus{
            outline: none;
        }
    }
}


.modal-registration-flow-custom {
    .registrationModal-box {
        &[data-registration-modal-stage="email"] {
            .registration-modal-phone,
            .registration-modal-personal-info,
            .registration-modal-waiting {
                display: none;
            }
        }
        &[data-registration-modal-stage="phone"]{
            .registration-modal-email,
            .registration-modal-personal-info,
            .registration-modal-waiting {
                display: none;
            }
        }
        &[data-registration-modal-stage="personal-info"]{
            .registration-modal-email,
            .registration-modal-phone,
            .registration-modal-waiting {
                display: none;
            }
        }
        &[data-registration-modal-stage="waiting-room"]{
            .registration-modal-email,
            .registration-modal-phone,
            .registration-modal-personal-info {
                display: none;
            }
        }
    }
}

.modal-loyalty-registration-flow-custom {
    .registrationModal-box {
        &[data-registration-modal-stage="content"] {
            button.close-arrow,
            .loyalty-registration-modal-registration,
            .loyalty-registration-modal-loyaltythankyoupage {
                display: none;
            }

            .login-popup-header h4.cc-registrationModal__headTitle {
                padding-left: 0;

                @include breakpoint(small down){
                    padding-left: rem-calc(16) !important;
                }
            }
        }
        &[data-registration-modal-stage="registration"]{
            .loyalty-registration-modal-content,
            .loyalty-registration-modal-loyaltythankyoupage {
                display: none;
            }
        }
        &[data-registration-modal-stage="loyalty-thankyou-page"] {
            button.close-arrow,
            .loyalty-registration-modal-registration,
            .loyalty-registration-modal-content {
                display: none;
            }

            .login-popup-header h4.cc-registrationModal__headTitle {
                padding-left: 0;

                @include breakpoint(small down){
                    padding-left: rem-calc(16) !important;
                }
            }
        }
    }
}

.cc-layerLogin {
    &__confirmRegister {
        .cc-titleWrp {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: rem-calc(16);

            .cc-icon {
                font-size: 0;
            }
        }

        &__title {
            font-weight: $global-weight-dark;
            font-size: rem-calc(14);
            line-height: rem-calc(21);
            letter-spacing: rem-calc(0.2);
            text-align: left;
            margin: 0;
            padding: rem-calc(0 0 0 16);
        }

        &__text {
            font-size: rem-calc(12);
            line-height: rem-calc(18);
            letter-spacing: rem-calc(0.2);
            text-align: left;
            margin: rem-calc(0 0 16);
            padding: 0;
        }
    }
}
