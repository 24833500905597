.cover {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.lazyload,
.lazyloading {
    opacity: 0;
}

img.lazyload:not([src]) {
    visibility: hidden;
}

svg.big {
    width: rem-calc(30);
    height: rem-calc(30);
}

.lazyloaded {
    opacity: 1;
    transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.image-container {
    position: relative;
    overflow: hidden;

    img {
        width:100%;

        &.loading,
        &.lazyload,
        &.lazyloaded {
            opacity: 1;
            // transform: scale(1) translateY(0);
            transition: opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1), transform 1.6s cubic-bezier(0.215, 0.61, 0.355, 1); 
        }
    }

    &.ratio-1-1 {
        padding-top: 100%;
    }

    &:hover {
        .quickview--tezenis {
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
        }
    }

}