@font-face {
    font-family: 'Personalization';
    src: url('../fonts/Personalization/personalization.eot?') format('eot'),
        url('../fonts/Personalization/personalization.woff') format('woff'),
        url('../fonts/Personalization/personalization.ttf') format('truetype'),
        url('../fonts/Personalization/personalization.svg#personalization') format('svg');
    font-weight: normal;
    font-style: normal;
}

.personalization-font {
    font-family: 'Personalization';
}