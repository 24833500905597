.card {
    position: relative;
    margin-top: rem-calc(24);
    margin-bottom: rem-calc(42);
    border: 1px solid lighten($border-gray, 5%);
    padding-top: rem-calc(12);
    background: $white;

    &.reduce-width-desktop {
        width: 60%;
        @include breakpoint (medium down) {
            width: auto;
            margin-top: auto;
        }
        @include breakpoint (small down) {
            width: auto;
            margin-top: auto;
        }
    }

    .margin-header-section {
        white-space: normal;
        font-size: rem-calc(16);
    }

    &.reduce-margin-top-desktop {
        margin-top: rem-calc(-28);
        @include breakpoint (medium down) {
            margin-top: auto;
        }
        @include breakpoint (small down) {
            margin-top: auto;
        }
    }

    &.ghost {
        padding-bottom: rem-calc(12);
    }

    @include breakpoint (small down) {
        margin-top: 0;
        margin-bottom: rem-calc(24);
        padding-top: rem-calc(12);
    }
    /*
    &.only-border-bottom {
        border: none;
        border-bottom: 1px solid $medium-light-gray;
    }
    */

    &.small-margin {
        margin-top: rem-calc(10);
        margin-bottom: rem-calc(10);
        padding-top: 0;
    }

    &--no-header {
        padding-top: 0;
    }
    /*
    &--no-shadow {
        box-shadow: none;
    }
    */

    &-header {
        padding: rem-calc(15 25 0 25);
        white-space: nowrap;

        &.return-border-bottom {
            border-bottom: 1px solid #E8E8E8;
        }  

        .ghost & {
            padding: rem-calc(15 25);
        }

        & h5 {
            font-weight: $global-weight-bold;
        }
    }

    &-body {
        padding: rem-calc(24 24 18);

        .remove-btn {
            position: absolute;
            top: rem-calc(4);
            right: rem-calc(12);
            font-size: rem-calc(24);
            font-weight: 300;
            cursor: pointer;
        }
    }

    &-footer {
        padding: rem-calc(0 24 18);
    }

    &-button {
        position: absolute;
        bottom: 0;
        right: rem-calc(24);
        transform: translateY(60%);
    }
}

.hidden {
    display: none;
}

.total-price {
    text-align: right;
    display: inline-flex;
    @include breakpoint (small down) {
        display: inline-flex;
    }
}

/*
.fixed-margin {
    margin-top: rem-calc(10);
}
*/

.border-color {
    background-color: #ff6699; 
    padding-top: rem-calc(15);
    padding-bottom: rem-calc(15);
    margin-top: rem-calc(15);
    @include breakpoint (small down) {
        margin-right: rem-calc(15);
    }
}

.text-decoration {
    text-decoration: none;
    -webkit-text-decoration-color: white;
    text-decoration-color: white !important;
}

.title-render {
    text-align: left;
    position: relative;
    float: right;
    &.resize-for-small {
        @include breakpoint (small down) {
            position: relative;
            float: none;
        }
    }
}

/*
.render-information {
    text-align: right;
    left: rem-calc(-14);
    position: relative;
    margin-right: rem-calc(10);
    @include breakpoint (small down) {
        left: rem-calc(-20);
    }
}
*/

/*
.padding-box {
    padding: 20px;
    &.container-info-products {
        padding-left: calc(9% - 18px); // #{map-get($grid-margin-gutters, small)};
        padding-right: calc(9% - 18px); // #{map-get($grid-margin-gutters, small)};
        width: calc(100% - 16%);
        position: inherit;
        margin-left: calc(100% - 92%);
    }
}
*/

/*
.all-border {
    border: 1px solid $black;
    margin-top: rem-calc(12);
    margin-bottom: rem-calc(12);
    width: 90%;
}
*/
/*
.text-white {
    color: white;
    margin-left: rem-calc(20);
    font-weight: bold;
    @include breakpoint (small down) { 
        display: inline-block;
        &.reset-margin-label {
            margin-left: 0;
        }
    }
}
*/

.margin-top {
    margin-top: rem-calc(12);
}

.subtitle-two {
    font-size: rem-calc(16);
    letter-spacing: rem-calc(0.9);
    font-weight: $global-weight-dark;
    text-transform: uppercase;
}

.disabled {
    pointer-events: none;
    opacity: 0.4;
}

.border-bottom {
    border-bottom: 1px solid $border-gray;
    margin-bottom: rem-calc(12);
}
/*
.more-padding-left {
    padding-left: rem-calc(50);
}
*/

.right {
    float: right;
}

.text-decoration {
    text-decoration: underline;
    text-decoration-color: black;
}


.leading-lines {
    position: relative;
    overflow: hidden;
    margin-bottom: rem-calc(16);
    label,
    p {
        margin-bottom: rem-calc(6);
    }
}

// Card shadow exceptions
.page-checkout,
.receipt {
    .card {
        box-shadow: none;
    }
}

.price-box-name {
    display: flex;
}

/*
.grid-margin-x>.small-12.margin-bottom-return{
    margin-bottom: rem-calc(-55);
    @include breakpoint (small down) {
        margin-bottom: rem-calc(-15);
    }
}
*/

.text-alert {
    margin-left: rem-calc(35);
}

.label-method {
    @include breakpoint (small only) {
        margin-left: rem-calc(25);
    }
}