$flag-icon-css-path: '../images/flags';
@import '../../../node_modules/flag-icon-css/sass/flag-icon.scss';

.geoblockContainerBlur {
    position: relative;
    width: 100%;
    z-index: 3;

    // Backdrop
    background-color: rgba(255, 255, 255, 1);

    /* If backdrop support: very transparent and blurred */
    @supports ((-webkit-backdrop-filter: blur(30px)) or (backdrop-filter: blur(30px))) {
        background-color: rgba(255, 255, 255, .5);
        -webkit-backdrop-filter: blur(30px);
        /* for Safari */
        backdrop-filter: blur(30px);
        /* for Chrome + enabled the "Experimental Web Platform Features" flag */
    }


    .geoblockContainer {
        width: 100%;
        flex-wrap: wrap;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        list-style: none;
        margin-left: auto;
        margin-right: auto;
        max-width: rem-calc(980);

        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .geoblock-copy {
        padding-right: 2%;
        display: inline-block;
        word-break: initial;

        @include breakpoint(medium down) {
            flex: 1 0 80%;
            max-width: 80%;
            margin-bottom: rem-calc(12);
        }

        @include breakpoint(small down) {
            flex: 1 0 90%;
            max-width: 90%;
        }
    }

    .geoblock-actions {
        display: -webkit-inline-box;
        display: -webkit-inline-flex;
        display: -ms-inline-flexbox;
        display: inline-flex;

        @include breakpoint(medium down) {
            -webkit-box-flex: 1;
            -webkit-flex: 1 0 100%;
            -ms-flex: 1 0 100%;
            flex: 1 0 100%;
        }

        @include breakpoint(small down) {
            margin: 0 rem-calc(-5);
            -webkit-box-flex: 1;
            -webkit-flex: 1 0 calc(100% + rem-calc(10));
            -ms-flex: 1 0 calc(100% + rem-calc(10));
            flex: 1 0 calc(100% + rem-calc(10));
        }

    }

    .geoblock-actions-item {
        display: inline-block;
        margin-left: rem-calc(25);

        &:first-child {
            margin-left: 0;
        }
    }

    .geoblock-close {
        font-size: rem-calc(20);
        line-height: rem-calc(20);

        @include breakpoint(medium down) {
            position: absolute;
            top: rem-calc(17);
            right: rem-calc(17);
        }

        @include breakpoint(small down) {
            right: rem-calc(13);
        }

        &:focus {
            @include shadow(none);
            outline: none;
        }
    }

    .button-shop {
        margin: 0 auto;
    }

}
