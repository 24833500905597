.product-giftcard {
    h6 {
        padding-top: rem-calc(10);
        font-size: rem-calc(14);
        @include breakpoint(small down) {
            font-size: rem-calc(14);
            font-weight: 600;
        }
    }

    .inputs-style, .height-text-area {
        @include breakpoint(small down){
            width: rem-calc(328)!important;
            height: rem-calc(56)!important;
        }
    }
    .datepickerTriggerElement{
        [type="text"]{
            cursor : pointer;
        }
    }

    h5 {
        margin-bottom: 0;
    }

    .title-main {
        font-size: rem-calc(18);
        line-height: rem-calc(27);
        font-weight: 600;
        margin-bottom: rem-calc(24);

        @include breakpoint(small down) {
            margin-bottom: rem-calc(16);
        }
    }

    .separator-custom {
        background-color: #ccc;
        height: 1px;
        margin-top: rem-calc(6);
        @include breakpoint(small down) {
            margin-top: rem-calc(96);
        }
    }

    .mobile-price-style {
        @include breakpoint(small down) {
            width: rem-calc(332);
            overflow: scroll;
            flex-flow: nowrap;
            height: rem-calc(60);
        }
    }

    .parsley-errors-list {
        &.filled {
            margin: rem-calc(4 0 0);
        }
    }

    .text-alert {
        margin-left: 0;
        @include breakpoint(small down) {
            margin-left: rem-calc(26);
        }
    }

    .separator {
        background-color: #ccc;
        height: 1px;
        margin-top: rem-calc(31);
        @include breakpoint(small down) {
            margin-top: rem-calc(48);
        }
    }
    .title-data {
        margin: rem-calc(26 0 30 0);
        font-weight: 600;
        @include breakpoint(small down) {
            margin: rem-calc(25 0 20 0);
            text-align: left;
        }
    }
    .title-importo {
        margin: rem-calc(37 0 23 0);
    }
    .title-style {
        font-weight: 500;
        font-size: rem-calc(14);
        line-height: rem-calc(21);
        letter-spacing: rem-calc(0.2);
        margin-bottom: rem-calc(16);
        padding-top: 0;
        padding-bottom: 0;
    }

    .wrapper-importo {
        @include breakpoint(small down) {
            width: fit-content!important;
            margin: auto;
        }
    }

    .preview-ecard {
        position: relative;
        text-align: center;
        font-weight: 600;
        font-size: rem-calc(14);
        letter-spacing: 0.42px;
        margin-bottom: 0;
    }

    input[type=number] {
        -webkit-appearance: none;
        -moz-appearance:textfield; /* Firefox */
    }

    .slider-nav {
        .slick-slide {
            outline: none;
            img {
                width: 87%;
                height: auto;
                border: rem-calc(1) solid $black;
                cursor: pointer;
                border-radius: 0;
                outline: none;
            }
        }
    }

    .title-subtitle-country {
        font-weight: 600;
        margin-bottom: rem-calc(19);
        @include breakpoint(small down) {
            margin-top: rem-calc(20);
            margin-left: rem-calc(0);
            text-align: left;
        }
    }

    .section-separator {
        height: rem-calc(1);
        background: $light-gray;
        margin: rem-calc(24 0);

        @include breakpoint(small down) {
            margin: rem-calc(16 0);
        }

        &--cta {
            margin-bottom: rem-calc(24);

            &-2 {
                margin: rem-calc(24 0);
            }
        }

        &--1 {
            margin: rem-calc(8 0 24);
        }

        &--no-top {
            margin-top: 0;
        }
    }

    .js-ImgGiftCard {
        width: auto!important;
        margin-right: rem-calc(8);
        border: rem-calc(1) solid transparent;
        border-radius: rem-calc(4);
        @include breakpoint(small down) {
            margin-bottom: rem-calc(8);
        }
        &.slick-active {
            img {
                border: none;
                border-radius: rem-calc(4);
                width: rem-calc(52);
                height: rem-calc(80);
                object-fit: cover;
            }
        }

        &.slick-current {
            border: rem-calc(1) solid $black;

            .checkmark-img {
                display: none;
            }
        }
    }


    .js-amount-selector {
        margin-bottom: 0;
    }

    .wrapper-img-big-gitcard {
        position: relative;
        z-index: 1;
        max-width: rem-calc(416);
        margin: 0 auto;

        @include breakpoint(small down) {
            margin: rem-calc(0 -16 16 -16);
        }
        .js-sliderFor {
            .slick-slide {
                img {
                    display: block;
                    margin: 0;
                    position: relative;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    aspect-ratio: 416 / 630;
                    border-radius: rem-calc(4);

                    @include breakpoint(small down) {
                        aspect-ratio: 376 / 563;
                        border-radius: 0;
                    }
                }
            }
        }


        .wrapperCarouselPrice {
            position: absolute;
            width: rem-calc(300);
            height: rem-calc(300);
            top:100%;
            left:50%;
            margin: rem-calc(-150 0 0 -150);
            z-index: 900;
            color: #FFFFFFDE;
            text-shadow: 0px 3px 6px #00000029;
            padding: rem-calc(10);
            @include breakpoint(medium down) {
                width: rem-calc(210);
                height: rem-calc(210);
                margin: rem-calc(-120 0 0 -106);
            }
            .sliderForPrice {
                text-align: center;
                font-size: rem-calc(30);
                font-weight: 600;
                margin-top: 0;

                p {
                    display: block;
                    padding: 0;
                    margin: 0;
                    line-height: rem-calc(60);
                    @include breakpoint(medium down) {
                        font-size: rem-calc(15);
                        line-height: rem-calc(30);
                    }
                    &.label {
                        line-height: 1;
                        @include breakpoint(medium down) {
                            font-size: rem-calc(15);
                        }
                    }
                    &.label-pos {
                        margin-top: rem-calc(7);
                    }
                }


            }
            .slider:before {
                display: none; // There is a more general definition on causing a white block on gift card element.
            }
        }


    }
    .sliderNavPrice {
        .slick-track {
            width: 100%!important;
            .slick-slide {
                width: 12.4%!important;
                font-size: rem-calc(13);
                line-height: rem-calc(20);
                height: rem-calc(45);
                display: inline-block;
                vertical-align: top;
                background-color: $white;
                border: rem-calc(1) solid $black;
                border-radius: 0;
                padding: rem-calc(13 6);
                margin-right: rem-calc(3);
                margin-bottom: rem-calc(5);
                cursor: pointer;
                text-align: center;
                font-weight: 500;
                letter-spacing: .05em;
                @include breakpoint(small down) {
                    width: 25%!important;
                }
                &:last-child {
                    margin: 0;
                    padding: 0;
                    width: 47%!important;


                    input {
                        margin: 0;
                        padding: 0;
                        border: 0;
                        text-align: center;
                        outline: none;

                        &::-webkit-outer-spin-button,
                        &::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }
                    }
                }
                &.slick-current {
                    border: rem-calc(2) solid $black;
                    &.border-off {
                        border: rem-calc(1) solid $black!important;
                    }
                }

            }
        }

    }
    .accordion {
        text-align: center;
        margin-top:rem-calc(90);
        @include breakpoint(small down) {
            width: rem-calc(319);
            margin: auto;
        }
    }
    .giftcard-labels-info {
        @include breakpoint(small down) {
            width: rem-calc(334);
            margin: 0 auto;
        }
    }
    .frequent-questions {
        margin-top: rem-calc(90);
        @include breakpoint(small down) {
            border-bottom: rem-calc(1) solid $more-light-gray;
            margin-top: rem-calc(0);
            padding-bottom: rem-calc(35);
        }
    }

    .d-none {display:none; }
    .d-block {display:block; }
    .btn-modal-giftcard {
        text-transform: capitalize;
        font-size: rem-calc(14);
        text-decoration: none;
        font-weight: 600;
        border-bottom: rem-calc(2) solid $black;
        padding-bottom: rem-calc(5);
        cursor: pointer;
    }

    .form-item {
        &--top-textarea {
            margin-top: rem-calc(8);
        }

        .height-text-area {
            resize: none;
            padding: rem-calc(34 20 20);
            margin-top: rem-calc(8);
        }
        .text-area-label {
            top: rem-calc(16);
        }
    }

    .add-to-cart-actions {
        margin-top: 0;
    }

    .icon-datepicker {
        background: url('../images/giftcard/calendar.svg') no-repeat right center;
        background-size: rem-calc(16);

        &:not(:placeholder-shown) {
            background: url('../images/giftcard/calendar.svg') no-repeat right center;

        }
    }

    .sliderForPriceStamp {
        padding: rem-calc(0 0 10);
        font-weight: 600;
        font-size: rem-calc(14);
    }
}
.wrapperModalGiftCard {
    &.tingle-modal {
        z-index:1000;
        margin-top: 0;
    }
    .tingle-modal-box {
        max-width: rem-calc(600);
        width: auto;
        max-height: rem-calc(764);
        height: auto;
        border-radius: em-calc(2);
        @include breakpoint(small down) {
            max-width: rem-calc(375);
            overflow-y: auto;
            height: 100%;
        }

        @media (max-width: 320px) {
            max-width: 100%;
          }

        .tingle-modal-box__content {
            padding: 0;

            .close-button {
                position: absolute;
                top: rem-calc(40);
                right: rem-calc(18);
                z-index: 1;
                width: rem-calc(24);
                height: rem-calc(24);
                margin: 0;
                cursor: pointer;
            }
        }
        .modalGiftCard {
            padding: rem-calc(0 60);
            @include breakpoint(small down) {
                padding: rem-calc(0 26);
            }

            .modalTitle {
                font-size: rem-calc(18);
                font-weight: 600;
                text-align: center;
                padding: rem-calc(40 0 0 0);

                .personSource {
                    font-size: rem-calc(14);
                    letter-spacing: 0.13px;
                    color: #000000DE;
                    padding: rem-calc(19 0 19 0);
                    font-weight: lighter;
                }
            }
            .giftcard-select {
                position: relative;
                .js-ImgGiftCardStamp {
                    width: rem-calc(480);
                    height: rem-calc(214);
                    object-fit: cover;
                    @include breakpoint(small down) {
                        width: rem-calc(324);
                        height: rem-calc(214);
                        object-fit: cover;
                    }
                    img {
                        width: rem-calc(480);
                        height: rem-calc(214);
                        object-fit: cover;
                        @include breakpoint(small down) {
                            width: rem-calc(324);
                            height: rem-calc(214);
                            object-fit: cover;
                        }
                    }
                }
            }
            .giftcard-details {
                width: 100%;
                margin-top: rem-calc(20);
                text-align: left;
                display: table;
                .infoRow {
                    display: table-row;
                    font-size: rem-calc(12);
                    .infoLabel {
                        display: table-cell;
                        text-align: left;
                        padding: rem-calc(5 0);
                        width: auto;
                        max-width: rem-calc(110);
                    }
                    .infoContent {
                        display: table-cell;
                        padding-left: rem-calc(15);
                        text-align: right;
                        span {
                            font-weight: 600;
                        }
                    }
                }
            }
        }
        .footerGiftCard {
            text-align: left;
            margin-top: rem-calc(15);
            margin-bottom: rem-calc(30);
            border-top: rem-calc(1) solid $border-light-gray;
            padding-top: rem-calc(20);
            .button-msg {
                font-size: rem-calc(10);
                letter-spacing: 0.34px;
                text-transform: uppercase;
                border-bottom: rem-calc(1) solid #949494;
                color: #949494;
                margin: rem-calc(20 0 15 0);
            }
            .infoText {
                font-size: rem-calc(10);
                color: #949494;
            }
            .barCodeRow {
                padding: rem-calc(30 0 0);
                text-align: center;
            }
        }
    }
    .d-none {display:none; }
    .d-block {display:block; }
}

.amount-cell {
    border: rem-calc(1) solid $light-gray;
    border-radius: rem-calc(20);
    font-size: rem-calc(12);
    line-height: rem-calc(18);
    font-weight: 500;
    letter-spacing: rem-calc(0.2);
    display: inline-block;
    vertical-align: top;
    background-color: $white;
    padding: rem-calc(8 16);
    margin: rem-calc(0 8 8 0);
    cursor: pointer;
    text-align: center;
    font-weight: 500;
    letter-spacing: .05em;
    width: auto;

    &.active {
        background: $white;
        color: $black;
        border-color: $black;
    }

    &:last-of-type {
        margin-right: 0;
    }
}

.amount-free-cell {
    margin: rem-calc(18 0 0 0);
}
.giftcard-add-to-cart-global {
    width: 100%;
}
.egcMessage {
    font-size: rem-calc(10);
    color: #646464;
    padding-left: rem-calc(4);
}
.wrapper-textarea {
    @include breakpoint(small down) {
        width: fit-content !important;
        margin: 0 auto;
    }
}

.giftcard-quickview {
    .amount-free-cell {
        max-width: 60%;

        @include breakpoint(small down) {
            max-width: 55%;
        }
    }
}

.accordion {
    &.margin-top-fix {
        margin-top: 0;
    }
}

.align-on-left {
    text-align: left;
    @include breakpoint(small down) {
        padding-left: 0 !important;
    }
}

.frequent-questions {
    &.no-margin-top {
        margin-top: 0;
        margin-bottom: rem-calc(30);
    }
}

.margin-bottom-added {
    @include breakpoint(large up) {
        margin-bottom: rem-calc(20);
    }
}

.privacy-policy-sentence {
    margin-top: rem-calc(20);
}

.list-of-shops-ru {
    margin: 0 0 2rem 0;
    @include breakpoint(small down){
        text-align: center;
    }
    a {
        border-color: #333;
    }
}


.country-selector-style {
    width: rem-calc(287)!important;
    padding: rem-calc(11)!important;



    @include breakpoint(small down){
        width: rem-calc(328)!important;
        height: rem-calc(56)!important;
        margin: 0 auto;
    }
}

.select-country-for-mobile {
    @include breakpoint(small down){
        margin: 0 auto;
        width: fit-content;
    }
}

.select-container {
    position: relative;
    &::after {
        content: '>';
        color: #333;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
        right: rem-calc(-14);
        top: rem-calc(12);
        padding: 0 0 2px;
        position: absolute;
        cursor: pointer;

        @include breakpoint(small down){
            right: rem-calc(14);
            top: rem-calc(25);
        }
      }
}

.inputs-style {
    width: 100%;
    margin-bottom: rem-calc(30);
    background-color: transparent;
    font-size: rem-calc(13);
    font-weight: 400;
    padding: rem-calc(10);
    border: rem-calc(1) solid transparent;
    border-bottom: rem-calc(1) solid black;
    border-top-left-radius: rem-calc(3);
    border-top-right-radius: rem-calc(3);
    height: rem-calc(40);
    transition: all .2s ease;
    @include breakpoint (medium down){
        margin-bottom: rem-calc(20);
    }
    @include breakpoint(small down) {
        margin: rem-calc(5) auto;
        font-size: rem-calc(16);
        padding: rem-calc(16);
    }
    &.is-invalid-input {
        ~ .label-for-input {
        display: none;
        }
    }
    &:hover {
        background-color: #CCCCCC;
    }
    &:focus {
        outline: none;
        background-color: white;
        border-bottom: rem-calc(2) solid #FF6699;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        &::-webkit-input-placeholder {
        opacity: 0;
        }
        &::-moz-placeholder {
        opacity: 0;
        }
        &:-ms-input-placeholder {
        opacity: 0;
        }
        &:-moz-placeholder {
        opacity: 0;
        }
        &:not(:placeholder-shown) {
        border-bottom: rem-calc(2) solid #FF6699;
        ~ .input-label {
            color: #FF6699;
        }
        }
        ~ .input-label {
            opacity: 1;
            color: #FF6699;
        }
    }
    &:not(:placeholder-shown) {
        padding: rem-calc(12 0 0 13);
        ~ .input-label {
        opacity: 1;
        color: $black;
        }
    }
}
    ::-webkit-input-placeholder {
    color: $black !important;
    }
    ::-moz-placeholder {
    color: $black !important;
    }
    :-ms-input-placeholder {
    color: $black !important;
    }
    :-moz-placeholder {
    color: $black !important;
    }

    .input-label {
        position: absolute;
        top: rem-calc(5);
        left: rem-calc(13);
        font-weight: 400;
        font-size: rem-calc(11);
        letter-spacing: 0.03em;
        color: #FF6699;
        margin-bottom: 0;
        opacity: 0;
        transition: all .2s ease;
        line-height: rem-calc(11);
        border:none;
        &.is-invalid-input {
            border:none;
        }
        @include breakpoint(small down) {
            top: rem-calc(8);
            left: rem-calc(36);
        }
    }

.label-box {
    display: inline-box;
    width: 100%;
    position: relative;
}

div[data-action^="Product-ShowGiftcard"] {
    .spacer.small.show-for-large {
        height: rem-calc(40);
    }

    .grid-container {
        padding: rem-calc(0 16);

        @include breakpoint(large up) {
            padding: rem-calc(0 40);
        }

        .grid-margin-x {
            margin-left: rem-calc(-8);
            margin-right: rem-calc(-8);

            > .cell.large-12 {
                margin-left: rem-calc(8);
                margin-right: rem-calc(8);
                width: calc(100% - 1.23076rem);
            }
            > .cell.large-10 {
                margin-left: rem-calc(8);
                margin-right: rem-calc(8);
                width: calc(100% - 1.23076rem);
            }
            > .cell.large-6 {
                margin-left: rem-calc(8);
                margin-right: rem-calc(8);
                width: calc(50% - 1.23076rem);
            }
            > .cell.small-12 {
                @include breakpoint(small down) {
                    width: calc(100% - 1.23076rem);
                }
            }
        }
    }

    .button-primary-black,
    .button-primary-black-outline {
        width: 100%;
    }

    .button-primary-black-outline {
        margin-bottom: 0;
    }

    .delivery-title {
        display: block;
        font-size: rem-calc(18);
        line-height: rem-calc(27);
        text-align: left;
        margin: rem-calc(0 0 16 0);
        font-weight: 500;
    }

    .delivery-text {
        display: block;
        font-size: rem-calc(14);
        line-height: rem-calc(21);
        text-align: left;
        margin: 0;
        font-weight: 400;
        letter-spacing: rem-calc(0.2);

        a {
            font-weight: inherit;
        }
    }

    .js-price-validation-error-not-selected,
    .js-price-validation-error {
        margin: rem-calc(-12 0 16);

        span {
            margin: 0;
            font-size: rem-calc(12);
            font-weight: 400;
            color: $error;
            text-transform: none !important;
        }
    }

}

//Gift card sticky 
.cc-giftcard-wrapper{
    height: fit-content;
    position: sticky;
    top: rem-calc(60);
    padding-top: rem-calc(2);
    @include breakpoint (medium down){
        position: relative;
    }
}