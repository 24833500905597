.component-3product-3cta-3price{
    background-repeat: repeat-x;
    background-image: url(../images/background3product.png);
    padding-left: rem-calc(230);
    padding-top:rem-calc(39);
    padding-right: rem-calc(21);
    @include breakpoint (medium down) {
        padding-left: rem-calc(100);
        padding-right: rem-calc(30);
        padding-top:rem-calc(68);

    }
    @include breakpoint (small down) {
        padding-left: rem-calc(10);
        padding-right: rem-calc(0);
        padding-top:rem-calc(39);
    }

    .component-title1{
        font-size: rem-calc(30);
        line-height: rem-calc(42);
        color:#ffff; 
        font-weight: bold;
        @include breakpoint (small down) {
            font-size: rem-calc(24);
        }
    }
   
    .title-cta{
        margin-top: rem-calc(-22);
        .component-title2{
            font-size: rem-calc(30);
            color: rgba(255, 255, 255, 0.3);
            margin-left:rem-calc(42);
            font-weight: bold;
            @include breakpoint (small down) {
                font-size: rem-calc(24);
            }
        }
        .link-right{
            float:right;
            margin-top: rem-calc(-30);
            margin-right: rem-calc(52);
            font-size: rem-calc(13);
            @include breakpoint (small down) {
                font-size: rem-calc(10);
                margin-right: rem-calc(10);

            }
        }
    }

    .wrapper-overflow {
        width: 100%;
        @include breakpoint (small down) {
        display: block;
        width: 100%;
        white-space: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        margin-right: rem-calc(10);
         }
        .card {
            display: inline-block;
            width: 32%;
            border: 0;
            margin-right:rem-calc(-2);
            margin-left:rem-calc(-1);
            background-color: transparent;
            @include breakpoint (small down) {
                width: auto;

            }
            img{  
                   
                margin: 0 auto;
                @include breakpoint (small down) {
                    height: rem-calc(373);
                    width: rem-calc(253); 
                    margin: 0 auto;
                }
             
            }
        }
    }
}