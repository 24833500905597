@import "../pages/productdetail.scss";

$add-to-cart-message-height: rem-calc(100px);
$add-to-cart-message-margin: rem-calc(20px);
$add-to-cart-child-message-margin: rem_calc(10px);
$add-to-cart-max-messages-shown: 6;

body.feedback-modal-open {
    position: fixed;
    overflow: hidden;
    left: 0;
    right: 0;
}

.black-background{
    position: fixed;
    background: rgba($color: #000000, $alpha: 0.5);
    height: 100vh;
    width: 100vw;
    z-index: 900;
    top: 0;
    left: 0;
    @include breakpoint(small down){
        z-index: 905;
    }
}

.add-to-cart-messages {
    position: absolute;
    display: none;
    width: auto;
    min-width: rem-calc(300px);
    top: 100%;
    right: rem-calc(-30px);
    // min-height: ($add-to-cart-message-height+$add-to-cart-child-message-margin)*$add-to-cart-max-messages-shown+$add-to-cart-message-margin;
    height: auto;
    // overflow: hidden;

    @include breakpoint(small down) {
        right: rem-calc(10);
        display: block;
        .alert--tezenis{
            border-radius: rem-calc(5);

            .name-product{
                font-size: rem-calc(14);
                font-weight: 700;
            }
            .price-product{
                font-size: rem-calc(14);
                font-weight: 700;
                margin-top: rem-calc(10);
            }
            .button-goto-bag{
                border-radius: rem-calc(40);
                padding: rem-calc(10 30);
            }
        }
    }

    .add-to-basket-alert {
        width: 100%;
        position: relative;
        min-height: $add-to-cart-message-height;
        // max-height: $add-to-cart-message-height;
        margin-bottom: $add-to-cart-child-message-margin;

        padding: rem-calc(10px);
        background: $white;
        box-shadow: $prototype-box-shadow;

        overflow: hidden;

        & .cart-title {
            margin-right: rem-calc(10);
            font-size: rem-calc(14);
            font-weight: 700;
            letter-spacing: rem-calc(0.25);
            margin-bottom: rem-calc(10);
            padding: rem-calc(0 10);
        }
    }

    .close-notification {
        position: absolute;
        right: rem-calc(10px);
        top: 0;
        font-size: rem-calc(20px);
        font-weight: 100;
    }

}

.minicart {
    position: relative;

    .line-item-added {
        font-size: rem-calc(14);
        font-weight: 600;
        line-height: rem-calc(21);
        letter-spacing: rem-calc(0.2);
        padding: rem-calc(27 40 24 24);
        text-align: left;

        @include breakpoint (small down) {
            padding: rem-calc(24 16);
        }
    }

    h4 {
        font-size: rem-calc(14);
        font-weight: 700;
    }

    .mini-product-item{
        .code{
            font-size: rem-calc(10);
            font-weight: 400;
            color: #949494;
        }
        .product-name{
            font-size: rem-calc(14);
            font-weight: 400;
            letter-spacing: rem-calc(0.2);
            line-height: rem-calc(21);
            margin-bottom: rem-calc(4);
            max-width: rem-calc(225);
            overflow: hidden;
            text-overflow: ellipsis;
            text-wrap: nowrap;


        }
    }

    .cart {
        padding: rem-calc(0);
        background-color: $white;

        .bonus-product-list {
            .bonus-product-line-item {
                // border-top: rem-calc(1) solid #d9d9d9;
            }
        }

        .item-container{
            padding: rem-calc(20);

            .price--tezenis .value {
                font-weight: 700;
            }
            &.shopping-bag-popover{
                padding: 0;
                height: calc(100vh - #{rem-calc(211px)});
                .shopping-bag-title-box{
                    line-height: rem-calc(24);
                    font-size: rem-calc(14);
                    padding: rem-calc(24 40 24 24);
                    position: relative;
                    @include breakpoint (small down) {
                        padding: rem-calc(24 28 24 16);
                    }

                    .title {
                        font-weight: 600;
                    }

                    .shopping-bag-items-number {
                        position: absolute;
                        right: rem-calc(40);
                    }
                }
                .product-summary{
                    height: calc(100vh - #{rem-calc(358)});
                    margin-right: rem-calc(15);
                    margin-top: rem-calc(16);
                    overflow-x: hidden;
                    overflow-y: auto;
                    padding-left: rem-calc(24);
                    padding-right: rem-calc(40);

                    &::-webkit-scrollbar {
                        display: none;
                        width: 0;
                    }
                    .minicart-item:first-child{
                        .card{
                            margin-top: 0;
                            .card-body{
                                padding-top: 0;
                            }
                        }
                    }

                    .discounts-container {
                        padding-left: 0;
                        padding-top: rem-calc(16);
                    }
                    .card-body{
                        .mini-product-item{
                            &.fix-margin-grid{
                                margin-left: rem-calc(-7.5);
                                margin-right: rem-calc(-7.5);
                                .mini-product-item-cell{
                                    margin-left: rem-calc(7.5);
                                    margin-right: rem-calc(7.5);
                                    &.medium-3{
                                        width: calc(25% - 15px);
                                    }
                                    &.medium-9{
                                        width: calc(75% - 15px);
                                    }
                                    .price-second-currency{
                                        margin-top: rem-calc(4);
                                        font-size: rem-calc(12);
                                        line-height: rem-calc(18);
                                        font-weight: 400;

                                        .double-currency-sales{
                                            &.discount-sale {
                                                display: inline-block;
                                                margin-left: rem-calc(4);
                                                color: #D10B82;
                                            }
                                        }
                                        .double-currency-sales, .double-currency-list, .discount-value-percentage{
                                            font-size: rem-calc(12);
                                            line-height: rem-calc(18);
                                            font-weight: 400;
                                        }
                                    }

                                    .product-image {
                                        border-radius: rem-calc(4);
                                        height: rem-calc(114);
                                        width: rem-calc(77);

                                        &.giftcard{
                                            margin-left: auto;
                                            display: flex;
                                            justify-content: center;
                                            overflow: hidden;

                                            img{
                                                height: 100%;
                                                max-width: unset;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .button-continue-pay{
            .checkout-button-circle{
                font-size: rem-calc(14);
                margin-bottom: 0;
                padding: rem-calc(16 32);
            }
        }
        .checkout-button-circle{
            border-radius: rem-calc(40);
        }
    }

    .remove-btn {
        color: $medium-gray;
        float: right;
        background-color: $white;
        border: none;
        font-size: rem-calc(1.625em);
        margin-top: rem-calc(-0.313em);
        padding: 0;
    }

    .product-summary {
        &::-webkit-scrollbar {
            width: rem-calc(8);
        }

        &::-webkit-scrollbar-thumb {
            background-color: #CCCCCC;
            border-radius: rem-calc(5/2);
        }

        .card{
            border:0;
            border-bottom: 1px solid $border-gray;
            padding: 0;
        }
        .no-border-card{
            .card{
                border-bottom: 0;
            }
        }

    }

    .card-body {
        padding: rem-calc(0);
        .product-line-item{
            .giftcard-product-info{
                .giftcard-code{
                    font-size: rem-calc(10);
                    font-weight: 400;
                    color: #949494;
                }
                .giftcard-name{
                    font-size: rem-calc(14);
                    font-weight: 700;
                    letter-spacing: 0.3px;
                    margin: 0;
                }
                .line-item-pricing-info, .line-item-pricing-info-double-currency{
                    margin-bottom: 0;
                    .line-item-total-text, .line-item-total-price-amount{
                        font-size: rem-calc(12);
                        letter-spacing: 0.3px;
                        color: $black;
                        font-weight: 400;
                    }
                }
                .line-item-product-info-taxt{
                    font-size: rem-calc(10);
                    font-weight: 400;
                    color: #949494;
                    margin: rem-calc(30 0 5);
                }
            }
        }
    }

    .price, .quantity-label {
        font-size: rem-calc(15);
        span {
            font-size: rem-calc(14);
            font-weight: 500;
            line-height: rem-calc(21);
            letter-spacing: rem-calc(0.2);
        }
    }

    .quantity {
        width: 100%;
    }
    &.affixed-top{
        @include breakpoint(small down){
            position: unset;
        }
    }
    @include breakpoint(medium up){
        position: relative;
    }
    .popover {
        @include breakpoint(medium up){
            border-bottom: 1px solid $light-gray;
            border-left: 1px solid $light-gray;
            height: calc(100dvh - #{rem-calc(56px)});
            left: auto;
            overflow-y: hidden;
            top: rem-calc(41);
            right: rem-calc(-40);
            width: rem-calc(459);
            background-color: $white;
        }
        &.popover-non-scrollable {
            @include breakpoint(medium up){
                overflow-y: hidden;
            }
        }

        &.sovra-present {
            @include breakpoint(medium up) {
                height: calc(100dvh - #{rem-calc(87px)});
            }
        }

        &.country-selector-present {
            @include breakpoint(medium up) {
                height: calc(100dvh - #{rem-calc(136px)});
            }
        }

        @include breakpoint(small down) {
            display: flex;
            top: rem-calc(0);
            left: rem-calc(0);
            height: 100dvh;
            overflow-y: hidden;
            position: fixed;
            width: 100vw;
            z-index: 1050;
        }
        display: block;
        opacity: 0;
        position: absolute;
        transition: all 0.3s ease;
        transform: translateX(100%);
        visibility: hidden;

        &::before {
            left: auto;
            right: rem-calc(15px);
        }

        &::after {
            left: auto;
            right: rem-calc(16px);
        }

        &.show,
        &.js-minicart-popover:hover {
            visibility: visible;
            opacity: 1;
            transform: translateX(0);
            z-index: 906;
        }

        .closeButton{
            position: absolute;
            right: rem-calc(24);
            top: rem-calc(24);

            @include breakpoint (small down) {
                right: rem-calc(16);
            }

            svg {
                height: rem-calc(24);
                margin-right: rem-calc(0);
                width: rem-calc(24);
            }
        }

        .minicart-item {
            margin-bottom: rem-calc(16);
            .cc-omnibusStack {
                margin: rem-calc(0 0 4 0);
            }

            &:last-of-type {
                margin-bottom: rem-calc(24);
            }
        }

        .omnibus-in-checkout,
        .product-name-cell {
            width: 100%;
            flex: 0 0 auto;
        }

        .price-with-omnibus {
            margin: rem-calc(4 0);

            .sales {
                margin-left: 0;
            }

            span {
                font-weight: 500;
                font-size: rem-calc(14);
                padding-right: rem-calc(4);

                &.price-info {
                    font-size: rem-calc(10);
                }

                &.discount-value-percentage {
                    display: none;
                }
            }

            .cc-CountryWithDifferentTemplate {
                display: inline-flex;
                .value {
                    .cc-value.cc-isOmnibus {
                        order: 1;
                        color: $sale;
                    }
                    .strike-through {
                        order: 0;
                        color: $black;
                    }
                    .discount-value-percentage {
                        order: 2;
                    }
                }
            }
        }
        .feedback-cart{
            @include breakpoint (small down) {
                display: flex;
                flex-direction: column;
                width: 100%;
            }
            .feedback-cart-container {
                overflow: scroll;
                height: calc(100dvh - #{rem-calc(80.31)});
                &::-webkit-scrollbar {
                    display: none;
                    width: 0;
                }

                @include breakpoint(medium up) {
                    height: calc(100dvh - #{rem-calc(284.31)});
                }

                .crosssell-view .price-container {
                    text-align: left;
                }
                
                .discount-value-percentage.cc-partiallyShownCountry {
                    display: inline-block;
                }
                .cc-CountryWithDifferentTemplate { 
                    display: inline-flex;
                    .value{
                        .cc-value.cc-isOmnibus {
                            order: 0;
                            color: $black;
                            padding-right: rem-calc(8);
                        }
                        .strike-through {
                            order: 1;
                            color: $off-white;
                        }
                        .discount-value-percentage {
                            order: 2;
                            display: inline-block;
                        }
                    }
                }
                .crosssell-view .cc-CountryWithDifferentTemplate .value {
                    .discount-value-percentage {
                        margin-left: rem-calc(4);
                    }
                }
            }
            .feedback-cart-ctas {
                position: sticky;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: $white;
                @include breakpoint (small down) {
                    background-color: $white;
                    width: 100vw;
                    bottom: 0;
                }
            }
            .item-container{
                padding: rem-calc(16 40 24 24);
                @include breakpoint(small down){
                    padding: rem-calc(16 16 24 16);
                }
                .product-summary{
                    padding: 0;
                    margin: 0;
                }
                .product-line-item{
                    .fix-margin-grid{
                        margin-left: rem-calc(-7.5);
                        margin-right: rem-calc(-7.5);
                        .mini-product-cell{
                            margin-left: rem-calc(7.5);
                            margin-right: rem-calc(7.5);
                            &.small-3{
                                width: calc(25% - 15px);
                            }
                            &.medium-9{
                                width: calc(75% - 15px);
                            }
                            .line-item-added{
                                margin-bottom: rem-calc(8);
                            }
                            .product-image {
                                border-radius: rem-calc(4);
                            }
                        }
                    }
                    .line-item-added.promotion{
                        svg{
                            width: rem-calc(13);
                            margin: rem-calc(0 4 0 0);
                        }
                    }
                    .line-item-product-info-taxt{
                        font-size: rem-calc(10);
                        font-weight: 400;
                        color: #949494;
                        margin: rem-calc(30 0 5);
                        @include breakpoint (medium down){
                            margin: rem-calc(6 0 5);
                        }
                    }
                }
            }
            .estimated-total{
                .sub-total-label{
                    .iva-text{
                        font-size: rem-calc(14);
                        font-weight: 500;
                        line-height: rem-calc(21);
                        letter-spacing: rem-calc(0.2);
                    }
                }
            }
        }

        .discounts-container {
            padding: rem-calc(16 40 16 24);

            @include breakpoint (small down) {
                padding: rem-calc(16);
            }
        }

        .button-continue-pay{
            padding: rem-calc(24 40 24 24);

            @include breakpoint (small down) {
                padding: rem-calc(16);
            }
        }

        .suggestedProductsContainer--tezenis {
            margin-top: 0;
            padding: rem-calc(16 0 24 24);
            position: relative;

            .suggested-products-default-title, .suggested-products-promo-title {
                font-size: rem-calc(14);
                font-weight: $global-weight-dark;

                span {
                    font-size: rem-calc(14);
                    font-weight: $global-weight-dark;
                }
            }
            .carousel-header {
                align-items: baseline ;
                padding-right: rem-calc(40);
            }

            .carousel-header__button-wrapper {
                gap: rem-calc(16);
            }

            .slick-arrow {
                svg {
                    height: rem-calc(20);
                    width: rem-calc(20);
                }
            }
        }

        #crossSellContainerMinicart {
            .product {
                margin-bottom: rem-calc(14);

                @include breakpoint (small down) {
                    margin-bottom: rem-calc(4);
                }

                .wishlistTile, .swatches {
                    display: none;
                }

                .pdp-link__title {
                    font-size: rem-calc(12);
                    font-weight: 400 ;
                    line-height: rem-calc(18) ;
                    letter-spacing: rem-calc(0.2);
                    margin-bottom: rem-calc(4);
                }

                .price {
                    .price-span {
                        span {
                            font-size: rem-calc(12);
                            font-weight: 500;
                            line-height: rem-calc(18) ;
                            letter-spacing: rem-calc(0.2);
                        }
                    }
                }
            }
            .crosssell-view {
                margin-right: rem-calc(8);

                @include breakpoint (small down) {
                    margin-right: rem-calc(4);
                }
            }

            .slick-arrow {
                svg {
                    height: rem-calc(20);
                    width: rem-calc(20);
                }
            }
        }
    }
    
    .cc-salesWrapper {
        display: inline-flex;
        .value {
            .cc-value.cc-isOmnibus {
                order: 1;
                color: $sale;
            }
            .strike-through {
                order: 0;
                color: $black;
            }
            .discount-value-percentage {
                order: 2;
            }
        }
    }

    @include breakpoint (small down) {
        &.open {
            position: fixed;
            display: block;
            width: 100%;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 3000;
            h6{
                font-weight: $global-weight-dark;
            }
            background-color: transparent;
        }

    }
    /*
    .minicart-footer {
        border-top: 1px solid $medium-gray;
    }
    */

    .estimated-total {
        border-bottom: 1px solid $light-gray;
        border-top: 1px solid $light-gray;
        padding: rem-calc(16 40 16 24);
        user-select: none;

        @include breakpoint (small down) {
            padding: rem-calc(16);
        }
    }

    .sub-total-label {
        font-size: rem-calc(14);
        font-weight: 500;
        line-height: rem-calc(21);
        letter-spacing: rem-calc(0.2);
        margin: 0;
        .text-light{
            font-weight: 300;
        }
    }

    .sub-total {
        font-size: rem-calc(14);
        font-weight: 500;
        line-height: rem-calc(21);
        letter-spacing: rem-calc(0.2);
        margin: 0;
    }
    .sub-total-double-currency{
        font-size: rem-calc(12);
        font-weight: 400;
        line-height: rem-calc(14);
        margin: 0;
    }

    .line-item-divider {
        margin: rem-calc(0.625em) rem-calc(-0.625em) rem-calc(0.625em) rem-calc(-1.325em);
    }

    .line-item-name {
        width: 90%;
    }

    .checkoutButtons {
        a {
            width: 100%;
            min-width: 100%;
        }
    }
}

.line-item-promo {
    .promo-pill{
        color: #FF6699;
        border: 1px solid #FF6699;
        border-radius: rem-calc(40);
        font-size: rem-calc(10);
        font-weight: 700;
        padding: rem-calc(3 10);

        &.promo-pill-free {
            background: linear-gradient(90deg, rgba(255,102,153,1) 0%, rgba(79,156,158,1) 100%);
            color: #fff;
            border: unset;
        }

        &.promo-pill-sales{
            background: #EE7199;
            color: #fff;
            border: unset;
        }

        &.promo-pill-employees {
            background: #4A9E9E;
            color: #fff;
            border: unset;
        }
    }
}

.margin-attribute-top{
    margin-top:rem-calc(0);
}

.text-attributes-product-mini{
    color: $black;
    font-weight: 400;
    font-size: rem-calc(14);
    line-height: rem-calc(21);
    letter-spacing: rem-calc(0.2);
}

.line-item-pricing-info{
    margin-bottom: rem-calc(4);
    .price{
        font-size: rem-calc(14);
        font-weight: 500;
        letter-spacing: rem-calc(0.2);

        .strong {
            font-weight: 700;
        }
    }

    .discount-percentage {
        color: $sale;
        display: inline-block;
        font-size: rem-calc(14) ;
        font-weight: 500;
        line-height: rem-calc(21) ;
        letter-spacing: rem-calc(0.2) ;
        margin-left: rem-calc(4);
    }
}

.line-item-pricing-info-double-currency{
    margin-bottom: rem-calc(4);
    .price{
        .value{
            font-size: rem-calc(12);
            line-height: rem-calc(14);
            font-weight: 400;
        }
    }

    .discount-percentage {
        color: $sale;
        display: inline-block;
        font-size: rem-calc(12);
        line-height: rem-calc(14);
        font-weight: 400;
        letter-spacing: rem-calc(0.2) ;
        margin-left: rem-calc(4);
    }
}

.price-second-currency{
    margin-top: rem-calc(4);
    font-size: rem-calc(12);
    line-height: rem-calc(18);
    font-weight: 400;

    .double-currency-sales{
        &.discount-sale {
            display: inline-block;
            margin-left: rem-calc(4);
            color: #D10B82;
        }
    }
    .double-currency-sales, .double-currency-list, .discount-value-percentage{
        font-size: rem-calc(12);
        line-height: rem-calc(18);
        font-weight: 400;
    }
}

/*
.small-margin-bottom{
    margin-bottom: rem-calc(10);
}
*/

.bonus-product-list {
    .bonus-product-line-item {
        padding-top: rem-calc(16);
    }
}

.miniCartCrossSellContainer {
    position: relative;
    padding: rem-calc(16 40 16 24);
    &-header {
        padding-bottom: rem-calc(30);
        width: 100%;
        &-title {
            font-size: rem-calc(14);
            font-weight: 500;
            line-height: rem-calc(21);
            letter-spacing: rem-calc(0.2);
        }
    }
    &-carousel {
        .product {
            padding: rem-calc(0 8);
            .productDescription {
                margin-bottom: 0;
                padding: rem-calc(18 4 0);
                @include flexGenerator(column);
                flex-grow: 0;
                &-name {
                    .pdp-link .link {
                        white-space: normal;
                        font-size: rem-calc(14);
                        line-height: rem-calc(20);
                    }

                    margin-bottom: rem-calc(8);
                }
                &-priceRow {
                    margin-bottom: rem-calc(16);
                    * {
                        line-height: rem-calc(18) !important;
                        letter-spacing: .6px !important;
                    }
                    .span-price-row-plp {
                        @include flexGenerator(row, flex-start, center);
                    }

                    .strike-through.list, .percentage {
                        @include breakpoint (small down) {
                            margin-left: rem-calc(4);
                            font-size: rem-calc(12);
                        }
                    }
                }
            }
        }
        .price-second-currency{
            font-size: rem-calc(12);
            font-weight: 400;
        }
        .arrow-minicart{
            margin-left: rem-calc(20);
        }
        .slick-arrow {
            top: rem-calc(-29);
            left: unset;
            &:before {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
            }
            &.slick-prev {
                right: rem-calc(31.6);
            }
            &.slick-next {
                right: 0;
            }
            &.slick-disabled{
                opacity: 0.5;
            }
        }

        .price-info-pdp-container {
            @include breakpoint (small down) {
                font-size: rem-calc(10);
                line-height: rem-calc(14);
            }
        }
    }
    +.estimated-total-eu {
        border-top: unset;
    }
    .quickview-icon {
        display: none !important;
    }
    .miniCartCrossSellContainer-carousel{
        margin: rem-calc(0 -4);
        width: calc(100% + 8px);
    }
    .prev-button, .next-button{
        cursor: pointer;
    }
    .next-button{
        margin-left: rem-calc(16);
    }
    .promo-animated-strip {
        display: none;
    }
}

.discounts-container {
    border-top: 1px solid $light-gray;
    font-size: rem-calc(12);
    font-weight: 500;
    line-height: rem-calc(18);
    letter-spacing: rem-calc(0.2);
    padding: rem-calc(20 40 30 24);
    text-align: left;

    @include breakpoint (small down) {
        padding: rem-calc(20 16 16 16);
    }

    .promo {
        white-space: nowrap;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;
        gap: rem-calc(6);

        &.approaching{
            .label-cta {
                background-color: $light-gray;
                color: $medium-gray;
            }

        }
        &.applied{
            .label-cta {
                background-color: $water-green-light;
                color: $black;
            }
        }

        &.promotion {
            .label-cta {
                background-color: $promotion;
                color: $white;
            }
        }

        &.applicable, &.banner-add-bonus {
            .label-cta {
                background-color: $light-gray;
                color: $medium-gray;
            }
        }

        .label-cta{
            font-size: rem-calc(12);
            font-weight: 500;
            line-height: rem-calc(18);
            letter-spacing: rem-calc(0.2);
            padding: rem-calc(4 12);
            margin-bottom: 0;
        }
    }
}

.cc-minicartPromoModal.popover {
    top: rem-calc(57);
    height: calc(100% - #{rem-calc(57)});

    .cc-minicartFeedback {
        &-items {
            display: none;
        }
        &-promo {
            border: 0;
            padding: rem-calc(16 16 8);
            .promo {
                white-space: unset;
            }
            .label-cta {
                flex: 0 0 auto;
            }
        }
        &-carousel {
            border: 0;
            padding-left: rem-calc(16);
            padding-top: 0;

            &-title {
                margin: rem-calc(16 0);
                &, &-h6 {
                    line-height: rem-calc(18);
                }
            }
        }
    }
}

.cc-omnibusStack {
    display: flex;
    flex-direction: column;
    gap: rem-calc(2);
    font-weight: $global-weight-normal;
    text-align: left;
    margin-top: rem-calc(4);
    @include breakpoint (large up) {
        margin-top: rem-calc(8);
    }

    &-item {
       color: $medium-gray;
    }

    &-item,
    &-text {
        font-size: rem-calc(10);
        line-height: rem-calc(15);
    }

    &-text {
        display: inline;
        white-space: nowrap;
    }

    &-strikeThrough {
        text-decoration: line-through;
    }

    &-sales {
        color: $sale;
    }
}

