.viewport {
    width: 100%;
    height: 100%;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
}


 .fontH2-responsive{
     @include breakpoint(small only) {
        font-size: rem-calc(24);
    }
    
    @include breakpoint(medium up) {
        font-size: rem-calc(30);
    }
}
.fontH2-responsive>h1{
    font-weight: 700;
}

.fontH5-responsive{
    @include breakpoint(small only) {
        font-size: rem-calc(14);
    }

    @include breakpoint(medium up) {
        font-size: rem-calc(18);
    }
}

.extra-padding-left {
    padding: 1.5rem 4px 1.5rem 0
}
.extra-padding-right {
    padding: 1.5rem 0 1.5rem 4px
}
.box {
    position: relative;
    padding: 1.5rem 0;
    color: #0a0a0a;
}
.bottom-wrapper {
    position: absolute;
    left: 2.5rem;
    right: 0;
    bottom: 2rem;
}
.white {
    color: white;
}
.wrapper-bacground {
    background-repeat: repeat;
}
.container1 {
    background-position: top;
}
.container2 {
    background-position: top;
}

.box-wrapper-image{
    background-repeat: repeat; 
    background-size: cover;
}

.no-border{
    border: none;
}

.text-alignment{
    text-align: -webkit-right;
}

.img-banner{
    object-position: 50% 50%; 
    width: 100%; 
    height: 100%; 
    object-fit: cover; 
    overflow: hidden;
}

.video-banner{
    object-position: 50% 50%; 
    object-fit: cover; 
    overflow: hidden;
}

.h2-options{
    font-weight: 700; 
    margin-right: rem-calc(30);
    line-height: 1.2;
    filter: drop-shadow(0px 3px 6px #00000029);
    @include breakpoint(small only) {
        margin-bottom: rem-calc(10);
    }
    
    @include breakpoint(medium up) {
        margin-bottom: rem-calc(20);
    } 
}

.h5-options{
    font-weight: 600; 
    margin-bottom: 0px;
    margin-right: 30px;
    line-height: 1.2;
    filter: drop-shadow(0px 3px 6px #00000029);
}
.left-align{
  text-align: left;
}

.full-dimensions{
    width:100%;
    height:100%;
}