.video-gallery-slider-component {
    position: relative;
    .pd-video-gallery-container {
        height: rem-calc(490);
        @include breakpoint(small only) {
            height: auto;
        }

        .pd-video-slide {
            padding: rem-calc(50 0);
            width: 100%;
            max-width: rem-calc(700);
            height: inherit;
            @include breakpoint(small only) {
                padding: 0;
            }
        }
    }

    .video-gallery-container {
        position: relative;
    }

    .video-text-wrapper-custom {
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;
        top: 35%;
        height: fit-content;

        .video-btns-custom {
            display: flex;
            margin: 0 auto;
            width: rem-calc(700);
            justify-content: space-evenly;
            @include breakpoint(small only) {
                display: flex;
                margin: 0 auto;
                flex-direction: column;
                align-items: center;
                width: auto;
            }
        }

        .text-box-slide {
            @include breakpoint(small only) {
                display: inline-flex;
            }
        }

        .text-box-slide {
            .link-wrapper {
                transform: translateY(50%);
                width: rem-calc(240);
                margin: rem-calc(0);
                height: rem-calc(40);
                border-radius: rem-calc(50);
                border: rem-calc(2) solid;
                box-shadow: rem-calc(3 3 10) #00000029;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                &:hover {
                    cursor: pointer;
                }

                &:hover {
                    a.custom-video-link{
                        span {
                            transform: translateY(-103%);
                        }
                    }
                }
            }

            .custom-video-link {
                padding: 0;
                overflow: hidden;
                span {
                    position: relative;
                    display: inline-block;
                    transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
                    &:before {
                        position: absolute;
                        top: 100%;
                        content: attr(data-hover);
                        transform: translate3d(0, 0, 0);
                        @include breakpoint(medium down) {
                            display: none;
                        }
                    }
                }
            }
        }

        a {
            &:hover {
                border-color: none;
                border: 0;
            }
        }


        .h2-video-custom {
            font-size: rem-calc(40);
            font-weight: bold;
            color: #fff;
            filter: drop-shadow(0px 3px 6px #00000029);
            @include breakpoint(medium down) {
                font-size: rem-calc(30);
            }
            @media screen and(max-width: 630px) {
                font-size: rem-calc(30);
            }
            @media screen and(max-width: 1024px) {
                font-size: rem-calc(30);
            }
        }

        .h4-video-custom {
            font-size: rem-calc(20);
            font-weight: 600;
            color: #fff;
            filter: drop-shadow(0px 3px 6px #00000029);
            margin-bottom: rem-calc(0);
            @include breakpoint(medium down) {
                font-size: rem-calc(20);
            }
        }
    }

    .show-medium-custom {
        @media screen and(min-width: 630px) {
            display: block !important;
        }
    }
}

.video-gallery-slides {
    max-width: rem-calc(700);
    width: 100%;
    margin: 0 auto;
    .slick-dots {
        position: relative;
        bottom: rem-calc(15);
        height: rem-calc(130);
        width: rem-calc(633);
        margin: 0 auto;
        @include breakpoint(medium down) {
            width: 82%;
            bottom: rem-calc(-5);
            margin: rem-calc(0 0 0 41);
        }
        .slick-list {
            height: rem-calc(300);
            @include breakpoint(medium down) {
                padding-left: rem-calc(10);
                margin-left: rem-calc(-14);
            }

            .slick-track {
                li:first-child:nth-last-child(3),
                li:first-child:nth-last-child(3) ~ li {
                    @include breakpoint(medium down) {
                        margin-top: rem-calc(25);
                    }
                }
            }
        }
        .slick-track {
            display: flex;
            max-width: max-content!important;
            width: max-content!important;
        }
        .slick-next {
            @include breakpoint(medium down) {
                right: -2rem;
            }
        }
        li, .slick-active  {
            button {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                background-size: contain;
                display: flex;
                &::before {
                    width: rem-calc(105);
                    height: rem-calc(105);
                    border: none;
                    background: transparent;
                    @include breakpoint(medium down) {
                        width: rem-calc(91);
                        height: rem-calc(91);
                    }
                }

                .dott-button-text {
                    color: $black;
                    text-transform: uppercase;
                    font-size: rem-calc(10);
                    margin-top: rem-calc(115);
                    width: rem-calc(112);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    @include breakpoint(medium down) {
                        margin-top: rem-calc(95);
                        width: rem-calc(78);
                    }
                }
            }
        }

        li {
            width: rem-calc(105)!important;
            height: rem-calc(105);
            margin-right: rem-calc(20);
            @include breakpoint(medium down) {
                width: rem-calc(91)!important;
                height: rem-calc(91);
                margin-right: rem-calc(14);
            }
        }
        li:nth-child(1){
            @include breakpoint(medium down) {
                margin-left: rem-calc(-2);
            }
        }
        li:nth-child(3){
            @include breakpoint(medium down) {
                margin-right: rem-calc(-2);
                margin-left: 0;
            }
        }
        li:nth-child(4){
            @include breakpoint(medium down) {
                margin-left: rem-calc(13);
                margin-right: rem-calc(13);
            }
        }


    }
}

.video-gallery-layout {
    height: rem-calc(659);
    background: transparent;
}


.pd-video-gallery-container-mobile:not(.video-has-controls) ~ a.image-link-cta {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
}
.pd-video-gallery-container:not(.video-has-controls) ~ a.image-link-cta {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
}
