strong,
.strong {
    font-weight: $global-weight-bold;
}

.dark {
    font-weight: $global-weight-dark;
}
.very-bold {
    font-weight: 700;
}

h1,
.h1,
h2,
.h2 {
    letter-spacing: rem-calc(0);
}

h3,
.h3,
h4,
.h4 {
    letter-spacing: rem-calc(0);
}

h5,
.h5,
h6,
.h6 {
    letter-spacing: 0;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4
 h5,
.h5 {
    &.inline {
        display: inline;
    }
}
/*
.font-secondary {
    font-family: $body-font-family-cursive;
}
*/
/*
.font-cursive {
    font-family: $body-font-family-cursive;
    font-style: italic;
}
*/

.uppercase {
    text-transform: uppercase;
}

.lowercase {
    text-transform: lowercase;
}

.capitalize {
    text-transform: capitalize;
}

.underline {
    text-decoration: underline;
    text-underline-offset: rem-calc(5);
}

.no-underline {
    text-decoration: none;
}

address {
    font-style: normal;
}

p,
span,
a,
button,
.button,
label,
input,
dl {
    letter-spacing: rem-calc(0.2);

    &.verysmall {
        font-size: rem-calc(8);
    }

    &.small {
        font-size: rem-calc(11);
    }

    &.compact {
        font-size: rem-calc(12);
    }
    
    &.regular {
        font-size: rem-calc(13);
    }
    
    &.middle {
        font-size: rem-calc(14);
    }

    &.medium {
        font-size: rem-calc(16);
    }

    &.big {
        font-size: rem-calc(20);
    }

    &.huge {
        font-size: rem-calc(30);
    }

    &.light {
        font-weight: $global-weight-normal;
    }

    &.no-margin {
        margin: 0;
    }

    &.small-margin {
        margin-bottom: $paragraph-margin-bottom / 2;
    }
}

caption,
.caption {
    text-transform: uppercase;
    font-size: $label-font-size;
}

a {
    font-weight: $global-weight-bold;
    border-bottom: 1.5px solid transparent;
    transition: .25s cubic-bezier(.25,.46,.45,.94);


    // Hover state
    &:not(.no-hover):hover,
    &.force-hover {
        border-color: $dark-gray;
    }

    &.underlined-link{
        border-color: $dark-gray;

        &:not(.no-hover):hover{
            border-color: transparent;
        }
    }
}

.cc-terms-conditions{
    margin-left: rem-calc(5);
}

// generate colored links
@mixin brand-links($palette) {
    a {
        @each $color, $hex in $palette {
            &.text-#{$color} {
                color: $hex;

                // Hover state
                &:not(.no-hover):hover,
                &.force-hover {
                    border-color: $hex;
                }
            }
        }
    }
}

p {
    a {
        display: inline;
        border: none;
        text-decoration: underline;
    }
}

.strike-through {
    text-decoration: line-through;
}

a.disabled,
.disabled > a {
    color: $medium-gray;
    text-decoration: line-through;
    cursor: not-allowed;
}

// generate colored paragraphs
@mixin brand-text($palette) {
    div, p, span, h1, h2, h3, h4, h5, i, label, input {
      @each $color, $hex in $palette {
            &.text-#{$color} {
                color: $hex;
            }
        }
    }
}
