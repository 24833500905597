@import "../setup/settings";

@mixin text-box-slide {
  .text-box-slide {
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    h1, h3 {
      color: $white;
      font-size: rem-calc(60);
      font-weight: 600;
      line-height: 1;
      letter-spacing: 0.03em;
      text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.16);
      margin-bottom: rem-calc(17);
      display: block;
      @include breakpoint (medium down) {
        font-size: rem-calc(32);
      }
    }
    h4 {
      color: $white;
      font-size: rem-calc(20);
      font-weight: 600;
      line-height: 1;
      letter-spacing: 0.03em;
      text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      margin-bottom: rem-calc(5);
      display: block;
      @include breakpoint (medium down) {
        font-size: rem-calc(17);
      }
    }
    .link-wrapper {
      display: inline-block;
      transform: translateY(50%);
      padding: rem-calc(12 62);
      border-radius: rem-calc(25);
      box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.16);
      @include breakpoint (medium down) {
        padding: rem-calc(9 35);
      }
      &:hover {
        a.button {
          border-color: transparent;
          span {
            transform: translateY(-103%);
          }
        }
      }
    }
    a.button {
      margin-bottom: 0;
      font-size: rem-calc(14);
      font-weight: bold;
      line-height: rem-calc(18);
      padding: 0;
      overflow: hidden;
      @include breakpoint (medium down) {
        font-size: rem-calc(13);
        line-height: 1;
        overflow: auto;
        border-bottom: none;
        padding: rem-calc(4 0);
      }
      span {
        position: relative;
        display: inline-block;
        transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        &:before {
          position: absolute;
          top: 100%;
          content: attr(data-hover);
          transform: translate3d(0, 0, 0);
          @include breakpoint (medium down) {
            display: none;
          }
        }
      }
    }
  }
}

@mixin image-link {
  .image-link {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border: none;
  }
}

@mixin slick-arrows {
  .prev-button,
  .next-button {
    outline: transparent;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    padding: 18px;
    background: rgba(255, 255, 255, 0.3);
    cursor: pointer;
    transition: all .2s ease;
    @include breakpoint (medium down) {
      display: none;
    }
    &.transparent {
      background: rgba(255, 255, 255, 0);
    }
    svg {
      width: auto;
      height: auto;
    }
  }
  .prev-button {
    left: 0;
    padding: rem-calc(18 15 18 7);
    border-top-right-radius: rem-calc(20);
    border-bottom-right-radius: rem-calc(20);
    @include breakpoint (medium down) {
      padding: rem-calc(9 11 9 6);
    }
  }
  .next-button {
    right: 0;
    padding: rem-calc(18 7 18 15);
    border-top-left-radius: rem-calc(20);
    border-bottom-left-radius: rem-calc(20);
    @include breakpoint (medium down) {
      padding: rem-calc(9 6 9 11);
    }
  }
}

#home-page {
  @include breakpoint (medium down) {
    overflow: hidden;
  }
  > .cell {
    @include breakpoint (medium down) {
      display: flex;
      flex-direction: column;
    }
    .experience-layouts-storiesSlider {
      @include breakpoint (medium down) {
        order: -1;
      }
    }
  }
}

.hero-banner {
  &-full--tezenis,
  &-grid--tezenis {
    * {
      outline: none;
    }
    overflow: hidden;
    position: relative;
    //margin-bottom: rem-calc(60);
    margin-bottom: 0;
    @include breakpoint (medium down) {
      margin-top: rem-calc(5);
    }
    .slick-dots{
      bottom: rem-calc(20);
      right: rem-calc(25);
      display: inline-block;
      width: auto;
      li {
        width: rem-calc(10);
        height: rem-calc(10);
        margin: rem-calc(0 5);
        button {
          &:before {
            width: rem-calc(10);
            height: rem-calc(10);
            border: none;
            background: rgba(255, 255, 255, 0.4);
          }
        }
        &.slick-active {
          button {
            &:before {
              width: rem-calc(10);
              height: rem-calc(10);
              border: 1px solid transparent;
              background: rgba(255, 255, 255, 1);
            }
          }
        }
      }
    }
    .slick-list {
      overflow: visible;
    }
    .scrollingTextWrap {
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      .scrollWrap {
        overflow: hidden;
        position: relative;
        height: 76px;
        padding: 0% 0 0% 0;
        .scrollInner {
          display: block;
          width: 450%;
          position: absolute;
          overflow: hidden;
          animation: marquee 26s linear infinite;
          @include breakpoint (medium down) {
            animation: marquee 16s linear infinite;
          }

          @keyframes marquee {
            0% {
              left: 0;
            }
            100% {
              left: -100%;
            }
          }

          span {
            float: left;
            font-size: rem-calc(60);
            font-weight: 600;
            letter-spacing: 0.03em;
            line-height: rem-calc(76);
            text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            @include breakpoint (medium down) {
              font-size: rem-calc(30);
              line-height: rem-calc(50);
              height: rem-calc(52);
            }
            &:nth-of-type(2),
            &:nth-of-type(3),
            &:last-of-type {
              @include breakpoint (medium down) {
                display: none;
              }
            }
          }
        }
      }
    }
    .hero-banner-slide--tezenis {
      position: relative;
      z-index: 2;
      @include breakpoint (medium down) {
        margin-bottom: rem-calc(44);
      }
      img {
        width: 100%;
      }
      @include image-link;
      @include text-box-slide;
      .image-link {
        background: linear-gradient(0deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 50%);
      }
    }
    .marquee-wrapper {
      position: relative;
      & > marquee {
        z-index: 1;
      }
      & > .hero-banner-slide--tezenis {
        z-index: auto;
      }
      .shadow-box {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(0deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 50%);
      }
    }
  }
  &-grid--tezenis {
    .hero-banner-slide--tezenis {
      margin-bottom: rem-calc(23);
      z-index: auto;
    }
    .marquee-wrapper {
      @include breakpoint (medium down) {
        position: static;
      }
    }
  }
}

.hero-banner-grid--tezenis {
  * {
    outline: none;
  }
  @include breakpoint (medium down) {
    margin-bottom: 0;
  }
}

.stories-slider--tezenis {
  * {
    outline: none;
  }
  text-align: center;
  padding: 0 10%;
  margin-bottom: 0;
  width: 100%;
  @include breakpoint (medium down) {
    padding: 0;
  }
  @include breakpoint (small down) {
    padding-top: rem-calc(9);
    padding-bottom: rem-calc(9);
  }
  h3 {
    font-size: rem-calc(30);
    font-weight: 600;
    letter-spacing: 0.03em;
    line-height: rem-calc(40);
    text-align: center;
    margin-bottom: rem-calc(22);
    @include breakpoint (small down) {
      display: none;
    }
  }
  .slick-prev {
    left: rem-calc(-90);
    @include breakpoint (large down) {
      left: rem-calc(-50);
    }
    @include breakpoint (medium down) {
      display: none;
    }
  }
  .slick-next {
    right: rem-calc(-90);
    @include breakpoint (large down) {
      right: rem-calc(-50);
    }
    @include breakpoint (medium down) {
      display: none;
    }
  }
  .experience-assets-storiesSlide {
    .story-slide
    {
      margin-left: rem-calc(22);
    }
  }
  .story-slide {
    position: relative;
    margin-top: rem-calc(27);
    margin-bottom: rem-calc(16);
    @include breakpoint (small down) {
      margin-top: 0;
      margin-bottom: 0;
    }
    &:hover {
      .story-slide__text {
        h4 {
          &:after {
            transform: scaleX(1);
            @include breakpoint (small down) {
              display: none;
            }
          }
        }
      }
    }
    &__link {
      border: 0px solid transparent;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
    img {
      margin: 0 auto 8px;
      border-radius: 50%;
      width: rem-calc(60);
      height: rem-calc(60);

      @include breakpoint (small down) {
        margin: 0 auto rem-calc(6);
      }
    }
    &__text {
      display: flex;
      justify-content: center;
      padding: 0 0.5rem;
      @include breakpoint (xlarge down) {
        padding: 0 0.5rem;
      }
      @include breakpoint (small down) {
        padding: 0;
      }
        h4 {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          color: $black;
          font-weight: 500;
          font-size: rem-calc(12);
          letter-spacing: 0.03em;
          line-height: rem-calc(30);
          text-align: center;
          text-transform: uppercase;
          margin-bottom: 0;
          display: inline-block;
          position: relative;
          @include breakpoint (small down) {
            font-size: rem-calc(10);
            font-weight: 600;
            line-height: rem-calc(20);
          }
          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: rem-calc(2);
            background-color: $black;
            transform: scaleX(0);
            transform-origin: bottom left;
            transition: all .2s ease;
          }
          @include breakpoint (ipad down) {
            font-size: rem-calc(10);
            line-height: rem-calc(20);
            letter-spacing: 0.02em;
            margin: 0 auto;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: rem-calc(68);
            &:after {
              content: none;
            }
          }
        }
    }
  }
}

.images-and-ctas {
  * {
    outline: none;
  }
  //margin-bottom: rem-calc(90);
  margin-bottom: 0;
  @include breakpoint (medium down) {
    //margin-bottom: rem-calc(80);
    margin-bottom: 0;
  }
  &__wrapper {
    display: flex;
    margin-left: rem-calc(-10);
    margin-right: rem-calc(-10);
    > div {
      &:not(.text-box-slide) {
        flex: 1;
        margin: rem-calc(0 10 22);
        position: relative;
        @include breakpoint (small down) {
          margin: 0 50px;
        }
        .shadow-box {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background: linear-gradient(0deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 50%);
        }
        img {
          width: 100%;
          @include breakpoint (medium down) {
            margin-top: rem-calc(45);
            margin-bottom: rem-calc(90);
          }
        }
        .slick-slide {
          position: relative;
          @include breakpoint (medium down) {
            margin-bottom: rem-calc(33);
          }
        }
        @include image-link;
        @include text-box-slide;
        .text-box-slide {
          h3 {
            font-size: rem-calc(32);
            font-weight: 600;
            line-height: rem-calc(36);
            letter-spacing: 0;
            margin-bottom: rem-calc(5);
            @include breakpoint (medium down) {
              font-size: rem-calc(22);
              letter-spacing: 0.03em;
              line-height: rem-calc(32);
              color: $black;
              flex: 1;
            }
          }
          h4 {
            font-size: rem-calc(20);
            font-weight: 500;
            text-transform: uppercase;
            line-height: rem-calc(36);
            margin-bottom: rem-calc(15);
            @include breakpoint (medium down) {
              font-size: rem-calc(20);
              letter-spacing: 0.03em;
              line-height: rem-calc(28);
              color: $black;
              margin-bottom: 0;
            }
          }
          > a {
            @include breakpoint (medium down) {
              margin: 0 auto;
              padding-left: rem-calc(45);
              padding-right: rem-calc(45);
            }
          }
          @include breakpoint (medium down) {
            bottom: auto;
            left: auto;
            transform: none;
            top: 0;
            height: 100%;
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
    .slick-dots{
      li {
        @include breakpoint (medium down) {
          width: rem-calc(10);
          height: rem-calc(10);
          margin: rem-calc(0 5);
        }
        button {
          &:before {
            @include breakpoint (medium down) {
              width: rem-calc(10);
              height: rem-calc(10);
            }
          }
        }
        &.slick-active {
          button {
            &:before {
              @include breakpoint (medium down) {
                width: rem-calc(10);
                height: rem-calc(10);
              }
            }
          }
        }
      }
    }
  }
  &--two {
    @include breakpoint (medium down) {
      margin-bottom: rem-calc(22);
    }
    &.grid-container {
      @include breakpoint (medium down) {
        padding-left: 0;
        padding-right: 0;
      }
    }
    .images-and-ctas__wrapper {
      @include breakpoint (medium down) {
        display: block;
        margin-left: 0;
        margin-right: 0;
      }
      > div {
        @include breakpoint (medium down) {
          margin: 0;
        }
        &:first-of-type {
          @include breakpoint (medium down) {
            margin-bottom: rem-calc(40);
          }
          .text-box-slide {
            @include breakpoint (medium down) {
              padding: rem-calc(0 55 0 15);
            }
            a.button {
              @include breakpoint (medium down) {
                margin-left: 0;
              }
            }
          }
        }
        &:last-of-type {
          @include breakpoint (medium down) {
            text-align: right;
          }
          .text-box-slide {
            @include breakpoint (medium down) {
              padding: rem-calc(0 15 0 55);
              text-align: right;
            }
            .link-wrapper {
              @include breakpoint (medium down) {
                margin-left: auto;
                margin-right: 0;
                width: 100%;
              }
            }
            a.button {
              @include breakpoint (medium down) {
                margin-right: 0;
              }
            }
          }
        }
        img {
          @include breakpoint (medium down) {
            margin-top: 0;
            margin-bottom: 0;
            width: calc(100% - 40px);
          }
        }
        .text-box-slide {
          max-width: 45%;
          text-align: left;
          transform: none;
          left: rem-calc(70);
          bottom: rem-calc(70);
          @include breakpoint (medium down) {
            max-width: none;
            height: auto;
            top: auto;
            left: 0;
            bottom: 0;
            transform: translateY(18px);
          }
          h2 {
            font-weight: 600;
            font-size: rem-calc(38);
            line-height: rem-calc(48);
            letter-spacing: 0.03em;
            color: $white;
            text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            margin-bottom: rem-calc(10);
            @include breakpoint (medium down) {
              font-size: rem-calc(26);
              line-height: rem-calc(34);
            }
          }
          h4 {
            font-weight: 600;
            font-size: rem-calc(19);
            line-height: rem-calc(30);
            letter-spacing: 0.03em;
            color: #fff;
            text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            margin-bottom: rem-calc(20);
            @include breakpoint (medium down) {
              font-size: rem-calc(17);
              line-height: rem-calc(25);
            }
          }
          .link-wrapper {
            @include breakpoint (medium down) {
              transform: translateY(0);
              text-align: center;
              max-width: rem-calc(200);
              padding-left: 0;
              padding-right: 0;
            }
          }
          a.button {
            transform: none;
          }
        }
      }
    }
  }
  &--two--full {
    position: relative;
    z-index: 2;
    margin-bottom: 0;
    @include breakpoint (medium down) {
      margin-bottom: rem-calc(45);
    }
    > h1 {
      position: absolute;
      font-size: rem-calc(60);
      font-weight: 600;
      line-height: 1;
      color: #fff;
      text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      bottom: rem-calc(50);
      display: block;
      text-align: center;
      z-index: 2;
      margin-bottom: 0;
      width: 40%;
      left: 50%;
      transform: translateX(-50%);
      letter-spacing: 0.03em;
      @include breakpoint (medium down) {
        width: 85%;
        font-size: rem-calc(30);
        line-height: rem-calc(40);
        bottom: rem-calc(100);
      }
    }
    .shadow-box {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
      background: linear-gradient(0deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 50%);
    }
    .images-and-ctas__wrapper {
      margin-left: 0;
      margin-right: 0;
      > div {
        margin: 0;
        &.show-for-large {
          .text-box-slide {
            max-width: none;
            width: auto;
            left: auto;
            right: rem-calc(30);
            bottom: 0;
            z-index: 2;
          }
          &:nth-last-of-type(2) {
            .text-box-slide {
              left: rem-calc(30);
              right: auto;
            }
          }
        }
        &.hide-for-large {
          img {
            @include breakpoint (medium down) {
              width: 100%;
            }
          }
          .text-box-slide {
            @include breakpoint (medium down) {
              padding: 0;
              z-index: 2;
              text-align: center;
            }
            .link-wrapper {
              @include breakpoint (medium down) {
                margin: 0 auto;
                padding-left: 0;
                padding-right: 0;
                width: rem-calc(200);
                transform: translateY(0);
              }
              &:last-of-type {
                @include breakpoint (medium down) {
                  margin-top: rem-calc(10);
                }
              }
            }
          }
        }
      }
    }
  }
  &--two--linked {
    margin-bottom: rem-calc(22);
    .images-and-ctas__wrapper {
      margin-left: 0;
      margin-right: 0;
      > div {
        margin: 0;
        &.show-for-large {
          .text-box-slide {
            max-width: none;
            width: auto;
            left: auto;
            right: rem-calc(30);
            bottom: 0;
          }
          &:nth-of-type(2) {
            .text-box-slide {
              left: rem-calc(30);
              right: auto;
            }
          }
        }
        &.hide-for-large {
          img {
            @include breakpoint (medium down) {
              width: 100%;
            }
          }
          .text-box-slide {
            @include breakpoint (medium down) {
              padding: 0;
              text-align: center;
            }
            a.button {
              @include breakpoint (medium down) {
                margin: 0 auto;
                padding-left: 0;
                padding-right: 0;
                width: rem-calc(200);
              }
              &:last-of-type {
                @include breakpoint (medium down) {
                  margin-top: 0;
                  display: inline-block;
                  width: auto;
                }
              }
            }
          }
        }
      }
      @include text-box-slide();
      .text-box-slide {
        color: #fff;
        bottom: rem-calc(40);
        max-width: 45%;
        @include breakpoint (medium down) {
          max-width: 90%;
          bottom: rem-calc(85);
          text-align: center !important;
        }
        h2 {
          font-weight: 600;
          font-size: rem-calc(40);
          letter-spacing: 0.04em;
          line-height: rem-calc(50);
          text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          @include breakpoint (medium down) {
            font-size: rem-calc(26);
            letter-spacing: 0.03em;
            line-height: rem-calc(36);
          }
        }
        p {
          font-size: rem-calc(18);
          letter-spacing: 0.03em;
          line-height: rem-calc(30);
          padding: 0 20%;
          text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          @include breakpoint (medium down) {
            font-size: rem-calc(16);
            line-height: rem-calc(24);
            padding: 0;
          }
        }
      }
    }
  }
}

.single-img-filmstrip--tezenis {
  * {
    outline: none;
  }
  display: flex;
  margin-bottom: rem-calc(22);
  @include breakpoint (medium down) {
    display: block;
    margin-bottom: rem-calc(-50);
  }
  > div {
    width: 50%;
    @include breakpoint (medium down) {
      width: 100%;
    }
  }
  &__image {
    position: relative;
    .shadow-box {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(0deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 50%);
    }
    @include breakpoint (medium down) {
      z-index: 1;
    }
    img {
      width: 100%;
      @include breakpoint (medium down) {
        padding: rem-calc(0 20);
      }
    }
    @include text-box-slide;
    h2 {
      color: $white;
      text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.16);
      font-size: rem-calc(40);
      line-height: rem-calc(50);
      letter-spacing: 0.04em;
      font-weight: 600;
      text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      max-width: 70%;
      margin: 0 auto;
      @include breakpoint (medium down) {
        font-size: rem-calc(26);
        letter-spacing: 0.03em;
        line-height: rem-calc(32);
        max-width: 65%;
      }
    }
    h4 {
      color: $white;
      font-size: rem-calc(20);
      line-height: rem-calc(30);
      letter-spacing: 0.03em;
      font-weight: 600;
      margin-top: rem-calc(12);
      text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.16);
      @include breakpoint (medium down) {
        font-size: rem-calc(20);
        line-height: rem-calc(28);
      }
    }
  }
  &__filmstrip {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    @include breakpoint (medium down) {
      transform: translateY(-50px);
      padding-top: rem-calc(80);
      padding-left: rem-calc(30);
      padding-right: 0;
    }
    @include breakpoint (small down) {
      padding-left: 0;
      padding-right: 0;
    }
    &.background-gradient {
      filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
      &-pink {
        background: linear-gradient(#f8b6bf 0%, #e77c64 100%);
      }
      &-blue {
        background: linear-gradient(#4a9e9e 0%, #3f5ba4 100%);
      }
      &-yellow {
        background: linear-gradient(#e77c64 0%, #f7c16b 100%);
      }
      &-sale {
        background: linear-gradient(#dc6363 0%, #e77c64 100%);
      }
    }
    h3 {
      font-weight: bold;
      font-size: rem-calc(20);
      letter-spacing: 0.03em;
      line-height: rem-calc(55);
      color: $black;
      margin-bottom: 0;
      padding-left: calc(9% + 15px);
      @include breakpoint (medium down) {
        padding-left: rem-calc(8);
      }
      @include breakpoint (small down) {
        padding-left: rem-calc(30);
      }
    }
  }
  .products-filmstrip {
    padding: 0 9%;
    @include breakpoint (medium down) {
      padding: 0 0 25px 0;
    }
    @include breakpoint (small down) {
      padding: 0 0 25px 20px;
    }
    .slick-slide {
      margin: 0 15px;
      @include breakpoint (medium down) {
        margin: 0 10px;
      }
    }
    .product-info-box {
      .product-text {
        margin-top: rem-calc(10);
        display: block;
        font-weight: bold;
        font-size: rem-calc(14);
        letter-spacing: 0.03em;
        line-height: rem-calc(20);
        color: $black;
        @include breakpoint (medium down) {
          margin-top: rem-calc(5);
          font-size: rem-calc(12);
          letter-spacing: 0.02em;
          line-height: rem-calc(19);
        }
      }
      .price--tezenis span {
        font-weight: 300;
      }
    }
  }
  @include slick-arrows;
}

.text-promo-full-width--tezenis {
  * {
    outline: none;
  }
  //margin-bottom: rem-calc(75);
  margin-bottom: 0;
  width: 100%;
  text-align: center;
  padding: rem-calc(70 0);
  @include breakpoint (medium down) {
    padding: rem-calc(45 15);
    //margin-bottom: rem-calc(60);
    margin-bottom: 0;
  }
  &:hover {
    .link-wrapper {
      a {
        &:after {
          animation: underline .4s forwards 1;
        }
        @keyframes underline {
          0%   {transform: scaleX(1);}
          50%  {transform: scaleX(0);}
          100%  {transform: scaleX(1);}
        }
      }
    }
  }
  img {
    max-width: rem-calc(30);
    margin-bottom: rem-calc(23);
  }
  h4 {
    font-weight: 600;
    font-size: rem-calc(20);
    letter-spacing: 0.03em;
    line-height: rem-calc(30);
    color: $black;
    margin-bottom: rem-calc(8);
    @include breakpoint (medium down) {
      font-size: rem-calc(18);
      line-height: rem-calc(28);
      margin-bottom: rem-calc(25);
    }
  }
  p {
    font-weight: 400;
    font-size: rem-calc(18);
    letter-spacing: 0.03em;
    line-height: rem-calc(26);
    color: $black;
    margin-bottom: rem-calc(20);
    @include breakpoint (medium down) {
      font-size: rem-calc(16);
      line-height: rem-calc(24);
      margin-bottom: rem-calc(25);
    }
  }
  .link-wrapper {
    overflow: hidden;
    display: inline-block;
    padding-bottom: rem-calc(5);
  }
  a {
    font-weight: 400;
    font-size: rem-calc(14);
    letter-spacing: 0.03em;
    line-height: rem-calc(18);
    color: $black;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: rem-calc(-5);
      left: 0;
      width: 100%;
      height: rem-calc(2);
      background-color: $black;
      transform: scaleX(1);
      transform-origin: bottom left;
      transition: all .2s ease;
    }
  }
}

.category-carousel--tezenis {
  color: $black;
  text-align: center;
  overflow: hidden;
  //margin-bottom: rem-calc(90);
  margin-bottom: 0;
  @include breakpoint (medium down) {
    //margin-bottom: rem-calc(60);
    margin-bottom: 0;
  }
  * {
    outline: none;
  }
  h3 {
    font-weight: 600;
    font-size: rem-calc(30);
    letter-spacing: 0.03em;
    line-height: rem-calc(40);
    margin-bottom: rem-calc(13);
    @include breakpoint (medium down) {
      font-size: rem-calc(22);
      line-height: rem-calc(32);
    }
  }
  p {
    font-weight: 400;
    font-size: rem-calc(18);
    line-height: rem-calc(30);
    letter-spacing: 0.03em;
    margin-bottom: rem-calc(30);
    @include breakpoint (medium down) {
      font-size: rem-calc(16);
      line-height: rem-calc(24);
    }
  }
  &__wrapper {
    position: relative;
    width: calc(100% + 12px);
  }
  &__left,
  &__right {
    position: absolute;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    top: 0;
    z-index: 1;
  }
  &__left {
    left: 0;
  }
  &__right {
    right: 0;
  }
  @include slick-arrows;
  .category-carousel {
    margin-left: -6px;
    margin-right: -6px;
    .slick-slide {
      margin: 0 6px;
      @media  (device-width: 834px) {
        width: rem-calc(165);
      }
      @media  (device-width: 768px) {
        width: rem-calc(165);
      }
      @media screen and  (max-device-width: 720px) {
        width: rem-calc(273);
      }
      &.not-clickable {
        a {
          opacity: 0;
        }
      }
    }
  }
}

.category-carousel-slide--tezenis {
  position: relative;
  display: inline-block;
  * {
    outline: none;
  }
  a {
    opacity: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    font-weight: 600;
    font-size: rem-calc(28);
    line-height: 1;
    color: #fff;
    text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    transition: all .2s ease;
    @include breakpoint (medium down) {
      font-size: rem-calc(20);
      text-transform: capitalize;
    }
    &:after {
      opacity: 0;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, .45);
      transition: all .2s ease;
    }
    span {
      position: relative;
      z-index: 1;
      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -5px;
        background-color: white;
        height: rem-calc(2);
        width: 100%;
        opacity: 0;
        transition: all .2s ease;
      }
    }
    &:hover {
      &:after {
        opacity: 1;
      }
      span {
        &:after {
          opacity: 1;
        }
      }
    }
  }
}

.category-grid--tezenis {
  color: $black;
  text-align: center;
  //margin-bottom: rem-calc(90);
  margin-bottom: 0;
  @include breakpoint (medium down) {
    //margin-bottom: rem-calc(60);
    margin-bottom: 0;
  }
  * {
    outline: none;
  }
  h3 {
    font-weight: 600;
    font-size: rem-calc(30);
    letter-spacing: 0.03em;
    line-height: rem-calc(40);
    margin-bottom: rem-calc(13);
    @include breakpoint (medium down) {
      font-size: rem-calc(22);
      line-height: rem-calc(32);
    }
  }
  p {
    font-weight: 400;
    font-size: rem-calc(18);
    line-height: rem-calc(30);
    letter-spacing: 0.03em;
    margin-bottom: rem-calc(30);
    @include breakpoint (medium down) {
      font-size: rem-calc(16);
      line-height: rem-calc(24);
    }
  }
  .category-grid {
    margin-top: rem-calc(-8);
    margin-left: rem-calc(-8);
    margin-right: rem-calc(-8);
    text-align: left;
    @include breakpoint (medium down) {
      margin-top: rem-calc(-3);
      margin-left: rem-calc(-3);
      margin-right: rem-calc(-3);
    }
    > div {
      display: inline-block;
      width: calc(100% / 3);
      padding: rem-calc(8);
      @include breakpoint (medium down) {
        width: calc(100% / 2);
        padding: rem-calc(3);
      }
    }
  }
}

.three-imgs-three-ctas-editorial {
  text-align: center;
  //margin-bottom: rem-calc(90);
  margin-bottom: 0;
  @include breakpoint (small down) {
    //margin-bottom: rem-calc(60);
    margin-bottom: 0;
  }
  * {
    outline: none;
  }
  &.grid-container {
    @include breakpoint (small down) {
      padding-right: 0;
      overflow: hidden;
    }
  }
  h3 {
    font-weight: 500;
    font-size: rem-calc(30);
    letter-spacing: 0.03em;
    line-height: rem-calc(40);
    margin-bottom: rem-calc(20);
    @include breakpoint (small down) {
      font-size: rem-calc(22);
      line-height: rem-calc(32);
      margin-bottom: rem-calc(7);
    }
  }
  p {
    font-weight: 400;
    font-size: rem-calc(18);
    letter-spacing: 0.03em;
    line-height: rem-calc(30);
    margin-bottom: rem-calc(40);
    @include breakpoint (small down) {
      font-size: rem-calc(16);
      line-height: rem-calc(22);
      margin-bottom: rem-calc(15);
    }
  }
  &__wrapper {
    margin-left: rem-calc(-10);
    margin-right: rem-calc(-10);
    display: flex;
  }
  &__single {
    margin:rem-calc(0 10);
    .text-box {
      @include breakpoint (small down) {
        text-align: left;
        padding-bottom: rem-calc(20);
      }
      &:hover {
        .link-wrapper {
          a {
            &:after {
              animation: underline .35s forwards 1;
            }
            @keyframes underline {
              0%   {transform: scaleX(1);}
              50%  {transform: scaleX(0);}
              100%  {transform: scaleX(1);}
            }
          }
        }
      }
      h4 {
        font-weight: 500;
        font-size: rem-calc(20);
        letter-spacing: 0.03em;
        line-height: rem-calc(30);
        margin: rem-calc(20 0);
        @include breakpoint (small down) {
          font-size: rem-calc(18);
          line-height: rem-calc(24);
          margin: rem-calc(17 0);
        }
      }
      .link-wrapper {
        overflow: hidden;
        display: inline-block;
        padding-bottom: rem-calc(5);
      }
      a {
        border-bottom: none;
        font-weight: 500;
        font-size: rem-calc(14);
        letter-spacing: 0.03em;
        line-height: rem-calc(18);
        position: relative;
        @include breakpoint (small down) {
          font-size: rem-calc(14);
          line-height: rem-calc(18);
        }
        &:after {
          content: '';
          position: absolute;
          bottom: rem-calc(-5);
          left: 0;
          width: 100%;
          height: rem-calc(2);
          background-color: $black;
          transform: scaleX(1);
          transform-origin: bottom left;
          transition: all .2s ease;
        }
      }
    }
  }
  .slick-dots{
    li {
      width: rem-calc(10);
      height: rem-calc(10);
      margin: rem-calc(0 5);
      button {
        &:before {
          width: rem-calc(10);
          height: rem-calc(10);
          border: none;
        }
      }
      &.slick-active {
        button {
          &:before {
            width: rem-calc(10);
            height: rem-calc(10);
            border: 1px solid transparent;
          }
        }
      }
    }
  }
}

.product-filmstrip--tezenis {
  //margin-bottom: rem-calc(90);
  margin-bottom: 0;
  @include breakpoint (medium down) {
    //margin-bottom: rem-calc(60);
    margin-bottom: 0;
  }
  * {
    outline: none;
  }
  h3 {
    text-align: center;
    font-weight: 600;
    font-size: rem-calc(30);
    letter-spacing: 0.03em;
    line-height: rem-calc(40);
    margin-bottom: rem-calc(25);
    @include breakpoint (medium down) {
      font-weight: 500;
      font-size: 20px;
      line-height: 40px;
      margin-bottom: rem-calc(30);
    }
  }
  &__wrapper {
    position: relative;
    @include slick-arrows;
    .prev-button--still,
    .next-button--still {
      @include breakpoint (medium down) {
        display: none !important;
      }
    }
    .slick-slide {
      margin: rem-calc(0 20);
      @include breakpoint (medium down) {
        margin: rem-calc(0 7);
      }
      &.not-clickable {
        a {
          opacity: 0;
        }
      }
    }
    .product-slide {
      .product-info-box {
        .product-text {
          display: none;
        }
      }
    }
  }
  &__left,
  &__right {
    position: absolute;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    top: 0;
    z-index: 1;
  }
  &__left {
    left: 0;
  }
  &__right {
    right: 0;
  }
}

.product-filmstrip-not-logged--tezenis {
  padding: rem-calc(85 0 130);
  //margin-bottom: rem-calc(90);
  margin-bottom: 0;
  @include breakpoint (medium down) {
    //margin-bottom: rem-calc(60);
    margin-bottom: 0;
    padding: rem-calc(50 0 55);
  }
  * {
    outline: none;
  }
  h3 {
    font-weight: 600;
    font-size: rem-calc(30);
    letter-spacing: 0.03em;
    line-height: rem-calc(40);
    text-align: center;
    color: #fff;
    margin-bottom: rem-calc(80);
    @include breakpoint (medium down) {
      font-size: rem-calc(22);
      line-height: rem-calc(32);
      margin-bottom: rem-calc(25);
    }
  }
  &__filmstrip {
    &.grid-container {
      @include breakpoint (medium down) {
        padding-right: 0;
        overflow: hidden;
      }
    }
  }
  &__wrapper {
    position: relative;
    @include slick-arrows;
    .prev-button {
      left: rem-calc(28);
    }
    .next-button {
      right: rem-calc(28);
    }
    .slick-slide {
      margin: rem-calc(0 28);
      @include breakpoint (medium down) {
        margin: rem-calc(0 9);
      }
      .product-slide {
        .product-info-box {
          padding-top: rem-calc(20);
          .product-text {
            font-weight: 400;
            font-size: rem-calc(14);
            letter-spacing: 0.03em;
            line-height: rem-calc(22);
            @include breakpoint (medium down) {
              letter-spacing: 0.02em;
            }
          }
        }
      }
    }
  }
}

.scrolling-text--tezenis {
  padding: rem-calc(22 0);
  .scrollingTextWrap {
    width: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
    .scrollWrap {
      overflow: hidden;
      position: relative;
      height: rem-calc(44);
      padding: 0% 0 0% 0;
      .scrollInner {
        display: block;
        width: 450%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        overflow: hidden;
        animation: marquee 26s linear infinite;
        white-space: nowrap;
        word-break: keep-all;
        word-wrap: normal;
        @media only screen and (max-width: 1024px) {
          animation-duration: 10s;
        }
        @keyframes marquee {
          0% {
            left: 0;
          }
          100% {
            left: -100%;
          }
        }
        span {
          font-weight: 600;
          font-size: rem-calc(20);
          letter-spacing: 0.03em;
          color: #fff;
          @include breakpoint (medium down) {
            font-weight: 500;
          }
          @include breakpoint (small down) {
            font-size: rem-calc(15);
          }
          &:nth-of-type(2) {
            @include breakpoint (medium down) {
              display: none;
            }
          }
        }
      }
    }
  }
}

.spacer {
  &--large {
    height: rem-calc(120);
    @include breakpoint (medium down) {
      height: rem-calc(60);
    }
  }
  &--medium {
    height: rem-calc(90);
    @include breakpoint (medium down) {
      height: rem-calc(45);
    }
  }
}

.above-all {
  z-index: 1;
}

.experience-assets-twoImgsTwoCtas .shadow-box {
  @include breakpoint (medium down) {
    width: calc(100% - 40px) !important;
    top: auto !important;
    left: auto !important;
    display: inline-block;
  }
}

.single-img-filmstrip--tezenis__image .shadow-box {
  @include breakpoint (medium down) {
    width: calc(100% - 40px) !important;
    margin: rem-calc(0 20px);
  }
}

.experience-assets-twoImgsTwoCtas .shadow-box {
  @include breakpoint(medium down) {
    width: calc(100% - 40px) !important;
    top: auto !important;
    left: auto !important;
    display: inline-block;
  }
}

.single-img-filmstrip--tezenis__image .shadow-box {
  @include breakpoint(medium down) {
    width: calc(100% - 40px) !important;
    margin: rem-calc(0 20px);
  }
}

.experience-assets-twoImgsTwoCtasLinked .images-and-ctas__wrapper {
  & > div:not(.text-box-slide) .image-link {
    z-index: 4;
  }
  .tablet-device {
    position: relative;
    justify-content: center;
    align-items: center;
    -webkit-justify-content: center;
    -webkit-align-items: center;
    display: none;
    @include breakpoint (tablet up) {
      display: inline-flex;
      display: -webkit-inline-flex;
    }
  }
}

.experience-assets-twoImgsTwoCtasLinked .images-and-ctas--two .images-and-ctas__wrapper {
  & > div .text-box-slide {
    text-align: center;
    display: inline-table;
    bottom: 0;
    left: auto;
    z-index: 5;
    right: 0;
  }
  & > div img {
    height: auto !important;
  }
  @media print, screen and (max-width: 47.99875em) {
    & > div img {
      width: 100%;
    }
    & > div .text-box-slide .link-wrapper {
      margin: auto;
      min-width: 200px;
    }
    & > div .text-box-slide .link-wrapper:last-child {
      margin-top: 10px;
    }
  }
  & > div:nth-child(1) > div.text-box-slide {
    right: 5%;
  }
  & > div:nth-child(2) > div.text-box-slide {
    left: 5%;
  }
}

.wrapper-newsletter-ww{
  position: relative;
  #newsletterFooterForm {
    .input-group {
      margin-bottom: 0;
    }
    @include newsletter-email;
    svg {
      width: rem-calc(17);
      height: rem-calc(12);
      position: absolute;
      top: 50%;
      left: rem-calc(17);
      transform: translateY(-50%);
      @media screen and (max-width:1024px) {
        left: rem-calc(10);
      }
    }
    #submitNewsletter {
      text-decoration: none;
      color: $white;
      background-color: $black;
      height: calc(100% - 14px);
      top: 50%;
      transform: translateY(-50%);
      right: rem-calc(7);
      border-radius: rem-calc(3);
      @include breakpoint(small only) {
        padding-left: rem-calc(10);
        padding-right: rem-calc(10);
      }
    }
  }

  img{
    width: 100%;
  }

  .text-fields-box{
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translateY(-50%);
    @include breakpoint (large up){
      width: rem-calc(450);
      left: 15%;
      .input-group{
        max-width: rem-calc(300);
        margin: 0 auto;
      }
    }
    #email-errors-newsletter-pd{
      @include breakpoint (large up){
        max-width: rem-calc(300);
      }
      margin: 0 auto;
      text-align: left;
      .form-error.is-visible{
        position: unset!important;
        background-color: transparent!important;
      }
    }
    @include breakpoint (medium down){
      transform: translate(-50%, -50%);
      width: 100%;
      padding: rem-calc(0 20);
    }
    .newsletter-title-ww{
      font-size: rem-calc(20);
      font-weight: 700;
      margin: rem-calc(17 0);
      letter-spacing: 0.3px;
    }
    svg{
      height: 20px;
      width: 25px;
    }
  }
}
