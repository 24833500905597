.card-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    overflow: hidden;
    z-index: 0;
    border-radius: rem-calc(16);
    opacity: 1;
    transition: opacity 0.1s linear;

    &__label-cta {
        position: absolute;
        bottom: rem-calc(12);
        left: rem-calc(16);
    }

    &--comingSoonBox {
        background:  linear-gradient(0deg, rgba(130, 193, 189, 0.4), rgba(130, 193, 189, 0.4));
    }

    &--soldoutBox {
        background: rgba($color: $white, $alpha: 0.4);
    }
}