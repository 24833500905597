.accordion {
    &.accordion-page {
    margin-left: 0;
    background: $white;
    list-style-type: none;
  
  &[disabled] .accordion-title {
    cursor: not-allowed;
  }
    .accordion-item {
      ul, li {
        display: block;
        list-style-type: none;
        border-bottom: 0;
        margin-left: 0;
        padding: 0;
      }
  
      ul {
        padding-bottom: rem-calc(20);
      }
  
      &:first-child > :first-child {
        border-radius: 0 0 0 0;
      }
  
  
      &:last-child > :last-child {
        border-radius: 0 0 0 0;
      }
  
      &:last-child.active {
        .accordion-title {
          border-bottom: 0;
        }
      }
  
      &:last-child {
        .accordion-title {
          border-bottom: rem-calc(1) solid $border-gray;
        }
      }
  
    
  
    .accordion-title {
      position: relative;
      display: block;
      padding: rem-calc(20 30 20 10);
      border: 0;
      border-top: 1px solid $border-gray;
      border-bottom: 0;
      background-color: $white;
      -webkit-transition: opacity .5s cubic-bezier(.25, .46, .45, .94);
      font-size: rem-calc(14);
      line-height: rem-calc(21);
      font-weight: 600;
  
  
      &:hover,
      &:focus {
        background-color: $white;
      }
    }
}
    .is-active > .accordion-title::before {
      content: '\2013';
    }
  
    .accordion-content {
      border-bottom: none;
      display: none;
      margin-bottom: rem-calc(24);
      margin-top: rem-calc(20);
      padding: rem-calc(0 40 0 32);
  
  
      p {
        &.share {
          font-style: normal;
          color: $medium-gray;
          font-size: rem-calc(11);
        }
  
        a {
          text-decoration: none;
          border-bottom: rem-calc(1) solid transparent;
  
          &:hover {
            border-color: $dark-gray;
          }
        }
      }
  
  
    }
  
    :last-child > .accordion-content:last-child {
      border-bottom: rem-calc(1) solid $border-gray;
    }
}
  
    //FOOTER ONLY MOBILE
    &.initialized {
        .accordion-title {
          border-bottom: 1px solid $border-gray;
          cursor: pointer;
          display: block;
          font-size: rem-calc(14);
          font-weight: 600;
          line-height: rem-calc(21);
          letter-spacing: rem-calc(0.2);
          padding: rem-calc(20 16 20 16);
          position: relative;
          width: 100%;
            
          &::after {
            content: '';
            font-weight: $global-weight-black;
            font-size: rem-calc(24);
            position: absolute;
            right: rem-calc(0);
            top: 44%;
            transform: translateY(-50%);
          }
        }

        .accordion-item {
          border-bottom: 1px solid $light-gray;
            &.active {
                .accordion-title {
                    &::after {
                        content: '';
                        transform: rotate(180deg);
                    }
                }
            }
        }

        .accordion-content {
          border-bottom: none;
          display: none;
          margin-bottom: rem-calc(24);
          padding: rem-calc(0 40 0 32);

            li {
                margin-bottom: 0;
                padding: rem-calc(16);

                &:not(:last-of-type) {
                    border-bottom: 1px solid $border-gray;
                }

                a {
                  font-size: rem-calc(14);
                  font-weight: 400;
                  line-height: rem-calc(21);
                }
            }
        }
    }
}
