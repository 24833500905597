.banner-full-width {
    .pd-container {
        width: 100%;
        max-width: 100%;
        overflow: hidden;
        background-repeat: no-repeat;
        margin: 0;
        padding: rem-calc(10 0);
        background-position: top center;
        background-size: cover;
        cursor: pointer;
    }

    .alignItems {
        text-align: center;
        height: 100%;
    }
    .svgDimensions {
        width: 100%;
        margin-bottom: rem-calc(-30);
        @include breakpoint(small only) {
            margin-bottom: rem-calc(-8);
        }
    }
    .svgImageOptions {
        width: 100%;
        height: 100%;
    }


    .video-section {
        object-fit: cover;
        object-position: center;
        width: 100%;
        position: absolute;
        overflow: hidden;
        z-index: -1;
    }
    .fontH1-responsive {
        color: white;
        @include breakpoint(small only) {
            font-size: rem-calc(30);
            font-weight: 700;
            margin-top: rem-calc(220);
            letter-spacing: rem-calc(0.9);
            margin-bottom: rem-calc(0);
        }

        @include breakpoint(medium up) {
            font-size: rem-calc(60);
            font-weight: 700;
            letter-spacing: 0.03em;
            line-height: rem-calc(76);
        }
    }

    #btnContainer {
        @include breakpoint(medium up) {
            display: flex;
            flex-flow: row;
            width: rem-calc(700);
            justify-content: space-around;
            @include breakpoint(small only) {
                flex-flow: column;
            }

        }
    }

    .btn-pd-style {
        display: block;
        .text-wrapper {
            overflow: hidden;
        }
    }

    .fontH4-responsive {
        color: white;
        @include breakpoint(small only) {
            font-size: rem-calc(20);
            font-weight: 600;
        }

        @include breakpoint(medium up) {
            font-size: rem-calc(20);
            font-weight: 700;
            letter-spacing: 0.03em;
            line-height: rem-calc(76);
        }
    }

    .wrapper-pd {
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        padding-bottom: rem-calc(40);
        height: 100%;
    }

    .btn-pd-style {
        box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.16);
        margin: rem-calc(10);
        max-height: rem-calc(40);
        width: rem-calc(240);
        height: rem-calc(40);
        border-radius: rem-calc(50);
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        &:hover {
            cursor: pointer;
            .btn-text {
                transform: translateY(-103%);
            }
        }
        @include breakpoint(small only) {
            margin-bottom: rem-calc(20)
        }
        a[class^="custom-pd-link"] {
            overflow: hidden;
            color: inherit;
        }
        .btn-text {
            position: relative;
            display: inline-block;
            font-size: rem-calc(14);
            transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
            &:before {
                position: absolute;
                top: 100%;
                content: attr(data-hover);
                transform: translate3d(0, 0, 0);
            }
        }
    }

    .custom-pd-link {
        display: inline-block;
    }

    .scrollingTextWrap {
        width: 100%;
        z-index: 1;
    }

    .scrollWrap {
        overflow: hidden;
        padding: 0;
    }

    .scrollInner {
        color: white;
        width: max-content;
        white-space: nowrap;
        overflow: hidden;
    }

    .scrollableText {
        animation: scrollAnimation 5s linear infinite;
        float: left;
        @include breakpoint(small only) {
            font-size: rem-calc(40);
            font-weight: 700;
            letter-spacing: 0.03em;
            line-height: rem-calc(76);
        }

        @include breakpoint(medium up) {
            font-size: rem-calc(60);
            font-weight: 700;
            letter-spacing: 0.03em;
            line-height: rem-calc(76);
        }
    }

    @keyframes scrollAnimation {
        0% {
            -webkit-transform: translateX(0px);
            transform: translateX(0px);
        }
        100% {
            -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
        }
    }


    .cta-link, .svg-link-custom {
        &:hover {
            border-color: none;
            border: 0;
        }
    }

    .svg-link-custom,:not(.no-hover):hover,
    .svg-link-custom,.force-hover {
        border: 0;
    }
    
    .cta-link:nth-child(1):nth-last-child(1) {
        @include breakpoint(small only) {
            display: none;
        }
    }


}
