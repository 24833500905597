.fnPersModal-options {
    .content-positions {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        padding: rem-calc(16 0 16 16);
        overflow-x: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }

        @include breakpoint (large up) {
            padding: rem-calc(8 0 24);
            overflow: visible;
            flex-wrap: wrap;
        }

        .single-position {
            display: block;
            margin: rem-calc(0 8 0 0);

            @include breakpoint (large up) {
                margin-top: rem-calc(8);
            }
        }

        .primary-tag {
            display: block;
            margin: 0;
            white-space: nowrap;
        }
    }

    .content-text-input {
        padding: rem-calc(8 16);

        @include breakpoint (large up) {
            padding: rem-calc(16 0 8);
        }

        .label-inside {
            margin: 0;
        }

        .limitNumberCounter {
            display: block;
            font-size: rem-calc(12);
            line-height: rem-calc(18);
            color: $medium-gray;
            letter-spacing: 0.02em;
            margin: 0;
            padding-top: rem-calc(8);
        }
    }

    .fontFamilySection {
        padding: rem-calc(16 0 8 16);

        @include breakpoint (large up) {
            padding: rem-calc(1 0 24);
        }

        &-wrp {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            overflow-x: auto;
            -ms-overflow-style: none;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }

            @include breakpoint (large up) {
                overflow: visible;
                flex-wrap: wrap;
            }
        }

        &-item {
            margin: rem-calc(0 8 0 0);

            @include breakpoint (large up) {
                margin-top: rem-calc(8);
            }
        }
    }

    .section-symbol {
        padding: rem-calc(16 0 8 16);

        @include breakpoint (large up) {
            padding: rem-calc(8 0 24);
        }

        &-wrp {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            overflow-x: auto;
            -ms-overflow-style: none;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }

            @include breakpoint (large up) {
                flex-wrap: wrap;
                overflow: visible;
            }
        }
    }

    .selectColorSection {
        padding: rem-calc(16 0 8 16);

        @include breakpoint (large up) {
            padding: rem-calc(8 0 24);
        }

        &-wrp {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            overflow-x: auto;
            -ms-overflow-style: none;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }

            @include breakpoint (large up) {
                flex-wrap: wrap;
                overflow: visible;
            }
        }

        &-item {
            margin: rem-calc(0 20 0 0);

            @include breakpoint (large up) {
                margin-top: rem-calc(8);
            }
        }
    }

    .labelForRadio {
        display: inline-flex;
        position: relative;
    }

    input[type="radio"] {
        height: 0;
        left: 0;
        margin: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        width: 0;

        &:checked + .primary-tag,
        &:checked + .customButtonInput {
            color: $black;
            background-color: $water-green-light;
            border-color: $water-green;
        }

        &:checked + .customColorButton {
            border-color: $black;
        }
    }

    .customButtonInput {
        display: block;
        background: transparent;
        border-radius: rem-calc(4);
        color: $black;
        border: rem-calc(1) solid $light-gray;
        cursor: pointer;
        font-size: rem-calc(18);
        line-height: rem-calc(48);
        font-weight: $global-weight-normal;
        margin: 0;
        width: rem-calc(48);
        text-align: center;
    }

    .symbol {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background: transparent;
        border-radius: rem-calc(4);
        color: $black;
        border: rem-calc(1) solid $light-gray;
        cursor: pointer;
        width: rem-calc(48);
        flex: 0 0 rem-calc(48);
        height: rem-calc(48);
        text-align: center;
        margin: rem-calc(0 8 0 0);

        @include breakpoint (large up) {
            margin-top: rem-calc(8);
        }

        img,
        svg {
            display: block;
            width: rem-calc(32);
            height: rem-calc(32);
        }

        &.active {
            background-color: $water-green-light;
            border-color: $water-green;
        }
    }

    .badWords,.badChar {
        font-size: rem-calc(14);
        line-height: rem-calc(21);
        color: $error;
        display: none;
    }

    .badInput,.badInputChar {
        input {
            background-color: transparent;
            border-bottom-color: $error;
        }
    }

    .customColorButton {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: rem-calc(30);
        flex: 0 0 rem-calc(30);
        height: rem-calc(30);
        border: rem-calc(1) solid transparent;
        border-radius: 50%;

        &-core {
            height: rem-calc(24);
            width: rem-calc(24);
            border-radius: 50%;
            display: block;
            cursor: pointer;
        }
    }
}
