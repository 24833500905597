h3.title-refund-login{
    font-size: rem-calc(30);
    font-weight: 600;
    padding: 0;
    margin: 0;
    @include breakpoint (small down) {
        font-size: rem-calc(16);
        padding-bottom: rem-calc(15);
    }
}
.wrapper-refund-login{
    padding: rem-calc(6 6 0 6);
    max-width: rem-calc(970);
    @include breakpoint (small down) {
        padding: rem-calc(0);
    }
   
    h4{
        font-size: rem-calc(20);
        font-weight: 600;
        @include breakpoint (small down) {
            font-size: rem-calc(14);
        }
    }
    h5{
        font-weight: 400;
        padding-bottom: rem-calc(20);
      
        @include breakpoint (small down) {
            font-size: rem-calc(14);
            padding-bottom: rem-calc(10);
        }
    }

    .note-message-required{
        font-size: rem-calc(12);
        font-weight: 600;
        line-height: rem-calc(20);
        padding-bottom: rem-calc(10);
        margin: 0;
        text-transform: uppercase;
        @include breakpoint (small down) {
            font-size: rem-calc(11);
        }
    }
    .box-note-message-required{
        font-size: rem-calc(12);
        line-height: rem-calc(17);
        padding: rem-calc(10 40 12 10);
        background-color: $white;
        box-shadow: rem-calc(10 10 10 -6) rgba(0, 0, 0, 0.1);
        margin-bottom: rem-calc(10);
        width: 100%;
        position: relative;
        @include breakpoint (small down) {
            margin-bottom: rem-calc(24);
        }
        img{
            //padding-right: rem-calc(10);
            position: absolute;
            left: rem-calc(10);
            top: rem-calc(14);
        }
        p{
            margin-left: rem-calc(20);
        }
    }
    #iban {
        text-transform: uppercase;
    }
    .field-with-tooltip{
        position: relative;
        .register-form-final-padding-right{
            width: 90%;
        }
        img {
            position: absolute;
            top: 35%;
            right: 0;
    
        }
    }
}
