.newsletter-popup {
  .newsletter-email-spacer{
    margin-bottom: rem-calc(18);
  }

  .newsletter-select-spacer{
    margin-bottom: rem-calc(28);
  }

  .newsletter-option-spacer{
    margin-bottom: rem-calc(16);
    @include breakpoint (small down){
      margin-bottom: rem-calc(24);
    }
  }
  // Modal
  &.tingle-modal {
    z-index: 1000; //override footer
    background: rgba(130, 193, 189, 0.6);
    backdrop-filter: blur(50px);

    .tingle-modal-box {
      max-width: 73vw;
      box-shadow: none;
      @include breakpoint (medium up) {
        min-height: 74.87vh;
        max-height: 74.87vh;
        height: 74.87vh;
      }

      @include breakpoint (small down){
        max-width: unset;
        max-height: unset;
        width: 100vw;
        height: 100dvh;
        box-shadow: none;
      }

      &__content {
        padding: 0;
      }
    }

    .close-button{
      content: url(../../images/tzn-close-icon.svg);
    }
  }

  &__content {
    padding: 0;
    display: flex;
    flex-direction: column;
    height: 100%;

    &-cell {
      overflow: auto;
      padding: rem-calc(16 16 0);
      @include breakpoint(medium up) {
        height: 74.87vh;
        overflow: auto;
        padding: rem-calc(40 40 0);
      }
    }

    .newsletterGender {
      margin-bottom: rem-calc(16);
    }

    .title {
      font-size: rem-calc(14);
      font-weight: 500;
      line-height: rem-calc(21);
      letter-spacing: rem-calc(0.2);

      @include breakpoint(medium up) {
        padding: rem-calc(40 0 16 0);
        margin-bottom: 0;
      }
      @include breakpoint(medium down){
        margin: rem-calc(16 0 40 0);
        width: rem-calc(232);
      }

      html.flexbox & {
        margin-top: 0;
        @include breakpoint (medium down){
            margin-top: rem-calc(16);
        }
      }

    }

    .email-popup-info {
        font-size: rem-calc(14);
        font-weight: 400;
        line-height: rem-calc(21);
        letter-spacing: rem-calc(0.2);
        color: rgba(28, 28, 28, 1);
        margin-bottom: rem-calc(16);
        @include breakpoint (medium down){
            padding: 0;
            margin-bottom: rem-calc(16);
        }
      }

    form {
      .form-control-label {
        font-size: rem-calc(12);
        font-weight: 400;
        line-height: rem-calc(18);
        letter-spacing: rem-calc(0.2);
        color: rgba(100, 100, 100, 1);
        top: rem-calc(-18);
      }
      .popup-privacy-policy-helper {
        p {
            font-size: rem-calc(12);
            font-weight: 400;
            line-height: rem-calc(18);
            letter-spacing: rem-calc(0.2);
            color: black;
          margin-bottom: rem-calc(24);
          @include breakpoint (medium down){
            margin-bottom: rem-calc(80);
          }
        }
        a{
          font-weight: 400;
        }
      }

      .email-popup-consent {
        overflow-wrap: break-word;
        font-size: rem-calc(12) !important;
        font-weight: 400;
        line-height: rem-calc(18);
        letter-spacing: rem-calc(0.2);
        color: rgba(0, 0, 0, 1);
        margin-bottom: rem-calc(16);
          @include breakpoint (medium down){
            margin-bottom: rem-calc(0);
          }
        a{
          font-weight: 400;
        }
      }

      .mrmrsDescription {
        margin: 0;

        p {
          margin: 0;
        }
      }

      .mrmrsChoice {
        margin-bottom: rem-calc(16);
      }

      .boolean-field {
        &:last-of-type {
          padding-right: 0;
        }
      }

      #email{
        font-size: rem-calc(14);
        font-weight: 400;
        line-height: rem-calc(21);
        letter-spacing: rem-calc(0.2);
        color: rgba(28, 28, 28, 1);
        &.is-invalid-input:not(:focus) {
          outline: none;
          border: none;
          border-bottom: rem-calc(1) solid rgba(100, 100, 100, 1);
        }
        &.is-invalid-input:focus {
          outline: none;
          border: none;
          border-bottom: rem-calc(1) solid #f66 !important;
        }
        &:focus{
          border-bottom: rem-calc(2) solid $dark-gray !important;
        }
      }

      #newsletterGender{
        margin-top: 0;
        margin-bottom: 0;
        &.is-invalid-input:not(:focus) {
          outline: none;
          border: none;
          border-bottom: rem-calc(1) solid $error;
        }
        &.is-invalid-input:focus {
          outline: none;
          border: none;
          border-bottom: rem-calc(1) solid rgba(100, 100, 100, 1);
        }
        @include breakpoint (medium down){
        }
        &:focus{
            outline: none;
            border-bottom: rem-calc(2) solid $dark-gray !important;
        }
      }

      #newsletterCountry{
        margin-top: 0;
        margin-bottom: 0;
        &.is-invalid-input:not(:focus) {
          outline: none;
          border: none;
          border-bottom:rem-calc(1) solid $error;
        }
        &.is-invalid-input:focus {
          outline: none;
          border: none;
          border-bottom: rem-calc(1) solid rgba(100, 100, 100, 1);
        }
        &:focus{
            outline: none;
            border-bottom: rem-calc(2) solid $dark-gray !important;
        }
        .form-control-label{
            margin-top: 0;
            margin-bottom: rem-calc(8);
        }
      }

      .promotions-targeted{
        @include breakpoint (small down){
          margin-bottom: rem-calc(11);
        }
      }

      .button {
        width: 100%;
        margin-bottom: 0;
      }

      .mandatory-hint {
        display: none;
        margin: 0;

        p {
          margin: 0;
        }
      }
    }

    .input-error-group {
      @include breakpoint(medium up) {
        text-align: right;
      }
    }
    .promotions, .promotions-targeted{
      .slider.round{
        width: rem-calc(49);
        height: rem-calc(24);
        top: rem-calc(-14);
        @include breakpoint(medium up) {
          width: rem-calc(32);
          height: rem-calc(16);
          left: unset;
          right: 0;
        }

        &::before {
          top: rem-calc(2);
          width: rem-calc(20);
          height: rem-calc(20);
          left: rem-calc(3);
          @include breakpoint(medium up) {
            width: rem-calc(12);
            height: rem-calc(12);
            left: rem-calc(2);
          }
        }
      }
    }
  }
    .slider.round:before {
        @include breakpoint (medium down){
            height: rem-calc(24);
            width: rem-calc(24);
            top: rem-calc(4);
        }
    }

    input:checked + .slider {
      background-color: $water-green;
    }

    input:checked + .slider:before {
        background: #fff;
        left: 0;
        transform: translateX(#{rem-calc(16)});
        @include breakpoint (medium down){
          transform: translateX(#{rem-calc(23)});
          left: rem-calc(4);
        }
    }
}

.cc-height100 {
  height: 100%;
  &_flex {
    display: flex;
    flex-direction: column;
  }
}

.cc-newsletterPopup {
  @include breakpoint (medium up) {
    &-grid {
      overflow: hidden;
    }
  }
  &-picture {
    img {
      height: 100%;
      object-fit: cover;
      position: fixed;
      width: 50%;
    }
  }
  &-title {
    margin-bottom: rem-calc(32);
    font-size: rem-calc(16);
    font-weight: $global-weight-dark;
    display: flex;
    gap: rem-calc(16);
    justify-content: space-between;
    align-items: flex-start;
    @include breakpoint(medium up) {
      margin-bottom: rem-calc(16);
      font-size: rem-calc(14);
      line-height: rem-calc(22);
    }
  }
  &-form {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .chosen-container {
      padding-top: rem-calc(8);
    }
  }
  &-close {
    flex: 0 0 auto;
    line-height: 0;
    cursor: pointer;
  }
  &-description {
    font-size: rem-calc(14);
    line-height: rem-calc(21);
    letter-spacing: rem-calc(0.2);

    &_success {
      display: flex;
      color: $success;
      font-weight: $global-weight-dark;
    }

    &-icon {
      margin-right: rem-calc(16);
      padding: rem-calc(1);
    }
  }
  &-btnCell,
  &-btnSuccess {
    margin-top: auto;
    margin-bottom: rem-calc(16);
    @include breakpoint(medium up) {
      margin-bottom: rem-calc(40);
    }
  }
  &-formGroup {
    position: relative;
    margin-bottom: rem-calc(16);
    @include breakpoint(medium up) {
      margin-bottom: 0;
    }

    &-label {
      position: unset;
    }

    &-slider {
      margin-bottom: 0;

      &-wrapper {
        @include breakpoint(medium up) {
          width: 100%;
          padding-right: rem-calc(4);
        }
      }
    }

    .errors-list span {
      margin-top: rem-calc(8);
      margin-bottom: 0;
      @include breakpoint(medium up) {
        margin-top: rem-calc(-12);
        margin-bottom: rem-calc(4);
      }
    }
  }
  .select-chosen {
    margin-bottom: 0;
    &.is-invalid-input {
      border: rem-calc(1) solid transparent;
      border-bottom-color: $error;
    }
  }

  .errors-list {
    margin-top: 0 !important; // To override default behavior
  }
}


.already-inserted-information {
  font-size: rem-calc(12);
  font-weight: $global-weight-dark;
  font-family: $body-font-family;
}

.selected-country-label {
  margin-top: rem-calc(8);
}

.registrationFormStepTwo {
  &.already-registered-form {
    .background-edit {
      background: $over-gray;
      width: calc(100% - #{rem-calc(40)});
      border-radius: rem-calc(8);
      padding: rem-calc(16);
      margin-bottom: rem-calc(20);
      @media (min-width: 768px) and (max-width: 1023px) {
        width: 100%;
      }
      @include breakpoint (small down) {
        width: 97% ;
      }
    }
  }
}


.written-email-label {
  @media (min-width: 768px) and (max-width: 1023px) {
    overflow-wrap: break-word;
  }
}
.already-registered-text {
  font-size: rem-calc(14);
  line-height: rem-calc(21);
  font-weight: $global-weight-dark;
  font-family: $body-font-family;
}

.already-used-email {
  color: $success;
  margin-left: rem-calc(8);
}

.bolded {
  font-weight: $global-weight-dark;
}

.more-information {
  font-size: rem-calc(12);
  line-height: rem-calc(18);
  font-weight: $global-weight-normal;
  font-family: $body-font-family;
  @include breakpoint (small down) {
    margin-left: rem-calc(4);
  }
  .btn-modal-link {
    text-decoration: underline;
    font-weight: $global-weight-normal;
  }
}

.newsletter-popup-button {
  &.newsletter-popup-continue {
    .newsletter-continue {
      margin-top: rem-calc(80);
      position: absolute;
      bottom: 0;
      width: 42%;
      margin-bottom: rem-calc(40);
      @media (min-width: 768px) and (max-width: 1023px) {
        width: 40%;
      }
      @include breakpoint (small down) {
        width: 90%;
        margin-bottom: rem-calc(24);
      }
      @include breakpoint (xxlarge only) {
        width: 44.5%;
      }
    }
  }
}
