.breadcrumb {
    list-style: none;
    margin-bottom: 0;

    &-item {
        display: inline;
    }

    span {
        display: inline-block;
        padding: rem-calc(0 4);
    }
}
